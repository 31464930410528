import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import Swal from 'sweetalert2';
import { BsSearch } from 'react-icons/bs';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import StoresController from '../../controllers/storesController';
import { useLoading } from '../../../../../hooks/loading';

const SearchBarStyled = styled.input`
	padding: 21px 24px;
	border-radius: 8px;
	background: 'white';
	border: none;
	color: rgba(10, 10, 10, 0.8);
`;

interface ISearchStore {
	margin: string;
	type: string;
	color: string;
	titleColor: string;
	title: string;
}

interface IStore {
	display_loja: string;
	link_loja: string;
	logo_loja: string;
	nome_loja: string;
	boleto_pop: boolean;
}

interface IStoreState {
	partners: JSX.Element[];
	loading: boolean;
	filteredPartners: JSX.Element[];
	queryString: string;
}

export function SearchStore({
	margin,
	type,
	color,
	titleColor,
	title,
}: ISearchStore) {
	const { setIsLoading } = useLoading();

	const [filter, setFilter] = useState<IStoreState>({
		loading: true,
		partners: [],
		filteredPartners: [],
		queryString: '',
	});

	// eslint-disable-next-line
	useEffect(() => {
		getPartners();
		setIsLoading(filter.loading);
	}, []);

	useEffect(() => {
		setIsLoading(filter.loading);
	}, [filter.loading]);

	const responsiveOwl = {
		responsive: {
			0: {
				items: type === 'aceita' ? 2 : 1,
				center: true,
			},
			461: {
				items: type === 'aceita' ? 3 : 2,
				center: true,
				margin: 10,
			},
			1024: {
				items: type === 'aceita' ? 4 : 2,
				margin: 10,
			},
			1224: {
				items: type === 'aceita' ? 5 : 3,
				margin: 10,
			},
			1424: {
				items: type === 'aceita' ? 6 : 4,
				margin: 10,
			},
			1624: {
				items: type === 'aceita' ? 7 : 5,
				margin: 10,
			},
		},
	};

	const controller = new StoresController();

	const getPartners = async () => {
		const { result, error } = await controller.getStores();

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result?.data?.message || 'Houve um erro',
			});
		}

		const stores: JSX.Element[] = [];

		result.map((item: IStore) => {
			if (type === 'aceita' && item.boleto_pop) {
				stores.push(
					<div key={item.display_loja}>
						<a
							key={item.display_loja}
							href={item.link_loja}
							target="_blank"
							rel="noreferrer"
							className="div-img is-flex is-align-items-center is-justify-content-center"
						>
							<img
								className="image-personalizada"
								src={item.logo_loja}
								alt={`${item.nome_loja.toUpperCase()}`}
							/>
						</a>
					</div>
				);
			} else if (type === 'parceiro') {
				stores.push(
					<div
						onClick={() => {
							window.open(item.link_loja, '_blank')?.focus();
						}}
						className="column is-clickable my-2 is-2-desktop is-6-mobile has-background-white p-5 is-flex is-align-items-center is-justify-content-center"
						style={{ borderRadius: '14px', width: 'auto', height: '150px' }}
						key={item.display_loja}
					>
						<img
							src={item.logo_loja}
							alt="Logo Loja"
							style={{ width: '190px' }}
						/>
					</div>
				);
			}
		});

		setFilter({
			partners: stores,
			loading: false,
			filteredPartners: [],
			queryString: '',
		});
	};

	const renderStores = () => {
		return filter.filteredPartners ?? filter.partners;
	};

	const applyFilter = (e: any) => {
		const filterResult = e.target.value;
		setFilter((previousState) => {
			return {
				partners: previousState.partners,
				loading: previousState.loading,
				filteredPartners: filter.partners.filter((e) =>
					e.key?.toString().toLowerCase().includes(filterResult.toLowerCase())
				),
				queryString: filterResult,
			};
		});
	};

	return (
		<div style={{ backgroundColor: color }} className="pt-3">
			<div
				className={`column is-multiline ${margin} is-10-mobile pt-6 container`}
			>
				<h2
					className={`${titleColor} mt-3 mb-5 has-text-left is-size-4 has-text-weight-semibold`}
					style={{ font: 'normal normal 600 35px/63px Montserrat' }}
				>
					{title}
				</h2>
				<div style={{ position: 'relative' }} className="pt-3">
					<SearchBarStyled
						value={filter.queryString}
						onChange={applyFilter}
						placeholder="Buscar lojas, Ex: Magalu, Extra, Dafiti, Kanui"
					></SearchBarStyled>

					<div
						className="is-flex is-flex-row is-align-items-center"
						style={{
							position: 'absolute',
							top: '9px',
							right: '15px',
							backgroundColor: 'white',
						}}
					>
						<div
							style={{
								height: '40px',
								width: '2px',
								background: 'rgba(10,10,10,0.2)',
								marginRight: '20px',
							}}
						/>
						<BsSearch className="is-size-5" />
					</div>
					<div className="columns container is-flex is-align-items-center is-justify-content-space-around my-4 is-multiline is-7">
						<OwlCarousel
							className="owl-carousel owl-theme px-3 pb-6"
							items={6}
							dots={false}
							autoplay={true}
							margin={90}
							rewind={true}
							responsive={responsiveOwl.responsive}
						>
							{renderStores()}
						</OwlCarousel>
					</div>
				</div>
			</div>
		</div>
	);
}
