import CreditDto from '../models/creditDto';
import CreditRepository, {
	ICreditPayload,
} from '../repositories/creditRepository';

import { dataLayerPush } from '../../../utils/dataLayer/dataLayerPush';

class CreditController {
	repository = new CreditRepository();
	dto: CreditDto;

	constructor() {
		this.dto = new CreditDto();
	}

	async submitCreditCard({
		orderData,
		transaction,
		receivableAmount,
		id,
		payload,
	}: ICreditPayload) {
		const result = await this.repository.postCreditCard({
			orderData,
			receivableAmount,
			transaction,
			id,
			payload,
		});

		if (result.status !== 200) return { result, error: true };

		dataLayerPush({
			event: 'first_payment',
			data: {
				payment: 'first_payment_creditcard',
				transaction_id: result.data.transaction,
				value: receivableAmount,
			},
		});

		return { result: result, error: false };
	}
}

export default CreditController;
