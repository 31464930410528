import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IoMdAddCircle } from 'react-icons/io';
import { AiFillCheckCircle } from 'react-icons/ai';

import Swal from 'sweetalert2';

import {
	TitleBankStyled,
	CenterDivStyledTrackingStyled,
	PContactStyled,
	InstructionsDivStyled,
	PSuccessStyled,
	DivContactStyled,
	PStyled,
	DivPaddingStyled,
	CenterDivStyled,
	ErrorStyled,
} from './styles';

import {
	InputUncontrolled,
	Select,
	Button,
} from '../../shared/components/index';

import {
	validName,
	validNameContact,
	emptyValidation,
} from '../../shared/utils/validators';

import { contactRelationship, contactCategories } from '../../shared/variables';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/KYC/tracking-contact.png';

import ContactsController from './controllers/contactsController';

import OrderNavbar from '../order/components/OrderNavbar';

import { IContacts, IParams } from './types/types';
import { useLoading } from '../../hooks/loading';

export const KycContacts = () => {
	const [mask, setMask] = useState('');

	const [registeredContacts, setRegisteredContacts] = useState(0);

	const [contacts, setContacts] = useState<IContacts[]>([]);
	const [contactName, setContactName] = useState('');
	const [contactCategory, setContactCategory] = useState<string | null>(null);
	const [contactDesc, setContactDesc] = useState('');
	const [contactRelationshipState, setContactRelationshipState] = useState<
		string | null
	>(null);
	const [contactsLength, setContactsLength] = useState(1);

	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const controller = new ContactsController();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		getContacts(); // eslint-disable-next-line
	}, []);

	const getContacts = async () => {
		const { result, error } = await controller.getContacts(id);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
			}).then(() => {
				history.push('/taker/order/' + id);
			});
		}

		if (result.length > 3) {
			return history.push(`/taker/order/${id}/formalizacao/thanks`);
		}

		setRegisteredContacts(parseInt(result.length));
	};

	const handleChangeCategory = (value: any) => {
		setContactCategory(value);

		if (value === 'celular') {
			setMask('(99) 9 9999-9999');
		} else if (value === 'telefone') {
			setMask('(99) 9999-9999');
		} else {
			setMask('');
		}
	};

	const renderCounter = () => {
		if (registeredContacts === 0) {
			return (
				<DivContactStyled>
					<PStyled>Você adicionou {registeredContacts} contato(s)</PStyled>
				</DivContactStyled>
			);
		}

		return (
			<DivContactStyled>
				<PSuccessStyled>
					Você adicionou {registeredContacts} contato(s) <AiFillCheckCircle />
				</PSuccessStyled>

				<PContactStyled>
					Aumente suas chances, adicione mais contatos!
				</PContactStyled>
			</DivContactStyled>
		);
	};

	const renderForm = () => {
		if (contactsLength <= 4) {
			const form = [];

			for (let index = 0; index < contactsLength; index++) {
				form.push(
					<div key={index} id={'block-' + index}>
						<div>
							<InputUncontrolled
								data-id="name"
								type="text"
								id={'name-' + index}
								placeholder="Nome"
								onBlur={() => validNameContact(contactName)}
								onChange={(e: any) => setContactName(e.target.value)}
							/>
							<ErrorStyled id="name-error-message"></ErrorStyled>
						</div>

						<DivPaddingStyled>
							<Select
								id={'category-' + index}
								label="Categoria"
								value={contactCategory}
								onChange={(e: any) => handleChangeCategory(e.target.value)}
								options={contactCategories}
							/>
						</DivPaddingStyled>

						<div>
							<InputUncontrolled
								type="tel"
								id={'contact-' + index}
								mask={mask}
								placeholder="Contato"
								onChange={(e: any) => setContactDesc(e.target.value)}
							/>
						</div>

						<DivPaddingStyled>
							<Select
								id={'relationship-' + index}
								label="Relação"
								value={contactRelationshipState}
								onChange={(e: any) =>
									setContactRelationshipState(e.target.value)
								}
								options={contactRelationship}
							/>
						</DivPaddingStyled>

						{renderAddButton()}

						{renderCounter()}
						<hr />
					</div>
				);
			}
			return form;
		}
	};

	const addForm = () => {
		if (contactsLength < 5) {
			const contact: IContacts = {
				name: contactName,
				type: contactCategory,
				contact: contactDesc,
				relationship: contactRelationshipState,
			};

			if (!validName(contact.name)) {
				return Swal.fire({
					icon: 'error',
					text: 'Digite o nome com sobrenome',
				});
			} else if (
				!emptyValidation(contact.type) ||
				!emptyValidation(contact.contact) ||
				!emptyValidation(contact.relationship)
			) {
				return Swal.fire({
					icon: 'error',
					text: 'Preencha todos os campos',
				});
			} else if (
				contact.relationship === 'Relação' ||
				contact.type === 'Categoria'
			) {
				return Swal.fire({
					icon: 'error',
					text: 'Preencha todos os campos',
				});
			} else if (contactCategory === 'celular') {
				if (contactDesc.replace(/[^\d]/g, '').length < 11)
					return Swal.fire({
						icon: 'error',
						text: 'Preencha o número de celular corretamente',
					});
			} else if (contactCategory === 'telefone') {
				if (contactDesc.replace(/[^\d]/g, '').length < 10)
					return Swal.fire({
						icon: 'error',
						text: 'Preencha o número de telefone corretamente',
					});
			}

			const length = parseInt(contactsLength.toString()) - 1;

			document.querySelector('#block-' + length)?.classList.add('hidden');

			Swal.fire({
				icon: 'success',
				title: 'Contato adicionado',
				showConfirmButton: false,
				timer: 1500,
			});

			setContactsLength(contactsLength + 1);
			setRegisteredContacts(registeredContacts + 1);
			setContacts((previousState) => [...previousState, contact]);
			setMask('');
			setContactName('');
			setContactName('');
			setContactCategory('');
			setContactDesc('');
			setContactRelationshipState('');
			return;
		}

		return Swal.fire({
			icon: 'info',
			text: 'Você pode adicionar até 4 contatos',
		});
	};

	const submitContacts = async () => {
		if (contacts.length === 0)
			return history.push(`/taker/order/${id}/formalizacao/thanks`);
		else if (contacts.length > 0) {
			setIsLoading(true);

			const { result, error } = await controller.submit(id, contacts);

			if (error) {
				Swal.fire({
					icon: 'error',
					text: result,
				});
			} else {
				history.push(result);
			}

			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
		} else {
			return Swal.fire({
				icon: 'error',
				text: 'Você precisa adicionar pelo menos um contato',
			});
		}
	};

	const renderAddButton = () => {
		if (
			validName(contactName) &&
			emptyValidation(contactCategory) &&
			emptyValidation(contactDesc) &&
			emptyValidation(contactRelationshipState) &&
			contactRelationshipState !== 'Relação' &&
			contactCategory !== 'Categoria'
		) {
			return (
				<CenterDivStyled>
					<PContactStyled onClick={addForm}>
						<IoMdAddCircle className="has-text-blue" /> Adicionar contato
					</PContactStyled>
				</CenterDivStyled>
			);
		}
	};

	const renderButton = () => {
		if (registeredContacts > 0) {
			return (
				<Button
					onClick={submitContacts}
					width="300px"
					text="Finalizar"
					className="has-gradient-blue"
				/>
			);
		}
	};

	return (
		<>
			<OrderNavbar src={Logo} />
			<main>
				<InstructionsDivStyled>
					<CenterDivStyledTrackingStyled>
						<img width="300px" src={Tracking} alt="tracking" />
					</CenterDivStyledTrackingStyled>

					<TitleBankStyled>Insira um contato adicional</TitleBankStyled>

					{renderForm()}

					<CenterDivStyled>{renderButton()}</CenterDivStyled>
				</InstructionsDivStyled>
			</main>
		</>
	);
};
