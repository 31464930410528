import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import SelectSearch, {
	fuzzySearch,
	SelectSearchOption,
} from 'react-select-search';
import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	SelectStyled,
	TitleStyled,
	LabelBoldPStyled,
	CenterDivStyled,
	ErrorStyled,
	MainStyled,
	CenteredDivStyled,
	MarginsDivStyled,
	ClickStyled,
	ModalStyled,
	LabelBluePStyled,
	AlignStyled,
} from '../../styles';

import { chargebackController, callBanksController } from '../../controller';

import {
	ChargebackProps,
	ComponentsProps,
	BanksSelectProps,
} from '../../types/types';

import { BANK_TYPES } from '../../../../shared/variables';
import {
	Input,
	InputUncontrolled,
	InputControlledCurrency,
	Button,
} from '../../../../shared/components/index';

import { useAuth } from '../../../../hooks/auth/auth';

const schema = Yup.object().shape({
	bank_branch: Yup.string()
		.required('Digite o número da sua agência')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(4, 'Digite um número de agência válido')
		.test('len', 'Digite um número de agência válido', function (value) {
			if (value === '0000') return false;
			return true;
		}),
	bank_account: Yup.string()
		.required('Digite o número da sua conta')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(3, 'Digite um número de conta válido'),
});

export const Chargeback = ({
	changeValueLoading,
	returnStep,
}: ComponentsProps) => {
	const [bankSelected, setBankSelected] = useState<string>('');
	const [confirm, setConfirm] = useState(false);
	const [banksSelect, setBanksSelect] = useState<SelectSearchOption[]>(
		[] as SelectSearchOption[]
	);
	const [errorBank, setErrorBank] = useState<string | undefined>();
	const [accountTypes, setAccountTypes] = useState<string>('');
	const [bank_account, setBank_account] = useState<string>('');
	const [bank_branch, setBank_branch] = useState<string>('');
	const [errorAccountTypes, setErrorAccountTypes] = useState<
		string | undefined
	>();

	const [dataObject, setDataObject] = useState<ChargebackProps>(
		{} as ChargebackProps
	);

	// const history = useHistory();

	const { user, setData } = useAuth();

	const history = useHistory();

	const { id } = useParams<{ id: string }>();

	const searchInput: MutableRefObject<any> = useRef();

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	// Por enquanto manteremos apenas a verificação feita no ChangeDL
	// Deixaremos essas linhas aqui caso esse component vire uma view
	// useEffect(() => {
	// 	checkUser(); // eslint-disable-next-line
	// }, []);

	useEffect(() => {
		getUpdatedUser();
		getBanks();
	}, [user]);

	// Por enquanto manteremos apenas a verificação feita no ChangeDL
	// Deixaremos essas linhas aqui caso esse component vire uma view
	// const checkUser = async () => {
	// 	const { error } = await validUserController(id);

	// 	if (error) {
	// 		history.push('/taker');
	// 	} else {
	// 		changeValueLoading();
	// 	}
	// };

	const getBanks = async () => {
		const { result } = await callBanksController();

		const bankList = result.map((item: BanksSelectProps) => {
			return { name: item.name, value: item.name, key: item.code };
		});

		setBanksSelect(bankList);
	};

	const getUpdatedUser = () => {
		const { full_name, cpf } = user;

		setValue('full_name', full_name, { shouldValidate: true });
		setValue('cpf', cpf, { shouldValidate: true });

		const dataUser = {
			full_name: full_name,
			cpf: cpf,
		};

		setDataObject(dataUser);
	};

	const firstSubmit = () => {
		if (bankSelected === '') {
			setErrorBank('Selecione algum banco');
			return;
		}

		if (accountTypes === '') {
			setErrorAccountTypes('Selecione qual o tipo da conta');
			return;
		}

		const bank_account = getValues('bank_account');
		const bank_branch = getValues('bank_branch');

		const lenght = bank_account.length;
		const finalString = bank_account.substr(-1, 1);
		const bank = bank_account.substr(0, lenght - 1);

		const bank_account_mask = bank + '-' + finalString;

		setBank_account(bank_account_mask);
		setBank_branch(bank_branch);

		return setConfirm(true);
	};

	const handleConfirm = async () => {
		changeValueLoading();

		const payload = {
			bank_code: bankSelected.substring(0, 3),
			bank_account: bank_account,
			bank_branch: bank_branch,
			bank_account_type: accountTypes,
			refund_by: 'TED',
		};

		const { result, error } = await chargebackController(id, payload);

		if (error) {
			changeValueLoading();
			Swal.fire({
				icon: 'error',
				text:
					result?.data.detail ??
					'Erro ao enviar os dados. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});
		} else {
			changeValueLoading();
			return Swal.fire({
				icon: 'success',
				title: 'Solicitação enviada com sucesso!',
				text: 'O reembolso será realizado na conta informada em até 3 dias úteis. Se houver alguma necessidade adicional, nós entraremos em contato.',
				confirmButtonText: 'Fechar',
			}).then(() => {
				setData();
				history.push('/taker');
			});
		}
	};

	const bankType = (accountTypes: string) => {
		if (accountTypes === 'checking') {
			return 'Conta Corrente';
		} else if (accountTypes === 'savings') {
			return 'Conta Poupança';
		} else if (accountTypes === 'salary') {
			return 'Conta Salário';
		} else if (accountTypes === 'payment') {
			return 'Conta Pagamento';
		}
	};

	const handleChange = (...args: any[]) => {
		setBankSelected(args[1].name);
		setErrorBank('');
	};

	return (
		<>
			{confirm ? (
				<ModalStyled>
					<TitleStyled>Revise os dados antes de prosseguir.</TitleStyled>
					<AlignStyled>
						<LabelBoldPStyled style={{ fontSize: '16px', textAlign: 'left' }}>
							Banco{' '}
							<LabelBluePStyled style={{ fontSize: '16px' }}>
								{bankSelected}
							</LabelBluePStyled>
						</LabelBoldPStyled>

						<LabelBoldPStyled style={{ fontSize: '16px', textAlign: 'left' }}>
							Agência{' '}
							<LabelBluePStyled style={{ fontSize: '16px' }}>
								{bank_branch}
							</LabelBluePStyled>
						</LabelBoldPStyled>

						<LabelBoldPStyled style={{ fontSize: '16px', textAlign: 'left' }}>
							Conta{' '}
							<LabelBluePStyled style={{ fontSize: '16px' }}>
								{bank_account}
							</LabelBluePStyled>
						</LabelBoldPStyled>

						<LabelBoldPStyled style={{ fontSize: '16px', textAlign: 'left' }}>
							Tipo de conta{' '}
							<LabelBluePStyled style={{ fontSize: '16px' }}>
								{bankType(accountTypes)}
							</LabelBluePStyled>
						</LabelBoldPStyled>

						<CenterDivStyled style={{ marginTop: '2rem' }}>
							<Button
								text="Próximo"
								className="has-gradient-blue"
								width="300px"
								onClick={handleConfirm}
								textColor="#fff"
								disable={false}
							/>
							<ClickStyled onClick={() => setConfirm(false)}>
								Voltar
							</ClickStyled>
						</CenterDivStyled>
					</AlignStyled>
				</ModalStyled>
			) : (
				<MainStyled>
					<form onSubmit={handleSubmit(firstSubmit)}>
						<CenteredDivStyled>
							<MarginsDivStyled style={{ margin: '1.2rem 0 2rem' }}>
								<TitleStyled>
									Os dados bancários precisam ser do próprio cliente conforme
									CPF informado.
								</TitleStyled>

								<LabelBoldPStyled>Titular:</LabelBoldPStyled>
								<InputUncontrolled
									disabled={true}
									mask=""
									id="name"
									value={dataObject.full_name}
									placeholder="CPF"
								/>

								<LabelBoldPStyled>CPF do Titular:</LabelBoldPStyled>
								<InputUncontrolled
									disabled={true}
									mask="999.999.999-99"
									id="cpf"
									value={dataObject.cpf}
									placeholder="CPF"
								/>
							</MarginsDivStyled>
						</CenteredDivStyled>

						<div style={{ borderBottom: '1px solid #28c0cd' }} />

						<CenteredDivStyled>
							<MarginsDivStyled style={{ margin: '0.2rem 0 2rem' }}>
								<TitleStyled>Dados bancários</TitleStyled>

								<LabelBoldPStyled>Banco:</LabelBoldPStyled>

								<SelectSearch
									ref={searchInput}
									options={banksSelect}
									value={bankSelected}
									placeholder="Selecione o Banco"
									search
									emptyMessage="Banco não encontrado"
									filterOptions={fuzzySearch}
									onChange={handleChange}
								/>
								<ErrorStyled>{errorBank}</ErrorStyled>

								<LabelBoldPStyled>Agência:</LabelBoldPStyled>
								<Input
									control={control}
									name="bank_branch"
									type="tel"
									placeholder="Digite sua agência"
									mask="9999"
									disabled={false}
									error={errors.bank_branch && errors.bank_branch.message}
								/>

								<LabelBoldPStyled>Conta: (com dígito)</LabelBoldPStyled>
								<InputControlledCurrency
									control={control}
									name="bank_account"
									precision="1"
									decimalSeparator="-"
									thousandSeparator=""
									prefix=""
									type="text"
									placeholder="Conta"
									disabled={false}
									error={errors.bank_account && errors.bank_account.message}
								/>

								<LabelBoldPStyled>Tipo de conta:</LabelBoldPStyled>
								<SelectStyled
									placeholder="Tipo da conta"
									onChange={(event) => {
										setAccountTypes(event.target.value);
										setErrorAccountTypes('');
									}}
									value={accountTypes}
								>
									{BANK_TYPES.map((item) => (
										<option key={item.value} value={item.value}>
											{item.name}
										</option>
									))}
								</SelectStyled>
								<ErrorStyled>{errorAccountTypes}</ErrorStyled>
							</MarginsDivStyled>
						</CenteredDivStyled>
						<CenterDivStyled style={{ marginBottom: '1rem' }}>
							<Button
								type="submit"
								text="Próximo"
								className="has-gradient-blue"
								width="300px"
								textColor="#fff"
								disable={false}
							/>
						</CenterDivStyled>

						<ClickStyled onClick={() => returnStep()}>Voltar</ClickStyled>
					</form>
				</MainStyled>
			)}{' '}
		</>
	);
};
