import { ReactNode, useState } from 'react';

export const Accordion = (ask: any, children: ReactNode) => {
	// Quando coloco JSX.Element no ask ele buga o Assignor,
	const [open, setOpen] = useState(false); // enquanto não resolvo deixarei ANY

	return (
		<div>
			<div onClick={() => setOpen((previousState) => !previousState)}>
				{ask}
			</div>
			{open && children}
		</div>
	);
};
