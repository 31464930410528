import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { IoLogoWhatsapp, IoAlertCircle } from 'react-icons/io5';

export const AiOutlineArrowLeftStyled = styled(AiOutlineArrowLeft)`
	display: flex;
	justify-content: start;
	align-items: center;
	color: grey;
	cursor: pointer;
`;

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const BlackTitleStyled = styled.h1`
	color: black;
	font-size: 24px;
	text-align: center;
	font-weight: 501;
	margin-bottom: 1rem;
`;

export const MainStyled = styled.main`
	background: #f5f5f5;
	min-height: 100vh;
	padding-bottom: 0.5em;
`;

export const ArrowDivStyled = styled.div`
	color: grey;
	text-align: left;
	font-weight: 601;
	margin: 1rem 2rem;
	cursor: pointer;
	padding: 0 1.3rem;
	font-size: 24px;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const FormStyled = styled.form``;

export const CenterMarginTopDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem;
`;

export const DivStyled = styled.div`
	display: flex;
	align-items: center;
`;

// OrderList
export const ContainerNoTileStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 60vh;
`;

export const OrdersDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 36%;

	@media (max-width: 2500px) {
		width: 36%;
	}

	@media (max-width: 2400px) {
		width: 38%;
	}

	@media (max-width: 2200px) {
		width: 40%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 65%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}

	@media (max-width: 700px) {
		width: 100%;
	}
`;

export const OrderDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

// OrderDetails

export const TileStyled = styled.div`
	background: white;
	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(10, 10, 10, 0.1);
	margin: 1rem 0;

	@media (max-width: 2500px) {
		width: 39%;
	}

	@media (max-width: 2400px) {
		width: 40%;
	}

	@media (max-width: 2200px) {
		width: 44%;
	}

	@media (max-width: 2150px) {
		width: 45%;
	}

	@media (max-width: 2050px) {
		width: 47%;
	}

	@media (max-width: 1900px) {
		width: 52%;
	}

	@media (max-width: 1700px) {
		width: 57%;
	}

	@media (max-width: 1550px) {
		width: 63%;
	}

	@media (max-width: 1370px) {
		width: 66%;
	}

	@media (max-width: 1310px) {
		width: 71%;
	}

	@media (max-width: 1230px) {
		width: 79%;
	}

	@media (max-width: 1100px) {
		width: 85%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}

	@media (max-width: 700px) {
		width: 100%;
	}
`;

export const SuccessButtonStyled = styled.button`
	color: white;
	background-color: #92d590;
	height: 37px;
	display: flex;
	align-items: center;
	border-radius: 3px;
	cursor: no-drop;
`;

export const LightButtonStyled = styled.button`
	color: #a4a4a4;
	background-color: white;
	height: 37px;
	display: flex;
	align-items: center;
	border-width: medium;
	border-radius: 3px;
	border-color: #d3d3d3;
	cursor: no-drop;
`;

export const DangerOrOrangeStyled = styled.button`
	color: white;
	height: 37px;
	display: flex;
	align-items: center;
	border-width: medium;
	border-radius: 3px;
	margin-bottom: 0.2rem;
`;

export const TableStyled = styled.table`
	text-align: center;
	width: 100%;
	border-collapse: collapse;

	td,
	th {
		border: 1px solid rgba(10, 10, 10, 0.12);
		border-bottom-width: 1px;
		border-top-width: 0;
		border-right-width: 0;
		border-left-width: 0;
		padding: 0.5rem;
	}
`;

export const ContainerStyled = styled.div`
	margin-top: 0.4rem;
	padding: 0 1rem;
`;

export const AStyled = styled.a`
	font-weight: bold;
`;

export const DownloadDivStyled = styled.div`
	color: black;
`;

export const GapDivStyled = styled.div`
	width: 2px;
	height: 100%;
	background: rgba(10, 10, 10, 0.12);
`;

export const VertDivStyled = styled.div`
	width: 2px;
	height: 50px;
	background: rgba(10, 10, 10, 0.12);
`;

export const GapVertDivStyled = styled.div`
	height: 2px;
	width: 100%;
	background: rgba(10, 10, 10, 0.12);
`;

export const TemplateDivStyled = styled.div`
	padding: 0.5rem 0.1rem;
	margin: 1rem 0;
	display: flex;
	flex-direction: row;

	@media (max-width: 750px) {
		display: none;
	}
`;

export const TemplateMobileDivStyled = styled.div`
	padding: 0.5rem 0.1rem;
	margin: 1rem 0;
	display: none;
	flex-direction: column;

	@media (max-width: 750px) {
		display: flex;
	}
`;

export const SuccessTitleStyled = styled.h3`
	text-align: center;
	font-size: 24px;
	margin: 0 2rem;
	font-weight: bold;
	color: ${colors.successColor};

	@media (max-width: 750px) {
		margin: 0 0.5rem;
	}
`;

export const PaddingPStyled = styled.p`
	padding: 0 1rem;
`;

export const TopDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: top;
	margin: 0.5rem 0.3rem 0.5rem 0;
`;

export const AmountPStyled = styled.p`
	padding: 0 1rem;
	font-weight: 650;
	font-size: 22px;

	@media (max-width: 750px) {
		font-size: 17px;
	}
`;

export const IoLogoWhatsappStyled = styled(IoLogoWhatsapp)`
	padding-bottom: 0.2rem;
	color: ${colors.successColor};
`;

export const AncStyled = styled.a`
	font-weight: 501;
	color: ${colors.successColor};
	display: flex;
	align-items: top;
	font-size: 20px;
	padding: 0 0.2rem;
	@media (max-width: 750px) {
		font-size: 17px;
	}
`;

export const AroundDivStyled = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const OrderPStyled = styled.p`
	font-size: 13px;
`;

export const MarginTopPStyled = styled.p`
	font-size: 13px;
	margin-top: 1rem;
`;

export const AcordoTitleStyled = styled.h1`
	font-size: 24px;
	text-align: center;
	font-weight: 500;
`;

export const AcordoDivStyled = styled.div`
	margin: 1rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 750px) {
		display: none;
	}
`;

export const AcordoMobileDivStyled = styled.div`
	margin: 1rem 0;
	display: none;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 750px) {
		display: flex;
	}
`;

// OrderThanks

export const IMGStyled = styled.img`
	margin: 2rem 0;
	width: 150px;
`;

export const PStyled = styled.p`
	font-size: 16px;
	margin: 0.5rem 0;
	font-weight: 500;
	text-align: center;
`;

export const PJafraStyled = styled.p`
	font-size: 12px;
	font-weight: 401;
	margin: 0.2rem 0;
	text-align: left;
`;

export const BlackPStyled = styled.p`
	font-size: 16px;
	margin: 0.5rem 0 1rem 0;
	font-weight: 501;
	text-align: center;
`;

export const PClickStyled = styled.p`
	text-align: center;
	font-weight: 650;
	cursor: pointer;
	margin-top: 1rem;
	color: ${colors.textBlue};
`;

export const ClickStyled = styled.a`
	text-align: center;
	font-weight: 650;
	cursor: pointer;
	margin-top: 1rem;
	color: ${colors.textBlue};
`;

export const GapThanksStyled = styled.div`
	width: 80%;
	margin: 1rem 0;
	border-bottom: 1px solid #cfcfcf;
`;

// OrderSending

export const SendingMainStyled = styled.main`
	background: #fff;
	min-height: 100vh;
	padding-bottom: 0.5em;
`;

export const ThanksDivStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 3rem 0;
	width: 500px;

	@media (max-width: 1000px) {
		width: 330px;
	}

	@media (max-width: 600px) {
		width: 300px;
	}
`;

export const SendingDivStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 3rem 0;
	width: 80%;
`;

export const SendingTitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-size: 24px;
	text-align: center;
	font-weight: 501;
	margin-bottom: 1rem;
`;

export const ThanksInputsStyled = styled.div`
	margin: 0.5rem 0;
`;

export const SendingPStyled = styled.p`
	font-size: 13px;
	margin: 0.5rem 0;
	font-weight: 500;
	text-align: center;
`;

export const CenteredDivStyled = styled.div`
	margin-bottom: 1.5rem;
	text-align: center;
`;

export const SendingBoldPStyled = styled.span`
	font-size: 13px;
	margin: 0.5rem 0;
	font-weight: 501;
	text-align: left;
`;

export const InputLabelStyled = styled.label(SendingBoldPStyled);

export const SendingGreyPStyled = styled.p`
	font-size: 16px;
	margin: 1rem 0;
	font-weight: 500;
	text-align: center;
`;

export const SendingGreyTitleStyled = styled.h2`
	font-weight: 500;
	font-size: 22px;
	margin: 1rem 0 0 0;
	text-align: center;

	@media (max-width: 750px) {
		font-size: 17px;
	}
`;

export const ModalDivStyled = styled.div`
	box-shadow: 0px 2px 6px rgba(10, 10, 10, 0.1);
	border-radius: 14px;
	background-color: #fff;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.7rem 0.4rem;
`;

export const IoAlertStyled = styled(IoAlertCircle)`
	display: flex;
	justify-content: center;
	align-items: center;
	color: red;
`;

export const InstallmentListStyled = styled.ul`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	margin: 1rem 0;
`;

export const SendingAmountPStyled = styled.p`
	font-size: 20px;
	margin: 0.2rem 0;
	font-weight: 500;
	text-align: center;
`;

export const SendingBlueAmountPStyled = styled.span`
	font-size: 20px;
	font-weight: 501;
	text-align: center;
	color: ${colors.primaryBlue};
`;

export const SendingTotalAmountPStyled = styled.p`
	font-size: 17px;
	margin: 0.5rem 0;
	font-weight: 400;
	text-align: center;
`;

export const SendingCETAmountPStyled = styled.p`
	font-size: 12px;
	margin: 0.5rem 0;
	font-weight: 400;
	text-align: center;
`;

export const ThroughPStyled = styled.span`
	font-size: 20px;
	font-weight: 501;
	text-align: center;
	color: gray;
	text-decoration: line-through;
`;

export const SendingSuccessAmountPStyled = styled.span`
	font-size: 20px;
	font-weight: 501;
	text-align: center;
	color: ${colors.successColor};
`;
export const GroupStyled = styled.div`
	position: relative;
	display: flex;
	margin-bottom: 0.9rem;
`;

export const InputFileStyled = styled.input`
	display: none;
`;

export const LabelStyled = styled.label`
	padding: 13px 18px;
	border: none;
	border-radius: 3px;
	font-size: 0.95rem;
	font-weight: bolder;
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	color: #fff;
	background: ${colors.gradient};
`;

export const SpanStyled = styled.span`
	font-family: 'Montserrat';
	font-size: 15px;
	display: flex;
	text-align: center;
`;

export const SpanNoneStyled = styled.span`
	display: none;
`;

// OrderApproved

export const ApprovedClickStyled = styled.p`
	text-align: center;
	font-weight: 400;
	cursor: pointer;
	margin-top: 1rem;
	color: ${colors.textBlue};
`;

// OrderCheckout

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	justify-content: center;
	margin: 0.5rem 0;
`;

export const AClickStyled = styled.a`
	text-align: center;
	font-weight: 401;
	margin: 0 0.2rem;
	cursor: pointer;
	text-decoration: underline;
	color: ${colors.textBlue};
`;

export const WhatsAppStyled = styled.img`
	width: 60px;
	height: 60px;
	position: fixed;
	margin-top: 5rem;
	right: 2%;
	cursor: pointer;
`;

export const WhatsAppDivStyled = styled.div`
	width: 60px;
	height: 60px;
	position: fixed;
	top: 60%;
	right: 2%;
	cursor: pointer;
`;

export const WhatsAppTextStyled = styled.p`
	width: 150px;
	position: fixed;
	right: 1%;
	cursor: pointer;

	font-size: 12px;
	font-weight: 500;
	text-align: center;

	@media (max-width: 600px) {
		display: none;
	}
`;
