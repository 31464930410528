import { dataLayerPush } from '../../../shared/utils/dataLayer/dataLayerPush';

export const payslipVerificationDataLayer = (value: string) => {
	dataLayerPush({
		event: 'conversion',
		data: {
			name: 'payslip_verification',
			value,
		},
	});
};
