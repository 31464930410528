import BankExtractRepository from '../repositories/bankExtRepository';
import KYCController from './kycController';

class BankExtractController {
	repository: BankExtractRepository;
	kycController: KYCController;
	lote: number;

	constructor(lote: number) {
		this.repository = new BankExtractRepository();
		this.kycController = new KYCController();
		this.lote = lote;
	}

	async submit(transaction: string, files: (string | null)[]) {
		const filesFiltered = files.filter((e) => e !== null);

		const contacts_url = `/taker/order/${transaction}/formalizacao/contacts`;

		if (filesFiltered.length > 0) {
			filesFiltered.map(async (item) => {
				await this.repository.submit(transaction, this.lote, {
					file_base64: item,
					type_file: 'extrato',
				});
			});

			if (filesFiltered.length > 2)
				return { redirect: contacts_url, success: false, emptyFiles: false };

			const steps = await this.kycController.getSteps(transaction);

			if (steps.result.files === 3)
				return { redirect: contacts_url, success: false, emptyFiles: false };

			return { redirect: contacts_url, success: true };
		}

		return { redirect: contacts_url, success: false, emptyFiles: true };
	}
}

export default BankExtractController;
