import orderApprovedRepository from '../repositories/orderApprovedRepository';

class orderApprovedController {
	repository: orderApprovedRepository;

	constructor() {
		this.repository = new orderApprovedRepository();
	}

	async checkReceivables(transaction: string) {
		const result = await this.repository.checkReceivables(transaction);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}

export default orderApprovedController;
