import MD5 from 'md5';

import { onlyNumber } from '../onlyNumbers';

export const hashCpfMd5 = (cpf: string) => {
	const clearedCpf = onlyNumber(cpf);
	const cpfHash = MD5(clearedCpf);

	return cpfHash;
};
