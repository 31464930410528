import Swal from 'sweetalert2';

import OrderRepository from '../repositories/orderRepository';
import AuthRepository from '../../../shared/repositories/authRepository';

class OrderController {
	repository = new OrderRepository();
	authRepository = new AuthRepository();

	async list() {
		const result = await this.repository.list();
		if (result.status !== 200) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: result.data.detail,
			});
		}
		return result.data;
	}

	async get(transaction: string) {
		const result = await this.repository.get(transaction);

		if (result.status !== 200) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Não foi possível carregar suas propostas',
			});
		}

		return result.data;
	}

	getCCBLink(array: any) {
		for (const index in array) {
			if (array[index].rel === 'ccb') {
				return array[index].href;
			}
		}
	}
}

export default OrderController;
