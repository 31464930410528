import styled from 'styled-components';

export const ContainerStyled = styled.div`
	display: flex;
	border-bottom: 1px solid #28c0cd;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: initial;
	justify-content: center;
	overflow-x: hidden;
`;
