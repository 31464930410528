import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import {
	MultilineDivStyled,
	InputDivStyled,
	FinalButtonStyled,
} from '../../styles';

import {
	InputUncontrolled,
	Input,
	Button,
	Accordion,
} from '../../../../shared/components/index';

import { useAuth } from '../../../../hooks/auth/auth';

import { AddressProps } from '../../types/types';
import UserController from '../../controllers/userController';
import AddressController from '../../../signUp/controllers/addressController';
import { useLoading } from '../../../../hooks/loading';
import { AdressSchema } from '../../validation';
export interface PersonalAddressProps {
	userAddress: AddressProps;
}

export const AddressFields = ({ userAddress }: PersonalAddressProps) => {
	const [cep, setCep] = useState('');

	const userController = new UserController();
	const addressController = new AddressController();

	const { user, setData } = useAuth();

	const { setIsLoading } = useLoading();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(AdressSchema) });

	useEffect(() => {
		reset(userAddress); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		getUpdatedUser();
	}, [user]);

	const getUpdatedUser = () => {
		const { address } = user;

		setCep(address?.cep);
		setValue('street', address?.street, { shouldValidate: true });
		setValue('number', address?.number, { shouldValidate: true });
		setValue('complement', address?.complement, { shouldValidate: true });
		setValue('city', address?.city, { shouldValidate: true });
		setValue('state', address?.state, { shouldValidate: true });
		setValue('neighborhood', address?.neighborhood, { shouldValidate: true });
	};

	const setAddress = async () => {
		try {
			const cepAtual = cep?.replace(/[^\d]+/g, '');
			if (cepAtual.length > 7) {
				const data = await addressController.getAddress(cepAtual);

				setValue('street', data?.logradouro ?? '', {
					shouldValidate: true,
				});
				setValue('city', data?.localidade ?? '', {
					shouldValidate: true,
				});
				setValue('state', data?.uf ?? '', { shouldValidate: true });
				setValue('neighborhood', data?.bairro ?? '', {
					shouldValidate: true,
				});
			}
		} catch (e) {
			Swal.fire({
				icon: 'error',
				text: 'Erro ao encontrar endereço. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});
		}
	};

	const handleOnSubmit = async (form: AddressProps) => {
		setIsLoading(true);

		const payload = {
			cep: cep,
			city: form.city,
			complement: form.complement,
			neighborhood: form.neighborhood,
			number: form.number,
			state: form.state,
			street: form.street,
		};

		if (cep?.replace(/[^\d]+/g, '').length > 7) {
			const { result, error } = await userController.updateAddress(
				payload,
				(response: any) => setData(response)
			);

			if (error) {
				Swal.fire({
					icon: 'error',
					text:
						result.data.message ??
						'Erro ao atualizar o endereço. Tente novamente em alguns minutos.',
					confirmButtonText: 'Fechar',
				});
			}
			setIsLoading(false);
			Swal.fire({
				icon: 'success',
				text: 'Endereço alterado com sucesso!',
				confirmButtonText: 'Continuar',
			}).then(() => {
				setData(result);
			});
		} else {
			setIsLoading(false);
			return Swal.fire({
				text: 'Insira um CEP válido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}
	};

	return (
		<Accordion dataTestId="Navbar" title="Atualizar meu endereço">
			<MultilineDivStyled>
				<InputDivStyled>
					<InputUncontrolled
						id="cep"
						dataTestId="cep"
						onKeyPress={setAddress}
						mask="99999-999"
						type="tel"
						name="cep"
						placeholder="CEP"
						value={cep}
						onChange={(e) => setCep(e.target.value)}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						mask=""
						id="street"
						dataTestID="street"
						name="street"
						type="text"
						placeholder="Endereço"
						error={errors.street && errors.street.message}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						mask=""
						id="neighborhood"
						dataTestID="neighborhood"
						name="neighborhood"
						type="text"
						placeholder="Bairro"
						error={errors.neighborhood && errors.neighborhood.message}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						mask=""
						id="number"
						dataTestID="number"
						name="number"
						type="cel"
						placeholder="Número"
						error={errors.number && errors.number.message}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						mask=""
						id="complement"
						dataTestID="complement"
						name="complement"
						type="text"
						placeholder="Complemento"
						error={errors.complement && errors.complement.message}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						mask=""
						id="city"
						dataTestID="city"
						name="city"
						type="text"
						placeholder="Cidade"
						error={errors.city && errors.city.message}
					/>
				</InputDivStyled>

				<InputDivStyled>
					<Input
						control={control}
						placeholder="Estado"
						mask=""
						id="uf"
						name="state"
						dataTestID="state"
						error={errors.state && errors.state.message}
					/>
				</InputDivStyled>

				<FinalButtonStyled>
					<Button
						text="Salvar alterações"
						onClick={handleSubmit(handleOnSubmit)}
						className="has-gradient-blue"
						textColor="#fff"
						dataTestid="submitButton"
					/>
				</FinalButtonStyled>
			</MultilineDivStyled>
		</Accordion>
	);
};
