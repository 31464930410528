import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	RedefinePasswordController,
	tokenRedefinePasswordController,
} from './controller';

import { RedefinePasswordI } from './types';

import {
	TitleStyled,
	InputsStyled,
	SectionStyled,
	CenterDivStyled,
	DivAlignCenterStyled,
	ImgStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_400.png';

import { useLoading } from '../../hooks/loading';

import {
	Button,
	InputPassword,
	InfoPassword,
	CheckPassword,
} from '../../shared/components/index';

import { useAuth } from '../../hooks/auth/auth';

import { hashEmailSha256 } from '../../shared/utils/hashes';
import eventPush from '../../shared/utils/eventPush';

export const schema = Yup.object().shape({
	new_password: Yup.string()
		.required('Digite a nova senha')
		.matches(/[a-z]/, 'Sua senha precisa ter no mínimo um caractere minúsculo.')
		.matches(/[A-Z]/, 'Sua senha precisa ter no mínimo um caractere maiúsculo.')
		.matches(/[0-9]/, 'Sua senha precisa ter no mínimo um caractere numérico.')
		.notOneOf(
			[Yup.ref('password'), null],
			'A nova senha deve ser diferente da senha atual'
		)
		.min(8, 'Sua senha precisa ter no mínimo 8 caracteres.'),
	new_password_confirm: Yup.string()
		.required('Repita a nova senha')
		.oneOf([Yup.ref('new_password'), null], 'As senhas devem ser idênticas'),
});

export const RedefinePassword = () => {
	const history = useHistory();

	const { setIsLoading } = useLoading();

	const { token } = useParams<{ token: string }>();

	const { user } = useAuth();

	const {
		control,
		handleSubmit,
		getValues,
		formState: { errors },
		watch,
	} = useForm({ resolver: yupResolver(schema) });

	const password: string = watch('new_password');

	// useEffect pertinente a validação do ChangeDL
	useEffect(() => {
		checkTicket(); // eslint-disable-next-line
	}, []);

	// Função pertinente a validação do ChangeDL
	const checkTicket = async () => {
		setIsLoading(true);

		const payload: RedefinePasswordI = {
			token: token,
		};

		const { error } = await tokenRedefinePasswordController(payload);

		if (error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'error',
				text: 'Você não tem acesso esse contéudo.',
				confirmButtonText: 'Fechar',
			}).then(() => history.push('/'));
		} else {
			return setIsLoading(false);
		}
	};

	const submit = async () => {
		setIsLoading(true);

		eventPush('web_esq_senha_enviar', 'INFO', {
			email_hash: hashEmailSha256(user.email),
		});

		const payload: RedefinePasswordI = {
			password: getValues('new_password'),
			token: token,
		};

		const { error } = await RedefinePasswordController(payload);

		if (!error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'success',
				text: 'Senha alterada com sucesso',
				confirmButtonText: 'Fechar',
			}).then(() => {
				history.push('/');
			});
		}

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	return (
		<div>
			<form onSubmit={handleSubmit(submit)}>
				<SectionStyled>
					<ImgStyled
						onClick={() => history.push('/')}
						src={Logo}
						alt="Logo VirtusPay"
					/>
					<TitleStyled>Alteração de Senha</TitleStyled>

					<InputsStyled>
						<CenterDivStyled>
							<InputPassword
								control={control}
								isControlled={true}
								name="new_password"
								dataTestId="new_password"
								placeholder="Nova senha"
								type="password"
								error={errors.new_password && errors.new_password.message}
							/>
							{password ? <CheckPassword password={password} /> : null}

							<InputPassword
								control={control}
								isControlled={true}
								name="new_password_confirm"
								dataTestId="new_password_confirm"
								placeholder="Confirmar nova senha"
								mask=""
								type="password"
								error={
									errors.new_password_confirm &&
									errors.new_password_confirm.message
								}
							/>
							<InfoPassword />
						</CenterDivStyled>
					</InputsStyled>

					<DivAlignCenterStyled>
						<Button
							type="submit"
							text="Enviar"
							width="300px"
							textColor="#fff"
							className="has-gradient-blue"
						/>
					</DivAlignCenterStyled>
				</SectionStyled>
			</form>
		</div>
	);
};
