import Swal from 'sweetalert2';
import validator from 'validator';
import moment from 'moment';

export const validEmail = (email: string) => {
	if (validator.isEmail(email)) return true;
	else return false;
};

export const validCurrentPassword = (password: string) => {
	if (!/([a-zA-Z])/.test(password)) {
		return false;
	} else if (!/([0-9])/.test(password)) {
		return false;
	}
	return true;
};

export const validPassword = (password: string) => {
	if (password.length < 8) {
		const message = 'Sua senha precisa ter no mínimo 8 caracteres.';
		Swal.fire({
			text: message,
			icon: 'error',
			confirmButtonText: 'Entendi',
		});
		return { result: false, message: message };
	} else if (!/[a-z]/.test(password)) {
		const message = 'Sua senha precisa ter no mínimo um caractere minúsculo.';
		Swal.fire({
			text: message,
			icon: 'error',
			confirmButtonText: 'Entendi',
		});
		return { result: false, message: message };
	} else if (!/[A-Z]/.test(password)) {
		const message = 'Sua senha precisa ter no mínimo um caractere maiúsculo.';
		Swal.fire({
			text: message,
			icon: 'error',
			confirmButtonText: 'Entendi',
		});
		return { result: false, message: message };
	} else if (!/[0-9]/.test(password)) {
		const message = 'Sua senha precisa ter no mínimo um caractere numérico.';
		Swal.fire({
			text: message,
			icon: 'error',
			confirmButtonText: 'Entendi',
		});
		return { result: false, message: message };
	} else {
		return { result: true, message: '' };
	}
};

export const validName = (name: string) => {
	if (name === null || name.length < 2) return false;

	const composeName = name?.trim().split(' ');

	if (composeName[0].length < 2) return false;
	if (composeName.length < 2) return false;

	return true;
};

export const validNameContact = (name: string) => {
	const composeName = name?.trim().split(' ');
	const regexName = /^([a-zA-ZÀ-ú]{1,}[ ])+([a-zA-ZÀ-ú]{1,})+$/;

	if (
		!regexName.test(name.trim()) ||
		name.length < 2 ||
		composeName[0].length < 2 ||
		composeName.length < 2
	) {
		const message = 'Digite nome e sobrenome, números são proibidos!';

		Swal.fire({
			text: message,
			icon: 'error',
			confirmButtonText: 'Entendi',
		});

		return false;
	}

	return true;
};

export const validBirthdate = (birthdate: string) => {
	const day = birthdate.split('/')[0];
	const month = birthdate.split('/')[1];
	const year = birthdate.split('/')[2];

	const date = new Date(year + '-' + month + '-' + day).getFullYear();
	const now = new Date().getFullYear();

	if (now - date < 18) return false;

	if (!moment(date).isValid()) return false;

	const diff = Math.abs(now - date);
	if (diff < 18) return false;

	return true;
};

export const validCPF = (cpf: string) => {
	const $strCPF = cpf.replace(/[^\d]+/g, '');
	let Soma;
	let Resto;
	Soma = 0;
	if (
		$strCPF === '00000000000' ||
		$strCPF === '11111111111' ||
		$strCPF === '22222222222' ||
		$strCPF === '33333333333' ||
		$strCPF === '44444444444' ||
		$strCPF === '55555555555' ||
		$strCPF === '66666666666' ||
		$strCPF === '77777777777' ||
		$strCPF === '88888888888' ||
		$strCPF === '99999999999'
	) {
		return false;
	}

	for (let i = 1; i <= 9; i++)
		Soma = Soma + parseInt($strCPF.substring(i - 1, i)) * (11 - i);
	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;
	if (Resto !== parseInt($strCPF.substring(9, 10))) {
		return false;
	}
	Soma = 0;
	for (let i = 1; i <= 10; i++)
		Soma = Soma + parseInt($strCPF.substring(i - 1, i)) * (12 - i);
	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;
	if (Resto !== parseInt($strCPF.substring(10, 11))) {
		return false;
	}
	return true;
};

export const validPhone = (phone: string) => {
	const phoneNumber = phone.replace(/[^\d]+/g, '');
	if (phoneNumber.length !== 11) return false;
	if (phoneNumber[2] !== '9') return false;
	return true;
};

export const validSalary = (amount: string) => {
	const salary = parseFloat(amount);
	if (salary < 1000) return false;
	return true;
};

export const emptyValidation = (value: string | null) => {
	if (!value) return false;
	if (value.trim().length > 0) return true;
	return false;
};

export const validFileExtension = (file: any, acceptPdf = false) => {
	const acceptedFormats = ['jpeg', 'jpg', 'png'];
	if (acceptPdf) acceptedFormats.push('pdf');
	for (let index = 0; index < acceptedFormats.length; index++) {
		if (file.type.includes(acceptedFormats[index])) {
			return true;
		}
	}
	return false;
};

export const validDocumentExtension = (file: File) => {
	const acceptedFormats = ['pdf', 'doc', 'docx', 'msword'];
	for (let index = 0; index < acceptedFormats.length; index++) {
		if (file.type.includes(acceptedFormats[index])) {
			return true;
		}
	}
	return false;
};

export const validNickname = (nickname: string) => {
	if (nickname === null || nickname.length < 1) return false;
	if (nickname?.trim().split(' ').length < 1) return false;

	return true;
};
