import KYCRepository from '../repositories/kycRepository';

import { dataLayerPush } from '../../../shared/utils/dataLayer/dataLayerPush';

import { IDTO } from '../types/types';

interface IResultGetSteps {
	string: string;
	steps: any; // vai guardar o response.data
	first_step: string;
	next_step: string;
	lote: number | null;
	files: number;
}

class KYCController {
	repository: KYCRepository;
	dto: IDTO;

	constructor() {
		this.repository = new KYCRepository();
		this.dto = {} as IDTO;
	}

	async getSteps(transaction: string) {
		const result = await this.repository.getSteps(transaction);

		if (result.status !== 200) {
			const resultCustom: IResultGetSteps = {
				string: result.data.message,
				steps: {},
				first_step: '',
				next_step: '',
				lote: null,
				files: 0,
			};

			return { result: resultCustom, error: true, redirect: null };
		}

		const instruction = this.getInstructionString(result.data.next_step);

		// if (instruction.length === 0 || firstStep === 'thanks') {
		//   return { result: '', error: false, redirect: `/taker/order/${transaction}/formalizacao/thanks` }
		// }

		const resultCustom: IResultGetSteps = {
			string: instruction,
			steps: result.data,
			first_step: result.data.first_step,
			next_step: result.data.next_step,
			lote: result.data.lote_extra_file,
			files: result.data.uploaded_files,
		};

		return {
			result: resultCustom,
			redirect: null,
			error: false,
		};
	}

	async handleExpiredProcess(result: any, transaction: string) {
		if (result.data.code === '50004')
			return {
				resultExpired: result,
				errorExpired: true,
				redirectExpired: `/taker/order/${transaction}/formalizacao`,
			};

		return { resultExpired: result, errorExpired: false, redirectExpired: '' };
	}

	async submitSelfie(transaction: string) {
		this.dto.file_name = 'selfie.jpeg';
		const result = await this.repository.submitSelfie(transaction, this.dto);

		const expiredProcess = await this.handleExpiredProcess(result, transaction);

		if (expiredProcess.errorExpired)
			return { result, error: false, expiredProcess, redirect: null };

		if (result.status !== 200)
			return { result, error: true, expiredProcess, redirect: null };

		const steps = await this.getSteps(transaction);

		return {
			result,
			error: false,
			expiredProcess,
			redirect: `/taker/order/${transaction}/formalizacao/${steps.result.next_step}`,
		};
	}

	async submitCNH(transaction: string) {
		this.dto.file_name = 'cnh.jpeg';
		this.dto.finish_process = true;

		const result = await this.repository.submitDocument(transaction, this.dto);

		const expiredProcess = await this.handleExpiredProcess(result, transaction);

		if (expiredProcess.errorExpired)
			return { result, error: false, expiredProcess, redirect: null };

		if (result.status !== 200)
			return { result, error: true, expiredProcess, redirect: null };

		dataLayerPush({
			event: 'conversion',
			data: {
				name: 'documents_submitted',
				transaction_id: transaction,
			},
		});

		const steps = await this.getSteps(transaction);

		return {
			result,
			error: false,
			expiredProcess,
			redirect: `/taker/order/${transaction}/formalizacao/${steps.result.next_step}`,
		};
	}

	async submitRGFront(transaction: string) {
		this.dto.file_name = 'rg_front.png';
		this.dto.finish_process = false;

		const result = await this.repository.submitDocument(transaction, this.dto);

		const expiredProcess = await this.handleExpiredProcess(result, transaction);

		if (expiredProcess.errorExpired)
			return { result, error: false, expiredProcess, redirect: null };

		if (result.status !== 200)
			return { result, error: true, expiredProcess, redirect: null };

		const steps = await this.getSteps(transaction);

		return {
			result,
			error: false,
			expiredProcess,
			redirect: `/taker/order/${transaction}/formalizacao/${steps.result.next_step}`,
		};
	}

	async submitRGBack(transaction: string) {
		this.dto.file_name = 'rg_back.png';
		this.dto.finish_process = true;

		const result = await this.repository.submitDocument(transaction, this.dto);

		const expiredProcess = await this.handleExpiredProcess(result, transaction);

		if (expiredProcess.errorExpired)
			return {
				result,
				error: false,
				expiredProcess,
				redirect: expiredProcess.redirectExpired,
			};

		if (result.status !== 200)
			return {
				result,
				error: true,
				expiredProcess,
				redirect: expiredProcess.redirectExpired,
			};

		dataLayerPush({
			event: 'conversion',
			data: {
				name: 'documents_submitted',
				transaction_id: transaction,
			},
		});

		const steps = await this.getSteps(transaction);

		return {
			result,
			error: false,
			expiredProcess,
			redirect: `/taker/order/${transaction}/formalizacao/${steps.result.next_step}`,
		};
	}

	getInstructionString(step: string) {
		switch (step) {
			case 'selfie':
				return 'Tire uma selfie';

			case 'doc_front':
				return 'Foto do RG ou CNH';

			case 'doc_back':
				return 'Foto do RG ou CNH';

			case 'bank_ext':
				return 'Envie um Extrato Bancário';

			case 'contacts':
				return 'Adicione um contato';

			default:
				return '';
		}
	}
}

export default KYCController;
