import { AiFillPlusCircle } from 'react-icons/ai';

import Banner from '../../assets/Assignor/banner-woman.webp';

import OnShop from '../../../../assets/images/landingPage/icons/online-shopping.png';
import CreditCard from '../../../../assets/images/landingPage/icons/credit-card.png';
import CreditCard2 from '../../../../assets/images/landingPage/icons/credit-card-2.png';
import MoneyBag from '../../../../assets/images/landingPage/icons/money-bag.png';
import Phone from '../../../../assets/images/landingPage/icons/phone.png';
import Phone2 from '../../../../assets/images/landingPage/icons/phone-2.png';
import WomanSearching from '../../assets/Assignor/woman-searching.webp';
import Faixa from '../../assets/Assignor/black-dashed-lines.svg';

import MediaFolha from '../../assets/media/logo_folha.png';
import MediaExame from '../../assets/media/logo_exame.png';
import MediaCredDigital from '../../assets/media/logo_seu_credito_digital.png';
import MediaTechTudo from '../../assets/media/logo_techtudo.png';

import Lines from '../../assets/Assignor/blue-dashed-lines.svg';
import BlackLines from '../../assets/Assignor/black-dashed-lines.svg';

import DepoimentoDouglas from '../../assets/Assignor/depoimento-cedente-douglas-v2.png';
import DepoimentoEduardo from '../../assets/Assignor/depoimento-cedente-eduardo-souza.png';
import DepoimentoSilvia from '../../assets/Assignor/depoimento-cedente-silvia-takey-v2.png';

import { Header } from '../../shared/components/Header';
import { Cards } from '../../shared/components/Card';
import { Social } from '../../shared/components/Social';
import { Footer } from '../../shared/components/Footer';
import { CopyRight } from '../../shared/components/Copyright';

import { ButtonDivStyled } from './styles';
import { Accordion } from '../../shared/components/Accordion';

export function LandingPageAssignor() {
	const navLinks = [
		{
			href: '#como',
			text: 'Como funciona?',
		},
		{
			href: '/companies',
			text: 'Para empresas',
		},
		{
			href: '/taker/landing-page',
			text: 'Parcelar Boleto',
		},
		{
			href: 'https://blog.virtuspay.com.br/',
			text: 'Blog',
		},
		{
			href: 'https://ajuda.usevirtus.com.br',
			text: 'FAQ',
		},
		{
			href: 'https://ajuda.usevirtus.com.br/support/solutions',
			text: 'Precisa de ajuda?',
			onlyPhone: true,
		},
	];

	const title = (
		<h1 className="is-size-2-desktop is-size-4-mobile ms-font has-text-white has-text-weight-bold">
			Acelere os{' '}
			<span className="has-text-primary">
				pontos do <br /> seu cartão
			</span>{' '}
			e ajude <br /> outras pessoas!
		</h1>
	);

	const subTitle = (
		<p className="has-text-white my-5 is-size-7-mobile">
			Aproveite aquele limite do cartão de crédito que sobra todo <br /> mês
			para
			<b className="has-text-weight-semibold"> acelerar seus pontos</b> e ainda
			ajuda outras pessoas <br /> a realizarem seus sonhos!
		</p>
	);

	const buttonsTouch = (
		<ButtonDivStyled className=" is-hidden-desktop">
			<button className="is-6 button button-primary">
				<p className="has-text-weight-semibold">Acelerar pontos</p>
			</button>
		</ButtonDivStyled>
	);

	const buttonsDesktop = (
		<ButtonDivStyled className=" is-hidden-touch">
			<button className="is-6 has-text-centered-mobile button button-primary">
				<p className="has-text-weight-semibold">Acelerar pontos</p>
			</button>
		</ButtonDivStyled>
	);

	const product = [
		{
			img: OnShop,
			titleCard: '1. Crie sua conta',
			text: 'Simples e rápido! 100% grátis e 100% digital!',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
		{
			img: CreditCard,
			titleCard: '2. Cadastre até 3 cartões!',
			text: 'Você pode oferecer até R$ 80mil de limite em cada cartão!',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
		{
			img: CreditCard2,
			titleCard: '3. Vamos usar seu limite',
			text: 'O valor que você disponibilizar será usado pela VirtusPay para ajudar outras pessoas!',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
		{
			img: MoneyBag,
			titleCard: '4. Acelere seus pontos!',
			text: 'A partir do valor que a VirtusPay usar você já começa a acumular.',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
		{
			img: Phone,
			titleCard: '5. Vamos te pagar de volta!',
			text: 'Antes do vencimento da fatura, o valor usado vai cair na sua conta.',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
		{
			img: Phone2,
			titleCard: '6. Aproveite',
			text: 'Enquanto você acelera seus pontos, também ajuda muitas Roses!',
			width: '50px',
			widthDiv: 'is-4-desktop',
		},
	];

	return (
		<>
			<Header
				whichScreen="assignor"
				backgroundImage={Banner}
				navLinks={navLinks}
				title={title}
				subTitle={subTitle}
				buttonsTouch={buttonsTouch}
				buttonsDesktop={buttonsDesktop}
				textColor="#ffffff"
			/>

			<div id="como">
				<Cards
					margin="is-12-desktop is-10-mobile"
					background="has-background-white"
					title="Como funciona?"
					product={product}
				/>
			</div>

			<section id="acelerar" className="column py-6 is-12 container">
				<div className="container">
					<div className="columns mt-4">
						<div className="column is-flex is-align-items-start is-justify-content-center is-flex-direction-column is-6-desktop">
							<img src={Faixa} alt="Linhas separadoras pretas" />
							<h2 className="ms-font mt-5 is-size-2 has-text-weight-bold">
								Bora acelerar <br />
								seus pontos?
							</h2>

							<p className="ms-font my-6 pr-6" style={{ lineHeight: '2rem' }}>
								Com a VirtusPay você pode oferecer o limite que anda sobrando em
								seu cartão de crédito, ajudar quem precisa desse limite, e ainda
								acumular pontos, sem gastar nada! Após criar sua conta aqui na
								VirtusPay, você pode adicionar até 3 cartões (com limite de até
								R$ 80 mil por cartão)
							</p>

							<a
								href="/assignor/"
								className="ms-font has-text-weight-bold is-size-4 has-text-black"
							>
								Criar conta <i className="fas fa-arrow-right"></i>
							</a>
						</div>

						<div className="column is-6-desktop">
							<img src={WomanSearching} alt="Bora acelerar seus pontos?" />
						</div>
					</div>
				</div>
			</section>

			<div id="beneficios" className="has-background-light">
				<section className="column is-12 container py-6">
					<div className="container">
						<h2 className="ms-font mt-5 is-size-2 has-text-weight-bold">
							Benefícios
						</h2>

						<div className="is-flex is-align-items-center mt-4">
							<div className="is-flex is-align-items-start is-justify-content-center is-flex-direction-column benefits-card my-2 mx-2">
								<img src={Lines} alt="Linhas separadoras azuis" />
								<h2 className="ms-font mt-3 is-size-4 has-text-weight-bold has-text-white">
									Use todo o limite!
								</h2>
								<p
									className="mt-3"
									style={{ lineHeight: '1.7rem', color: '#FFFFFFAA' }}
								>
									Informe quanto você quer oferecer a VirtusPay e seja pago de
									volta direto na conta corrente, sem precisar comprar.
								</p>
							</div>

							<div className="is-flex is-align-items-start is-justify-content-center is-flex-direction-column benefits-card my-2 mx-2">
								<img src={Lines} alt="Linhas separadoras azuis" />
								<h2 className="ms-font mt-3 is-size-4 has-text-weight-bold has-text-white">
									Ajude várias pessoas!
								</h2>
								<p
									className="mt-3"
									style={{ lineHeight: '1.7rem', color: '#FFFFFFAA' }}
								>
									Ao oferecer seu limite, você está ajudando a VirtusPay a
									realizar compras parceladas de quem não tem esse limite.
								</p>
							</div>

							<div className="is-flex is-align-items-start is-justify-content-center is-flex-direction-column benefits-card my-2 mx-2">
								<img src={Lines} alt="Linhas separadoras pretas" />
								<h2 className="ms-font mt-3 is-size-4 has-text-weight-bold has-text-white">
									100% digital e seguro!
								</h2>
								<p
									className="mt-3"
									style={{ lineHeight: '1.7rem', color: '#FFFFFFAA' }}
								>
									{/* Somos correspondentes bancários do BMP Money Plus e seguimos
									os mais altos padrões de segurança dos principais bancos. */}
									Seguimos os mais altos padrões de segurança dos principais bancos.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div className="has-background-light">
				<section className="column container is-12 py-6">
					<div className="container">
						<h2 className="ms-font mt-5 is-size-2 has-text-weight-bold">
							Depoimentos
						</h2>

						<div className="columns mt-3 ms-font">
							<div className="column is-desktop-4">
								<img src={BlackLines} alt="Linhas separadoras pretas" />
								<div className="is-flex is-flex-direction-row is-align-items-center mt-2">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												src={DepoimentoSilvia}
												alt="Depoimento Silvia"
											/>
										</div>
										<div>
											<b className="has-text-weight-semibold">Silvia Taciano</b>{' '}
											<br />
											Cedente há 1 ano
										</div>
									</p>
								</div>
								<p className="mt-3">
									&lsquo;Muito além do ganho de milhas, que foi completamente
									descomplicado achei legal o fato de contribuir para o sistema
									de microcrédito e ajudar as empresas e seus clientes.&lsquo;
								</p>
							</div>

							<div className="column is-desktop-4">
								<img
									className="is-hidden-desktop"
									src={BlackLines}
									alt="Linhas separadoras pretas"
								/>

								<div className="is-flex is-flex-direction-row is-align-items-center mt-2">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												src={DepoimentoDouglas}
												alt="Depoimento Douglas"
											/>
										</div>
										<div>
											<b className="has-text-weight-semibold">Douglas Luzio</b>{' '}
											<br />
											Cedente há 2 anos
										</div>
									</p>
								</div>

								<p className="my-3">
									&lsquo;Minha experiência foi simples e tranquila. Achei o site
									objetivo e as instruções deixaram o processo bem claro para
									mim.&lsquo;
								</p>

								<img
									className="is-hidden-mobile"
									src={BlackLines}
									alt="Linhas separadoras pretas"
								/>
							</div>

							<div className="column is-desktop-4">
								<img src={BlackLines} alt="Linhas separadoras pretas" />
								<div className="is-flex is-flex-direction-row is-align-items-center mt-2">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												src={DepoimentoEduardo}
												alt="Depoimento Eduardo"
											/>
										</div>
										<div>
											<b className="has-text-weight-semibold">Eduardo Souza</b>{' '}
											<br />
											Cedente há 1 ano
										</div>
									</p>
								</div>
								<p className="mt-3">
									&lsquo;VirtusPay é espetacular e rápida! O pagamento é feito
									direitinho antes do vencimento da fatura de forma que não dá
									trabalho algum e acelera o acúmulo de milhas.&lsquo;
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className="column is-12 container py-6">
				<div className="container">
					<h2 className="ms-font mt-5 is-size-2 has-text-weight-bold">
						Dúvidas comuns
					</h2>

					<div className="column is-12 py-0 mt-3">
						<Accordion
							ask={
								<p
									className="accordion is-size-5 my-2 is-flex is-justify-content-space-between is-align-items-center"
									style={{ backgroundColor: '#fafafa', padding: '1rem' }}
								>
									Como posso acelerar os pontos do meu cartão?
									<AiFillPlusCircle
										className="is-size-4 has-text-blue"
										size={35}
									/>
								</p>
							}
						>
							<div className="panel">
								<p>
									Se você tem limite sobrando em seu cartão de crédito, você
									pode ajudar quem precisa desse limite e ainda acumular pontos,
									sem gastar nada! Após criar sua conta aqui na VirtusPay, você
									pode adicionar
									<strong>
										até 3 cartões (com limite de até R$80 mil por cartão)
									</strong>{' '}
									para usarmos seu limite.
								</p>
							</div>
						</Accordion>
					</div>

					<div className="column is-12 py-0">
						<Accordion
							ask={
								<p
									className="accordion is-size-5 my-2 is-flex is-justify-content-space-between is-align-items-center"
									style={{ backgroundColor: '#fafafa', padding: '1rem' }}
								>
									Como posso acelerar os pontos do meu cartão?
									<AiFillPlusCircle
										className="is-size-4 has-text-blue"
										size={35}
									/>
								</p>
							}
						>
							<div className="panel">
								<p>
									O limite de seus cartões poderá ser usado todos os meses,
									<strong>e cada transação será paga em 12x.</strong>
									<br />
									<br />
									Para cada pagamento, a VirtusPay irá transferir o valor
									utilizado naquele mesmo mês para a conta bancária que você
									cadastrou. Ou seja, suas transações parceladas também serão
									pagas de forma parcelada. A cada parcela paga, uma nova
									transação no seu cartão já poderá ser feita. Assim o limite
									cedido será 100% utilizado e você potencializa o ganho de
									pontos do cartão!
									<br />
									<br />
									Exemplo: Você disponibilizou um valor total mensal de R$12.000
									para ser transacionado. Após o pagamento de cada uma das 12x
									parcelas de R$1.000,00 teremos um saldo positivo de R$1000,00
									para transacionar novamente no mês seguinte.
									<br />
									<br />
									Lembrando que o limite mínimo de reutilização é de R$500 e
									máximo do valor cedido que você disponibilizou no cadastro.
								</p>
							</div>
						</Accordion>
					</div>

					<div className="column is-12 py-0">
						<Accordion
							ask={
								<p
									className="accordion is-size-5 my-2 is-flex is-justify-content-space-between is-align-items-center"
									style={{ backgroundColor: '#fafafa', padding: '1rem' }}
								>
									Como posso acelerar os pontos do meu cartão?
									<AiFillPlusCircle
										className="is-size-4 has-text-blue"
										size={35}
									/>
								</p>
							}
						>
							<div className="panel">
								<p>
									Claro! Você pode editar o valor do limite oferecido por cartão
									a qualquer momento em sua conta aqui na VirtusPay. Adicione
									até 3 cartões com limite de até R$80 mil por cartão, como
									quiser!
								</p>
							</div>
						</Accordion>
					</div>

					<div className="column is-12 py-0">
						<Accordion
							ask={
								<p
									className="accordion is-size-5 my-2 is-flex is-justify-content-space-between is-align-items-center"
									style={{ backgroundColor: '#fafafa', padding: '1rem' }}
								>
									Como posso acelerar os pontos do meu cartão?
									<AiFillPlusCircle
										className="is-size-4 has-text-blue"
										size={35}
									/>
								</p>
							}
						>
							<div className="panel">
								<p>
									Na criação da sua conta, você também irá{' '}
									<strong>cadastrar a conta bancária</strong> em que deseja
									receber os pagamentos de acordo com o valor do limite usado
									pela VirtusPay. Sempre depositamos o valor da parcela do
									limite utilizado{' '}
									<strong>um dia antes do vencimento da sua fatura</strong> via
									Ted nessa mesma conta.
								</p>
							</div>
						</Accordion>
					</div>
				</div>

				<div className="is-12 has-text-centered mt-6">
					<a
						href="https://boletopop.usevirtus.com.br/duvidas-frequentes-cedentes"
						className="ms-font has-text-weight-bold is-size-5 has-text-black"
					>
						Ver todas <i className="fas fa-arrow-right"></i>
					</a>
				</div>
			</section>

			<div>
				<section className="column is-10 container py-6" id="media">
					<div className="columns is-flex is-5 is-hidden-touch">
						<div className="column is-12-desktop">
							<h2 className="ms-font my-5 is-size-2 has-text-weight-bold">
								VirtusPay na mídia
							</h2>
							<div className="columns mt-5  is-flex flex align-items-center is-justify-content-space-between is-hidden-touch is-align-items-center is-justify-content-center">
								<div className="column is-2-desktop">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.techtudo.com.br/listas/2021/06/quatro-aplicativos-de-credito-para-quem-nao-tem-cartao.ghtml"
									>
										<img
											alt="Notícia TechTudo"
											className="lazyload news-images"
											src={MediaTechTudo}
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://seucreditodigital.com.br/apps-de-credito-para-quem-nao-tem-cartao/"
									>
										<img
											alt="Notícia Seu Crédito Digital"
											className="lazyload news-images"
											src={MediaCredDigital}
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www1.folha.uol.com.br/mpme/2021/05/pagamentos-digitais-facilitam-a-operacao-de-pequenos-negocios.shtml"
									>
										<img
											alt="Notícia Folha"
											className="noticia-folha lazyload"
											src={MediaFolha}
											width="100%"
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://exame.com/pme/dinheiro-sobrando-startup-troca-limite-no-cartao-de-credito-por-milhas/"
									>
										<img
											alt="Notícia Exame"
											className="lazyload"
											src={MediaExame}
											width="100px"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="columns is-flex is-5 is-hidden-desktop">
						<div className="column is-12-touch">
							<h2 className="ms-font my-5 is-size-2 has-text-weight-bold">
								VirtusPay na mídia
							</h2>
							<div className="columns mt-5  is-flex flex align-items-center is-justify-content-space-between is-align-items-center is-justify-content-center">
								<div className="column is-2-touch">
									<a
										href="https://www.techtudo.com.br/listas/2021/06/quatro-aplicativos-de-credito-para-quem-nao-tem-cartao.ghtml"
										target="_blank"
										rel="noreferrer"
									>
										<img
											alt="Notícia TechTudo"
											className="lazyload news-images"
											src={MediaTechTudo}
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a
										href="https://exame.com/pme/startup-que-troca-limite-no-cartao-por-milhas-recebe-aporte-de-r-6-mi/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											alt="Notícia Exame"
											className="lazyload news-images"
											src={MediaExame}
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a
										href="https://www1.folha.uol.com.br/mpme/2021/05/pagamentos-digitais-facilitam-a-operacao-de-pequenos-negocios.shtml"
										target="_blank"
										rel="noreferrer"
									>
										<img
											alt="Notícia Folha"
											className="noticia-folha lazyload news-images"
											src={MediaFolha}
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a
										href="https://seucreditodigital.com.br/apps-de-credito-para-quem-nao-tem-cartao/"
										target="_blank"
										rel="noreferrer"
									>
										<img
											alt="Notícia Seu Crédito Digital"
											className="lazyload news-images"
											src={MediaCredDigital}
											style={{ maxHeight: '60px' }}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div id="Social">
				<Social margin="is-11-desktop" color="#fafafa" />
			</div>

			<div id="Footer">
				<Footer margin="is-11-desktop" color="#000" type="assignor" />
			</div>

			<div id="CopyRight">
				<CopyRight
					margin="is-12-desktop"
					textColor="rgba(204, 204, 204, 0.659)"
					color="#303030"
				/>
			</div>
		</>
	);
}
