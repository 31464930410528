import Swal from 'sweetalert2';

import {
	chargebackRepository,
	changeDLRepository,
	getDLRepository,
	validUserRepository,
	callBanksRepository,
} from '../repository';

import { BankData, ChangeDLProps } from '../types/types';

export async function validUserController(transaction: string) {
	const result = await validUserRepository(transaction);

	if (result.status !== 200) {
		Swal.fire({
			icon: 'error',
			text:
				result?.data.message ??
				'Essa proposta não apresenta problemas com o pagamento',
			confirmButtonText: 'Fechar',
		});

		return { error: true };
	}

	return { error: false };
}

export async function chargebackController(
	transaction: string,
	dataRequest: BankData
) {
	const result = await chargebackRepository(transaction, dataRequest);

	if (result.status !== 200) return { result: result, error: true };

	return { error: false };
}

export async function getDLController(transaction: string) {
	const result = await getDLRepository(transaction);

	if (result.status !== 200) return { result, error: true };

	return { result: result.data, error: false };
}

export async function changeDLController(
	transaction: string,
	dataRequest: ChangeDLProps
) {
	const result = await changeDLRepository(transaction, dataRequest);

	if (result.status !== 200) return { result: result, error: true };

	return { error: false };
}

export async function callBanksController() {
	const result = await callBanksRepository();

	if (result.status !== 200) {
		Swal.fire({
			icon: 'error',
			text:
				result?.data.message ??
				'Erro ao obter a lista de bancos. Tente novamente em alguns minutos.',
			confirmButtonText: 'Fechar',
		});

		return { error: true };
	}

	return { result: result.data, error: false };
}
