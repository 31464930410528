import Swal from 'sweetalert2';

import AuthRepository from '../repositories/authRepository';

import { config } from '../../config.json';

import dotenv from 'dotenv';
dotenv.config();

const repository = new AuthRepository();

class AuthController {
	username = '';
	password = '';
	captcha = '';
	profilesAccepted =
		config.environment === 'PROD'
			? ['tomador']
			: ['tomador', 'cedente', 'vendedor', 'admin'];

	isAuthenticated = localStorage.getItem('token') !== null;

	async login(redirect = true, withCredentials = false) {
		if (!withCredentials) {
			const login = await repository.auth({
				username: this.username,
				password: this.password,
				captcha: this.captcha,
			});

			if (login.status !== 200) {
				Swal.fire({
					icon: 'error',
					confirmButtonText: 'Fechar',
					text: 'Não foi possível realizar seu login',
				});

				return;
			}
		}

		const response = await this.getUser();
		const user = response.data;

		if (!this.profilesAccepted.includes(user.profile)) {
			Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Você não tem acesso a esse conteúdo.',
			});

			return;
		}

		if (redirect) {
			if (user.incomplete_step === 'complementar') {
				window.location.href = '/register/2';
				return;
			} else if (user.incomplete_step === 'endereco') {
				window.location.href = '/register/3';
				return;
			}
		}

		if (response.status === 200) {
			return { response };
		}
	}

	async facebookLogin(data: {
		profile: { email: string; name: string; id: string };
	}) {
		const dto = {
			email: data.profile.email,
			social_id: data.profile.id,
			name: data.profile.name,
		};

		const result = await repository.loginWithFacebook(dto);

		if (result.data.is_social && result.data.is_user) {
			localStorage.setItem('token', result.data.jwt.access);
			localStorage.setItem('refresh', result.data.jwt.refresh);

			const userRequest = await repository.getUser();

			if (!this.profilesAccepted.includes(userRequest.data.profile)) {
				return Swal.fire({
					icon: 'error',
					confirmButtonText: 'Fechar',
					text: 'Você não tem acesso a esse conteúdo.',
				});
			}

			if (userRequest.status === 200)
				localStorage.setItem('user', JSON.stringify(userRequest.data));

			if (userRequest.data.incomplete_step === 'complementar') {
				return (window.location.href = '/register/2');
			} else if (userRequest.data.incomplete_step === 'endereco') {
				return (window.location.href = '/register/3');
			}

			if (userRequest.status === 200) return (window.location.href = '/taker');
		}
	}

	async facebookError(data: { message: string }) {
		Swal.fire({
			text: data.message,
			icon: 'error',
		});
	}

	async getUser() {
		const result = await repository.getUser();

		return result;
	}

	async getUserFromCore(token: any) {
		const result = await repository.getUserFromCore(token);

		if (result.status === 200) {
			localStorage.setItem('token', result.data.token);
			localStorage.setItem('refresh', result.data.refresh);

			const user = await this.getUser();

			if (user.data.incomplete_step === 'complementar') {
				return (window.location.href = '/register/2');
			} else if (user.data.incomplete_step === 'endereco') {
				return (window.location.href = '/register/3');
			}

			if (user.status === 200)
				localStorage.setItem('user', JSON.stringify(user.data));

			if (user.data.profile === 'tomador') window.location.href = '/taker';
			if (user.data.profile === 'cedente' && config.environment !== 'PROD')
				window.location.href = '/assignor';
		} else {
			window.location.href = '/login';
		}
		return result;
	}

	logout(redirect = true, funcRedirect: () => void) {
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		if (redirect) funcRedirect();
	}
}

export default AuthController;
