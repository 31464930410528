const statusOptions = new Map([
	['APV', 'Aprovada'],
	['PEN', 'Pendente'],
	['REC', 'Recusada'],
	['ERR', 'Erro'],
	['CAN', 'Cancelada'],
	['ANA', 'Analisada'],
	['EFE', 'Efetivada'],
	['EXE', 'Executando'],
	['DOC', 'Documentos'],
	['PAG', 'Pago'],
	['VEN', 'Vencida'],
	['ENV', 'Enviado'],
	['INA', 'Inativo'],
	['AUT', 'Autorizada'],
	['CED', 'Cedido'],
]);

const displayStatus = (status: string) => {
	return statusOptions.get(status) ?? status;
};

export { displayStatus };
