import styled from 'styled-components';

export const PasswordDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 10px;
	padding: 5px 10px;

	height: 83px;

	background: #f8f8f8;
	border-radius: 4px;
`;

export const PasswordLIDivStyled = styled.div`
	width: 48%;
`;

export const PasswordTitleStyled = styled.h1`
	position: static;
	margin-left: 15px;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 130%;

	display: flex;
	align-items: center;

	color: #4a4a4a;

	margin: 10px 0px;
`;

export const PasswordULStyled = styled.li`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	color: #4a4a4a;

	list-style: inherit;
`;

export const PasswordLIStyled = styled.li`
	width: 100%;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;

	color: #4a4a4a;

	margin: 0 10px 5px;
`;
