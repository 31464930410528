import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	ContainerStyled,
	TileDivStyled,
	RowDivStyled,
	DesktopDivStyled,
	Desktop2DivStyled,
	GapDivStyled,
	SmallPStyled,
	PendenciasDivStyled,
	CouponTitleStyled,
	MobileCouponDivStyled,
	CouponRulesStyled,
	RowRulesDivStyled,
	SmallRulesPStyled,
	GapMobileDivStyled,
} from '../../styles';

import { Button } from '../../../../shared/components/index';

import { colors } from '../../../../shared/styles/colors';
import { setCookie } from '../../../../shared/utils/cookies';

import { CouponProps } from '../../types/types';
import { useState } from 'react';

export function CouponTile({
	coupon,
	text,
	dute,
	textButton,
}: // haveShareButton,
CouponProps) {
	const [isOpen, setIsOpen] = useState(false);

	const history = useHistory();

	// Botão de copiar
	// const copyCoupon = () => {
	// 	navigator.clipboard.writeText(coupon);

	// 	return Swal.fire({
	// 		icon: 'success',
	// 		text: 'Cupom copiado com sucesso!',
	// 		confirmButtonText: 'Okay',
	// 	});
	// };
	// const renderCopyButton = () => {
	// 	return (
	// 		<Button
	// 			onClick={copyCoupon}
	// 			width="170px"
	// 			text="Copiar cupom"
	// 			color="#fff"
	// 			padding="12px 7px"
	// 			hoverColor="#fff"
	// 			textColor={colors.primary}
	// 			border={`1px solid ${colors.primary}`}
	// 		/>
	// 	);
	// };

	const copyCoupon = () => {
		setCookie('coupon', coupon, 0, 0, 0, 20);

		navigator.clipboard.writeText(coupon);

		return Swal.fire({
			icon: 'success',
			text: 'Cupom copiado com sucesso!',
			confirmButtonText: 'Okay',
		}).then(() => history.push('/taker/order/add'));
	};

	const couponTile = () => {
		return (
			<>
				<TileDivStyled>
					<RowDivStyled>
						<DesktopDivStyled>
							<CouponTitleStyled>{coupon}</CouponTitleStyled>
						</DesktopDivStyled>

						<Desktop2DivStyled>
							<SmallPStyled style={{ margin: '0 1rem', fontSize: '17px' }}>
								{text}
							</SmallPStyled>

							<PendenciasDivStyled>
								<Button
									onClick={copyCoupon}
									width="170px"
									padding="12px 7px"
									text={textButton}
									dataTestid="buttonSubmit"
									color={colors.primary}
									hoverColor={colors.purple}
									textColor="white"
								/>
							</PendenciasDivStyled>
						</Desktop2DivStyled>
					</RowDivStyled>
					<GapDivStyled
						style={{
							height: '1px',
							margin: '1rem 3rem',
							width: '90%',
						}}
					/>

					<div style={{ margin: '0 2.5rem' }}>
						{' '}
						{isOpen ? (
							<SmallRulesPStyled>
								1 - Usando o seu cupom, você terá desconto na primeira parcela
								da proposta aprovada e efetivada; <br />2 - A oferta garante
								desconto para propostas aprovadas e efetivadas, da seguinte
								forma: R$ 50,00 para propostas efetivadas no valor entre R$
								850,00 a R$ 1.200,00 e R$ 100,00 para propostas efetivadas no
								valor acima R$ 1.200,01
								<br />3 - Entende-se por proposta efetivadas, as propostas
								enviadas para parcelamento de compra, nas lojas aceitas pela
								VirtusPay, que foram aprovadas e consequentemente pagas a
								primeira parcela (entrada). <br />
								<a
									target="_blank"
									rel="noreferrer"
									href="https://boletopop.usevirtus.com.br/oferta-regulamento"
								>
									Ler mais
								</a>
							</SmallRulesPStyled>
						) : (
							''
						)}
					</div>
					<RowRulesDivStyled style={{ margin: '0.7rem 0 0 0' }}>
						<div>
							<CouponRulesStyled
								onClick={() => setIsOpen((previousState) => !previousState)}
							>
								{isOpen ? 'Ocultar' : 'Ver regras'}
							</CouponRulesStyled>
						</div>

						<div>
							<SmallRulesPStyled style={{ margin: '0 2.5rem 0 0' }}>
								validade: {dute.length < 1 ? 'inderteminado' : dute}
							</SmallRulesPStyled>
						</div>
					</RowRulesDivStyled>
				</TileDivStyled>

				<MobileCouponDivStyled>
					<CouponTitleStyled>{coupon}</CouponTitleStyled>
					<GapMobileDivStyled
						style={{
							height: '1px',
							margin: '0.8rem 1rem 0',
							width: '90%',
						}}
					/>
					<SmallPStyled>{text}</SmallPStyled>

					<PendenciasDivStyled>
						<Button
							onClick={copyCoupon}
							width="250px"
							padding="12px 7px"
							text={textButton}
							color={colors.primary}
							hoverColor={colors.purple}
							textColor="white"
						/>
					</PendenciasDivStyled>

					<div style={{ margin: '1rem 0.7rem 0' }}>
						{' '}
						{isOpen ? (
							<SmallRulesPStyled>
								1 - Usando o seu cupom, você terá desconto na primeira parcela
								da proposta aprovada e efetivada; <br />2 - A oferta garante
								desconto para propostas aprovadas e efetivadas, da seguinte
								forma: R$ 50,00 para propostas efetivadas no valor entre R$
								850,00 a R$ 1.200,00 e R$ 100,00 para propostas efetivadas no
								valor acima R$ 1.200,01
								<br />3 - Entende-se por proposta efetivadas, as propostas
								enviadas para parcelamento de compra, nas lojas aceitas pela
								VirtusPay, que foram aprovadas e consequentemente pagas a
								primeira parcela (entrada). <br />
								<a
									target="_blank"
									rel="noreferrer"
									href="https://boletopop.usevirtus.com.br/oferta-regulamento"
								>
									Ler mais
								</a>
							</SmallRulesPStyled>
						) : (
							''
						)}
					</div>
					<RowRulesDivStyled>
						<div>
							<CouponRulesStyled
								onClick={() => setIsOpen((previousState) => !previousState)}
							>
								{isOpen ? 'Ocultar' : 'Ver regras'}
							</CouponRulesStyled>
						</div>

						<div>
							<SmallRulesPStyled>
								validade: {dute.length < 1 ? 'inderteminado' : dute}
							</SmallRulesPStyled>
						</div>
					</RowRulesDivStyled>
				</MobileCouponDivStyled>
			</>
		);
	};

	return (
		<>
			<ContainerStyled>{couponTile()}</ContainerStyled>
		</>
	);
}
