interface IProducts {
	img: string;
	titleCard: string;
	text: string;
	width: string;
	widthDiv: string;
}

interface ICards {
	margin?: string;
	title?: string;
	product: IProducts[];
	background?: string;
}

export function Cards({ margin, title, product, background }: ICards) {
	return (
		<div id="produtos" className={`${background}`}>
			<section className={`column py-2 ${margin} container`}>
				<h2
					className="has-text-weight-semibold mt-4 has-text-black has-text-left is-size-4 has-text-weight-semibold"
					style={{ font: 'normal normal 600 35px/63px Montserrat' }}
				>
					{title}
				</h2>

				<div className="is-hidden-touch">
					<div className="column mb-5 is-multiline is-flex is-flex-direction-row container is-justify-content-space-between mt-5">
						<div className="columns is-multiline is-flex is-flex-direction-row container is-justify-content-space-between">
							{product.map((item: IProducts) => (
								<div
									key={item.titleCard}
									className={`column is-10-mobile ${item.widthDiv} is-flex is-justify-content-center is-flex-direction-column`}
								>
									<img
										className="lazyload mt-5 is-justify-content-start"
										style={{ width: item.width }}
										src={item.img}
										alt=""
									/>
									<p className="is-size-6 has-text-weight-semibold mt-3 is-12 has-text-black">
										{item.titleCard}
									</p>
									<p className="is-size-7 is-12 mt-2 has-text-weight-normal has-text-black">
										{item.text}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="is-hidden-desktop">
					<div className="column mb-5 is-multiline is-flex is-flex-direction-row container is-justify-content-space-between mt-3 is-12">
						<div className="columns is-multiline is-flex is-flex-direction-row container is-justify-content-space-between  mt-5">
							{product.map((item: IProducts) => (
								<div
									key={item.titleCard}
									className="column is-4 is-12-mobile is-flex is-justify-content-center is-align-items-center is-flex-direction-column"
								>
									<img
										className="lazyload is-justify-content-start"
										style={{ width: item.width }}
										src={item.img}
										alt=""
									/>
									<p className="is-size-6 has-text-weight-semibold mt-3 is-12 has-text-black">
										{item.titleCard}
									</p>
									<p className="is-size-7 is-12 mt-2 has-text-weight-normal has-text-centered has-text-black">
										{item.text}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
