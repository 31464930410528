import styled from 'styled-components';

const Image = styled.img`
	height: auto;
	max-width: 86%;
	border-radius: 8px;

	@media (min-width: 600px) {
		max-width: 50%;
	}
`;

function Canvas({ base64 }: any) {
	return <Image src={base64} alt="Preview" />;
}

export default Canvas;
