import styled from 'styled-components';

export const ButtonDivStyled = styled.div`
	.button {
		padding: 12px 40px !important;
		border-radius: 8px !important;
		font-size: 0.95rem !important;
		display: inline-block;
		border: none;
		cursor: pointer !important;

		width: 251px;
		height: 46px;
	}

	.button:hover {
		text-decoration: none;
		transition: all 0.5s;
	}

	.button-primary {
		background-color: #26dbd6 !important;
		color: black !important;
	}

	.button-primary:hover {
		background-color: #17c2ce !important;
		color: black !important;
	}

	.button-primary-outline {
		border: 1px solid #26dbd6;
		background-color: #fefefe00;
		outline: none;
	}

	.button-primary-outline:hover {
		background-color: #26dbd6;
		color: black;
		outline: none;
	}

	.button-primary-outline:active,
	.button-primary-outline:focus {
		outline: none;
	}
`;
