import axios from '../../../shared/http/customAxios';

import { BankData, ChangeDLProps } from '../types/types';

export async function validUserRepository(transaction: string) {
	try {
		const result = await axios().get(
			`v1/order/${transaction}/request-chargeback`
		);
		return result;
	} catch (error: any) {
		return error.response;
	}
}

export async function chargebackRepository(
	transaction: string,
	dataRequest: BankData
) {
	try {
		const result = await axios().post(
			`v1/order/${transaction}/request-chargeback`,
			dataRequest
		);
		return result;
	} catch (error: any) {
		return error.response;
	}
}

export async function getDLRepository(transaction: string) {
	try {
		const result = await axios().get(
			`v1/order/${transaction}/request-digitable-line-change`
		);
		return result;
	} catch (error: any) {
		return error.response;
	}
}

export async function changeDLRepository(
	transaction: string,
	dataRequest: ChangeDLProps
) {
	try {
		const result = await axios().put(
			`v1/order/${transaction}/request-digitable-line-change`,
			dataRequest
		);
		return result;
	} catch (error: any) {
		return error.response;
	}
}

export async function callBanksRepository() {
	try {
		const result = await axios().get(`v1/banks`);
		return result;
	} catch (error: any) {
		return error.response;
	}
}
