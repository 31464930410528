import { IFile } from '..';
class ContactDto {
	name = '';
	email = '';
	subject = null;
	phone = '';
	message = '';
	captcha = false;
	rcptch_version = 'v2'
	cv: IFile = {} as IFile;
}

export default ContactDto;
