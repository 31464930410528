import { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { IoCardOutline } from 'react-icons/io5';

import { InputUncontrolled, Button, Select } from '../index';
import Swal from 'sweetalert2';

import Logo from '../../../assets/images/logo_virtuspay_azulgrad_200.png';
import { moneyMask } from '../../utils/masks';

import CreditController from './controller/creditController';
import CreditFormValidator from './utils/validator';
import CreditRepository, {
	ICreditPayload,
} from './repositories/creditRepository';

import {
	TextAmountStyled,
	ContainerStyled,
	TitleStyled,
	BlueAmountStyled,
	CardDivStyled,
	ErrorStyled,
	ButtonDivStyled,
	HalfInputStyled,
	InputDivStyled,
	WrapDivStyled,
	InfoStyled,
} from './styles';

import { dueMonth, dueYear } from '../../variables';
import { useLoading } from '../../../hooks/loading';

export function CreditPayment({
	transaction,
	id,
	receivableAmount,
	creditCardPaid,
}: ICreditPayload) {
	const [holderName, setHolderName] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [brand, setBrand] = useState('');
	const [code, setCode] = useState('');
	const [dueMonthChange, setDueMonthChange] = useState('');
	const [dueYearChange, setDueYearChange] = useState('');
	const [expiry, setExpiry] = useState('');
	const [focus, setFocus] = useState();

	const [validName, setValidName] = useState(false);
	const [validDueMonth, setValidDueMonth] = useState(false);
	const [validValidDueYear, setValidDueYear] = useState(false);
	const [validCardNumber, setValidCardNumber] = useState(false);
	const [validCode, setValidCode] = useState(false);

	const { setIsLoading } = useLoading();

	// eslint-disable-next-line
	useEffect(() => {
		getBrand();
	}, []);

	const creditController = new CreditController();
	const creditRepository = new CreditRepository();
	const validator = new CreditFormValidator();

	const getBrand = async () => {
		const cardNumberFormat = cardNumber.replace(/[^\d]+/g, '');

		if (cardNumberFormat.length >= 6) {
			const retorno = await creditRepository.getCall(cardNumberFormat);
			setBrand(retorno.brandName);
		}
	};

	const submitCreditCard = async () => {
		if (
			!validDueMonth ||
			!validValidDueYear ||
			!validName ||
			!validCardNumber ||
			!validCode
		) {
			return Swal.fire({
				icon: 'error',
				text: 'Preencha todos os campos corretamente para enviar',
			});
		}
		setIsLoading(true);
		const payload = {
			holder_name: holderName,
			card_number: cardNumber,
			code: code,
			exp_month: dueMonthChange,
			exp_year: dueYearChange,
			brand: brand,
		};

		const { result, error } = await creditController.submitCreditCard({
			transaction,
			receivableAmount,
			id,
			payload,
		});

		if (error) {
			return Swal.fire({
				title: !result.data.message && 'Ops! Cartão Inválido',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Verifique os dados do seu cartão e tente novamente',
			}).then(() => {
				setIsLoading(false);
			});
		} else {
			return Swal.fire({
				icon: 'success',
				text: 'Você receberá em breve nosso e-mail confirmando a efetivação da sua compra na loja! Aguarde =) ',
				confirmButtonText: 'Entendi',
			}).then(() => {
				if (creditCardPaid) {
					creditCardPaid();
				}
				setIsLoading(false);
			});
		}
	};

	function mergeMonthAndYear(value: string, isYear: boolean) {
		if (isYear) {
			setDueYearChange(value);
			setExpiry(`${dueMonthChange}/${value}`);
		} else {
			setDueMonthChange(value);
			setExpiry(`${value}/${dueYearChange}`);
		}
	}

	const validateName = () => {
		const valid = validator.validateName(holderName);
		setValidName(valid);
	};

	const validateDueMonth = () => {
		const valid = validator.validateDueMonth(dueMonthChange);
		setValidDueMonth(valid);
	};

	const validateDueYear = () => {
		const valid = validator.validateDueYear(dueYearChange);
		setValidDueYear(valid);
	};

	const validateCardNumber = () => {
		const valid = validator.validateCardNumber(cardNumber);
		setValidCardNumber(valid);
	};

	const validateCodeNumber = () => {
		const valid = validator.validateCodeNumber(code);
		setValidCode(valid);
	};

	const template = () => {
		return (
			<ContainerStyled>
				<div style={{ marginTop: '1rem' }}>
					<img src={Logo} alt="Logo VirtusPay" />
				</div>

				<TitleStyled>Pagamento com cartão</TitleStyled>
				<div>
					<CardDivStyled>
						<div className="App">
							<Cards
								locale={{ valid: 'Validade' }}
								placeholders={{ name: 'NOME COMPLETO' }}
								number={cardNumber}
								name={holderName}
								expiry={expiry}
								cvc={code}
								focused={focus}
							/>
						</div>

						<TextAmountStyled>
							Entrada de{' '}
							<BlueAmountStyled>{moneyMask(receivableAmount)}</BlueAmountStyled>{' '}
							no cartão <br />
							<small>As demais parcelas você pagará com boleto báncario</small>
						</TextAmountStyled>
					</CardDivStyled>

					<div>
						<div>
							<InputUncontrolled
								type="name"
								name="name"
								value={holderName}
								onChange={(e) => setHolderName(e.target.value)}
								onFocus={(e) => setFocus(e.target.name)}
								onBlur={validateName}
								id="name"
								placeholder="Nome no Cartão"
							/>
							<ErrorStyled id="name-error-message"></ErrorStyled>
						</div>

						<div>
							<InputUncontrolled
								id="cardNumber"
								type="tel"
								name="numero"
								value={cardNumber}
								mask="9999 9999 9999 9999"
								onChange={(e) => setCardNumber(e.target.value)}
								onFocus={(e) => setFocus(e.target.name)}
								onBlur={validateCardNumber}
								onKeyPress={getBrand}
								placeholder="Número do cartão"
							/>
							<ErrorStyled id="cardNumber-error-message"></ErrorStyled>
						</div>
					</div>

					<WrapDivStyled>
						<HalfInputStyled>
							<Select
								onBlur={validateDueMonth}
								id="dueMonth"
								onChange={(e) => mergeMonthAndYear(e.target.value, false)}
								options={dueMonth}
								value={dueMonthChange}
								data-type="cel"
								label="Mês"
							/>
							<ErrorStyled id="dueMonth-error-message"></ErrorStyled>
						</HalfInputStyled>

						<InputDivStyled>
							<Select
								onBlur={validateDueYear}
								id="dueYear"
								onChange={(e) => mergeMonthAndYear(e.target.value, true)}
								value={dueYearChange}
								options={dueYear}
								data-type="text"
								label="Ano"
							/>
							<ErrorStyled id="dueYear-error-message"></ErrorStyled>
						</InputDivStyled>

						<HalfInputStyled>
							<InputUncontrolled
								name="cvc"
								onFocus={(e) => setFocus(e.target.name)}
								onBlur={validateCodeNumber}
								id="codeNumber"
								onChange={(e) => setCode(e.target.value)}
								type="tel"
								value={code}
								placeholder="CVV"
							/>
							<ErrorStyled id="codeNumber-error-message"></ErrorStyled>
						</HalfInputStyled>

						<InputDivStyled>
							<InfoStyled>
								<IoCardOutline size={33} /> Código de 3 dígitos impresso no
								verso do seu cartão
							</InfoStyled>
						</InputDivStyled>
					</WrapDivStyled>

					<ButtonDivStyled>
						<Button
							width="300px"
							className="has-gradient-blue"
							text="Confirmar pagamento"
							onClick={submitCreditCard}
						/>
					</ButtonDivStyled>
				</div>
			</ContainerStyled>
		);
	};

	return <>{template()}</>;
}
