import {
	ContainerStyled,
	ContainerReturnStyled,
	ContainerTemplateStyled,
	FourthDivStyled,
	ItemsDivStyled,
	PItemsStyled,
	CenterDivStyled,
	TitleStyled,
	CenterDivStyledLogo,
} from './styles';

import onlineShopping from '../../assets/images/takerStores/online-shopping.png';
import tiles from '../../assets/images/takerStores/tiles.png';
import trackPad from '../../assets/images/takerStores/trackpad.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_400.png';

import { SearchStore } from './components/searchStore';

export function TakerStores() {
	const templateTakerStores = () => (
		<ContainerStyled>
			<ContainerTemplateStyled>
				<CenterDivStyledLogo>
					<img src={Logo} alt="Logo VirtusPay" width="280px" />
				</CenterDivStyledLogo>
				<CenterDivStyled>
					<TitleStyled>Veja como é fácil comprar!</TitleStyled>
				</CenterDivStyled>
				<FourthDivStyled>
					<ItemsDivStyled>
						<img src={onlineShopping} alt="Compras Online" />
						<PItemsStyled>
							Faça uma compra de <br /> <b>R$ 150</b> a <b>R$ 10.000</b> nas
							lojas aceitas.
						</PItemsStyled>
					</ItemsDivStyled>
					<ItemsDivStyled>
						<img src={tiles} alt="Compras Online" />
						<PItemsStyled>
							Escolha pagar sua compra com <b>boleto à vista</b>
						</PItemsStyled>
					</ItemsDivStyled>
					<ItemsDivStyled>
						<img src={trackPad} alt="Compras Online" />
						<PItemsStyled>
							Envie o boleto para <br /> <b>parcelar com a gente!</b>
						</PItemsStyled>
					</ItemsDivStyled>
				</FourthDivStyled>
				<CenterDivStyled>
					<p>
						Compre em uma das <b> lojas abaixo</b>
					</p>
					<SearchStore />
				</CenterDivStyled>
			</ContainerTemplateStyled>
		</ContainerStyled>
	);

	return (
		<>
			<ContainerReturnStyled>{templateTakerStores()}</ContainerReturnStyled>
		</>
	);
}
