import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import {
	ContainerStyled,
	DetailsStyled,
	MainStyled,
	DivDetailsStyled,
	ContainerDetailsStyled,
	ContainerRenderAllStyled,
	TitleStyled,
	ButtonDivStyled,
	SpanGreyStyled,
	SpanGreenStyled,
	CenterDivStyled,
	TitleViajaNetStyled,
	DivBoxStyled,
	Title2ViajaNetStyled,
	SpanViajaNetStyled,
	H2ViajaNetStyled,
	PViajaNetStyled,
	P2ViajaNetStyled,
	TextRenderStyled,
	SpanRenderStyled,
	RenderDivStyled,
	PRenderStyled,
	FirstDivDetailsStyled,
} from './styles';

import Swal from 'sweetalert2';
import dateFormat from 'dateformat';

import { ContractSheet } from './components/Sheet/sheet';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';

import CCBController from './controllers/ccbController';

import { Button } from '../../shared/components/index';

import eventPush from '../../shared/utils/eventPush';
import { checkOrderNextStep } from '../../shared/utils/checkOrderNextStep';
import { moneyMask } from '../../shared/utils/masks';
import { redirectReturnUrlVtex } from '../../shared/utils/redirectReturnUrlVtex';

import OrderNavbar from '../order/components/OrderNavbar';

import { IParams, ICostumers } from './index.interface';
import { useLoading } from '../../hooks/loading';

export const Ccb = () => {
	// const [termsAccepted, setTermsAccepted] = useState(false);
	const [interest, setInterest] = useState('');
	const [orderAmount, setOrderAmount] = useState('');
	const [installmentValue, setInstallmentValue] = useState('');
	const [counterProposal, setCounterProposal] = useState(false);
	const [amountInstallments, setAmountInstallments] = useState('');
	const [downPayment, setDownPayment] = useState('');
	const [cet, setCet] = useState('');
	const [cetText, setCetText] = useState('');
	const [iof, setIof] = useState('');
	const [dueDate, setDueDate] = useState('');
	const [company_name, setCompany_name] = useState('');
	const [openedContract, setOpenedContract] = useState(false);
	const [installment, setInstallment] = useState('');
	const [customer, setCustomer] = useState<ICostumers>({} as ICostumers);
	const [returnUrl, setReturnUrl] = useState(null);
	const [channel, setChannel] = useState('');

	const [hasCupom, setHasCupom] = useState(false);
	const [discountValue, setDiscountValue] = useState('');
	const [amountOriginal, setAmountOriginal] = useState<string>('');

	const [hasGracePeriodGt0, setHasGracePeriodGt0] = useState(false);
	const [showSheet, setShowSheet] = useState(false);

	const { setIsLoading, isLoading } = useLoading();
	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();

	const controller = new CCBController();
	controller.dto.transaction = id;

	useEffect(() => {
		getCCB();
		// eslint-disable-next-line
		return () => {
			setShowSheet(false);
		};
	}, []);

	const getCCB = async () => {
		setIsLoading(true);

		const { result, error } = await controller.get();

		const nextStep = result.next_step;
		const actualPage = 'TakerOrderSignature';

		const { redirectPage } = checkOrderNextStep(nextStep, actualPage, id);
		if (redirectPage) return history.push(redirectPage);

		redirectReturnUrlVtex(returnUrl, channel, id);

		setIsLoading(false);

		if (result.ccb_signature) {
			return Swal.fire({
				icon: 'error',
				text: 'Essa CCB já foi assinada',
				confirmButtonText: 'Fechar',
			}).then(() => {
				history.push('/taker/order/' + id);
			});
		}

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message ?? 'Não foi possível carregar seu contrato',
				confirmButtonText: 'Fechar',
			}).then(() => {
				history.push('/taker/order/' + id);
			});
		}

		setCustomer(result.customer);
		setIsLoading(false);
		setInterest(result.interest);
		setInstallmentValue(result.installment_value);
		setAmountInstallments(result.amount_installments);
		setCet(result.cet_monthly ?? result.cet_anual);
		setCetText(result.cet_monthly ? 'a.m.' : 'a.a.');
		setIof(result.iof);
		setDueDate(result.first_due_date);
		setDownPayment(result.down_payment);
		setCounterProposal(result.counterproposal);
		// setTermsAccepted(result.company_name === 'ViajaNet');
		setCompany_name(result.company_name);
		setOpenedContract(false);
		setInstallment(result.installment);
		setReturnUrl(null);
		setChannel('');
		setHasGracePeriodGt0(result.has_grace_period_gt_0);
		setShowSheet((state) => !state);

		setHasCupom(result.has_coupon);
		setDiscountValue(result.discount_value);
		setAmountOriginal(result.total_amount);
		setOrderAmount(result.total_amount_with_discount);

		setIsLoading(false);
	};

	const showContract = () => {
		setOpenedContract(true);
	};

	const validateSignature = async () => {
		setIsLoading(true);

		eventPush('web_ccb_continuar', 'INFO', {
			vencimento: dueDate,
			loja: company_name,
			valor: amountOriginal ? amountOriginal : orderAmount,
			num_parc: installment,
			ld: id,
		});

		const { result, error } = await controller.get();

		setIsLoading(false);

		// if (!termsAccepted) {
		//   return Swal.fire({
		//     icon: 'error',
		//     text: 'Você precisa aceitar os termos para continuar seu parcelamento',
		//     confirmButtonText: 'Fechar'
		//   });
		// }

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
				confirmButtonText: 'Fechar',
			}).then(() => {
				history.push('/taker/order/' + id);
			});
		}

		setIsLoading(true);

		const { resultSubmit, errorSubmit, redirect } = await controller.submit();

		if (redirect) history.push(redirect);

		if (errorSubmit) {
			return Swal.fire({
				icon: 'error',
				text: resultSubmit.data.message,
				confirmButtonText: 'Fechar',
			}).then(() => {
				history.push('/taker/order/' + id);
			});
		}

		// setTermsAccepted(false);

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	// const toggleTerms = () => {
	//   setTermsAccepted(!termsAccepted);
	// }

	const renderButton = () => {
		return (
			<ButtonDivStyled>
				<Button
					width="100%"
					onClick={validateSignature}
					text="Continuar"
					className="has-gradient-blue"
					textColor="#fff"
					disabled={isLoading}
				/>
			</ButtonDivStyled>
		);
	};

	const renderTerms = () => {
		return (
			<CenterDivStyled>
				<RenderDivStyled>
					{counterProposal ? (
						<PRenderStyled>
							A sua nova condição já foi aprovada pela nossa equipe de Crédito!
							A condição contratual acima indica somente o valor da entrada, não
							considere o valor e quantidade das demais parcelas! Após o
							pagamento da primeira parcela, as demais parcelas serão ajustadas
							de acordo com a condição especial definida com a área de Crédito e
							que foi informada por e-mail. Qualquer dúvida entre em contato com
							o Atendimento VirtusPay.
						</PRenderStyled>
					) : (
						''
					)}
					{hasGracePeriodGt0 ? (
						<p>
							Valor final a ser pago para essa compra é de{' '}
							<strong>{moneyMask(orderAmount)}</strong> até o vencimento no dia{' '}
							{dateFormat(dueDate, 'dd/mm/yyyy')}.
						</p>
					) : (
						''
					)}
					<p>
						Ao continuar, declaro estar ciente e de acordo com os termos e
						condições do&nbsp;
						<SpanRenderStyled
							onClick={() => {
								eventPush('web_ccb_contrato_d_cred', 'INFO', {
									vencimento: dueDate,
									loja: company_name,
									valor: amountOriginal ? amountOriginal : orderAmount,
									num_parc: installment,
									ld: id,
								});
								showContract();
							}}
						>
							contrato de crédito.
						</SpanRenderStyled>
					</p>
				</RenderDivStyled>
			</CenterDivStyled>
		);
	};

	const anyCoupon = () => {
		if (hasCupom) {
			return (
				<ContainerDetailsStyled>
					<FirstDivDetailsStyled props={counterProposal}>
						<DetailsStyled>
							Valor total da compra:{' '}
							<SpanGreyStyled>{moneyMask(amountOriginal)}</SpanGreyStyled> por{' '}
							<SpanGreenStyled>{moneyMask(orderAmount)}</SpanGreenStyled>
						</DetailsStyled>

						<DetailsStyled>
							Qt de parcelas: <strong>{installment}</strong>
						</DetailsStyled>

						<DetailsStyled>
							Desconto total:{' '}
							<SpanGreenStyled>{moneyMask(discountValue)}</SpanGreenStyled>
						</DetailsStyled>

						<>
							{counterProposal && (
								<DetailsStyled>
									Entrada: <strong>{moneyMask(downPayment)}</strong>
								</DetailsStyled>
							)}
						</>

						<DetailsStyled>
							Valor total das parcelas:{' '}
							<strong>{moneyMask(installmentValue)}</strong>
						</DetailsStyled>
						<DetailsStyled>
							Valor total a pagar:{' '}
							<strong>{moneyMask(amountInstallments)}</strong>
						</DetailsStyled>
					</FirstDivDetailsStyled>
					<DivDetailsStyled props={counterProposal}>
						<DetailsStyled>
							Juros: <strong>{interest}% a.m.</strong>
						</DetailsStyled>
						<DetailsStyled>
							CET:{' '}
							<strong>
								{cet} {cetText}
							</strong>
						</DetailsStyled>
						<DetailsStyled>
							IOF: <strong>{moneyMask(iof)}</strong>
						</DetailsStyled>
						<DetailsStyled>
							Vencimento da {hasGracePeriodGt0 ? 'compra' : 'primeira parcela'}:{' '}
							<strong>{dateFormat(dueDate, 'dd/mm/yyyy')}</strong>
						</DetailsStyled>
					</DivDetailsStyled>
				</ContainerDetailsStyled>
			);
		} else {
			return (
				<ContainerDetailsStyled>
					<FirstDivDetailsStyled props={counterProposal}>
						<DetailsStyled>
							Valor da compra: <strong>{moneyMask(orderAmount)}</strong>
						</DetailsStyled>
						<DetailsStyled>
							Qt de parcelas: <strong>{installment}</strong>
						</DetailsStyled>
						<>
							{counterProposal && (
								<DetailsStyled>
									Entrada: <strong>{moneyMask(downPayment)}</strong>
								</DetailsStyled>
							)}
						</>
						<DetailsStyled>
							Valor total das parcelas:{' '}
							<strong>{moneyMask(installmentValue)}</strong>
						</DetailsStyled>
						<DetailsStyled>
							Valor total a pagar:{' '}
							<strong>{moneyMask(amountInstallments)}</strong>
						</DetailsStyled>
					</FirstDivDetailsStyled>
					<DivDetailsStyled props={counterProposal}>
						<DetailsStyled>
							Juros: <strong>{interest}% a.m.</strong>
						</DetailsStyled>
						<DetailsStyled>
							CET:{' '}
							<strong>
								{cet} {cetText}
							</strong>
						</DetailsStyled>
						<DetailsStyled>
							IOF: <strong>{moneyMask(iof)}</strong>
						</DetailsStyled>
						<DetailsStyled>
							Vencimento da {hasGracePeriodGt0 ? 'compra' : 'primeira parcela'}:{' '}
							<strong>{dateFormat(dueDate, 'dd/mm/yyyy')}</strong>
						</DetailsStyled>
					</DivDetailsStyled>
				</ContainerDetailsStyled>
			);
		}
	};

	const renderAll = () => {
		return (
			<ContainerRenderAllStyled>
				<TitleStyled>
					Revise os dados{' '}
					{hasGracePeriodGt0 ? 'da sua compra' : 'do seu parcelamento'}:
				</TitleStyled>

				{anyCoupon()}
				{renderTerms()}

				<ButtonDivStyled>{renderButton()}</ButtonDivStyled>
			</ContainerRenderAllStyled>
		);
	};

	const renderTermsViajaNet = () => {
		return (
			<CenterDivStyled>
				<RenderDivStyled>
					{counterProposal ? (
						<p>
							A sua nova condição já foi aprovada pela nossa equipe de Crédito!
							A condição contratual acima indica somente o valor da entrada, não
							considere o valor e quantidade das demais parcelas! Após o
							pagamento da primeira parcela, as demais parcelas serão ajustadas
							de acordo com a condição especial definida com a área de Crédito e
							que foi informada por e-mail. Qualquer dúvida entre em contato com
							o Atendimento VirtusPay.
						</p>
					) : (
						''
					)}
					<TextRenderStyled>
						Ao continuar, declaro ter ciência e concordo com todas as cláusulas
						e condições da
						<SpanRenderStyled onClick={showContract}>
							&nbsp;Cédula de Crédito Bancário
						</SpanRenderStyled>
						de forma livre, expressa, inequívoca e informada.
					</TextRenderStyled>
				</RenderDivStyled>
			</CenterDivStyled>
		);
	};

	const renderViajaNet = () => {
		return (
			<CenterDivStyled>
				<TitleViajaNetStyled>Resumo do seu parcelamento</TitleViajaNetStyled>

				<DivBoxStyled>
					<CenterDivStyled>
						<Title2ViajaNetStyled>
							<SpanViajaNetStyled>{installment}x</SpanViajaNetStyled> de{' '}
							<SpanViajaNetStyled>
								{moneyMask(installmentValue)}
							</SpanViajaNetStyled>
						</Title2ViajaNetStyled>
						<H2ViajaNetStyled>
							Valor total: {moneyMask(amountInstallments)}
						</H2ViajaNetStyled>
						<PViajaNetStyled>
							Juros de {interest}% a.m. CET de {cet} a.m.
						</PViajaNetStyled>
						<p>Vencimento da primeira parcela:</p>
						<P2ViajaNetStyled>
							{dateFormat(dueDate, 'dd/mm/yyyy')}
						</P2ViajaNetStyled>
					</CenterDivStyled>
				</DivBoxStyled>

				{renderTermsViajaNet()}

				<ButtonDivStyled>{renderButton()}</ButtonDivStyled>
			</CenterDivStyled>
		);
	};

	return (
		<ContainerStyled>
			<OrderNavbar src={Logo} />

			<MainStyled>
				{company_name === 'ViajaNet' ? renderViajaNet() : renderAll()}
				{showSheet ? (
					<ContractSheet
						customer={customer}
						state={{
							orderAmount,
							cet,
							dueDate,
							installment,
							installmentValue,
							interest,
							iof,
							amountInstallments,
							hasGracePeriodGt0,
						}}
						isOpened={openedContract}
						onClose={() => {
							setOpenedContract(false);
						}}
					/>
				) : null}
			</MainStyled>
		</ContainerStyled>
	);
};
