import styled from 'styled-components';
import { colors } from '../../styles';

export interface CheckBoxI {
	areTerms: boolean;
}

export const ContainerStyled = styled.div`
	height: 20px;
`;

export const RawCheckboxStyled = styled.input<CheckBoxI>`
	appearance: none;
	cursor: pointer;
	position: relative;
	width: ${({ areTerms }) => (areTerms ? '23px' : '20px')};
	height: ${({ areTerms }) => (areTerms ? '23px' : '20px')};
	border-radius: ${({ areTerms }) => (areTerms ? '2px' : '50%')};
	border: ${({ areTerms }) =>
		areTerms ? '' : '1px solid rgba(10, 10, 10, 0.4)'};
	padding: 0;
	&:checked::after {
		content: '';
		display: inline-block;
		position: absolute;
		left: ${({ areTerms }) => (areTerms ? '6px' : '')};
		transform: ${({ areTerms }) => (areTerms ? 'rotate(45deg)' : '')};
		height: 18px;
		width: ${({ areTerms }) => (areTerms ? '10px' : '18px')};
		border-bottom: ${({ areTerms }) =>
			areTerms ? `4.5px solid ${colors.primary}` : ''};
		border-right: ${({ areTerms }) =>
			areTerms ? `5px solid ${colors.primary}` : ''};
		background-color: ${({ areTerms }) =>
			areTerms ? '' : `${colors.primary}`};
		border-radius: ${({ areTerms }) => (areTerms ? '' : '50%')};
	}
`;
