import { useCallback, useEffect } from 'react';

import { Button } from './style';

import './styles.scss';
export interface InstallmentButtonProps {
	entrada: string;
	restante: number;
	total: string;
	installments: number;
	interest?: string;
	active: boolean;
	firstButton?: boolean;
	valorRestante: string | number;
	defaultInstallment?: boolean;

	onSelect: (
		installments: number,
		entrada: string,
		restante: number,
		total: string,
		interest?: string
	) => void;
}

export function InstallmentButton({
	entrada,
	restante,
	total,
	onSelect,
	installments,
	interest,
	active,
	firstButton,
}: InstallmentButtonProps) {
	const toggleActive = useCallback(() => {
		onSelect(installments, entrada, restante, total, interest);
	}, [onSelect, installments, restante, entrada, total, interest]);

	useEffect(() => {
		if (firstButton) toggleActive();
	}, [firstButton, toggleActive]);

	return (
		<Button
			data-id={'installments-option-' + installments}
			onClick={(e) => {
				e.preventDefault();
				toggleActive();
			}}
			className={`is-flex is-justify-content-center 
          is-align-items-center installment-button 
          ${active && 'installment-active'}`}
		>
			<p className="has-text-weight-bold">{installments + 'x'}</p>
		</Button>
	);
}
