import { config } from '../../../config.json';

declare global {
	interface Window {
		dataLayer: any;
	}
}

const dataLayerPush = (object: any) => {
	if (config.environment !== 'PROD') {
		return;
	}
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push(object);
};

export { dataLayerPush };
