import { useEffect, useLayoutEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

// import { FacebookProvider, Login as FBLogin } from 'react-facebook';

// import { FaFacebookF } from 'react-icons/fa';

import { useForm } from 'react-hook-form';

import {
	fetchAndActivate,
	getBoolean,
	getRemoteConfig,
} from 'firebase/remote-config';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import SignUpController from './controllers/signUpController';

import {
	BlueTitleStyled,
	ContainerStyled,
	CenterDivStyled,
	InstructionsDivStyled,
	ErrorStyled,
	ButtonDivStyled,
	PStyled,
	SpanStyled,
} from './styles';

import { EmailToken } from './components/EmailToken/index';

import { firebase } from '../../firebase';

// import { config } from '../../config.json';

import {
	initializeAppCheck,
	ReCaptchaV3Provider,
	getToken,
} from 'firebase/app-check';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/signUp/step-1.png';

import ValidatorController from '../../shared/controllers/validatorController';

import {
	Button,
	Input,
	InputPassword,
	CheckPassword,
	InfoPassword,
} from '../../shared/components/index';

import colors from '../../shared/variables';
import getCookie from '../../shared/utils/cookies';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';
import { isValidToken } from '../../shared/utils/isValidToken';
import eventPush from '../../shared/utils/eventPush';
import { hashEmailSha256 } from '../../shared/utils/hashes';

export interface FormData {
	nickName: string;
	email: string;
	password: string;
	confirmPassword: string;
}

export interface PayloadStepOne {
	email: string;
	password: string;
	nickname: string;
	epar: string | null;
	ad_id: string | null;
	captcha: string;
	rcptch_version: string;
	profile: string;
	version: string;
	celcoin?: string;
}

const schema = Yup.object().shape({
	nickname: Yup.string().trim().nullable(),
	email: Yup.string().email().required('Digite seu email'),
	password: Yup.string()
		.required('Digite uma senha senha')
		.matches(/[a-z]/, 'Sua senha precisa ter no mínimo um caractere minúsculo.')
		.matches(/[A-Z]/, 'Sua senha precisa ter no mínimo um caractere maiúsculo.')
		.matches(/[0-9]/, 'Sua senha precisa ter no mínimo um caractere numérico.')
		.min(8, 'Sua senha precisa ter no mínimo 8 caracteres.'),
	confirmPassword: Yup.string()
		.required('Repita a nova senha')
		.oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas'),
});

const validatorController = new ValidatorController();

const validateEmail = async (email: string) => {
	const result = await validatorController.validateEmail(email);

	const data = result.data;

	if (!data.valid) {
		return { errorEmail: true, message: 'Digite um email válido' };
	} else if (data.has_user) {
		return { errorEmail: true, message: 'Email já cadastrado' };
	}

	return { errorEmail: false, message: '' };
};

export const SignUpStepOne = () => {
	const appCheck = initializeAppCheck(firebase, {
		provider: new ReCaptchaV3Provider(
			'6LeB980bAAAAAAL5rRueBMzqR9MMRMRwOWvxMg6F'
		),
	});

	// const [cupom] = useState(null);
	const [email, setEmail] = useState('');
	const [emailPayload, setEmailPayload] = useState(null);
	const [testAB, setTestAB] = useState<boolean | null>(null);
	const [isTestAB, setIsTestAB] = useState(false);
	const [profile, setProfile] = useState('');
	const [epar, setEpar] = useState<string | null>(null);
	const [adId, setAdId] = useState<string | null>(null);

	const history = useHistory();
	const { search } = useLocation();

	const controller = new SignUpController();
	// const validator = new Validator();
	// const authController = new AuthController();

	const { setData, setTypeUser, getUser, user } = useAuth();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
	} = useForm({ resolver: yupResolver(schema) });

	const { setIsLoading } = useLoading();
	const password: string = watch('password');

	const remoteConfig = getRemoteConfig(firebase);
	fetchAndActivate(remoteConfig);
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	useLayoutEffect(() => {
		loadTest();
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const checkUser = async () => {
			if (isValidToken()) {
				if (user?.email !== null && user?.email !== undefined && !testAB)
					return history.push('/register/2');
			}
			setTimeout(() => {
				setIsLoading(false);
			}, 500);
		};

		checkUser(); // eslint-disable-next-line
	}, [user]);

	const profileUser = localStorage.getItem('user');

	useEffect(() => {
		if (profileUser) {
			const profileParse = JSON.parse(profileUser);
			setProfile(profileParse.profile);
			const localUser = getUser();
			if (!localUser.email) setTypeUser(profileParse.profile);
		}
		// } else {
		// 	if (config.environment !== 'PROD' && user.profile === undefined) {
		// 		Swal.fire({
		// 			html: `<h2 class='is-size-3 has-text-blue'>Por que você está se cadastrando?</h2>`,
		// 			iconColor: `#28C0CD`,
		// 			icon: 'question',
		// 			showDenyButton: true,
		// 			confirmButtonText: 'Quero PARCELAR uma compra online',
		// 			denyButtonText: 'Quero OFERECER LIMITE do meu cartão de crédito',
		// 			denyButtonColor: `${colors.primaryBlue}`,
		// 			confirmButtonColor: `${colors.primaryBlue}`,
		// 		}).then((result) => {
		// 			if (result.isConfirmed) {
		// 				eventPush('web_pq_cadastro_quero_parc', 'INFO');
		// 				setProfile('tomador');
		// 				setTypeUser('tomador');
		// 			} else if (result.isDenied) {
		// 				eventPush('web_pq_cadastro_quero_of_lim', 'INFO');
		// 				setProfile('cedente');
		// 				setTypeUser('cedente');
		// 			}
		// 		});
		// 	}
		// }
	}, []);

	useEffect(() => {
		setIsLoading(true);

		const promoCodeCookie = getCookie('promo_code');
		const eparCookie = getCookie('epar');
		const adIdCookie = getCookie('ad_id');

		if (promoCodeCookie || eparCookie) setEpar(promoCodeCookie ?? eparCookie);
		if (adIdCookie) setAdId(adIdCookie);

		const query = new URLSearchParams(search);
		const tokenURL = query.get('token') ?? '';

		const checkUser = async () => {
			const { result, error } = await controller.preRegister(tokenURL);

			if (error) {
				setIsLoading(false);
			} else {
				return Swal.fire({
					icon: 'success',
					text: 'Muito bem, agora basta prosseguir com o cadastro!',
					confirmButtonText: 'Entendi',
				}).then(() => {
					setIsLoading(false);

					setValue('email', result.email, { shouldValidate: true });
					setEmail(result.email);
					setEmailPayload(result.email.trim().toLowerCase());

					localStorage.setItem('full_name', result.full_name);
					localStorage.setItem('phone_number', result.phone_number);
					localStorage.setItem('cpf', result.cpf);
					localStorage.setItem('epar', result.epar);
					localStorage.setItem('ad_id', result.ad_id);

					if (result.epar) setEpar(result.epar);
					if (result.ad_id) setAdId(result.ad_id);
				});
			}
		};

		if (tokenURL) {
			checkUser();
		}

		// eslint-disable-next-line
	}, []);

	const setTest = () => {
		const isTestLdHome = getBoolean(remoteConfig, 'teste_token_signup');
		const testFromFirebase = {
			teste_token_signup: isTestLdHome,
		};
		setTestAB(isTestLdHome);
		localStorage.setItem('testAB', JSON.stringify(testFromFirebase));
	};

	const loadTest = () => {
		const testFromLocalStorage = localStorage.getItem('testAB');

		if (!testFromLocalStorage) {
			return setTest();
		} else {
			const testJson = JSON.parse(testFromLocalStorage);
			if (
				testJson.teste_token_signup === undefined ||
				testJson.teste_token_signup === null
			) {
				return setTest();
			}
			setTestAB(testJson.teste_token_signup);
		}
	};

	// const facebookSignUpHandler = async (data: any) => {
	// 	setEmail(data.profile.email);
	// 	const documentElement: HTMLElement = document.querySelector('#email')!;
	// 	documentElement.focus();
	// };

	// const FacebookSignUp = () => {
	// 	return (
	// 		<FacebookProvider appId={facebookId}>
	// 			<FBLogin
	// 				scope="email"
	// 				onCompleted={facebookSignUpHandler}
	// 				onError={authController.facebookError}
	// 			>
	// 				{(handleClick: any) => (
	// 					<span onClick={handleClick}>
	// 						<button
	// 							className="button py-5 px-4 mx-1"
	// 							style={{ borderRadius: '10px', background: '#3d5a98' }}
	// 						>
	// 							<FaFacebookF className="has-text-white is-size-5" />
	// 						</button>
	// 					</span>
	// 				)}
	// 			</FBLogin>
	// 		</FacebookProvider>
	// 	);
	// };

	// const placeType = () => {
	// 	if (window.innerWidth <= 900) return 'left';

	// 	if (window.innerWidth > 900) return 'right';
	// };

	const userType = (form: FormData) => {
		confirmEmail(form);
		// if (config.environment === 'PROD') {
		// return validateForm(form, 'tomador');
		// }
		// if (!profile) {
		// 	return Swal.fire({
		// 		html: `<h2 class='is-size-3 has-text-blue'>Por que você está se cadastrando?</h2>`,
		// 		iconColor: `#28C0CD`,
		// 		icon: 'question',
		// 		showDenyButton: true,
		// 		confirmButtonText: 'Quero PARCELAR uma compra online',
		// 		denyButtonText: 'Quero OFERECER LIMITE do meu cartão de crédito',
		// 		denyButtonColor: `${colors.primaryBlue}`,
		// 		confirmButtonColor: `${colors.primaryBlue}`,
		// 	}).then((result) => {
		// 		if (result.isConfirmed) {
		// 			eventPush('web_pq_cadastro_quero_parc', 'INFO');
		// 			confirmEmail(form, 'tomador');
		// 		} else if (result.isDenied) {
		// 			eventPush('web_pq_cadastro_quero_of_lim', 'INFO');
		// 			confirmEmail(form, 'cedente');
		// 		}
		// 	});
		// } else {
		// 	confirmEmail(form);
		// }
	};

	const confirmEmail = (form: FormData, userProfile?: string) => {
		return Swal.fire({
			html: `<h2 class='is-size-3 has-text-blue'>Você tem certeza que o seu e-mail está correto?</h2>
			<p class='mt-3'>E-mail que você preencheu: </br> <strong>${form.email}</strong></p>`,
			icon: 'question',
			iconColor: `#28C0CD`,
			showDenyButton: true,
			confirmButtonText: 'Sim, está correto',
			denyButtonText: 'Corrigir',
			denyButtonColor: `${colors.primary}`,
			confirmButtonColor: `${colors.primaryBlue}`,
		}).then((result) => {
			if (result.isConfirmed) {
				validateForm(form, userProfile);
			} else if (result.isDenied) {
				eventPush('web_cad_ini_corrigir', 'INFO', {
					email_hash: hashEmailSha256(user.email),
				});
				const documentElement: HTMLElement | null =
					document.querySelector('#email');
				if (documentElement) documentElement.focus();
			}
		});
	};

	const validateForm = async (form: FormData, userProfile?: string) => {
		let appCheckTokenResponse;
		try {
			appCheckTokenResponse = await getToken(appCheck, false);
		} catch (err) {
			console.log(err);
			return;
		}

		setIsLoading(true);

		eventPush('web_cad_ini_criar_conta', 'INFO', {
			email_hash: hashEmailSha256(
				emailPayload ? emailPayload : form.email.trim().toLowerCase()
			),
		});

		const { errorEmail, message } = await validateEmail(form.email);

		if (errorEmail) {
			setIsLoading(false);
			return Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}

		const payload: PayloadStepOne = {
			email: emailPayload ? emailPayload : form.email.trim().toLowerCase(),
			password: form.password,
			// cupom: cupom,
			nickname: form.nickName,
			epar: epar,
			ad_id: adId,
			captcha: appCheckTokenResponse.token,
			rcptch_version: 'v3',
			profile: userProfile ?? profile,
			version: testAB ? 'v2' : 'v1',
		};

		const { result, error } = await controller.submitStepOne(payload);

		if (error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'error',
				text:
					result.data.message ?? 'Erro. Tente novamente em alguns instantes.',
				confirmButtonText: 'Fechar',
			});
		}

		setIsLoading(true);
		eventPush('web_cad_ini_correto', 'INFO', {
			email_hash: hashEmailSha256(
				emailPayload ? emailPayload : form.email.trim().toLowerCase()
			),
		});

		if (testAB) {
			setData(result.email);
			setIsLoading(false);
			return setIsTestAB(true);
		} else {
			setData();
			return history.push('/register/2');
		}
	};

	const handleEmailBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
		const email = event.target.value;
		const { errorEmail, message } = await validateEmail(email);

		if (errorEmail) {
			setIsLoading(false);
			return Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}
	};

	return (
		<>
			{isTestAB ? (
				<EmailToken
					title="Falta pouco, Verifique o seu e-mail"
					isSignUpStepOne={true}
				/>
			) : (
				<ContainerStyled>
					<CenterDivStyled>
						<img src={Logo} alt="Logo VirtusPay" />
					</CenterDivStyled>

					<InstructionsDivStyled
						onSubmit={handleSubmit(userType)}
						data-name="signUpSection"
					>
						<div>
							<BlueTitleStyled>Vamos criar sua conta?</BlueTitleStyled>

							<CenterDivStyled>
								<img src={Tracking} width="300px" alt="Tracking" />
							</CenterDivStyled>

							<Input
								control={control}
								id="email"
								dataTestID="email"
								name="email"
								type="email"
								disabled={!!email}
								placeholder="Digite seu e-mail"
								onBlur={(event) => handleEmailBlur(event)}
								error={errors.email && errors.email.message}
							/>

							<InputPassword
								control={control}
								isControlled={true}
								id="password"
								dataTestId="password"
								name="password"
								type="password"
								placeholder="Digite sua senha"
								error={errors.password && errors.password.message}
							/>
							{password ? <CheckPassword password={password} /> : null}
							<InputPassword
								control={control}
								isControlled={true}
								id="repeat-password"
								dataTestId="repeat-password"
								name="confirmPassword"
								type="password"
								placeholder="Digite sua senha novamente"
								error={errors.confirmPassword && errors.confirmPassword.message}
							/>
							<ErrorStyled id="password-confirm-error-message"></ErrorStyled>
							<InfoPassword />

							<Input
								control={control}
								id="nickname"
								dataTestID="nickname"
								name="nickname"
								type="text"
								placeholder="Como gostaria de ser chamado?"
								error={errors.nickname && errors.nickname.message}
							/>
							<ErrorStyled id="nickname-error-message"></ErrorStyled>

							<ButtonDivStyled>
								<Button
									className="has-gradient-blue"
									type="submit"
									textColor="#fff"
									width="300px"
									text="Criar conta"
									dataTestid="submitButton"
								/>
							</ButtonDivStyled>

							<PStyled>
								Já possui uma conta?
								<SpanStyled
									onClick={() => {
										eventPush('web_cad_ini_fazer_login', 'INFO');
										localStorage.removeItem('user');
										localStorage.removeItem('refresh');
										localStorage.removeItem('token');
										history.push('/');
									}}
								>
									{' '}
									Fazer login
								</SpanStyled>
							</PStyled>

							{/* <div>
								<PStyled>ou</PStyled>
							</div>

							<PStyled>Crie sua conta com rede social</PStyled>

							<FaceDivStyled>
								<FacebookSignUp />
							</FaceDivStyled> */}
						</div>
					</InstructionsDivStyled>
				</ContainerStyled>
			)}
		</>
	);
};
