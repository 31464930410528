import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import KYCController from './controllers/kycController';

import { useLoading } from '../../hooks/loading';

import { IParams } from './types/types';

export function KycRedirect() {
	const params: IParams = useParams();
	const { id } = params;

	const { setIsLoading } = useLoading();

	const history = useHistory();

	useEffect(() => {
		setIsLoading(true);
		const formalizationController = new KYCController();

		const getSteps = async () => {
			const { result, error, redirect } =
				await formalizationController.getSteps(id);
			setIsLoading(false);

			if (error) {
				return Swal.fire({
					icon: 'error',
					text: result.string, // No controller, esse é o result.data.message
					confirmButtonText: 'Fechar',
				});
			}

			if (redirect) {
				return history.push(redirect);
			}

			const { next_step } = result;

			return history.push(`/taker/order/${id}/formalizacao/${next_step}`);
		};

		getSteps(); // eslint-disable-next-line
	}, [id]);

	return '';
}
