import Swal from 'sweetalert2';

import {
	redefinePasswordRepository,
	tokenRedefinePasswordRepository,
} from '../repository';

import { RedefinePasswordI } from '../types';

export async function RedefinePasswordController(payload: RedefinePasswordI) {
	const result = await redefinePasswordRepository(payload);
	if (result.status !== 200) {
		Swal.fire({
			icon: 'error',
			text: result?.data.message ?? 'Erro ao atualizar a senha',
			confirmButtonText: 'Fechar',
		});
		return { error: true };
	}
	return { error: false };
}

export async function tokenRedefinePasswordController(
	token: RedefinePasswordI
) {
	const result = await tokenRedefinePasswordRepository(token);

	if (result.status !== 200) return { error: true };

	return { error: false };
}
