import {
	PasswordDivStyled,
	PasswordTitleStyled,
	PasswordLIStyled,
	PasswordULStyled,
	PasswordLIDivStyled,
} from './style';

export function InfoPassword() {
	return (
		<PasswordDivStyled>
			<PasswordTitleStyled>
				Sua senha precisa ter pelo menos:{' '}
			</PasswordTitleStyled>
			<PasswordULStyled>
				<PasswordLIDivStyled>
					<PasswordLIStyled>8 caracteres</PasswordLIStyled>
				</PasswordLIDivStyled>
				<PasswordLIDivStyled>
					<PasswordLIStyled>1 número</PasswordLIStyled>
				</PasswordLIDivStyled>
				<PasswordLIDivStyled>
					<PasswordLIStyled>1 letra maiúscula</PasswordLIStyled>
				</PasswordLIDivStyled>
				<PasswordLIDivStyled>
					<PasswordLIStyled>1 letra minúscula</PasswordLIStyled>
				</PasswordLIDivStyled>
			</PasswordULStyled>
		</PasswordDivStyled>
	);
}
