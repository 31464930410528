import { ICupom } from '../interfaces';

import { IPostStore } from '../components/OrderSendingForm';

import axios from '../../../shared/http/customAxios';

class OrderRepository {
	async list() {
		try {
			const result = await axios().get('/v1/order');
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async installment(totalAmount: number) {
		try {
			const result = await axios().post('/v2/installments', {
				total_amount: totalAmount,
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async cupom(orderData: ICupom) {
		try {
			const result = await axios().post('/v1/order/validate_coupon', orderData);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async send(orderData: any) {
		// Any pq ainda não foi criada a interface
		try {
			const result = await axios().post('/v1/order/create', orderData);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async get(transaction: string) {
		try {
			const result = await axios().get('/v1/order/' + transaction);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async postStore(payload: IPostStore) {
		try {
			const result = await axios().post(
				`preapproved/${payload.id}/store`,
				payload
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default OrderRepository;
