import { useLayoutEffect, useEffect } from 'react';

import { firebase } from './firebase';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

import 'firebase/remote-config';
import { config } from './config.json';
import Routes from './routes';

import { AuthProvider, useAuth } from './hooks/auth/auth';
import { LoadingProvider, useLoading } from './hooks/loading';
import { UpdateFreshDeskData } from './shared/utils/freshdesk';

function App() {
	const { isLoadingUser } = useAuth();
	const { setIsLoading } = useLoading();

	firebase;

	const remoteConfig = getRemoteConfig();

	fetchAndActivate(remoteConfig);

	useEffect(() => {
		document.title = config.title_taker;
	}, []);

	useLayoutEffect(() => {
		setIsLoading && setIsLoading(isLoadingUser);
		if (config.environment === 'PROD') UpdateFreshDeskData();
	}, [isLoadingUser]);

	return (
		<LoadingProvider>
			<AuthProvider>
				<div data-testid="app" className="App">
					<Routes />
				</div>
			</AuthProvider>
		</LoadingProvider>
	);
}

export default App;
