import axios from '../../../shared/http/customAxios';

export async function couponsRepository() {
	try {
		const result = await axios().get(`v1/order/get_coupons`);
		return result;
	} catch (error: any) {
		return error.response;
	}
}
