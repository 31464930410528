import styled from 'styled-components';

export const ContainerStyled = styled.div`
	width: 100%;
	border-radius: 20px;
	background: #ffffff;
	color: #707070;
	box-shadow: 0px 0px 6px rgba(10, 10, 10, 0.1);
	padding: 0.6rem 3rem;
	margin-bottom: 1rem;
`;

export const AccordionTitleStyled = styled.p`
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	width: 100%;
	padding: 1rem;
	cursor: pointer;
`;

export const PanelStyled = styled.div`
	width: 100%;
`;
