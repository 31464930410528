import styled from 'styled-components';

import { colors } from '../../../shared/styles/colors';

import { OfferItemProps } from './offerItem';

export const IndicatorStyled = styled.div`
	height: 20px;
	width: 20px;
	margin: 8px;
	border-radius: 100%;
	background: rgba(10, 10, 10, 0.2);
`;

export const ItemStyled = styled.div<OfferItemProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 160px;
	width: 160px;
	padding: 20px 0;
	margin: 5px;
	border-radius: 12px;
	border: 2px solid
		${(props) => (props.actived ? colors.primaryBlue : colors.defaultGrey)};
`;

export const ContainerStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-direction: column;
	margin-bottom: 1rem;
`;

export const IndicatorDivStyled = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
`;

export const LabelDivStyled = styled.div`
	text-align: center;
	color: #000000;
	font-size: 13px;
`;
