import { useState } from 'react';

import { DigitStyled, DivRowStyled } from './styles';

const Digits = () => {
	const [paste, setPaste] = useState(false);

	const handleKeyPress = (params: {
		event: { code: string; target: { value: string } };
		lastId?: string;
		nextId?: string;
	}) => {
		if (!paste) {
			const key = params.event.code;

			if (key === 'Backspace' || key === 'Delete') {
				if (params.lastId) {
					params.event.target.value = '';
					const lastId: HTMLElement | null = document.querySelector(
						`#${params.lastId}`
					);
					lastId?.focus();
				}
			} else if (params.nextId) {
				const nextId: HTMLElement | null = document.querySelector(
					`#${params.nextId}`
				);
				nextId?.focus();
			}
		}

		setPaste(false);
	};

	const handlePaste = (
		event: React.ClipboardEvent<HTMLInputElement>,
		currentId: number
	) => {
		setPaste(true);
		const dataPaste = event.clipboardData.getData('TextStyled');
		const sizePaste = dataPaste.length;

		for (let digit = 0; digit < sizePaste; digit++) {
			const input: HTMLElement | null = document.querySelector(
				`#digit-${currentId}`
			);
			if (!input) return;
			input.focus();
			input.value = dataPaste[digit];

			currentId++;

			if (currentId > 5) return;
		}
	};

	return (
		<DivRowStyled>
			<DigitStyled
				onPaste={(event) => handlePaste(event, 1)}
				id="digit-1"
				data-testid="digit-1"
				type="tel"
				maxLength={1}
				onKeyUp={(e: any) => handleKeyPress({ event: e, nextId: 'digit-2' })}
			/>
			<DigitStyled
				onPaste={(event) => handlePaste(event, 2)}
				id="digit-2"
				data-testid="digit-2"
				type="tel"
				maxLength={1}
				onKeyUp={(e: any) =>
					handleKeyPress({ event: e, nextId: 'digit-3', lastId: 'digit-1' })
				}
			/>
			<DigitStyled
				onPaste={(event) => handlePaste(event, 3)}
				id="digit-3"
				data-testid="digit-3"
				type="tel"
				maxLength={1}
				onKeyUp={(e: any) =>
					handleKeyPress({ event: e, nextId: 'digit-4', lastId: 'digit-2' })
				}
			/>
			<DigitStyled
				onPaste={(event) => handlePaste(event, 4)}
				id="digit-4"
				data-testid="digit-4"
				type="tel"
				maxLength={1}
				onKeyUp={(e: any) =>
					handleKeyPress({ event: e, nextId: 'digit-5', lastId: 'digit-3' })
				}
			/>
			<DigitStyled
				onPaste={(event) => handlePaste(event, 5)}
				id="digit-5"
				data-testid="digit-5"
				type="tel"
				maxLength={1}
				onKeyUp={(e: any) => handleKeyPress({ event: e, lastId: 'digit-4' })}
			/>
		</DivRowStyled>
	);
};

export default Digits;
