import Swal from 'sweetalert2';

import axios from '../../../http/customAxios';

import defaultAxios from 'axios';

const http = defaultAxios.create();

export interface ICreditPayload {
	transaction: string;
	id: string;
	receivableAmount?: any;
	orderData?: any;
	creditCardPaid?: () => void;

	payload?: {
		holder_name: string;
		card_number: string;
		code: string;
		exp_month: string;
		exp_year: string;
		brand: string;
	};
}

class CreditRepository {
	async postCreditCard({ transaction, id, payload }: ICreditPayload) {
		try {
			const result = await axios().post(
				`/v2/order/${transaction}/receivable/${id}/credit_card_pay`,
				payload,
				{ headers: { noAuthorization: true } }
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async getCall(cardNumberFormat: string) {
		try {
			const retorno = await http.get(
				`https://api.mundipagg.com/bin/v1/${cardNumberFormat}`
			);
			return retorno.data;
		} catch (error: any) {
			Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Não foi possível obter a bandeira do cartão.',
			});
		}
	}
}

export default CreditRepository;
