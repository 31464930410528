import StoresRepository from '../repositories/storesRepository';

export default class StoresController {
	repository = new StoresRepository();

	async getStores() {
		const result = await this.repository.getStores();

		if (result?.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}
