interface ICopyRight {
	margin?: string;
	color?: string;
	textColor?: string;
}

export function CopyRight({ margin, color, textColor }: ICopyRight) {
	return (
		<div className="pb-3" style={{ backgroundColor: color }}>
			<div className={`column is-multiline ${margin} is-10-mobile container`}>
				<div className="is-12 has-text-centered">
					<div
						style={{ opacity: '0' }}
						id="btn-footer"
						className="my-2 has-text-centered is-flex is-justify-content-center is-align-items-center is-12 px-0"
					>
						<a
							style={{ maxWidth: '240px' }}
							href="/register"
							className="new-button py-3 blue-gradient btn-sazonal"
						>
							<a
								style={{ maxWidth: '240px' }}
								href="/register"
								className="new-button py-3 btn-sazonal"
							>
								<strong>Quero parcelar!</strong>
							</a>
						</a>
					</div>
				</div>
				<p
					style={{ color: textColor }}
					className="has-text-justify has-text-weight-semibold is-size-7"
				>
					A VirtusPay não realiza qualquer tipo de abordagem nas redes sociais
					ou sites de relacionamento, tampouco disponibiliza e-mails de contato
					com domínios distintos de @virtuspay.com.br e @usevirtus.com.br.
					Endereços de e-mails com domínios: @hotmail.com @gmail.com
					@outlook.com ou qualquer outro neste sentido não tem qualquer relação
					com a VirtusPay. <br /> <br />A Virtus Tech Tecnologia e Serviços SA
					(“VirtusPay”), é uma sociedade por ações registrada sob o CNPJ
					27.148.375/0001-50 com sede no endereço Avenida Paulista, 726, 17o
					Andar, conjunto 1707, Bela Vista, São Paulo – SP, CEP: 01310-100.
					{/* A
					VirtusPay não é uma instituição financeira e não realiza operações de
					crédito diretamente, mas um correspondente bancário do BMP Money Plus
					Sociedade de Crédito Direto S/A, registrado sob CNPJ/MF nº
					34.337.707/0001-00. A atividade de correspondente bancário é
					regulamentada nos termos da resolução CMN nº 3.954, de 24 de fevereiro
					de 2011. */}
				</p>
				<p className="has-text-centered has-text-white is-size-7  mt-2">
					<span className="is-size-7">VirtusPay 💙 Realiza com você.</span>{' '}
					<br />
					Use Virtus © - Todos os Direitos Reservados 2021
				</p>
			</div>
		</div>
	);
}
