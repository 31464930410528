import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

import { InputCouponContainerStyled, Button } from './style';

import { InputProps } from './input.interface';

function InputCoupon({
	name,
	control,
	label,
	disabled,
	activatedCupom,
	id,
	type,
	value,
	className,
	mask,
	isHiddenInputs,
	dataTestID,
	inputRef,
	checkCupom,
	removeCoupon,
	onChange,
}: InputProps) {
	let activeCupom = null;
	let input = null;

	if (type === 'cupom') {
		activeCupom = !activatedCupom ? (
			<Button onClick={checkCupom}>Ativar Cupom</Button>
		) : (
			<Button onClick={removeCoupon}>Retirar Cupom</Button>
		);
	}

	input =
		name && control ? (
			<Controller
				data-testid="controlCouponInput"
				control={control}
				name={name}
				render={({ field: { onChange: onChangeForm, value } }) => (
					<InputMask
						placeholder={label}
						className={className}
						disabled={disabled}
						mask={mask ?? ''}
						value={value ?? ''}
						name={name}
						id={id}
						type={type}
						data-testid={dataTestID}
						inputRef={inputRef}
						onChange={(e) => {
							onChangeForm(e);
							if (onChange) {
								onChange(e);
							}
						}}
					/>
				)}
			/>
		) : (
			<InputMask
				placeholder={label}
				className={className}
				disabled={disabled}
				mask={mask ?? ''}
				value={value}
				onChange={onChange}
				name={name}
				id={id}
				type={type}
				data-testid={dataTestID}
				inputRef={inputRef}
			/>
		);

	return (
		<InputCouponContainerStyled>
			{input}
			{!isHiddenInputs ? activeCupom : null}
		</InputCouponContainerStyled>
	);
}

export default InputCoupon;
