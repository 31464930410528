import axios from '../../../shared/http/customAxios';

import dto from '../models/contactModel';

class ContactRepository {
	async submit(dto: dto) {
		const { captcha, rcptch_version, ...data } = dto;
		try {
			const result = await axios().post('/contact', data, {
				headers: {
					noAuthorization: true,
					'X-Firebase-AppCheck': captcha,
					'X-Recaptcha-Version': rcptch_version,
				},
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default ContactRepository;
