import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import {
	Accordion,
	Button,
	InputPassword,
	InfoPassword,
	CheckPassword,
} from '../../../../shared/components/index';

import { PasswordProps } from '../../types/types';
import UserController from '../../controllers/userController';

import { CenterDivStyled } from '../../styles';
import { useLoading } from '../../../../hooks/loading';
import { PasswordSchema } from '../../validation';
// import { ReactTooltipStyled, InfoStyled } from '../../../SignUp/styles';
// import { colors } from '../../../../shared/styles/colors';

export interface PersonalPasswordProps {
	userPassword: PasswordProps;
}

export const PasswordFields = ({ userPassword }: PersonalPasswordProps) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm({ resolver: yupResolver(PasswordSchema) });

	const { setIsLoading } = useLoading();
	const password: string = watch('new_password');

	const userController = new UserController();

	useEffect(() => {
		reset(userPassword); // eslint-disable-next-line
	}, []);

	const handleOnSubmit = async (form: PasswordProps) => {
		setIsLoading(true);
		const { result, error } = await userController.changePassword(form);

		if (error) {
			Swal.fire({
				icon: 'error',
				text: result.data.message ?? 'Erro. Tente novamente em alguns minutos',
				confirmButtonText: 'Fechar',
			});
		} else {
			Swal.fire({
				icon: 'success',
				text: 'Senha alterada com sucesso!',
				confirmButtonText: 'Continuar',
			});
		}

		setIsLoading(false);
	};

	// const placeType = () => {
	// 	if (window.innerWidth <= 900) return 'left';

	// 	if (window.innerWidth > 900) return 'right';
	// };

	return (
		<Accordion dataTestId="Navbar" title="Atualizar minha senha">
			<div>
				<div>
					<div>
						<InputPassword
							control={control}
							isControlled={true}
							id="password"
							name="password"
							type="password"
							placeholder="Senha atual"
							dataTestId="password"
							error={errors.password && errors.password.message}
						/>
					</div>
					<div>
						<InputPassword
							control={control}
							isControlled={true}
							name="new_password"
							dataTestId="new_password"
							placeholder="Nova senha"
							type="password"
							error={errors.new_password && errors.new_password.message}
						/>
					</div>
					{password ? <CheckPassword password={password} /> : null}

					<div>
						<InputPassword
							control={control}
							isControlled={true}
							name="new_password_confirm"
							dataTestId="new_password_confirm"
							placeholder="Confirmar nova senha"
							mask=""
							type="password"
							error={
								errors.new_password_confirm &&
								errors.new_password_confirm.message
							}
						/>
					</div>
					<InfoPassword />
				</div>
				<CenterDivStyled>
					<Button
						text="Salvar alterações"
						dataTestid="submitButton"
						onClick={handleSubmit(handleOnSubmit)}
						className="has-gradient-blue"
						textColor="#fff"
					/>
				</CenterDivStyled>
			</div>
		</Accordion>
	);
};
