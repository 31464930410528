import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const CenterDivStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 3rem;
`;

export const InfoDivStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 0.5rem;
`;

export const TitleStyled = styled.h1`
	text-align: center;
	font-weight: 650;
	font-size: 27px;
	color: #000;

	@media (max-width: 900px) {
		font-size: 21px;
	}
`;

export const PStyled = styled.p`
	text-align: center;
	font-weight: 401;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
`;

export const SpanStyled = styled.span`
	text-align: center;
	font-weight: 401;
	color: #000;
	text-decoration: underline;
	cursor: pointer;
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 2rem 0 1rem;
`;

export const PBlueStyled = styled.p`
	text-align: center;
	font-weight: 500;
	margin-top: 0.3rem;
	color: ${colors.textBlue};
`;

export const TelDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 0.5rem;
	padding: 1rem 0.2rem;
	font-size: 14px;
	color: ${colors.textBlue};
	font-weight: 500;
	background-color: #fff;
	border: 1px solid #48c1d2;
	border-radius: 11px;
	width: 300px;
	cursor: pointer;
`;

export const IMGStyled = styled.img`
	margin: 0 0.9rem;
`;

export const TimePStyled = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 401;
	margin: 0.1rem 0;
`;
