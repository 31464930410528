import Swal from 'sweetalert2';

import { PasswordProps, UserProps, AddressProps } from '../types/types';

import AuthController from '../../../shared/controllers/authController';
import ProfileDto from '../models/profileModel';
import ChangePasswordDto from '../models/passwordModel';
import UserRepository from '../repositories/userRepository';
import bankDto from '../models/bankModel';

class UserController {
	repository: UserRepository;
	profileDto: ProfileDto;
	passwordChangeDto: ChangePasswordDto;
	authController: AuthController;

	constructor() {
		this.repository = new UserRepository();
		this.profileDto = new ProfileDto();
		this.passwordChangeDto = new ChangePasswordDto();
		this.authController = new AuthController();
	}

	async updateAddress(payload: AddressProps, setData: (response: any) => void) {
		const result = await this.repository.updateAddress(payload);

		if (result.status !== 200) return { result, error: true };

		setData(result.address);

		return { result, error: false };
	}

	async updateProfile(payload: UserProps, setData: (response: any) => void) {
		const result = await this.repository.updateProfile(payload);

		if (result.status !== 200) return { result, error: true };

		setData(result);

		return { result, error: false };
	}

	async inactivateAccount() {
		const e = await Swal.fire({
			icon: 'info',
			confirmButtonText: 'Confirmar',
			showCloseButton: true,
			cancelButtonText: 'Fechar',
			focusCancel: true,
			showCancelButton: true,
			title:
				"<h2 class='is-size-4 has-text-weight-bold has-text-blue has-text-centered'>Que pena que você quer deixar a VirtusPay</h2>",
			text: 'Ao cancelar sua conta você será desconectado automaticamente do site, e não conseguirá entrar novamente. Deseja realmente cancelar seu cadastro conosco?',
		});

		if (e.isConfirmed) {
			const result = await this.repository.inactivateAccount();

			if (result.status !== 200) return { result, error: true, redirect: null };

			return { result, error: false, redirect: '/user/canceled' };
		}
	}

	async changePassword(payload: PasswordProps) {
		const result = await this.repository.changePassword(payload);

		if (result.status !== 200) return { result, error: true };

		return { result, error: false };
	}

	logout(bool: boolean, funcRedirect: () => void) {
		this.authController.logout(bool, funcRedirect);
	}

	async submitStepFiveController(dataRequest: bankDto) {
		const result = await this.repository.submitStepFiveRepository(dataRequest);

		if (result.status !== 201) {
			Swal.fire({
				icon: 'error',
				text:
					result?.data.message ??
					'Erro ao fazer o cadastro. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});

			return { error: true };
		}

		return { error: false };
	}

	async callBanksController() {
		const result = await this.repository.callBanksRepository();

		if (result.status !== 200) {
			Swal.fire({
				icon: 'error',
				text:
					result?.data.message ??
					'Erro ao obter a lista de bancos. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});

			return { error: true };
		}

		return { result: result.data, error: false };
	}
}

export default UserController;
