import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	TitleStyled,
	BlueTitleStyled,
	CenterDivStyledTrackingStyled,
	ContainerStyled,
	InstructionsDivStyled,
	IMGDivStyled,
	CenterDivStyled,
	PStyled,
	PClickStyled,
	CamDivStyled,
	ArrowStyled,
	ButtonDivStyled,
	DocSelectStyled,
	IMGStyled,
	PdocStyled,
	DivRowStyled,
	DivdocStyled,
	IMGdocStyled,
	ButtonStyled,
	ButtonHiddenDesktopStyled,
} from './styles';

import Swal from 'sweetalert2';

import KYCController from './controllers/kycController';

import Canvas from './components/Canvas/canvas';

import { modalErrorUnico } from './utils/errorUnico';

import OrderNavbar from '../order/components/OrderNavbar';

import { UnicoFrame, Button } from '../../shared/components/index';

import { clearBase64 } from '../../shared/utils/clearBase64';
import fileToBase64 from '../../shared/utils/fileToBase64';
import { validFileExtension } from '../../shared/utils/validators';

import RgFront from '../../assets/images/KYC/rg-front.png';
import CnhFull from '../../assets/images/KYC/cnh-full.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import RG from '../../assets/images/KYC/rg.png';
import Tracking from '../../assets/images/KYC/tracking-doc.png';
// import CNH from '../../assets/images/KYC/cnh-full.png';

import { IParams, IResponseErrorUnico } from './types/types';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';

export const KycDocFront = () => {
	const [activeDocument, setActiveDocument] = useState('');
	const [doc, setDoc] = useState('');
	const [docBase64, setDocBase64] = useState('');
	const [docEncrypted, setDocEncrypted] = useState('');
	const [showCamera, setShowCamera] = useState(false);
	const [numType, setNumType] = useState(-1);

	// States que cuidarão do aparecimento das respetivas DIV's na tela
	const [isHiddenSelect, setIsHiddenSelect] = useState(false);
	const [isHiddenRg, setIsHiddenRg] = useState(true);
	const [isHiddenCnh, setIsHiddenCnh] = useState(true);
	const [isHiddenPreview, setIsHiddenPreview] = useState(true);
	const [isHiddenInstructions, setIsHiddenInstructions] = useState(false);

	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		checkStep(); // eslint-disable-next-line
	}, []);

	const controller = new KYCController();

	const checkStep = async () => {
		const { result, error, redirect } = await controller.getSteps(id);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.string, // No controller, esse é o result.data.message
				confirmButtonText: 'Fechar',
			});
		}

		if (redirect) {
			return history.push(redirect);
		}

		const { next_step } = result;

		if (next_step !== 'doc_front') {
			return history.push(`/taker/order/${id}/formalizacao/${next_step}`);
		}
	};

	const handleRgStep = () => {
		hideAll();
		setIsHiddenRg(false);
	};

	const handleCnhStep = () => {
		hideAll();
		setIsHiddenCnh(false);
	};

	const showPreview = () => {
		hideAll();
		setIsHiddenInstructions(false);
		setIsHiddenPreview(false);
	};

	const handleSelectStep = () => {
		hideAll();
		setIsHiddenInstructions(false);
		setIsHiddenSelect(false);
		setIsHiddenPreview(true);

		setDoc('');
		setNumType(-1);
		setDocBase64('');
		setDocEncrypted('');
		setActiveDocument('');

		const fileEl = document.getElementById('file');
		if (fileEl) {
			fileEl.value = '';
		}
	};

	const handleShowCamera = (doc: string, type: number) => {
		hideAll();
		setIsHiddenInstructions(true);

		setDoc(doc);
		setNumType(type);
		setShowCamera(true);
	};

	const hideAll = () => {
		setIsHiddenSelect(true);
		setIsHiddenRg(true);
		setIsHiddenCnh(true);
		setIsHiddenPreview(true);

		setShowCamera(false);
	};

	const handleInput = (doc: string, type: number) => {
		setDoc(doc);
		setNumType(type);

		const input: HTMLElement | null = document.querySelector('#file');
		input?.click();
	};

	const fileSelectorHandler = async (event: any) => {
		const file = event.target.files[0];

		if (!validFileExtension(file)) {
			setDocBase64('');
			setDocEncrypted('');

			return Swal.fire({
				icon: 'error',
				text: 'Formato de arquivo inválido. Só aceitamos imagens.',
			});
		}

		const image = await fileToBase64(file);

		setDocBase64('data:image/jpeg;base64,' + image);
		setDocEncrypted('data:image/jpeg;base64,' + image);

		showPreview();
	};

	const handleSetDocBase64 = async (base64: string, encrypted: string) => {
		setDocBase64(base64);
		setDocEncrypted(encrypted);

		showPreview();
	};

	const showStep = () => {
		eventPush('web_escolha_doc_proximo', 'INFO', {
			ld: id,
		});
		if (activeDocument === 'rg') {
			handleRgStep();
		} else if (activeDocument === 'cnh') {
			handleCnhStep();
		}
	};

	const submit = async () => {
		setIsLoading(true);

		controller.dto.image_base_64 = clearBase64(docBase64);
		controller.dto.image_encrypted = docEncrypted;
		controller.dto.num_type = numType;

		if (doc === 'cnh') {
			eventPush('web_cnh_conf_sim_enviar', 'INFO', {
				ld: id,
			});
			const { result, error, expiredProcess, redirect } =
				await controller.submitCNH(id);

			if (expiredProcess.errorExpired) {
				Swal.fire({
					icon: 'error',
					text: expiredProcess.resultExpired.data.message,
					confirmButtonText: 'Refazer Processo',
				}).then(() => {
					history.push(expiredProcess.redirectExpired);
				});
			}

			if (error) {
				Swal.fire({
					icon: 'error',
					text: result.data.message ?? 'Erro ao enviar a CNH. Tente novamente em alguns minutos.',
					confirmButtonText: 'Fechar',
				}).then(() => {
					handleSelectStep();
					checkStep();
				});
			}

			if (redirect) return history.push(redirect);
		} else if (doc === 'rg') {
			eventPush('web_rg_frente_conf_sim_enviar', 'INFO', {
				ld: id,
			});
			const { result, error, expiredProcess, redirect } =
				await controller.submitRGFront(id);

			if (expiredProcess.errorExpired) {
				Swal.fire({
					icon: 'error',
					text: expiredProcess.resultExpired.data.message,
					confirmButtonText: 'Refazer Processo',
				}).then(() => {
					setIsLoading(false);
					history.push(expiredProcess.redirectExpired);
				});
			}

			if (error) {
				Swal.fire({
					icon: 'error',
					text: result.data.message ?? 'Erro ao enviar o RG. Tente novamente em alguns minutos.',
					confirmButtonText: 'Fechar',
				}).then(() => {
					handleSelectStep();
					checkStep();
				});
			}

			if (redirect) {
				setIsLoading(false);
				return history.push(redirect);
			}
		}

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const renderPreview = () => {
		return <Canvas base64={'' + docBase64} />;
	};

	const handleErrorUnico = (error: IResponseErrorUnico, call: string) => {
		modalErrorUnico(error, call, () =>
			history.push(`/taker/order/${id}/formalizacao`)
		);
	};

	const camera = () => {
		if (showCamera) {
			const type = getCameraType(numType);

			return (
				<div>
					<CamDivStyled>
						<ArrowStyled size={30} onClick={handleSelectStep} />
					</CamDivStyled>

					<UnicoFrame
						handleError={(error: IResponseErrorUnico, call: string) =>
							handleErrorUnico(error, call)
						}
						handlePicture={handleSetDocBase64}
						camera="document"
						type={type}
					/>
				</div>
			);
		}
	};

	const getCameraType = (selectedType: number) => {
		switch (selectedType) {
			case 501: {
				return 6;
			}

			case 4: {
				return 1;
			}

			default: {
				return 0;
			}
		}
	};

	return (
		<main>
			<div id="instructions" className={`${isHiddenInstructions && 'hidden'}`}>
				<OrderNavbar src={Logo} />

				<ContainerStyled>
					<InstructionsDivStyled id="select" isHidden={isHiddenSelect}>
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<TitleStyled>Agora envie o seu documento</TitleStyled>

						<PStyled>Selecione o documento mais atualizado para enviar</PStyled>

						<DivRowStyled>
							<DivdocStyled>
								<DocSelectStyled
									id="select-rg"
									onClick={() => setActiveDocument('rg')}
									className={`${
										activeDocument === 'rg' && 'selected_indicator'
									}`}
								>
									<IMGdocStyled src={RG} alt="RG" />

									<PdocStyled>RG</PdocStyled>
								</DocSelectStyled>
							</DivdocStyled>

							<DivdocStyled>
								<DocSelectStyled
									id="select-cnh"
									onClick={() => setActiveDocument('cnh')}
									className={` ${
										activeDocument === 'cnh' && 'selected_indicator'
									}`}
								>
									<IMGdocStyled src={CnhFull} alt="CNH" />

									<PdocStyled>CNH</PdocStyled>
								</DocSelectStyled>
							</DivdocStyled>
						</DivRowStyled>

						<CenterDivStyled>
							<Button
								className="has-gradient-blue"
								text="Próximo"
								textColor="#fff"
								onClick={showStep}
								width="300px"
							/>
						</CenterDivStyled>
					</InstructionsDivStyled>

					<input
						onChange={fileSelectorHandler}
						id="file"
						accept=".png,.jpeg,.jpg"
						className="hidden"
						type="file"
					/>

					<InstructionsDivStyled id="rg" isHidden={isHiddenRg}>
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<TitleStyled>Tire uma foto da frente do seu RG</TitleStyled>

						<IMGDivStyled>
							<IMGStyled src={RgFront} alt="RG" />
						</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Não corte o documento, ajuste a iluminação e cuidado com o
							foco e reflexos
						</PStyled>

						<ButtonHiddenDesktopStyled>
							<ButtonStyled
								className="has-gradient-blue"
								text="Tirar Foto"
								padding="9px 110px"
								textColor="#fff"
								onClick={() => handleShowCamera('rg', 501)}
								width="300px"
							/>
						</ButtonHiddenDesktopStyled>

						<PClickStyled
							style={{ marginBottom: '2rem' }}
							onClick={() => {
								eventPush('web_rg_frente_imp_foto', 'INFO', {
									ld: id,
								});
								handleInput('rg', 501);
							}}
						>
							Importar uma foto
						</PClickStyled>
					</InstructionsDivStyled>

					<InstructionsDivStyled id="cnh" isHidden={isHiddenCnh}>
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<TitleStyled>Envie uma foto da sua CNH aberta</TitleStyled>

						<IMGDivStyled>
							<IMGStyled src={CnhFull} alt="CNH" />
						</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Não corte o documento, ajuste a iluminação e cuidado com o
							foco e reflexos
						</PStyled>

						<ButtonHiddenDesktopStyled>
							<ButtonStyled
								className="has-gradient-blue"
								text="Tirar Foto"
								padding="9px 110px"
								textColor="#fff"
								onClick={() => handleShowCamera('cnh', 4)}
								width="300px"
							/>
						</ButtonHiddenDesktopStyled>

						<PClickStyled
							style={{ marginBottom: '2rem' }}
							onClick={() => {
								eventPush('web_cnh_imp_foto', 'INFO', {
									ld: id,
								});
								handleInput('cnh', 4);
							}}
						>
							Importar uma foto
						</PClickStyled>
					</InstructionsDivStyled>

					<InstructionsDivStyled id="preview" isHidden={isHiddenPreview}>
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<BlueTitleStyled>E ai, a foto ficou boa?</BlueTitleStyled>

						<IMGDivStyled>{renderPreview()}</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Verifique se o documento está com boa iluminação e bem
							centralizado.
						</PStyled>

						<ButtonDivStyled>
							<Button
								width="300px"
								text="Sim, enviar"
								textColor="#fff"
								className="has-gradient-blue step_enviardocumentos"
								onClick={submit}
							/>
						</ButtonDivStyled>

						<CenterDivStyled>
							<PClickStyled
								onClick={() => {
									if (doc === 'cnh') {
										eventPush('web_cnh_conf_tirar_outra', 'INFO', {
											ld: id,
										});
									} else {
										eventPush('web_rg_frente_conf_tirar_outra', 'INFO', {
											ld: id,
										});
									}
									handleSelectStep();
								}}
							>
								Quero tirar outra
							</PClickStyled>
						</CenterDivStyled>
					</InstructionsDivStyled>
				</ContainerStyled>
			</div>

			{camera()}
		</main>
	);
};
