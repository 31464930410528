import axios from 'axios';

const http = axios.create();

class AddressRepository {
	async getAddress(cep: string) {
		try {
			const response = await http.get(`https://viacep.com.br/ws/${cep}/json/`);
			return response.data;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default AddressRepository;
