import axios from '../../../../shared/http/customAxios';

export default class StoresRepository {
	async getStores() {
		try {
			const result = await axios().get('/v1/partners');
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}
