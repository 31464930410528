import { useState, useEffect } from 'react';
import { BarDivStyled, StrengthStyled, PasswordStrengthStyled } from './styles';

type PasswordStrength = 'senha fraca' | 'senha aceitável' | 'senha forte';
type ColorStrength = '#E3403E' | '#FBC436' | '#6DCA6B';
interface PasswordI {
	password: string;
}
export interface StyleI {
	password: string;
	color: ColorStrength;
}

export const CheckPassword = ({ password }: PasswordI) => {
	const [passwordStrength, setPasswordStrength] =
		useState<PasswordStrength>('senha fraca');
	const [barStyle, setBarStyle] = useState<StyleI>({
		password: '',
		color: '#E3403E',
	});

	useEffect(() => {
		if (password.length <= 7) {
			setPasswordStrength('senha fraca');
			setBarStyle({ password: password, color: '#E3403E' });
			return;
		}

		// Regex para conferir se a senha é forte, uma senha aceitável mais um caracter especial
		const IsStrongPassword =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/.test(
				password
			);
		if (IsStrongPassword) {
			setPasswordStrength('senha forte');
			setBarStyle({
				password: password.length <= 9 ? 'passwordd' : password,
				color: '#6DCA6B',
			});
			return;
		}

		// Regex para conferir se a senha é aceitável, sem caracter especial
		const IsAveragePassword =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);
		if (IsAveragePassword) {
			setPasswordStrength('senha aceitável');
			setBarStyle({ password: 'password', color: '#FBC436' });
			return;
		}
	}, [password]);

	return (
		<>
			<BarDivStyled>
				<StrengthStyled color={barStyle.color} password={barStyle.password} />
			</BarDivStyled>

			<PasswordStrengthStyled>{passwordStrength}</PasswordStrengthStyled>
		</>
	);
};
