import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	BlueTitleStyled,
	AiOutlineArrowLeftGreyStyled,
	FourDivStyled,
	InstructionsDivFourStyled,
	StoreDivStyled,
	CenterDivStyled,
	InstructionsDivStyled,
	AClickFourStyled,
	SpanFourStyled,
	ButtonDivStyled,
	BonusDivStyled,
} from './styles';

import Airplane from '../../assets/images/signUp/offers/airplane.png';
import IPhone from '../../assets/images/signUp/offers/iphone.png';
import Joystick from '../../assets/images/signUp/offers/joystick.png';
import Laptop from '../../assets/images/signUp/offers/laptop.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Microwave from '../../assets/images/signUp/offers/microwave.png';
import Shoes from '../../assets/images/signUp/offers/shoes.png';
import Tracking from '../../assets/images/signUp/step-4.png';
import TV from '../../assets/images/signUp/offers/tv.png';
import Wardrobe from '../../assets/images/signUp/offers/wardrobe.png';

import { Button } from '../../shared/components/index';

import AuthController from '../../shared/controllers/authController';

import { dataLayerUserData } from '../../shared/utils/dataLayer/dataLayerUserData';

import { colors } from '../../shared/styles/colors';

import { OfferItem } from './components/offerItem';

import SignUpController from './controllers/signUpController';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';

export const SignUpStepFour = () => {
	const [offers, setOffers] = useState([
		{ name: 'tvs', actived: true, label: 'TVs e Áudio', img: TV },
		{ name: 'smartphones', actived: true, label: 'Smartphones', img: IPhone },
		{ name: 'informatica', actived: true, label: 'Informática', img: Laptop },
		{
			name: 'eletrodomesticos',
			actived: true,
			label: 'Eletrodoméstico',
			img: Microwave,
		},
		{ name: 'moveis', actived: true, label: 'Móveis', img: Wardrobe },
		{ name: 'viagens', actived: true, label: 'Viagens', img: Airplane },
		{
			name: 'games',
			actived: true,
			label: 'Games e brinquedos',
			img: Joystick,
		},
		{
			name: 'moda-perfumaria',
			actived: true,
			label: 'Moda e perfumaria',
			img: Shoes,
		},
	]);

	const history = useHistory();

	const { user, setData, removeData } = useAuth();

	const { setIsLoading } = useLoading();

	useEffect(() => {
		const checkUser = async () => {
			if (!user) history.push('/taker');

			dataLayerUserData(user);
		};

		checkUser(); // eslint-disable-next-line
	}, [user]);

	const authController = new AuthController();
	const controller = new SignUpController();

	const sendOffers = async () => {
		eventPush('web_cad_ofertas_finalizar', 'INFO');

		setOffersDto();

		setIsLoading(true);

		await controller.submitStepOffers();

		skipModal();
	};

	const setOffersDto = () => {
		const offersNamesAccepted = offers
			.filter((item) => item.actived)
			.map((item) => item.name);
		controller.dto.offers = offersNamesAccepted;
	};

	const skipModal = async () => {
		setIsLoading(false);
		if (!user.is_negative) {
			return Swal.fire({
				icon: 'success',
				text: 'Conta criada com sucesso!\nEntre em sua conta para começar a parcelar suas compras.',
				confirmButtonText: 'Certo',
			}).then((e) => {
				if (e.isConfirmed) setData();
				history.push('/taker');
			});
		} else {
			setData();
			history.push('/taker');
		}
	};

	const toggleOfferItem = (id: string) => {
		const offersChanged = offers.map((item) => {
			if (item.name !== id) {
				return item;
			} else {
				item.actived = !item.actived;
				return item;
			}
		});

		setOffers(offersChanged);
	};

	const RenderAcceptButton = () => {
		const offersAccepted = offers.filter((item) => item.actived);
		if (offersAccepted.length > 0)
			return (
				<Button
					className="p-3 mx-3"
					color={colors.primaryBlue}
					onClick={() => changeAllItems(false)}
				/>
			);
		return (
			<Button
				className="p-3 mx-3"
				color={colors.defaultGrey}
				onClick={() => changeAllItems(true)}
			/>
		);
	};

	const changeAllItems = (boolean: boolean) => {
		const allItemsChanged = offers.map((item) => {
			item.actived = boolean;
			return item;
		});

		setOffers(allItemsChanged);
	};

	return (
		<main>
			<FourDivStyled>
				<AiOutlineArrowLeftGreyStyled
					onClick={() => {
						removeData();
						authController.logout(
							undefined,
							() => (window.location.href = '/')
						);
					}}
				/>
				<img src={Logo} alt="Logo VirtusPay" />
				<span></span>
			</FourDivStyled>

			<InstructionsDivStyled>
				<InstructionsDivFourStyled>
					<div>
						<BlueTitleStyled> Receba ofertas por e-mail</BlueTitleStyled>

						<CenterDivStyled>
							<img src={Tracking} width="300px" alt="Tracking" />
						</CenterDivStyled>

						<StoreDivStyled>
							{offers.map((item) => (
								<OfferItem
									id={item.name}
									onClick={() => toggleOfferItem(item.name)}
									actived={item.actived}
									label={item.label}
									key={item.name}
								>
									<img src={item.img} alt={item.name} />
								</OfferItem>
							))}
						</StoreDivStyled>

						<BonusDivStyled>
							<div>
								<RenderAcceptButton />
							</div>
							<span>Aceito receber ofertas por e-mail</span>
						</BonusDivStyled>

						<CenterDivStyled>
							<SpanFourStyled onClick={() => changeAllItems(false)}>
								Desmarcar todas
							</SpanFourStyled>
						</CenterDivStyled>

						<ButtonDivStyled>
							<Button
								onClick={sendOffers}
								text="Finalizar"
								className="has-gradient-blue"
								textColor="#fff"
								width="300px"
							/>

							<AClickFourStyled
								onClick={() => {
									eventPush('web_cad_ofertas_pul_e_fin', 'INFO');
									skipModal();
								}}
							>
								Pular e finalizar
							</AClickFourStyled>
						</ButtonDivStyled>
					</div>
				</InstructionsDivFourStyled>
			</InstructionsDivStyled>
		</main>
	);
};
