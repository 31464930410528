import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const IconNegativeStyled = styled.div`
	display: flex;
	position: relative;
	box-sizing: content-box;
	justify-content: center;
	width: 5em;
	height: 5em;
	margin: 2.5em auto 0.6em;
	border: 0.25em solid transparent;
	border-radius: 50%;
	font-family: inherit;
	line-height: 5em;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	border-color: #facea8;
	color: #f8bb86;

	.icon-content {
		display: flex;
		align-items: center;
		font-size: 3.75em;
	}
`;

export const ContainerStyled = styled.div`
	display: flex;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 35%;
	background: hsl(0, 0%, 96%);

	@media (max-width: 2200px) {
		width: 42%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 65%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}
`;

export const ContainerReturnStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: hsl(0, 0%, 96%);
`;

export const FirstDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	border-radius: 14px;
	box-shadow: 0px 2px 6px rgba(10, 10, 10, 0.1);
	background-color: #fff;
	padding: 0.5rem 0.5rem;

	@media (max-width: 700px) {
		padding: 1rem 0.5rem;
	}
`;

export const SecondDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin: 0.9rem 0 0;
`;

export const BlueBoxStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: ${colors.gradient};
	border-radius: 20px;
	padding: 0.5rem 2rem;
	box-shadow: 0px 0px 6px rgba(10, 10, 10, 0.1);
	cursor: pointer;
`;

export const WhiteFirstBoxStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	border-radius: 20px;
	padding: 0.5rem 2rem;
	box-shadow: 0px 0px 6px rgba(10, 10, 10, 0.1);
	cursor: pointer;
`;

export const WhiteBoxStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	border-radius: 20px;
	padding: 0.5rem 2rem;
	box-shadow: 0px 0px 6px rgba(10, 10, 10, 0.1);

	@media (max-width: 700px) {
		justify-content: start;
		align-items: start;
		flex-direction: column;
	}
`;

export const ContainerTemplateStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1.5rem 0;
`;

export const FigureStyled = styled.img`
	width: 128px;
	padding: 0.9rem;
	margin-left: 3rem;

	@media (max-width: 700px) {
		padding: 0.6rem;
		margin-left: 0.9rem;
	}
`;

export const ShoppingBasketStyled = styled.img`
	margin-top: 1rem;
	width: 35px;
	height: 35px;

	@media (max-width: 500px) {
		margin-top: 1.7rem;
	}

	@media (max-width: 450px) {
		margin-top: 2.5rem;
	}

	@media (max-width: 300px) {
		margin-top: 3rem;
	}
`;

export const CupomStyled = styled.img`
	margin-top: 1.2rem;
	margin-left: 0.2rem;
	width: 28px;
	height: 28px;

	@media (max-width: 500px) {
		margin-top: 1.7rem;
	}

	@media (max-width: 300px) {
		margin-top: 2rem;
	}
`;

export const DownloadStyled = styled.img`
	margin-top: 1rem;
	margin-left: 0.3rem;
	width: 28px;
	height: 28px;

	@media (max-width: 500px) {
		margin-top: 1.7rem;
	}

	@media (max-width: 300px) {
		margin-top: 2rem;
	}
`;

export const StoreStyled = styled.img`
	width: 124px;
	height: 37px;
	cursor: pointer;

	@media (max-width: 700px) {
		width: 186px;
		height: 53px;
		padding: 0.1rem;
	}

	@media (max-width: 420px) {
		width: 186px;
		height: 53px;
		padding: 0.5rem;
	}
`;

export const CaminhoStyled = styled.img`
	width: 10px;
	height: 18px;
`;

export const LinkStyled = styled.span`
	margin: 1rem 0;
	color: #000;
	font-weight: 600;
	color: ${colors.textBlue};
	cursor: pointer;

	@media (max-width: 700px) {
		font-size: 15px;
		font-weight: 600;
		margin-left: 0.7rem;
		margin-bottom: 1rem;
	}
`;

export const TitleFirstDivStyled = styled.h3`
	text-align: left;
	font-size: 21px;
	letter-spacing: 0px;
	font-weight: 700;

	@media (max-width: 700px) {
		margin-top: 0.5rem;
		margin-left: 0.7rem;
		font-size: 17px;
		font-weight: 700;
	}
`;

export const TextFirstDivStyled = styled.h3`
	text-align: left;
	font-size: 19px;
	letter-spacing: 0px;
	font-weight: 500;
	margin-bottom: 1.5rem;

	@media (max-width: 700px) {
		margin-left: 0.7rem;
		font-size: 16px;
		margin-bottom: 0.5rem;
	}
`;

export const PSecondDivStyled = styled.p`
	text-align: left;
	font-size: 19px;
	letter-spacing: 0px;
	font-weight: 501;
	color: #707070;
`;

export const SubPSecondDivStyled = styled.p`
	text-align: left;
	font-size: 17px;
	letter-spacing: 0px;
	color: #707070;
`;

export const TextDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	margin: 0.7rem 0;
	color: #000;

	@media (max-width: 800px) {
		text-align: center;
	}
`;

export const ButtonSecondDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: right;
	margin: 0.7rem 0;

	@media (max-width: 800px) {
		text-align: center;
	}
`;

export const ThirdDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 0.9rem;
`;

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 550px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const BoxDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: 0 0.2rem;

	@media (max-width: 550px) {
		width: 100%;
		margin: 0;
	}
`;

export const ArrowStyled = styled.span`
	color: ${colors.textBlue};
	font-weight: 700;
`;

export const PAccordionStyled = styled.p`
	font-size: 12px;
`;

export const SpanAccordionStyled = styled.span`
	font-size: 12px;
	font-weight: 600;
`;

export const FourthDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.5rem;
	margin-bottom: 2.1rem;

	@media (max-width: 760px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const ItemsDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 40%;
	margin: 0 0.2rem;

	@media (max-width: 760px) {
		width: 95%;
		margin: 0;
	}
`;

export const PItemsStyled = styled.p`
	font-size: 16px;
	font-weight: 401;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	margin: 1.7rem 0;
`;

export const NegativeDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	background: #fff;
	box-shadow: 0px 2px 6px rgba(10, 10, 10, 0.1);
	border-radius: 14px;
	padding: 1rem 0.8rem;
`;

export const NegativeInfoDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 70%;
	margin-bottom: 1.5rem;
`;

export const NegativeInfoH2Styled = styled.h3`
	text-align: center;
	font-size: 20px;
	font-weight: 550;
	color: ${colors.textBlue};
	margin: 1.5rem;
`;

export const NegativeButtonDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 1.5rem;
`;

export const NegativeAncStyled = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-weight: 601;
	color: ${colors.textBlue};
`;

export const FirstDivABStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 14px;
	padding: 0.5rem 0.5rem;

	@media (max-width: 700px) {
		padding: 1rem 0.5rem;
	}
`;

export const TextFirstDivABStyled = styled.h3`
	text-align: left;
	font-size: 19px;
	letter-spacing: 0px;
	font-weight: 500;
	margin-top: 0.5rem;

	@media (max-width: 700px) {
		margin-left: 0.7rem;
		font-size: 16px;
		margin-bottom: 0.5rem;
	}
`;

export const TitleBlueABStyled = styled.h3`
	font-size: 24px;
	letter-spacing: 0px;
	font-weight: 700;
	margin-top: 0.9rem;
	color: ${colors.textBlue};
`;

export const BlueBoxABStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background: #effeff;
	box-shadow: 0px 3px 12px rgba(40, 192, 205, 0.1);
	border-width: 2px;
	border-style: solid;
	border-color: #26dbd6;
	border-radius: 14px;
`;

export const InputLDDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0.1rem 0.3rem 0;
`;

export const GroupSecondDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 700px) {
		justify-content: center;
		flex-direction: column;
	}
`;

export const CenterIconDivStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TitleFirstDivABStyled = styled.h3`
	text-align: left;
	font-size: 21px;
	letter-spacing: 0px;
	font-weight: 700;

	@media (max-width: 700px) {
		margin-top: 0.5rem;
		margin-left: 0.7rem;
		font-size: 17px;
		font-weight: 700;
	}
`;

export const SecondABDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin: 0.9rem 0.3rem 0;
`;

export const CupomABStyled = styled.img`
	width: 28px;
	height: 28px;
`;

export const DownloadABStyled = styled.img`
	width: 28px;
	height: 28px;
`;

export const FigureABStyled = styled.img`
	width: 128px;
	padding: 0.9rem;

	@media (max-width: 700px) {
		padding: 0.6rem;
	}
`;
