import { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import {
	BlueTitleStyled,
	ContainerStyled,
	CenterDivStyled,
	FormStyled,
	InputDivStyled,
	ButtonDivStyled,
	InputsDivStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/signUp/step-3.png';

import {
	Input,
	InputUncontrolled,
	Button,
} from '../../shared/components/index';

import { dataLayerUserData } from '../../shared/utils/dataLayer/dataLayerUserData';

import AddressController from './controllers/addressController';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';
import { hashCpfMd5, hashEmailSha256 } from '../../shared/utils/hashes';

export interface FormData {
	cep: string;
	street: string;
	number: string;
	complement: string;
	city: string;
	state: string;
	neighborhood: string;
}

type ValuesAddress = {
	street?: string;
	neighborhood?: string;
	city?: string;
	state?: string;
};

const schema = Yup.object().shape({
	street: Yup.string().trim().required('Nome da rua obrigatório'),
	neighborhood: Yup.string()
		.trim()
		.required('Preencha com o nome do bairro que você mora'),
	city: Yup.string().trim().required('Cidade é obrigatória'),
	state: Yup.string()
		.trim()
		.required('Estado é obrigatório')
		.transform((value) => value.replace('_', ''))
		.length(2, 'Digite a sigla do estado. Ex: SP'),
	number: Yup.string()
		.trim()
		.max(9999999999, 'Digite um número válido')
		.required('Número é obrigatório'),
	complement: Yup.string().notRequired(),
});

export const SignUpStepThree = () => {
	const [hidden, setHidden] = useState<boolean>(true);

	const [cep, setCep] = useState('');

	const [addressObject, setAddressObject] = useState<ValuesAddress>(
		{} as ValuesAddress
	);

	const { user, setData } = useAuth();

	const addressController = new AddressController();

	const history = useHistory();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema) });

	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const checkUser = () => {
			if (user?.cpf === null || user?.cpf === undefined)
				history.push('/register/2');
			if (user?.address?.cep !== null && user?.address?.cep !== undefined) {
				if (user?.profile === 'cedente') window.location.href = '/assignor';
				if (user?.profile === 'tomador') {
					const urlReplace = document.URL.replace('/register/3', '');
					window.location.assign(`${urlReplace}/taker/register/finish`);
				}
			}

			dataLayerUserData(user);

			setTimeout(() => {
				setIsLoading(false);
			}, 500);
		};

		checkUser(); // eslint-disable-next-line
	}, [user]);

	const setAddress = async () => {
		if (cep?.replace(/[^\d]+/g, '').length > 7) {
			const data = await addressController.getAddress(cep);
			const { logradouro, bairro, localidade, uf } = data;

			const dataAddress = {
				street: logradouro,
				neighborhood: bairro,
				city: localidade,
				state: uf,
				number: '',
			};
			setAddressObject(dataAddress);
			reset(dataAddress);

			setHidden(false);
		}
	};

	const validateFields = async (form: FormData) => {
		if (cep?.replace(/[^\d]+/g, '').length > 7) {
			eventPush('web_cad_cep_proximo', 'INFO', {
				email_hash: hashEmailSha256(user.email),
				cpf_hash: hashCpfMd5(user.cpf),
				is_negative: user.is_negative,
				state: form.state,
			});
			const payload = {
				state: form.state,
				number: form.number,
				street: form.street,
				neighborhood: form.neighborhood,
				complement: form.complement,
				city: form.city,
				cep: cep,
			};

			setIsLoading(true);
			await addressController.submit(payload);

			setData();

			const urlReplace = document.URL.replace('/register/3', '');
			setIsLoading(false);

			return window.location.assign(`${urlReplace}/taker/register/finish`);
		} else {
			return Swal.fire({
				text: 'Insira um CEP válido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}
	};

	return (
		<ContainerStyled>
			<CenterDivStyled>
				<img src={Logo} alt="Logo VirtusPay" />
			</CenterDivStyled>
			<FormStyled onSubmit={handleSubmit(validateFields)}>
				<div>
					<BlueTitleStyled>Qual o seu endereço?</BlueTitleStyled>

					<CenterDivStyled>
						<img src={Tracking} width="300px" alt="Tracking" />
					</CenterDivStyled>

					<InputUncontrolled
						id="cep"
						onKeyPress={setAddress}
						mask="99999-999"
						type="tel"
						dataTestId="cep"
						name="cep"
						placeholder="CEP"
						value={cep}
						onChange={(e) => setCep(e.target.value)}
					/>

					{!hidden ? (
						<>
							<Input
								control={control}
								mask=""
								id="street"
								name="street"
								dataTestID="street"
								type="text"
								placeholder="Endereço"
								disabled={!!addressObject.street}
								value={addressObject.street}
								error={errors.street && errors.street.message}
							/>

							<Input
								control={control}
								mask=""
								id="number"
								name="number"
								dataTestID="number"
								type="cel"
								placeholder="Número"
								error={errors.number && errors.number.message}
							/>

							<Input
								control={control}
								mask=""
								id="complement"
								name="complement"
								dataTestID="complement"
								type="text"
								placeholder="Complemento"
								error={errors.complement && errors.complement.message}
							/>

							<Input
								control={control}
								mask=""
								id="neighborhood"
								name="neighborhood"
								dataTestID="neighborhood"
								type="text"
								placeholder="Bairro"
								disabled={!!addressObject.neighborhood}
								value={addressObject.neighborhood}
								error={errors.neighborhood && errors.neighborhood.message}
							/>

							<InputsDivStyled>
								<InputDivStyled>
									<Input
										control={control}
										placeholder="Estado"
										mask=""
										id="uf"
										name="state"
										dataTestID="state"
										disabled={!!addressObject.state}
										value={addressObject.state}
										error={errors.state && errors.state.message}
									/>
								</InputDivStyled>

								<InputDivStyled>
									<Input
										control={control}
										mask=""
										id="city"
										name="city"
										dataTestID="city"
										type="text"
										placeholder="Cidade"
										disabled={!!addressObject.city}
										value={addressObject.city}
										error={errors.city && errors.city.message}
									/>
								</InputDivStyled>
							</InputsDivStyled>

							<ButtonDivStyled>
								<Button
									className="has-gradient-blue step_enderecoresidencial"
									type="submit"
									dataTestid="submit"
									textColor="#fff"
									width="300px"
									text="Próximo"
								/>
							</ButtonDivStyled>
						</>
					) : (
						<></>
					)}
				</div>
			</FormStyled>
		</ContainerStyled>
	);
};
