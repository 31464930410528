import axios from '../../../shared/http/customAxios';

class OrderEmailValidationRepository {
	async validation(transaction: string, token: string | null) {
		try {
			const result = await axios().get(
				`v2/order/${transaction}/email/validation?token=${token}`,
				{
					headers: { noAuthorization: true },
				}
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default OrderEmailValidationRepository;
