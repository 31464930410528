export const colors = {
	primary: '#26dbd6',
	primaryHover: '#17C2CE',
	textBlue: '#28C0CD',
	purple: '#6377ff',
	primaryBlue: '#27BDCC',
	defaultGrey: 'rgba(10,10,10,0.1)',
	orangeColor: '#ff9900',
	errorColor: 'red',
	warningColor: 'hsl(44, 100%, 77%)',
	infoColor: 'hsl(207, 61%, 53%)',
	successColor: '#05A400',
	gradient: 'linear-gradient(90deg, #288CC8 0%, #26dbd6 100%) !important',
	black: '#000000',
	white: '#FFFFFF',
};
