import Swal from 'sweetalert2';

import { recoverPasswordRepository } from '../repository';

import { RecoverPasswordI } from '../types';

export async function RecoverPasswordController(payload: RecoverPasswordI) {
	const result = await recoverPasswordRepository(payload);
	if (result.status !== 200) {
		Swal.fire({
			icon: 'error',
			text: result?.data.message ?? 'Não foi possível encontrar seu usuário',
			confirmButtonText: 'Fechar',
		});
		return { error: true };
	}
	return { result: result.data, error: false };
}
