import {
	ContainerStyled,
	ZIndex5Styled,
	ZIndex6Styled,
	CenterDivStyled,
	PStyled,
	BlueDivStyled,
	GreyDivStyled,
	DivTouchStyled,
	DivDesktopStyled,
} from './styles';

export const OrderTracking = () => {
	return (
		<div>
			<DivDesktopStyled>
				<ContainerStyled>
					<ZIndex5Styled data-zIndex={5} style={{ top: '62%' }} />

					<ZIndex6Styled data-zIndex={6} style={{ width: '33%', top: '62%' }} />

					<CenterDivStyled>
						<PStyled>Solicitação Enviada</PStyled>

						<BlueDivStyled style={{ marginTop: '5px' }} />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled>Pendente</PStyled>

						<BlueDivStyled style={{ marginTop: '5px' }} />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled>Em análise de crédito</PStyled>

						<BlueDivStyled style={{ marginTop: '5px' }} />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled>Retorno</PStyled>

						<GreyDivStyled style={{ marginTop: '5px' }} />
					</CenterDivStyled>
				</ContainerStyled>
			</DivDesktopStyled>

			<DivTouchStyled>
				<ContainerStyled>
					<ZIndex5Styled data-zIndex={5} style={{ top: '55%' }} />

					<ZIndex6Styled data-zIndex={6} style={{ width: '34%', top: '55%' }} />

					<CenterDivStyled>
						<PStyled style={{ fontSize: '0.5rem' }}>Enviada</PStyled>

						<BlueDivStyled />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled style={{ fontSize: '0.5rem' }}>Pendente</PStyled>

						<BlueDivStyled />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled style={{ fontSize: '0.5rem' }}>Análise</PStyled>

						<BlueDivStyled />
					</CenterDivStyled>

					<CenterDivStyled>
						<PStyled style={{ fontSize: '0.5rem' }}>Retorno</PStyled>

						<GreyDivStyled />
					</CenterDivStyled>
				</ContainerStyled>
			</DivTouchStyled>
		</div>
	);
};
