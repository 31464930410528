import { useEffect, useLayoutEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import { OrderProps } from '../interfaces';

import { getGeolocation } from '../../../shared/utils/geolocation';

import Logo from '../../../assets/images/logo_virtuspay_azulgrad_400.png';

import { OrderCheckoutController } from '../controllers/orderCheckoutController';
import { checkOrderNextStep } from '../../../shared/utils/checkOrderNextStep';

import OrderNavbar from '../components/OrderNavbar';

import { InsufficientAmount } from './InsufficientAmount';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import SimplifiedProcessSection from './SimplifiedProcessSection';
import { useLoading } from '../../../hooks/loading';

export const OrderCheckout = () => {
	const [resultState, setResultState] = useState();
	const [noInstallments, setNoInstallments] = useState<any | null>(false);

	const [section, setSection] = useState(1);

	const [order, setOrder] = useState<OrderProps>({} as OrderProps);
	const [installment, setInstallment] = useState();
	const [cet, setCet] = useState('');
	const [geolocation, setGeolocation] = useState({});
	const [isSimplifiedProcess, setIsSimplifiedProcess] = useState(false);

	const [firstSectionData, setFirstSectionData] = useState<any>({
		selectedInstallment: { parcelas: '' },
		reason: '',
	});

	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const controller = new OrderCheckoutController();

		const getOrder = async (id: any) => {
			const { result, error, noInstallments } = await controller.get(id);

			setResultState(result);
			setNoInstallments(noInstallments);
			setResultState(result);

			if (error) {
				return Swal.fire({
					icon: 'error',
					confirmButtonText: 'Fechar',
					text:
						result.data.message ??
						'Erro ao recuperar a proposta. Tente novamente em alguns minutos.',
				}).then(() => {
					window.location.href = '/';
				});
			}

			if (!noInstallments) {
				const { next_step } = result;
				const actualPage = 'TakerOrderCheckout';

				const { redirectPage } = checkOrderNextStep(next_step, actualPage, id);

				if (redirectPage) history.push(redirectPage);

				const resOrder = result.order;
				const resInstallments = result.installments;

				setOrder(resOrder);
				setInstallment(resInstallments);
				setIsSimplifiedProcess(result.simplified_process);

				setCet(result.cet_monthly ?? result.cet);
			}

			setIsLoading(false);
		};

		getOrder(id);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: Unreachable code error
		csdp('sessionid', id);
		setGeolocation(getGeolocation);
	}, [id]);

	const sendOrder = async (secondSectionData: {
		income: string;
		cellphone: string;
	}) => {
		const controller = new OrderCheckoutController();

		setIsLoading(true);

		const { selectedInstallmentState, reason } = firstSectionData;
		const { income, cellphone } = secondSectionData;

		const payload = {
			installment: selectedInstallmentState.parcelas,
			installment_reason: reason,
			income: income,
			cellphone: cellphone,
			geolocation: '',
		};

		const { result, error, redirectPage } = await controller.submit(
			id,
			payload
		);

		if (error) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Erro enviar os dados. Tente novamente em alguns minutos.',
			});
		}

		if (redirectPage) history.push(redirectPage);

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const firstNextSection = (secondSectionData: {
		income: string;
		cellphone: string;
	}) => {
		setFirstSectionData(secondSectionData);
		setSection(2);
	};

	const secondNextSection = (secondSectionData: {
		income: string;
		cellphone: string;
	}) => {
		sendOrder(secondSectionData);
	};

	const sendOrderSimplifiedProcess = async () => {
		const controller = new OrderCheckoutController();

		setIsLoading(true);

		const payload = {
			geolocation,
		};

		const { result, error, redirectPage } = await controller.submit(
			id,
			payload
		);

		if (error) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Erro enviar os dados. Tente novamente em alguns minutos.',
			});
		}

		if (redirectPage) history.push(redirectPage);

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	return (
		<>
			{!noInstallments ? (
				<>
					<OrderNavbar src={Logo} partnerLogo={order.brand_logo_url} />
					<main>
						<div className="column">
							{isSimplifiedProcess ? (
								<SimplifiedProcessSection
									order={order}
									handleNextSection={sendOrderSimplifiedProcess}
								/>
							) : section === 1 ? (
								<FirstSection
									order={order}
									installments={installment ?? []}
									cet={cet}
									handleNextSection={firstNextSection}
								/>
							) : section === 2 ? (
								<SecondSection
									order={order}
									handleNextSection={secondNextSection}
								/>
							) : (
								'Ops'
							)}
						</div>
					</main>
				</>
			) : (
				<InsufficientAmount result={resultState} />
			)}
		</>
	);
};
