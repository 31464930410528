import styled from 'styled-components';

import { colors } from '../../../../shared/styles/colors';

export const ButtonStyled = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 40px;
	color: #363636;
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 5px;
	background: white;
	font-weight: 650;
	height: 6rem;

	&:hover {
		cursor: pointer;
	}
`;

export const OkayButtonStyled = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 16px;
	height: 6rem;
	padding: 20px 40px;
	color: #363636;
	border: 1px solid ${colors.successColor};
	border-radius: 5px;

	&:hover {
		cursor: pointer;
	}
`;

export const PClickSuccessStyled = styled.p`
	text-align: center;
	font-weight: 650;
	text-decoration: underline;
	cursor: pointer;
	color: ${colors.successColor};
`;

export const PClickStyled = styled.p`
	text-align: center;
	font-weight: 650;
	text-decoration: underline;
	cursor: pointer;
	color: grey;
`;

export const SpanStyled = styled.span`
	color: grey;
	margin: 0.5rem 0;
	width: 100%;
	text-align: center;
`;
