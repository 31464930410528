import { Route } from 'react-router-dom';
import validator from 'validator';

import { NotFound } from '../../../views/notFound';

export const TransactionRoute = ({ component: Component, ...rest }: any) => {
	const transaction = rest.computedMatch.params.id;

	if (transaction) {
		if (!validator.isUUID(transaction)) {
			return <NotFound />;
		}
	}

	return <Route {...rest} render={(props) => <Component {...props} />} />;
};
