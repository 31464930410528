import MD5 from 'md5';

export const hashEmailMd5 = (email?: string) => {
	const clearedEmail = email?.toLowerCase()?.replace(' ', '');

	if (clearedEmail) {
		const emailHash = MD5(clearedEmail);
		return emailHash;
	}

	return '';
};
