import { validName, emptyValidation } from '../../../utils/validators';

import { colors } from '../../../styles/colors';

export default class CreditFormValidator {
	validateName = (name: string) => {
		const input: HTMLElement | null = document.querySelector('#name');
		const errorInput: HTMLElement | null = document.querySelector(
			'#name-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validName(name)) {
			const message = 'Digite um nome válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			return false;
		}

		input.style.borderColor = colors.successColor;

		errorInput.innerText = '';

		return true;
	};

	validateDueMonth = (date: string) => {
		const input: HTMLElement | null = document.querySelector('#dueMonth');
		const errorInput: HTMLElement | null = document.querySelector(
			'#dueMonth-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(date)) {
			const message = 'Insira o mês de vencimento';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			return false;
		}

		errorInput.innerText = '';

		return true;
	};

	validateDueYear = (date: string) => {
		const input: HTMLElement | null = document.querySelector('#dueYear');
		const errorInput: HTMLElement | null = document.querySelector(
			'#dueYear-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(date)) {
			const message = 'Insira o ano de vencimento';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			return false;
		}

		errorInput.innerText = '';

		return true;
	};

	validateCardNumber = (cardNumber: string) => {
		const input: HTMLElement | null = document.querySelector('#cardNumber');
		const errorInput: HTMLElement | null = document.querySelector(
			'#cardNumber-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (cardNumber?.replace(/[^\d]+/g, '').length < 14) {
			const message = 'Digite um número válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateCodeNumber = (code: string) => {
		const input: HTMLElement | null = document.querySelector('#codeNumber');
		const errorInput: HTMLElement | null = document.querySelector(
			'#codeNumber-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!code || code.replace(/[^\d]+/g, '').length < 3) {
			const message = 'Digite um número válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			return false;
		}

		errorInput.innerText = '';

		return true;
	};
}
