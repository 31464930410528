import { useEffect, useState } from 'react';

import {
	getBoolean,
	getRemoteConfig,
	fetchAndActivate,
} from 'firebase/remote-config';

import { useHistory } from 'react-router';

import {
	SendingMainStyled,
	CenterDivStyled,
	SendingTitleStyled,
	ThanksInputsStyled,
	PClickStyled,
	CenteredDivStyled,
	SendingDivStyled,
	ModalDivStyled,
	IoAlertStyled,
} from './styles';

// import { Button } from '../../shared/components/index';

import { OrderSendingForm } from './components/OrderSendingForm';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';

export const OrderSending = () => {
	const [isNegative, setIsNegative] = useState<boolean | null>(null);
	const [userName, setUserName] = useState<string>('');
	const [isSendOrderActive, setIsSendOrderActive] = useState<boolean>(false);

	const { user } = useAuth();
	const { setIsLoading } = useLoading();

	const history = useHistory();

	const remoteConfig = getRemoteConfig();
	fetchAndActivate(remoteConfig);
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	useEffect(() => {
		setIsLoading(true);
		fetchAndActivate(remoteConfig)
			.then(() => {
				const isSendOrderActiveValue = getBoolean(
					remoteConfig,
					'is_send_order_active'
				);
				setIsSendOrderActive(isSendOrderActiveValue);

				if (!isSendOrderActiveValue) {
					history.push('/taker');
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (user) {
			setIsLoading(true);
			loadUser();
		}
	}, [user]);

	const loadUser = async () => {
		setIsNegative(user?.is_negative);
		setUserName(user?.full_name?.split(' ')[0]);
		setIsLoading(false);
	};

	const templateNegativeUser = () => {
		return (
			<SendingMainStyled>
				<CenterDivStyled>
					<ModalDivStyled>
						<IoAlertStyled size={33} />

						<SendingDivStyled>
							<CenteredDivStyled>
								<SendingTitleStyled>
									Poxa, {userName}, que pena!
								</SendingTitleStyled>

								<p>
									Para parcelar uma compra online, você irá enviar um pedido de
									parcelamento que passará por uma análise de crédito. <br />
									Infelizmente, cliente com restrições no nome terão seu crédito
									reprovado automaticamente.
								</p>
								<ThanksInputsStyled>
									<PClickStyled>E agora?</PClickStyled>
								</ThanksInputsStyled>
								<ThanksInputsStyled>
									<p>
										Sua conta na VirtusPay está criada, e assim que limpar as
										restrições em seu CPF, você pode falar com nosso atendimento
										para alterar o status da sua conta.
									</p>
								</ThanksInputsStyled>
								{/* <Button
									className="has-gradient-blue"
									text="Falar com atendimento"
									textColor="#fff"
									onClick={() =>
										window.open(
											'https://api.whatsapp.com/send?phone=5511989442146&text=Para%20facilitar%20seu%20contato%20conosco,%20envie%20essa%20mensagem%20e%20nos%20adicione%20em%20seus%20contatos.%20Obrigado!%20Equipe%20VirtusPay&source=&data=#.',
											'_blank'
										)
									}
								/> */}
							</CenteredDivStyled>
						</SendingDivStyled>
					</ModalDivStyled>
				</CenterDivStyled>
			</SendingMainStyled>
		);
	};
	return (
		<>
			<main>
				{isNegative ? (
					templateNegativeUser()
				) : isSendOrderActive ? (
					<OrderSendingForm user={user} />
				) : null}
			</main>
		</>
	);
};
