import axios from '../../../shared/http/customAxios';

interface ISubmit {
	file_base64: string | null;
	type_file: string;
}

class BankExtractRepository {
	async submit(transaction: string, lote: number, data: ISubmit) {
		const result = await axios().post(
			`${axios()?.defaults?.baseURL?.replace(
				'/api',
				''
			)}/taker/order/${transaction}/bankext/${lote}/ajax`,
			data,
			{ headers: { noAuthorization: true } }
		);

		return result;
	}
}

export default BankExtractRepository;
