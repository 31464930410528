import axios from '../http/customAxios';

export interface ICredentials {
	username: string;
	password: string;
	captcha: string;
}

class AuthRepository {
	async auth(credentials: ICredentials) {
		try {
			const result = await axios().post(
				'/token/',
				{
					username: credentials.username,
					password: credentials.password,
				},
				{
					headers: { 'X-Firebase-AppCheck': credentials.captcha },
				}
			);

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async getUserFromCore(token: any) {
		try {
			const response = await axios().get('/user/auth/' + token);
			return response;
		} catch (error: any) {
			return error.response;
		}
	}

	async loginWithFacebook(dto: any) {
		try {
			const result = await axios().post('/user/exists', dto);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async refreshToken() {
		try {
			const refresh = await axios().post('/token/refresh/', {
				refresh: localStorage.getItem('refresh'),
			});
			localStorage.setItem('token', refresh.data.access);
		} catch (error: any) {
			return error.response;
		}
	}

	async getUser() {
		try {
			const response = await axios().get('/user');
			return response;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default AuthRepository;
