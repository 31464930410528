import '../input/style.css';
import { Control, Controller } from 'react-hook-form';

import { SelectsStyled } from './styles';

interface IOptions {
	value: string;
	name: string;
}

export interface SelectProps {
	// Props for Element
	id: string;
	label?: string;
	options: IOptions[];
	className?: string;
	value?: string | any;
	name?: string;
	control?: Control;
	dataTestID?: string;
	/* 
		Se useExternalValue for true a prop
		value será usada como valor do input 
		(middleware para o valor final do input)
	*/
	useExternalValue?: boolean;
	/* 
		Estas funções serão executadas apos 
		as funções fornecidas pelo Controller
	*/
	onChange?: (e: any) => void;
	onBlur?: () => void;
}

export const SelectControlled = ({
	id,
	label,
	options,
	className,
	value,
	onChange,
	onBlur,
	name,
	control,
	useExternalValue,
	dataTestID,
}: SelectProps) => {
	const createOptions = () => {
		const newOptions = options.map((item: any) => {
			return (
				<option key={item.name} value={item.value}>
					{item.name}
				</option>
			);
		});
		return newOptions;
	};

	if (control && name) {
		return (
			<Controller
				data-testid="controlInputFile"
				control={control}
				name={name}
				render={({
					field: {
						onChange: onChangeForm,
						onBlur: onBlurForm,
						value: valueForm,
					},
				}) => {
					return (
						<SelectsStyled
							defaultValue=""
							value={useExternalValue ? value : valueForm}
							id={id}
							onChange={(e) => {
								onChangeForm(e);
								if (onChange) {
									onChange(e);
								}
							}}
							onBlur={() => {
								onBlurForm();
								if (onBlur) {
									onBlur();
								}
							}}
							className={className}
							data-testid={dataTestID}
						>
							<option value="" disabled>
								{label}{' '}
							</option>
							{createOptions()}
						</SelectsStyled>
					);
				}}
			/>
		);
	}
	return (
		<SelectsStyled
			id={id}
			onChange={onChange}
			onBlur={onBlur}
			className={className}
			data-testid={dataTestID}
			defaultValue=""
		>
			<option value="" disabled>
				{label}{' '}
			</option>
			{createOptions()}
		</SelectsStyled>
	);
};
