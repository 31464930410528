export const getInstallmentsFromElement = (
	element: {
		installment: any;
		down_payment: string;
		outstanding_balance: string;
		total: string;
		interest: string;
		parcelas: string;
		entrada: string;
		qtd_restante: string;
		juros: string;
	},
	preApproved: boolean
) => {
	if (preApproved) {
		return {
			installment: element.installment,
			down_payment: element.down_payment,
			outstanding_balance: element.outstanding_balance,
			total: element.total,
			interest: element.interest,
		};
	} else {
		return {
			installment: element.parcelas,
			down_payment: element.entrada,
			outstanding_balance: element.qtd_restante,
			total: element.total,
			interest: element.juros,
		};
	}
};

export const getInstallmentsFromElementCoupon = (
	element: {
		quantity: any;
		installment_amount: number;
		total: number;
		discount_value: number;
		starting_amount: number;
		total_with_discount: number;
		interest: string;
	},
	preApproved: boolean
) => {
	if (preApproved) {
		return {
			quantity: element.quantity,
			installment_amount: element.installment_amount,
			total: element.total,
			interest: element.interest,
			discount_value: element.discount_value,
			starting_amount: element.starting_amount,
			total_with_discount: element.total_with_discount,
		};
	} else {
		return {
			quantity: element.quantity,
			installment_amount: element.installment_amount,
			total: element.total,
			interest: element.interest,
			discount_value: element.discount_value,
			starting_amount: element.starting_amount,
			total_with_discount: element.total_with_discount,
		};
	}
};
