export function UpdateFreshDeskData() {
	const user = JSON.parse(localStorage.getItem('user') ?? '{}');

	if (user.email) {
		const [first_name, ...last_name] = user.full_name.split(' ');

		setTimeout(() => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore: Unreachable code error
			window.fcWidget.user.setProperties({
				firstName: first_name,
				lastName: last_name.join(' '),
				email: user.email,
				phone: user.cellphone.substring(3),
				phoneCountry: user.cellphone.substring(0, 3),
				"profile": user.profile,
			});
		}, 5000);
	}
}
