import { FC } from 'react';

import {
	CenteredDivStyled,
	SendingAmountPStyled,
	SendingBlueAmountPStyled,
	SendingTotalAmountPStyled,
	SendingCETAmountPStyled,
} from '../../styles';

import { moneyMask } from '../../../../shared/utils/masks';

export interface IInstallmentInfo {
	cet: number | null;
	cetText: string;
	selectedInstallment: number;
	installmentAmount: string | number;
	rest: number;
	totalAmount: string | number;
	interest: string;
	isValid: boolean;
	discountValue: string | number;
}

const InstallmentInfo: FC<IInstallmentInfo> = ({
	cet,
	cetText,
	// selectedInstallment,
	installmentAmount,
	rest,
	totalAmount,
	interest,
	// isValid,
}) => {
	return (
		<CenteredDivStyled>
			<SendingAmountPStyled>
				Entrada de{' '}
				<SendingBlueAmountPStyled>
					{moneyMask(installmentAmount)}
				</SendingBlueAmountPStyled>
				<br />
			</SendingAmountPStyled>

			<SendingAmountPStyled>
				mais {rest}x de{' '}
				<SendingBlueAmountPStyled>
					{moneyMask(installmentAmount)}
				</SendingBlueAmountPStyled>
			</SendingAmountPStyled>

			<SendingTotalAmountPStyled>
				Total a pagar: <strong>{moneyMask(totalAmount)}</strong>
			</SendingTotalAmountPStyled>

			<SendingCETAmountPStyled>
				Juros de {interest} a.m. CET de {cet} {cetText}
			</SendingCETAmountPStyled>

			<hr />
		</CenteredDivStyled>
	);
};

export default InstallmentInfo;
