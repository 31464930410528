import { SelectHTMLAttributes } from 'react';
import '../input/style.css';

import { SelectsStyled } from '../SelectControlled/styles';

interface IOptions {
	value: string;
	name: string;
}

export interface SelectProps extends SelectHTMLAttributes<any> {
	// Props for Element
	label?: string;
	options: IOptions[];
	value: string | any;

	onBlur?: () => void;
}

export const Select = ({
	label,
	options,
	value,
	onBlur,
	...rest
}: SelectProps) => {
	const createOptions = () => {
		return options.map((item: IOptions) => (
			<option key={item.name} value={item.value}>
				{item.name}
			</option>
		));
	};

	return (
		<SelectsStyled
			value={value ?? label}
			onChange={rest.onChange}
			onBlur={onBlur}
			{...rest}
		>
			{createOptions()}
		</SelectsStyled>
	);
};
