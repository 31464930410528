import BoostLAB from '../../../../../assets/images/landingPage/logo_boostLAB.png';
import WhatsApp from '../../../../../assets/images/landingPage/icon_whatsapp_white_16x16.png';
import ReclameAqui from '../../../../../assets/images/landingPage/seloreclame_aqui.png';
import ABCOMM from '../../../../../assets/images/landingPage/abcomm.png';
import StartupCubo from '../../../../../assets/images/landingPage/cubo_startup-negativo.png';
import Google from '../../../../../assets/images/landingPage/googleforstartup.png';
import Visa from '../../../../../assets/images/landingPage/visa.png';

interface IFooter {
	margin?: string;
	color?: string;
	type?: string;
}

export function Footer({ margin, color, type }: IFooter) {
	const MenuFooter = () => {
		if (type === 'taker') {
			return (
				<div>
					<div className="column is-hidden-mobile is-12-desktop">
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Menu
						</h4>
						<ul className="my-3 is-size-6-mobile is-size-7-desktop">
							<li>
								{' '}
								<a className="has-text-white my-2" href="/login">
									Entrar
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://documenter.getpostman.com/view/215460/SVSPnmLs"
								>
									Desenvolvedor
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://jobs.kenoby.com/virtuspay"
								>
									Carreiras
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://ajuda.usevirtus.com.br"
								>
									FAQ
								</a>
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Precisa de ajuda?
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								<a
									href="tel:551140039471"
									target="_blank"
									rel="noreferrer"
									className="has-text-white"
								>
									4003-9471
								</a>
							</li>
							<li>
								<a
									href="//api.whatsapp.com/send?phone=5511989442146&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#."
									target="_blank"
									rel="noreferrer"
									title="WhatsApp VirtusPay"
								>
									<span className="has-text-white is-flex mt-1">
										<img
											src={WhatsApp}
											className="mr-1 mb-1"
											alt="Logo WhatsApp"
										/>
										(11) 99219-3161
									</span>
								</a>
							</li>
						</ul>
					</div>

					<div className="column is-hidden-desktop is-hidden-tablet 12-mobile">
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Menu
						</h4>
						<ul className="my-3 is-size-6-mobile is-size-7-desktop">
							<li>
								{' '}
								<a className="has-text-white my-2" href="/login">
									Entrar
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://documenter.getpostman.com/view/215460/SVSPnmLs"
								>
									Desenvolvedor
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://jobs.kenoby.com/virtuspay"
								>
									Carreiras
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://ajuda.usevirtus.com.br"
								>
									FAQ
								</a>
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Precisa de ajuda?
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								<a
									href="tel:551140039471"
									target="_blank"
									rel="noreferrer"
									className="has-text-white"
								>
									4003-9471
								</a>
							</li>
							<li>
								<a
									href="//api.whatsapp.com/send?phone=5511989442146&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#."
									target="_blank"
									rel="noreferrer"
									title="WhatsApp VirtusPay"
								>
									<span className="has-text-white is-flex mt-1">
										<img
											src={WhatsApp}
											className="mr-1 mb-1"
											alt="Logo WhatsApp"
										/>
										(11) 99219-3161
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			);
		} else if (type === 'companies') {
			return (
				<div>
					<div className="column mr-6 is-hidden-mobile is-12-desktop">
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Menu
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									target="_blank"
									rel="noreferrer"
									href="https://s3-prd-core.s3.amazonaws.com/static/terms/Termos+e+Condi%C3%A7%C3%B5es+VirtusPay+Semi-pop+v7.pdf"
								>
									Termos e condições de uso
								</a>{' '}
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Desenvolvedor
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									target="_blank"
									rel="noreferrer"
									href="https://documenter.getpostman.com/view/215460/SVSPnmLs#intro"
								>
									API Pix
								</a>{' '}
							</li>
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://documenter.getpostman.com/view/215460/SVSPnmLs#intro"
								>
									API Boleto Parcelado
								</a>{' '}
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Precisa de ajuda?
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								<a
									href="tel:551140039471"
									target="_blank"
									rel="noreferrer"
									className="has-text-white"
								>
									4003-9471
								</a>
							</li>
							<li>
								<a
									href="//api.whatsapp.com/send?phone=5511989442146&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#."
									target="_blank"
									rel="noreferrer"
									title="WhatsApp VirtusPay"
								>
									<span className="has-text-white is-flex mt-1">
										<img
											src={WhatsApp}
											className="mr-1 mb-1"
											alt="Logo WhatsApp"
										/>
										(11) 99219-3161
									</span>
								</a>
							</li>
						</ul>
					</div>

					<div className="column is-hidden-desktop is-12-mobile">
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Menu
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								{' '}
								<a
									className="has-text-white my-2"
									href="https://s3-prd-core.s3.amazonaws.com/static/terms/Termos+e+Condi%C3%A7%C3%B5es+VirtusPay+Semi-pop+v7.pdf"
								>
									Termos e condições de uso
								</a>{' '}
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Desenvolvedor
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								{' '}
								<a className="has-text-white my-2" href=" ">
									API Pix
								</a>{' '}
							</li>
							<li>
								{' '}
								<a className="has-text-white my-2" href=" ">
									API Boleto Parcelado
								</a>{' '}
							</li>
						</ul>

						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Precisa de ajuda?
						</h4>
						<ul className="my-3 is-size-7-mobile is-size-7-desktop">
							<li>
								<a href="tel:551140039471" className="has-text-white">
									4003-9471
								</a>
							</li>
							<li>
								<a
									href="//api.whatsapp.com/send?phone=5511989442146&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#."
									target="_blank"
									rel="noreferrer"
									title="WhatsApp VirtusPay"
								>
									<span className="has-text-white">
										<img src={WhatsApp} alt="WhatsApp" />
										(11) 99219-3161
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			);
		} else if (type === 'assignor') {
			return (
				<div className="column is-3-desktop">
					<h4 className="is-size-6 has-text-weight-semibold has-text-white">
						Menu
					</h4>
					<ul className="my-3 is-size-6-mobile is-size-7-desktop">
						<li>
							{' '}
							<a className="has-text-white my-2" href="/login">
								Entrar
							</a>{' '}
						</li>
						<li>
							{' '}
							<a
								className="has-text-white my-2"
								href="https://documenter.getpostman.com/view/215460/SVSPnmLs"
							>
								Desenvolvedor
							</a>{' '}
						</li>
						<li>
							{' '}
							<a
								className="has-text-white my-2"
								href="https://jobs.kenoby.com/virtuspay"
							>
								Carreiras
							</a>{' '}
						</li>
						<li>
							{' '}
							<a
								className="has-text-white my-2"
								href="https://boletopop.usevirtus.com.br/duvidas-frequentes-cedentes"
							>
								FAQ
							</a>
						</li>
					</ul>
					<h4 className="is-size-6 has-text-weight-semibold has-text-white">
						Precisa de ajuda?
					</h4>
					<ul className="my-3 is-size-7-mobile is-size-7-desktop">
						<li>
							<a
								className="has-text-white my-2"
								href="mailto:atendimento@usevirtus.com.br"
							>
								atendimento@usevirtus.com.br
							</a>
						</li>
						<li>
							<a
								href="tel:551140039471"
								target="_blank"
								rel="noreferrer"
								className="has-text-white"
							>
								4003-9471
							</a>
						</li>
						<li>
							<a
								href="//api.whatsapp.com/send?phone=5511989442146&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#."
								target="_blank"
								rel="noreferrer"
								title="WhatsApp VirtusPay"
							>
								<span className="has-text-white is-flex mt-1">
									<img
										src={WhatsApp}
										className="mr-1 mb-1"
										alt="Logo WhatsApp"
									/>
									(11) 99219-3161
								</span>
							</a>
						</li>
					</ul>
				</div>
			);
		}
	};

	return (
		<footer
			style={{ background: color }}
			className="footer pb-0 has-text-white"
		>
			<section
				style={{ boxShadow: 'none ' }}
				className={`column is-multiline py-5 ${margin} container`}
			>
				<div className="columns is-hidden-touch is-12 is-flex is-justify-content-space-between">
					{MenuFooter()}

					<div className="column is-4-desktop is-flex is-flex-direction-column is-align-items-end">
						<div className="is-12 ml-6">
							<h4 className="is-size-6 has-text-weight-semibold has-text-white">
								Acelerações
							</h4>
							<div className="is-12 is-flex is-justify-content-center is-align-items-center">
								<div className="column">
									<img
										className="lazyload"
										src={BoostLAB}
										alt="Logo Boostlab"
									/>
								</div>
							</div>
							<h4 className="is-size-6 has-text-weight-semibold has-text-white">
								Selos
							</h4>
							<div className="d-flex justify-content-start align-items-center">
								<div className="column">
									<img
										className="lazyload mr-1"
										src={ReclameAqui}
										alt="Selo Reclame Aqui"
										style={{ width: '80px' }}
									/>
									<img
										className="lazyload ml-1"
										src={ABCOMM}
										alt="Selo ABCOMM"
										style={{ width: '80px', height: '80px' }}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="column is-4-desktop is-flex is-flex-direction-column is-align-items-end">
						<div className="is-12 ml-4">
							<h4 className="is-size-6 has-text-weight-semibold has-text-white">
								Membro Digital
							</h4>
							<div className="is-12 is-flex is-justify-content-flex-end is-align-items-center">
								<div className="column is-6-mobile">
									<img
										className="lazyload"
										style={{ width: '65px' }}
										src={StartupCubo}
										alt="Logo Cubo"
									/>
									<img
										className="lazyload mt-2"
										style={{ width: '130px' }}
										src={Google}
										alt="Logo Google for Startup"
									/>
								</div>
							</div>

							<h4 className="is-size-6 mt-3 has-text-weight-semibold has-text-white">
								Finalistas
							</h4>
							<div className="is-12 is-flex is-justify-content-flex-start is-align-items-center">
								<div className="column is-6-mobile">
									<img className="lazyload" src={Visa} alt="Logo Visa" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="columns is-hidden-desktop is-12">
					{MenuFooter()}

					<div className="column is-4-desktop is-12-mobile">
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Acelerações
						</h4>
						<div className="is-12 is-flex is-justify-content-center is-align-items-center">
							<div className="column">
								<img className="lazyload" src={BoostLAB} alt="Logo Boostlab" />
							</div>
						</div>
						<h4 className="is-size-6 has-text-weight-semibold has-text-white">
							Selos
						</h4>
						<div className="d-flex justify-content-start align-items-center">
							<div className="column">
								<img
									className="lazyload"
									src={ReclameAqui}
									alt="Selo Reclame Aqui"
									style={{ width: '80px', height: '80px' }}
								/>
								<img
									className="lazyload ml-2"
									src={ABCOMM}
									alt="Selo ABCOMM"
									style={{ width: '80px', height: '80px' }}
								/>
							</div>
						</div>
					</div>

					<div className="column is-4-desktop is-12-mobile is-flex is-flex-direction-column is-justify-content-flex-end is-align-content-flex-end">
						<h4 className="is-size-6 mt-3 has-text-weight-semibold has-text-white">
							Membro Digital
						</h4>
						<div className="is-12 is-flex is-justify-content-flex-start is-align-items-center">
							<div className="column is-6-mobile">
								<img
									className="lazyload"
									style={{ width: '65px' }}
									src={StartupCubo}
									alt="Logo Cubo"
								/>
								<img
									className="lazyload mt-2"
									style={{ width: '130px' }}
									src={Google}
									alt="Logo Google for Startup"
								/>
							</div>
						</div>

						<h4 className="is-size-6 mt-3 has-text-weight-semibold has-text-white">
							Finalistas
						</h4>
						<div className="is-12 is-flex is-justify-content-flex-start is-align-items-center">
							<div className="column is-6-mobile">
								<img className="lazyload" src={Visa} alt="Logo Visa" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
}
