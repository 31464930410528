import styled from 'styled-components';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { colors } from '../../../../shared/styles/colors';

export const FirstDivStyled = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	text-align: center;
	margin: 0.5rem 0.9rem;
`;

export const AiOutlineArrowLeftStyled = styled(AiOutlineArrowLeft)`
	display: flex;
	justify-content: start;
	align-items: center;
	color: grey;
`;

export const TitleStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 650;
	font-size: 23px;
	margin-top: 0.5rem;
	text-align: center;
	color: black;
`;

export const TitleSectionStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 650;
	margin-top: 0.6rem;
	font-size: 23px;
	text-align: start;
	color: black;
`;

export const GapStyled = styled.div`
	display: flex;
	height: 2px;
	border-radius: 20px;
	margin: 0.5rem 0 0.9rem 0;
	background-color: ${colors.primary};
`;

export const DivSectionStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: auto;
	justify-content: space-between;

	@media (max-width: 800px) {
		width: 100%;
		margin: 1rem auto;
	}
`;

export const DivDetailsStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: auto;
	text-align: left;
	justify-content: start;
	margin-bottom: 1rem;

	@media (max-width: 800px) {
		width: 100%;
		margin-bottom: 0rem;
		flex-direction: column;
	}
`;

export const TextStyled = styled.p`
	font-family: 'Montserrat';
	font-size: 17px;
	text-align: start;
	color: black;
	margin: 0.5rem 0rem;
	width: 500px;

	@media (max-width: 800px) {
		width: 100%;
		margin-bottom: 0.9rem;
		flex-direction: column;
	}
`;

export const TextAreaStyled = styled.textarea`
	font-family: 'Montserrat';
	font-size: 17px;
	text-align: start;
	padding: 0.5rem;
	color: black;
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	margin: 1rem auto;
	width: 65%;
	align-items: center;
	justify-content: center;

	@media (max-width: 800px) {
		width: 80%;
		margin: 1rem auto;
	}
`;

export const TextJustificationStyled = styled.p`
	font-family: 'Montserrat';
	font-size: 17px;
	text-align: start;
	color: black;
	margin: 0.5rem 0rem;

	@media (max-width: 800px) {
		width: 100%;
		margin-bottom: 0.9rem;
		flex-direction: column;
	}
`;

export const SpanStyled = styled.span`
	font-family: 'Montserrat';
	font-size: 17px;
	text-align: start;
	font-weight: 700;
	text-decoration: underline;
`;
