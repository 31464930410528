import styled from 'styled-components';
import { colors } from '../../../../shared/styles';

export const InputCouponContainerStyled = styled.div`
	position: relative;
	margin-bottom: 10px;

	input,
	select {
		border: 1px solid rgba(10, 10, 10, 0.1);
		border-radius: 4px;
		padding: 14px 20px;
		font-size: 0.85rem;
		width: 100%;
	}

	input:focus,
	select:focus {
		outline: none;
	}
`;

export const Button = styled.button`
	display: inline-block;
	width: auto;
	position: absolute;
	right: 0px;
	padding: 13px 18px;
	font-size: 0.95rem;
	font-weight: bolder;
	border: none;
	cursor: pointer;
	background: linear-gradient(90deg, #288cc8 0%, #26dbd6 100%) !important;
	color: ${colors.white};
`;
