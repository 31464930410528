import { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router';

import Swal from 'sweetalert2';

import {
	IMGStyled,
	TitleStyled,
	PStyled,
	CenterDivStyled,
	RowDivStyled,
	OptSelectStyled,
	PdocStyled,
	CenteredDivStyled,
	SpanStyled,
	ArrowDivStyled,
} from './styles';

import { getDLController } from './controller';

import { Chargeback } from './components/Chargeback/Chargeback';
import { ChangeDL } from './components/ChangeDL/ChangeDL';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import ImageRefused from '../../assets/images/refused_2.0.png';

import { GiWallet } from 'react-icons/gi';

import { VirtusLoader, Button } from '../../shared/components/index';

import { moneyMask } from '../../shared/utils/masks';

import OrderNavbar from '../order/components/OrderNavbar';
import { colors } from '../../shared/styles/colors';
import { AiOutlineArrowLeft } from 'react-icons/ai';

export const PaymentError = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeDocument, setActiveDocument] = useState('');

	// Estados pertinentes ao ChangeDL
	const [minAmount, setMinAmount] = useState('');
	const [maxAmount, setMaxAmount] = useState('');

	// States que cuidarão do aparecimento das respetivas DIV's na tela
	const [isHiddenSelect, setIsHiddenSelect] = useState(false);
	const [isHiddenNewTicket, setIsHiddenNewTicket] = useState(true);
	const [isHiddenChargeback, setIsHiddenChargeback] = useState(true);

	const { id } = useParams<{ id: string }>();

	const history = useHistory();

	// useEffect pertinente a validação do ChangeDL
	useEffect(() => {
		checkTicket(); // eslint-disable-next-line
	}, []);

	// Função pertinente a validação do ChangeDL
	const checkTicket = async () => {
		setIsLoading(true);

		const { result, error } = await getDLController(id);

		if (error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'error',
				text:
					result?.data.message ??
					'Erro processar os dados. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			}).then(() => history.push('/taker'));
		} else {
			setMinAmount(moneyMask(result.min_allowed_amount));
			setMaxAmount(moneyMask(result.max_allowed_amount));
			setIsLoading(false);
		}
	};

	const showStep = (estorno = false) => {
		if (estorno) {
			handleChargeback();
		} else if (activeDocument === 'novaLD') {
			handleNewTicket();
		}
	};

	const handleChargeback = () => {
		hideAll();
		setIsHiddenChargeback(false);
	};

	const handleNewTicket = () => {
		hideAll();
		setIsHiddenNewTicket(false);
	};

	const hideAll = () => {
		setIsHiddenSelect(true);
		setIsHiddenChargeback(true);
		setIsHiddenNewTicket(true);
	};

	const changeValueLoading = () =>
		setIsLoading((previousState) => !previousState);

	const returnStep = () => {
		setActiveDocument('');
		setIsHiddenChargeback(true);
		setIsHiddenNewTicket(true);
		setIsHiddenSelect(false);
	};
	return (
		<>
			{isLoading && <VirtusLoader />}
			<>
				<OrderNavbar src={Logo} />
				{!isHiddenChargeback || !isHiddenNewTicket ? (
					''
				) : (
					<ArrowDivStyled>
						<AiOutlineArrowLeft onClick={() => history.push('/taker/order')} />
					</ArrowDivStyled>
				)}
				<CenterDivStyled isHidden={isHiddenSelect}>
					<IMGStyled src={ImageRefused} alt="Error" />
					<TitleStyled>
						Houve um problema com o boleto da loja. Vamos resolver!
					</TitleStyled>
					<PStyled style={{ width: '70%' }}>
						Para que possamos resolver, é simples! Basta que você gere um novo
						boleto da loja do seu produto e nos envie clicando em “Vincular novo
						boleto”{' '}
					</PStyled>

					<RowDivStyled>
						<RowDivStyled>
							<OptSelectStyled
								id="novaLD"
								onClick={() => setActiveDocument('novaLD')}
								className={` ${
									activeDocument === 'novaLD' && 'selected_indicator'
								}`}
							>
								<GiWallet size={40} color={colors.primaryBlue} />

								<PdocStyled>Vincular novo boleto</PdocStyled>
							</OptSelectStyled>
						</RowDivStyled>
					</RowDivStyled>

					<CenteredDivStyled>
						<Button
							className="has-gradient-blue"
							text="Próximo"
							textColor="#fff"
							onClick={() => showStep(false)}
							width="300px"
						/>
						<PStyled
							style={{ fontSize: '13px', width: '300px', marginTop: '1rem' }}
						>
							Caso prefira o estorno do valor da parcela de entrada,
							<SpanStyled
								onClick={() => {
									showStep(true);
								}}
							>
								{' '}
								clique aqui
							</SpanStyled>
						</PStyled>
					</CenteredDivStyled>
				</CenterDivStyled>

				<CenterDivStyled isHidden={isHiddenChargeback}>
					{!isLoading && (
						<Chargeback
							changeValueLoading={changeValueLoading}
							returnStep={returnStep}
						/>
					)}
				</CenterDivStyled>
				<CenterDivStyled isHidden={isHiddenNewTicket}>
					<ChangeDL
						changeValueLoading={changeValueLoading}
						returnStep={returnStep}
						type={activeDocument}
						minAmount={minAmount}
						maxAmount={maxAmount}
					/>
				</CenterDivStyled>
			</>
		</>
	);
};
