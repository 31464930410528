import { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Countdown from 'react-countdown';
import Swal from 'sweetalert2';

import { MdTimer } from 'react-icons/md';

import {
	CenterDivStyled,
	TitleStyled,
	PStyled,
	SpanStyled,
	ButtonDivStyled,
	InfoDivStyled,
	TelDivStyled,
	IMGStyled,
	TimePStyled,
} from './styles';

import Call from '../../assets/images/call.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';

import { Button } from '../../shared/components/index';

import { checkOrderNextStep } from '../../shared/utils/checkOrderNextStep';
import { redirectReturnUrlVtex } from '../../shared/utils/redirectReturnUrlVtex';

import OrderNavbar from '../order/components/OrderNavbar';

import Digits from './components/digits';

import TokenController from './controllers/tokenController';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';

const controller = new TokenController();

export const Token = () => {
	const [expiredTimer, setExpiredTimer] = useState(false);
	const [phone, setPhone] = useState('');
	const [returnUrl, setReturnUrl] = useState(null);
	const [channel, setChannel] = useState('');
	const [time, setTime] = useState(0);
	const [activeTimer, setActiveTimer] = useState(false);
	const [contactType, setContactType] = useState('phonenumber');

	const { id } = useParams<{ id: string }>();
	const { setIsLoading, isLoading } = useLoading();
	const history = useHistory();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		checkToken();
		// eslint-disable-next-line
	}, []);

	const checkToken = async () => {
		const { result } = await controller.check(id);

		const nextStep = result.next_step;
		const actualPage = 'TakerOrderTokenValidation';

		const { redirectPage } = checkOrderNextStep(nextStep, actualPage, id);

		if (redirectPage) {
			history.push(redirectPage);
		}

		setPhone(result.customer_cellphone);
		setReturnUrl(result.return_url);
		setChannel(result.channel);

		redirectReturnUrlVtex(returnUrl, channel, id);

		initTimer();

		setIsLoading(false);
	};

	const sendToken = async (selectedContactType: string) => {
		setIsLoading(true);

		await controller.send(phone, id, selectedContactType);

		if (time === 5) {
			history.push('/taker/order/' + id);
		}

		initTimer();

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const initTimer = () => {
		setActiveTimer(true);
		setTime(time + 1);
	};

	const completeTimer = () => {
		setActiveTimer(false);
		setExpiredTimer(true);
	};

	const renderTimer = () => {
		if (activeTimer) {
			return (
				<TimePStyled>
					<MdTimer size={17} />
					Tempo restante&nbsp;
					<Countdown
						date={Date.now() + 60000}
						onComplete={completeTimer}
						renderer={(props) => (
							<strong>
								{props.minutes.toLocaleString(undefined, {
									minimumIntegerDigits: 2,
								})}
								:
								{props.seconds.toLocaleString(undefined, {
									minimumIntegerDigits: 2,
								})}
							</strong>
						)}
					/>
				</TimePStyled>
			);
		}
		return (
			<Button
				width="300px"
				className="has-gradient-blue"
				textColor="#fff"
				text="Próximo"
				onClick={submit}
			/>
		);
	};

	const renderButton = () => {
		if (!activeTimer && expiredTimer) {
			return (
				<>
					<TelDivStyled
						onClick={() => {
							sendToken('voice');
							setContactType('voice');
						}}
					>
						{' '}
						<IMGStyled alt="Telefone chamando" src={Call} />
						Receber código por ligação no número (**) *****-
						{phone.substring(phone.length, phone.length - 4)}
					</TelDivStyled>

					<Button
						width="300px"
						className="has-gradient-blue"
						textColor="#fff"
						text="Reenviar código por SMS"
						onClick={() => {
							sendToken('phonenumber');
							setContactType('phonenumber');
						}}
					/>
				</>
			);
		}

		if (!isLoading) {
			return (
				<Button
					width="300px"
					className="has-gradient-blue"
					textColor="#fff"
					text="Próximo"
					onClick={submit}
				/>
			);
		}
	};

	const submit = async () => {
		eventPush('web_token_continuar', 'INFO', { transaction: id });

		controller.dto.transaction = id;
		controller.dto.token = controller.getTokenValue();
		controller.dto.contactType = contactType;

		if (controller.dto.token !== false) {
			setIsLoading(true);
			const { result, error, redirectPage } = await controller.submit();

			if (error) {
				controller.cleanDigits();
				controller.dto.token = '';
				Swal.fire({
					icon: 'error',
					text: result.data.message,
					confirmButtonText: 'Fechar',
				});
			}

			if (redirectPage) history.push(redirectPage);

			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
		}
	};

	return (
		<main>
			<OrderNavbar src={Logo} />

			<CenterDivStyled>
				<TitleStyled>
					Valide seu celular
					{/* <br /> para continuar */}
				</TitleStyled>

				<PStyled>
					Informe o código enviado <br /> para o número (**) *****-
					{phone.substring(phone.length, phone.length - 4)}
				</PStyled>

				<Digits />

				<InfoDivStyled>
					<SpanStyled onClick={() => controller.cleanDigits()}>
						Limpar
					</SpanStyled>
				</InfoDivStyled>

				<InfoDivStyled>{renderTimer()}</InfoDivStyled>

				<ButtonDivStyled>{renderButton()}</ButtonDivStyled>
			</CenterDivStyled>
		</main>
	);
};
