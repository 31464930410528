/* eslint-disable react/display-name */
/* eslint-disable no-constant-condition */
import React, {
	useEffect,
	useRef,
	useState,
	FC,
	useMemo,
	ChangeEvent,
} from 'react';

import Swal from 'sweetalert2';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	getString,
	getRemoteConfig,
	fetchAndActivate,
} from 'firebase/remote-config';

import {
	SendingMainStyled,
	AiOutlineArrowLeftStyled,
	CenterMarginTopDivStyled,
	CenterDivStyled,
	ThanksDivStyled,
	SendingTitleStyled,
	ThanksInputsStyled,
	SendingPStyled,
	GroupStyled,
	InputLabelStyled,
	FormStyled,
	// WhatsAppStyled,
	// WhatsAppTextStyled,
	// WhatsAppDivStyled,
} from '../../styles';
import { OrderSendingFormSchema } from '../../validation';

import Logo from '../../../../assets/images/logo_virtuspay_azulgrad_200.png';
// import WhatsApp from '../../../../assets/images/whatsapp.png';

import {
	Input,
	InputFile,
	Button,
	SelectControlled,
} from '../../../../shared/components/index';

import InputCoupon from '../inputCoupon/input';

import OrderSendingController from '../../controllers/orderSendingController';
// import { uxResearchController } from '../../controllers/uxResearchController';

import getCookie, { deleteCookie } from '../../../../shared/utils/cookies';
import { dataLayerUserData } from '../../../../shared/utils/dataLayer/dataLayerUserData';
import { getGeolocation } from '../../../../shared/utils/geolocation';
import { getIp } from '../../../../shared/utils/getIp';
import { hashJwtMd5 } from '../../../../shared/utils/hashes';
import fileToBase64 from '../../../../shared/utils/fileToBase64';
import {
	moneyMask,
	dateMask,
	undoDateMask,
	undoMoneyMask,
	capitalize,
} from '../../../../shared/utils/masks';
import { onlyNumber } from '../../../../shared/utils/onlyNumbers';

import { orderCategories } from '../../../../shared/variables';

import RenderInstallmentsOptions from '../InstallmentsOptions';
import RenderInstallmentInfo from '../InstallmentInfo';
import RenderPreApprovedNegative from '../PreApprovedNegative';

import BilletService from '../../services/billetService';
import PreApprovedService from '../../services/preApprovedService';

import { elkBankingBilletConsult } from '../../utils/elkServices';
import { payslipVerificationDataLayer } from '../../utils/payslipVerificationDataLayer';
import OrderSendingValidator from '../../utils/validator';
import Validator from '../../../signUp/utils/validator';

import { IFile } from '../../interfaces';
import { ICupom } from '../../interfaces';
import { IUserAuth } from '../../../../hooks/auth/auth';
import { useLoading } from '../../../../hooks/loading';
import { formatBilletLine } from '../../utils/formatBillet';
import { colors } from '../../../../shared/styles/colors';
import eventPush from '../../../../shared/utils/eventPush';
export interface ISelectOptions {
	name: string;
	value: string;
}

export interface IOrderSendingForm {
	user: IUserAuth;
}

interface IStore {
	cnpj: string;
	fantasia: string;
	opn: [string];
}

export interface IPostStore {
	id: string | null;
	store: string;
}

interface FormData {
	billet: string;
	dueDate: string;
	amount: string;
	ticket: string;
	otherInput: string;
	otherCategory: string;
	store: string;
	coupon: string;
	installment: string;
	tickedFile: string;
	category: string;
}

declare global {
	interface Window {
		gtag: any; // this will be your variable name
	}
}

export const OrderSendingForm: FC<IOrderSendingForm> = ({ user }) => {
	const {
		control,
		formState: { isValid: isValidToSubmit, errors },
		getValues,
		setValue,
		reset,
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(OrderSendingFormSchema),
	});

	const [isHidden, setIsHidden] = useState(true);
	const [isHiddenInputs, setIsHiddenInputs] = useState(true);
	const [cpf, setCpf] = useState('');
	const [email, setEmail] = useState('');

	const [cellphone, setCellphone] = useState('');
	const [cep, setCep] = useState('');
	const [ip, setIp] = useState('');
	const [tests, setTests] = useState('');

	const [line, setLine] = useState('');
	const [amount, setAmount] = useState('');
	const [renderInput, setRenderInput] = useState(false);

	const [dueDate, setDueDate] = useState('');
	const [validBillet, setValidBillet] = useState(false);
	const [cet, setCet] = useState(null);
	const [cetText, setCetText] = useState('');
	const [installments, setInstallments] = useState([]);
	const [selectedInstallment, setSelectedInstallment] = useState(-1);
	const [stores, setStores] = useState<IStore[]>([]);
	const [preApproved, setPreApproved] = useState(null);
	// const [showWhatsApp, setShowWhatsApp] = useState(false);
	const [preApprovedNegative, setPreApprovedNegative] = useState(false);
	const [attempts, setAttempts] = useState(0);

	const [store, setStore] = useState(null);
	const [needDoc, setNeedDoc] = useState(false);
	const [installmentAmount, setInstallmentAmount] = useState<string | number>(
		''
	);
	const [rest, setRest] = useState(-1);
	const [totalAmount, setTotalAmount] = useState('');
	const [interest, setInterest] = useState<string | undefined>('');
	const [clSessionId, setClSessionId] = useState('');
	const [geolocation, setGeolocation] = useState({});
	const [gclid, setGclid] = useState<string | null>(null);

	const [firstRendering, setFirstRendering] = useState(true);

	const [amountCoupon, setAmountCoupon] = useState<string | number>('');
	const [, setAmountOriginal] = useState<string>('');
	const [isValid, setIsCupomValid] = useState(false);
	const [discountValue, setDiscountValue] = useState<string | number>(0);

	const [doc, setDoc] = useState<IFile>({} as IFile);
	const [installmentItems, setInstallmentItems] = useState({} as any);
	const [hideStoreField, setHideStoreField] = useState(false);

	const [clientID, setClientID] = useState('');

	const [billet, setBillet] = useState<string | null>('');

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const remoteConfig = getRemoteConfig();
	fetchAndActivate(remoteConfig);
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	const ButtonContinueRef = useRef<HTMLButtonElement>(null);
	const BilletInputRef = useRef<HTMLInputElement>(null);
	const stateRef = useRef<string>('');
	stateRef.current = line;
	const stateBefore = useRef<string>('');
	const storeRef = useRef<IStore[]>([]);
	storeRef.current = stores;

	const orderSendingController = new OrderSendingController();
	const validator = new OrderSendingValidator();
	const validatorDoc = new Validator();

	useEffect(() => {
		if (!billet) return;
		setIsLoading(true);

		if (billet.length === 47) {
			deleteCookie('billet');
			setValue('billet', billet, { shouldValidate: true });
			validateBillet(billet);
		}
	}, [billet]);

	useEffect(() => {
		loadFormData();
		setGeolocation(getGeolocation);
	}, [user]);

	useEffect(() => {
		if (stores?.length && store) {
			validateStore();
		}
	}, [store]);

	useEffect(() => {
		if (stores?.length === 1) {
			setValue('store', stores[0].opn);
			setHideStoreField(true);
		}
	}, [stores]);

	window.gtag('get', 'G-PQ9J9ZN879', 'client_id', (clientId: string) => {
		setClientID(clientId);
	});

	useEffect(() => {
		if (attempts >= 6) {
			setIsLoading(false);
			Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Erro ao processar os dados. Tente novamente em alguns minutos.',
			}).then(() => {
				history.push('/taker');
			});
		} else {
			if (installmentItems.installments?.length < 1) {
				tests.includes('preapproved')
					? sendToPreApproved(installmentItems.total_amount)
					: getInstallments(installmentItems.total_amount);
			} else {
				setInstallments(installmentItems.installments);
				setIsLoading(false);
			}
		}
	}, [installmentItems, attempts]);

	useEffect(() => {
		setIsLoading(true);
		if (installments?.length) {
			const initialInstallment: number = (installments[0] as any).installment;
			setSelectedInstallment(initialInstallment);
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (installments?.length) {
			sazonal();
		}
	}, [attempts]);

	const sazonal = () => {
		setIsLoading(true);
		const globalCoupon = getString(remoteConfig, 'web_global_coupon');
		const cookieCoupon = getCookie('coupon');
		if (globalCoupon.length > 1) {
			setValue('coupon', globalCoupon);
			validateCupom();
		} else {
			if (cookieCoupon) {
				setValue('coupon', cookieCoupon);
				validateCupom();
			} else {
				setIsLoading(false);
			}
		}
	};

	/* Função para determinar tempo de execução*/
	// const sleep = (milliseconds: number) => {
	// 	return new Promise((resolve) => setTimeout(resolve, milliseconds));
	// };

	// const showWhatsAppIcon = async (preApprovedProp: boolean) => {
	// 	await sleep(15000);
	// 	if (preApprovedProp) {
	// 		// setShowWhatsApp(true);

	// 		eventPush('web_parc_bol_aparecer_caixa_sugestao', 'INFO', {
	// 			ld: line,
	// 			vencimento: dueDate,
	// 			loja: store,
	// 			valor: amount,
	// 			num_parc: selectedInstallment,
	// 		});

	// 		const payload: IUXResearch = {
	// 			event: 'Show',
	// 			platform: 'web-app',
	// 			date: new Date().toISOString(),
	// 		};

	// 		await uxResearchController(payload);
	// 	}
	// };

	// const clickWhatsAppIcon = async () => {
	// 	eventPush('web_parc_bol_esta_com_dif', 'INFO', {
	// 		ld: line,
	// 	});

	// 	const payload: IUXResearch = {
	// 		event: 'Click',
	// 		platform: 'web-app',
	// 		date: new Date().toISOString(),
	// 	};

	// 	await uxResearchController(payload);

	// 	window.open(
	// 		'https://api.whatsapp.com/send?phone=5511939455392&text=Para facilitar seu contato conosco, envie essa mensagem e nos adicione em seus contatos. Obrigado! Equipe VirtusPay&source=&data=#.',
	// 		'_blank'
	// 	);
	// };

	/* Funções de validação */
	const validateCategory = () => {
		validator.validateCategory(getValues('category'));
	};

	const validateOtherCategory = () => {
		validator.validateOtherCategory(getValues('otherCategory'));
	};

	const validateStore = async () => {
		const storeValue = getValues('store');

		validator.validateStore(storeValue);

		if (preApproved) await updatePreApprovedStore(storeValue, preApproved);
	};

	const validateCupom = async (e?: React.MouseEvent<HTMLButtonElement>) => {
		if (e) e.preventDefault();
		const couponCookie = getCookie('coupon') ?? '';

		const couponText =
			couponCookie.length > 1 ? couponCookie : getValues('coupon');

		eventPush('web_parc_bol_ativar_cupom', 'INFO', { ld: line });

		if (couponText) {
			deleteCookie('coupon');
			const payload: ICupom = {
				total_amount: undoMoneyMask(amount).toString().replace('.', ','),
				coupon: couponText.toUpperCase(),
			};

			const result = await orderSendingController.postCupom(payload);

			setValue('amount', moneyMask(result.total_amount));
			setValue('amountCoupon', moneyMask(result.total_amount_with_discount));
			setValue('coupon', couponText);
			setIsCupomValid(result.is_valid);
			setAmountOriginal(result.total_amount);
			setAmountCoupon(moneyMask(result.total_amount_with_discount));

			if (!result.is_valid) {
				setValue('coupon', '');
				return Swal.fire({
					title: couponText,
					icon: 'error',
					text: result.message ?? 'Preencha o campo com um cupom válido.',
					confirmButtonText: 'Entendi',
				});
			} else {
				setIsLoading(true);
				const amountCouponInput: HTMLElement | null = document.querySelector(
					'#amount-coupon-input'
				);
				if (amountCouponInput)
					amountCouponInput.style.borderColor = colors.successColor;

				tests.includes('preapproved')
					? sendToPreApproved(result.total_amount_with_discount)
					: getInstallments(result.total_amount_with_discount);
			}
		} else {
			setIsLoading(false);
			return Swal.fire({
				icon: 'error',
				text: 'Preencha o campo de cupom',
				confirmButtonText: 'Entendi',
			});
		}
	};

	const validateBillet = (billet: string) => {
		if (billet) {
			const formatValue = formatBilletLine(billet);
			setLine(formatValue);
			if (formatValue?.length === 47) {
				billetLineHandler(formatValue);
			} else {
				reset();
				resetFields();
				setIsHiddenInputs(true);
				setIsHidden(true);
				return;
			}
		}
	};

	const billetLineHandler = async (textLine: string) => {
		eventPush('web_home_teste_digitou_ld_B', 'INFO');
		const billetService = new BilletService();
		setIsLoading(true);
		const result = await billetService.validate(textLine);
		if (result?.status !== 200) {
			reset();
			resetFields();
			setIsLoading(false);

			return Swal.fire({
				icon: 'error',
				text: result.data.message ?? 'Não podemos parcelar esse boleto',
				confirmButtonText: 'Fechar',
			});
		}

		setIsHiddenInputs(false);

		const data = result.data;

		const storesData = data.lojas;

		let storeData = null;

		if (storesData?.length === 1) {
			storeData = storesData[0].opn;
		}

		const amountMask = moneyMask(data.boleto.valor);
		const dueDateMask = dateMask(data.boleto.vencimento);

		setValue('amount', amountMask);
		setValue('dueDate', dueDateMask);

		setAmount(amountMask);
		setDueDate(dueDateMask);
		setStore(storeData);
		setValidBillet(true);
		setStores(storesData);

		setNeedDoc(data.need_file);

		const isValid = validateBilletWithExternalValidator(
			amountMask,
			dueDateMask
		);

		if (isValid) {
			tests.includes('preapproved')
				? sendToPreApproved(data.boleto.valor)
				: getInstallments(data.boleto.valor);
		}
	};

	const validateBilletWithExternalValidator = (
		amountParameter = amount,
		dueDateParameter = dueDate
	) => {
		const amountMask = undoMoneyMask(amountParameter)
			.toString()
			.replace(',', '.');
		const valid = validator.validateBillet(amountMask, dueDateParameter);

		if (valid) {
			return true;
		}
		reset();
		resetFields();
		return false;
	};

	/* Handlers de inputs não controlados */
	const handleChangeCv = async (event: any) => {
		const file = event?.target?.files[0];
		if (file?.size > 0) {
			const valid = validatorDoc.validateCV(file);
			if (valid) {
				const convertedFile = await fileToBase64(file);
				const image = `data:${file.type};base64,` + convertedFile;
				setValue('tickedFile', image);
				setDoc(file);
			}
		}
	};

	const handleChangeInstallment = (
		installmentsProp: number,
		entrada: string,
		restante: number,
		total: string,
		interest?: string
	) => {
		setTimeout(() => {
			if (ButtonContinueRef.current) {
				ButtonContinueRef.current.focus();
			}
		}, 100);
		setValue('installment', installmentsProp);
		setSelectedInstallment(installmentsProp);
		setRest(restante);
		setTotalAmount(total);
		setInterest(interest);
		setInstallmentAmount(entrada);
	};

	const handleChangeCategory = (e: any) => {
		setRenderInput(e.target.value === 'outro');
	};

	/* Funções para setar as parcelas (installments) */
	const getInstallments = async (amount: any) => {
		setIsLoading(true);
		const installments = await orderSendingController.getInstallments(amount);

		setCet(installments.cet_monthly ?? installments.cet);
		setCetText('');
		setInstallmentItems({
			installments: installments.installments,
			total_amount: amount,
		});

		setIsHidden(false);

		const consultType = 'default';

		payslipVerificationDataLayer(consultType);
		elkBankingBilletConsult({
			email,
			cpf,
			consultType,
		});
		setAttempts((previousState) => previousState + 1);

		handleChangeInstallment(
			installments.installments.parcelas,
			installments.installments.entrada,
			installments.installments.restante,
			installments.installments.total,
			installments.installments.juros
		);
		if (getValues('coupon')) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'success',
				text: 'Cupom ativado com sucesso!',
				confirmButtonText: 'Entendi',
			});
		}
	};

	const sendToPreApproved = async (total_amount: string) => {
		const preApprovedService = new PreApprovedService();

		const pluck = (arr: IStore[], key: string) => arr.map((o: any) => o[key]);
		const stores = pluck(storeRef.current, 'opn').join();

		const data = {
			total_amount,
			cpf,
			cellphone,
			email,
			ip,
			cep,
			store: stores,
		};

		const preApprovedResult = await preApprovedService.preApproved(data);
		if (preApprovedResult === 'timeout') {
			getInstallments(total_amount);
			return;
		}

		let consultType = '';

		if (
			preApprovedResult.status !== 200 ||
			!preApprovedResult.data.preapproved ||
			preApprovedResult.data.installments?.length <= 0
		) {
			reset();
			resetFields(true);
			setPreApprovedNegative(true);
			setIsLoading(false);
			setIsHidden(true);
			eventPush('web_parc_bol_voltar_negado', 'ERROR');

			consultType = 'preapproved_false';
		} else {
			const preApprovedData = preApprovedResult.data;
			setPreApproved(preApprovedData.id);
			setCet(preApprovedData.cet_monthly ?? preApprovedData.cet);
			setCetText(preApprovedData.cet_monthly ? 'a.m.' : 'a.a.');
			setInstallmentItems({
				installments: preApprovedData.installments,
				total_amount: total_amount,
			});
			setIsHidden(false);
			consultType = 'preapproved_true';
			// showWhatsAppIcon(preApprovedData.id);
			setAttempts((previousState) => previousState + 1);

			handleChangeInstallment(
				preApprovedData.installments[0].installment,
				preApprovedData.installments[0].down_payment,
				preApprovedData.installments[0].installment - 1,
				preApprovedData.installments[0].total,
				preApprovedData.installments[0].interest
			);

			const storeValue = getValues('store');

			if (storeValue)
				await updatePreApprovedStore(storeValue, preApprovedData.id);

			if (getValues('coupon')) {
				setIsLoading(false);
			}
		}
		payslipVerificationDataLayer(consultType);
		elkBankingBilletConsult({
			email,
			cpf,
			consultType,
		});

		if (getValues('coupon')) {
			return Swal.fire({
				icon: 'success',
				text: 'Cupom ativado com sucesso!',
				confirmButtonText: 'Entendi',
			});
		}
	};

	/* Funções extras */
	const loadFormData = async () => {
		const ip = await getIp();
		dataLayerUserData(user);
		setCpf(user?.cpf);
		setEmail(user?.email);
		setCellphone(user?.cellphone?.replace('+55', ''));
		setCep(onlyNumber(user?.address?.cep));
		setIp(ip);
		setTests(user?.tests);
		setGclid(getCookie('gclid'));

		const jwtHash = hashJwtMd5();

		setClSessionId(jwtHash);
		setIsLoading(false);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: Unreachable code error
		csdp('sessionid', jwtHash);

		setBillet(getCookie('billet'));
	};

	const removeCoupon = (e?: React.MouseEvent<HTMLButtonElement>) => {
		if (e) e.preventDefault();
		setValue('coupon', '');
		setIsCupomValid(false);
		setDiscountValue(0);
		setValue('amountCoupon', null);
		setAmountOriginal('');
		setAmountCoupon('');

		const amountOriginal = getValues('amount');
		if (amountOriginal) {
			setIsLoading(true);
			tests.includes('preapproved')
				? sendToPreApproved(undoMoneyMask(amountOriginal))
				: getInstallments(undoMoneyMask(amountOriginal));
		}
	};

	const pasteLd = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setIsHidden(true);
		setIsHiddenInputs(true);
		reset();
		resetFields();
		setValue('billet', '');
		navigator.clipboard.readText().then((clipText) => {
			setValue('billet', clipText, { shouldTouch: true });
			validateBillet(clipText);
		});
	};

	const resetFields = (isPreApprovedFailed = false) => {
		if (isPreApprovedFailed) {
			setIsHidden(false);
		} else {
			setAmount('');
			setDueDate('');
			setIsHidden(true);
			setIsHiddenInputs(true);
		}

		setStore(null);
		setValidBillet(false);
		setCet(null);
		setCetText('');
		setInterest('');
		setInstallments([]);
		setSelectedInstallment(-1);
		setStores([]);
		setPreApproved(null);
		setPreApprovedNegative(false);
		setIsHiddenInputs(true);
		setNeedDoc(false);
		setDoc({} as IFile);
		setHideStoreField(false);
		setIsCupomValid(false);
		setDiscountValue(0);
		setInstallmentItems({});
		setValue('coupon', '');
		setIsCupomValid(false);
		setDiscountValue(0);
		setValue('amountCoupon', null);
		setAmountOriginal('');
		setAmountCoupon('');

		if (BilletInputRef.current)
			BilletInputRef.current.style.borderColor = 'red';
	};

	const submitForm = async (form: FormData) => {
		eventPush('web_parc_bol_proximo', 'INFO', {
			ld: line,
			vencimento: form.dueDate,
			loja: form.store,
			valor: form.amount,
			num_parc: form.installment,
		});

		if (!isValidToSubmit && errors === {}) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Entendi',
				text: 'Preencha todos os campos corretamente',
			});
		}

		const {
			dueDate,
			amount,
			installment,
			store,
			otherCategory,
			tickedFile,
			coupon,
			billet,
			category,
		} = form;

		validateStore();

		if (storesOptions?.length && !hideStoreField) validateCategory();

		if (storesOptions?.length && !hideStoreField && store === undefined) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Entendi',
				text: 'Selecione uma loja aceita',
			});
		}

		if (category === undefined) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Entendi',
				text: 'Selecione uma categoria de produto',
			});
		}

		if (needDoc && tickedFile === undefined) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Entendi',
				text: 'Insira o arquivo do boleto',
			});
		}

		const payload = {
			linha_digitavel: formatBilletLine(billet),
			due_date: undoDateMask(dueDate),
			total_amount: undoMoneyMask(amount).toString().replace('.', ','),
			description:
				category === 'outro' ? capitalize(otherCategory) : capitalize(category),
			coupon_code: isValid ? coupon.toUpperCase() : undefined,
			gclid: gclid ? gclid : undefined,
			geolocation: geolocation ? geolocation : undefined,
			loja: store,
			installment: installment,
			banking_billet_base64: tickedFile,
			preapproved: preApproved,
			cl_session_id: clSessionId,
			gtag_client_id: clientID,
		};

		setIsLoading(true);

		const { result, error, redirectPage } = await orderSendingController.submit(
			payload
		);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Erro enviar os dados. Tente novamente em alguns minutos.',
			});
		}

		if (redirectPage) return history.push(redirectPage);
	};

	const updatePreApprovedStore = async (
		storeValue: string,
		preApprovedId: string | null
	) => {
		const payload: IPostStore = {
			id: preApprovedId,
			store: storeValue,
		};

		await orderSendingController.postStore(payload);
	};

	/* Valores memorizados */
	const storesOptions = useMemo(() => {
		if (!stores?.length) return [];
		const options: ISelectOptions[] = [];
		stores.forEach((store: any) => {
			options.push({
				name:
					store.fantasia
						?.replace(' Ame', '')
						.replace(' Marketplace', '')
						.replace(' Pagamentos', '')
						.replace(' PagarMe', '') || store.opn,
				value: store.opn,
			});
		});

		return options;
	}, [stores, store]);

	return (
		<>
			<SendingMainStyled>
				<AiOutlineArrowLeftStyled
					size={33}
					onClick={() => history.push('/taker')}
					style={{ position: 'absolute' }}
				/>

				<CenterMarginTopDivStyled>
					<img src={Logo} alt="Logo VirtusPay" />
				</CenterMarginTopDivStyled>

				<CenterDivStyled>
					<FormStyled>
						<ThanksDivStyled>
							<SendingTitleStyled>Descubra a sua oferta =)</SendingTitleStyled>

							<ThanksInputsStyled>
								<Input
									name="billet"
									control={control}
									inputRef={BilletInputRef}
									id="billet-input"
									type="ld"
									className="input-ld"
									mask="99999.99999 99999.999999 99999.999999 9 99999999999999"
									placeholder="Digite o código de barras da sua compra"
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										const value = e.target.value;
										stateBefore.current = formatBilletLine(value);
										if (stateRef.current !== stateBefore.current)
											validateBillet(value);
									}}
									defaultValue=""
									pasteLd={pasteLd}
								/>
								<SendingPStyled>
									<b>Atenção:</b> Insira o código do boleto que carregaremos as
									informações
								</SendingPStyled>
								<div
									style={{ marginTop: '15px' }}
									className={`${isHidden && 'is-hidden'}`}
								>
									<InputLabelStyled htmlFor="due-date-input">
										Vencimento do boleto:
									</InputLabelStyled>

									<Input
										control={control}
										name="dueDate"
										id="due-date-input"
										disabled={true}
										defaultValue=""
										dataTestID="due-date-input"
									/>
								</div>
								{amountCoupon ? (
									<div className={`${isHidden && 'is-hidden'}`}>
										<InputLabelStyled htmlFor="amount-input">
											Valor do boleto:
										</InputLabelStyled>

										<Input
											control={control}
											name="amount"
											id="amount-input"
											disabled={true}
											defaultValue=""
											dataTestID="amount-input"
										/>
										<InputLabelStyled htmlFor="amount-coupon-input">
											Valor com desconto:
										</InputLabelStyled>

										<Input
											control={control}
											name="amountCoupon"
											id="amount-coupon-input"
											disabled={true}
											defaultValue=""
											dataTestID="amount-coupon-input"
										/>
									</div>
								) : (
									<div className={`${isHidden && 'is-hidden'}`}>
										<InputLabelStyled htmlFor="amount-input">
											Valor do boleto:
										</InputLabelStyled>

										<Input
											control={control}
											name="amount"
											id="amount-input"
											disabled={true}
											defaultValue=""
											dataTestID="amount-input"
										/>
									</div>
								)}
								{needDoc ? (
									<GroupStyled
										className={`${
											isHiddenInputs && 'is-hidden'
										} formStyled-group`}
									>
										<InputFile
											placeholder={
												doc?.name ? doc.name : 'Selecione o Boleto...'
											}
											name="tickedFile"
											control={control}
											className="file-input"
											accept=".pdf"
											type="file"
											onChange={handleChangeCv}
											dataTestID="tickedFile-input"
										/>
									</GroupStyled>
								) : null}
								{!isHiddenInputs ? (
									<ThanksInputsStyled>
										<SelectControlled
											name="category"
											control={control}
											id="category-input"
											onBlur={validateCategory}
											onChange={handleChangeCategory}
											label="O que está parcelando?"
											options={orderCategories}
											dataTestID="category-input"
										/>
									</ThanksInputsStyled>
								) : null}
								{renderInput ? (
									<ThanksInputsStyled>
										<Input
											name="otherCategory"
											control={control}
											id="other-category-input"
											onBlur={validateOtherCategory}
											placeholder="Digite abaixo o que está parcelando"
											dataTestID="otherCategory-input"
										/>
									</ThanksInputsStyled>
								) : null}
								{storesOptions?.length && !hideStoreField ? (
									<ThanksInputsStyled>
										<SelectControlled
											name="store"
											control={control}
											onBlur={validateStore}
											id="store"
											label="Selecione uma loja aceita"
											options={storesOptions}
											dataTestID="store-input"
										/>
									</ThanksInputsStyled>
								) : null}
								<InputCoupon
									name="coupon"
									control={control}
									id="cupom"
									activatedCupom={isValid}
									type="cupom"
									label="CUPOM"
									isHiddenInputs={isHiddenInputs}
									checkCupom={validateCupom}
									removeCoupon={removeCoupon}
									className={`${isHiddenInputs && 'is-hidden'}`}
								/>
							</ThanksInputsStyled>

							{installments?.length ? (
								<>
									<RenderInstallmentsOptions
										tests={tests}
										multipleInstallments={installments?.length > 1}
										installments={installments}
										preApproved={preApproved ?? false}
										selectInstallment={handleChangeInstallment}
										setFirstRendering={setFirstRendering}
										selectedInstallment={selectedInstallment}
										firstRendering={firstRendering}
									/>
									<RenderInstallmentInfo
										cet={cet}
										cetText={cetText}
										selectedInstallment={selectedInstallment}
										installmentAmount={installmentAmount}
										rest={rest}
										totalAmount={totalAmount}
										interest={interest ?? ''}
										isValid={isValid}
										discountValue={discountValue}
									/>
								</>
							) : null}
							{validBillet && selectedInstallment !== null ? (
								<Button
									ref={ButtonContinueRef}
									className="has-gradient-blue"
									width="100%"
									textColor="#fff"
									text="Próximo"
									id="send-order-button"
									dataTestid="orderSubmitButton"
									//Este evento foi necessário para disparar uma validação forçada com o Swal
									//seguindo o comportamento original
									onClick={() => submitForm(getValues() as FormData)}
								/>
							) : null}

							{preApprovedNegative ? <RenderPreApprovedNegative /> : null}
						</ThanksDivStyled>
					</FormStyled>
				</CenterDivStyled>
				{/* {showWhatsApp ? (
					<WhatsAppDivStyled>
						<WhatsAppTextStyled>
							Está com dificuldades para continuar? Clique aqui e deixe sua
							sugestão sobre como poderíamos melhorar.
						</WhatsAppTextStyled>
						<WhatsAppStyled
							src={WhatsApp}
							onClick={() => clickWhatsAppIcon()}
						/>
					</WhatsAppDivStyled>
				) : null} */}
			</SendingMainStyled>
		</>
	);
};
