import { useState } from 'react';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { AccordionTitleStyled, ContainerStyled, PanelStyled } from './style';

export interface AccordionProps {
	title: string;
	children: React.ReactNode;
	className?: string;
	dataTestId?: string;
}

export const Accordion = ({
	title,
	children,
	className,
	dataTestId,
}: AccordionProps) => {
	const [open, setOpen] = useState(false);

	function IconArrow() {
		const style = { marginRight: '1rem' };

		return (
			<>
				{!open ? (
					<IoIosArrowForward color="#00E8EC" style={style} size={30} />
				) : (
					<IoIosArrowDown color="#00E8EC" style={style} size={30} />
				)}
			</>
		);
	}

	return (
		<ContainerStyled className={className}>
			<AccordionTitleStyled
				data-testid={dataTestId}
				onClick={() => setOpen(!open)}
			>
				{IconArrow()}
				{title}
			</AccordionTitleStyled>
			<PanelStyled>{open && children}</PanelStyled>
		</ContainerStyled>
	);
};
