import orderRefusedRepository from '../repositories/orderRefusedRepository';

class orderRefusedController {
	repository: orderRefusedRepository;

	constructor() {
		this.repository = new orderRefusedRepository();
	}

	async getOrder(transaction: string) {
		const result = await this.repository.getOrder(transaction);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}

export default orderRefusedController;
