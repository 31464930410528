import { config } from '../config.json';

const styles = {
	primary: '#26dbd6',
	primaryHover: '#17C2CE',
	textBlue: '#28C0CD',
	purple: '#6377ff',
	primaryBlue: '#27BDCC',
	defaultGrey: 'rgba(10,10,10,0.1)',
	orangeColor: '#ff9900',
	errorColor: 'red',
	warningColor: 'hsl(44, 100%, 77%)',
	infoColor: 'hsl(207, 61%, 53%)',
	successColor: '#05A400',
	gradient: 'linear-gradient(90deg, #288CC8 0%, #26dbd6 100%) !important',
};

export const facebookId = config.facebook_id_hml;

export const installmentReason = [
	{
		name: 'Tenho conta em banco, mas não tenho cartão',
		value: 'sem_cartao',
	},
	{
		name: 'Tenho cartão, mas pouco limite',
		value: 'pouco_limite',
	},
	{
		name: 'Tenho cartão, mas não quero usar',
		value: 'nao_quero',
	},
	{
		name: 'Não tenho conta em banco',
		value: 'sem_conta',
	},
	{
		name: 'Desejo não informar',
		value: 'nao_informar',
	},
];

export const contactRelationship = [
	{ value: '', name: 'Relação' },
	{ value: 'meu', name: 'Meu Próprio' },
	{ value: 'pais', name: 'Pai/Mãe' },
	{ value: 'conjuge', name: 'Cônjuge' },
	{ value: 'filho', name: 'Filho(a)' },
	{ value: 'irmao', name: 'Irmão(ã)' },
	{ value: 'tio', name: 'Tio(a)' },
	{ value: 'amigo', name: 'Amigo(a)' },
	{ value: 'vizinho', name: 'Vizinho(a)' },
	{ value: 'outros', name: 'Outros' },
];

export const contactCategories = [
	{ value: '', name: 'Categoria' },
	{ value: 'celular', name: 'Celular' },
	{ value: 'email', name: 'E-mail' },
	{ value: 'telefone', name: 'Telefone' },
];

export const orderCategories = [
	{
		name: 'Celular',
		value: 'celular',
	},
	{
		name: 'Notebook',
		value: 'notebook',
	},
	{
		name: 'Tablet',
		value: 'tablet',
	},
	{
		name: 'Móveis',
		value: 'moveis',
	},
	{
		name: 'Eletrodomésticos',
		value: 'eletrodomesticos',
	},
	{
		name: 'Outro',
		value: 'outro',
	},
];

export const contactReasons = [
	{
		name: 'Trabalhe conosco',
		value: 'trabalhe_conosco',
	},
	{
		name: 'Dúvida',
		value: 'duvida',
	},
	{
		name: 'Contato comercial/Marketing',
		value: 'comercial_marketing',
	},
	{
		name: 'Problema de acesso',
		value: 'acesso',
	},
	{
		name: 'Sugestão',
		value: 'sugestao',
	},
	{
		name: 'Reclamação',
		value: 'reclamacao',
	},
];

export const dueMonth = [
	{
		name: '01',
		value: '01',
	},
	{
		name: '02',
		value: '02',
	},
	{
		name: '03',
		value: '03',
	},
	{
		name: '04',
		value: '04',
	},
	{
		name: '05',
		value: '05',
	},
	{
		name: '06',
		value: '06',
	},
	{
		name: '07',
		value: '07',
	},
	{
		name: '08',
		value: '08',
	},
	{
		name: '09',
		value: '09',
	},
	{
		name: '10',
		value: '10',
	},
	{
		name: '11',
		value: '11',
	},
	{
		name: '12',
		value: '12',
	},
];

export const dueYear = [
	{
		name: '2021',
		value: '2021',
	},
	{
		name: '2022',
		value: '2022',
	},
	{
		name: '2023',
		value: '2023',
	},
	{
		name: '2024',
		value: '2024',
	},
	{
		name: '2025',
		value: '2025',
	},
	{
		name: '2026',
		value: '2026',
	},
	{
		name: '2027',
		value: '2027',
	},
	{
		name: '2028',
		value: '2028',
	},
	{
		name: '2029',
		value: '2029',
	},
];

export const states = [
	{
		value: 'AC',
		name: 'Acre',
	},
	{
		value: 'AL',
		name: 'Alagoas',
	},
	{
		value: 'AP',
		name: 'Amapá',
	},
	{
		value: 'AM',
		name: 'Amazonas',
	},
	{
		value: 'BA',
		name: 'Bahia',
	},
	{
		value: 'CE',
		name: 'Ceará',
	},
	{
		value: 'DF',
		name: 'Distrito Federal',
	},
	{
		value: 'ES',
		name: 'Espírito Santo',
	},
	{
		value: 'GO',
		name: 'Goías',
	},
	{
		value: 'MA',
		name: 'Maranhão',
	},
	{
		value: 'MT',
		name: 'Mato Grosso',
	},
	{
		value: 'MS',
		name: 'Mato Grosso do Sul',
	},
	{
		value: 'MG',
		name: 'Minas Gerais',
	},
	{
		value: 'PA',
		name: 'Pará',
	},
	{
		value: 'PB',
		name: 'Paraíba',
	},
	{
		value: 'PR',
		name: 'Paraná',
	},
	{
		value: 'PE',
		name: 'Pernambuco',
	},
	{
		value: 'PI',
		name: 'Piauí',
	},
	{
		value: 'RJ',
		name: 'Rio de Janeiro',
	},
	{
		value: 'RN',
		name: 'Rio Grande do Norte',
	},
	{
		value: 'RS',
		name: 'Rio Grande do Sul',
	},
	{
		value: 'RO',
		name: 'Rondônia',
	},
	{
		value: 'RR',
		name: 'Roraíma',
	},
	{
		value: 'SC',
		name: 'Santa Catarina',
	},
	{
		value: 'SP',
		name: 'São Paulo',
	},
	{
		value: 'SE',
		name: 'Sergipe',
	},
	{
		value: 'TO',
		name: 'Tocantins',
	},
];

export const BANK_TYPES = [
	{ value: '', name: 'Selecione o tipo da conta' },
	{ value: 'checking', name: 'Conta Corrente' },
	{ value: 'savings', name: 'Conta Poupança' },
	{ value: 'salary', name: 'Conta Salário' },
	{ value: 'payment', name: 'Conta Pagamento' },
];

export default styles;
