import { ReactNode } from 'react';

import {
	ItemStyled,
	IndicatorStyled,
	IndicatorDivStyled,
	ContainerStyled,
	LabelDivStyled,
} from './styles';
export interface OfferItemProps {
	// Props for Element
	children: ReactNode;
	id?: string;
	label?: string;
	actived?: boolean;

	onClick: () => void;
}

export const OfferItem = ({
	id,
	children,
	label,
	actived,
	onClick,
}: OfferItemProps) => {
	return (
		<ContainerStyled>
			<ItemStyled
				className={`${actived && 'offer-active'}`}
				id={id}
				onClick={onClick}
			>
				<IndicatorDivStyled>
					<IndicatorStyled
						className={`indicator ${actived && 'installment-active'}`}
					/>
				</IndicatorDivStyled>

				{children}

				<LabelDivStyled>{label}</LabelDivStyled>
			</ItemStyled>
		</ContainerStyled>
	);
};
