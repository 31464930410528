import PartnerRepository from '../repositories/partnerRepository';

export default class PartnerController {
	repository: PartnerRepository;

	constructor() {
		this.repository = new PartnerRepository();
	}

	async get() {
		const result = await this.repository.get();

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}
