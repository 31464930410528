import styled from 'styled-components';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { Button } from '../../shared/components/index';

import { colors } from '../../shared/styles/colors';

interface IDiv {
	isHidden?: boolean;
}

export const TitleStyled = styled.h1`
	text-align: center;
	font-weight: 650;
	font-size: 33px;
	color: #000;

	@media (max-width: 900px) {
		font-size: 24px;
	}
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	width: 220px;
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const BlueTitleStyled = styled.h1`
	text-align: center;
	font-weight: 650;
	font-size: 27px;
	color: ${colors.textBlue};
	margin-top: 1.5rem;

	@media (max-width: 900px) {
		font-size: 24px;
	}
`;

export const ContainerStyled = styled.div`
	flex-direction: column;
	align-items: center;
`;

export const InstructionsDivStyled = styled.div<IDiv>`
	display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1.3rem 0 1rem;
`;

export const IMGDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 1.8rem 0 1rem 0;
`;

export const PStyled = styled.p`
	text-align: center;
	font-weight: 401;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
`;

export const PdocStyled = styled.p`
	text-align: center;
	font-weight: 400;
	padding: 0.5rem 0;
`;

export const PClickStyled = styled.p`
	text-align: center;
	font-weight: 650;
	cursor: pointer;
	color: ${colors.textBlue};
`;

export const CamDivStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	z-index: 99999;
	position: fixed;
	top: 0;
	left: 0;
`;

export const ArrowStyled = styled(AiOutlineArrowLeft)`
	color: ${colors.textBlue};
	cursor: pointer;
	margin: 0.5rem;
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0.6rem;
`;

export const ButtonHiddenDesktopStyled = styled.div`
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 1rem 0 1.3rem;

	@media (max-width: 1100px) {
		display: flex;
	}
`;

export const DocSelect = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 200px;
	padding: 0.5rem 25px 0 25px;
	border-radius: 5px;
	border: 1px solid rgba(10, 10, 10, 0.1);
	cursor: pointer;
`;

export const IMG = styled.img`
	border: 5px solid #000; 
	display: block
	width: 220px;
`;

export const IMGdoc = styled.img`
	width: 100px;
`;

export const DivRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const Divdoc = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0.7rem 0.1rem;
`;

export const DivContact = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
	width: 300px;
`;

export const DivPadding = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.6rem 0;
	width: 300px;
`;

export const Gap = styled.div`
	width: 100%, 
	border-bottom: 1px solid #CFCFCF;
	margin: 1rem 0;
`;

// KYCBank

export const DivPBankSpace = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		justify-content: center;
		align-items: left;
		text-align: left;
		width: 100%;
	}
`;

export const DivPBank = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		display: none;
		align-items: left;
		text-align: left;
		margin-left: 0.3rem;
		width: 100%;
	}
`;

export const DivPBankMobile = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		display: flex;
		align-items: left;
		text-align: left;
		margin-bottom: 1rem;
		width: 100%;
	}
`;

export const PBank = styled.p`
	font-weight: 500;
	margin-right: 0.1rem;

	@media (max-width: 1100px) {
		margin-left: 0.3rem;
		text-align: left;
		flex: 1;
		margin-bottom: 0.5rem;

		:before {
			content: '';
			margin-top: 5px;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			padding: 2px;
			display: inline-block;
			opacity: 0.5;
			background: #27bdcc;
	};
`;

export const PContact = styled.p`
	font-weight: 400;
	cursor: pointer;
	text-align: center;
`;

export const DivUpload = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin: 1rem 1rem;

	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

export const TitleBank = styled.h1`
	text-align: center;
	font-weight: 550;
	font-size: 24px;
	color: #000;
	margin: 0.5rem 0 0.5rem;

	@media (max-width: 900px) {
		font-size: 22px;
	}
`;

export const DivBank = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 0.5rem;

	@media (max-width: 1100px) {
		flex-direction: column;
		width: 400px;
	}
`;

// KYCThanks

export const TitleThanks = styled.h1`
	text-align: center;
	font-weight: 550;
	font-size: 24px;
	color: #000;
	margin: 1.5rem 0 0.5rem;

	@media (max-width: 900px) {
		font-size: 22px;
	}
`;

export const ContainerThanks = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.9rem 0;
`;

export const DivThanks = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 30%;

	@media (max-width: 2200px) {
		width: 40%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 65%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}
`;

// KYCContact

export const CenterDivTracking = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 3.3rem 0 1.5rem;
`;

export const PSuccess = styled.p`
	text-align: center;
	font-weight: 401;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	color: ${colors.successColor};
`;

export const ButtonStyled = styled(Button)`
	display: none;

	@media (max-width: 1100px) {
		display: flex;
		text-align: center;
	}
`;

export const DocSelectStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 200px;
	padding: 0.5rem 25px 0 25px;
	border-radius: 5px;
	border: 1px solid rgba(10, 10, 10, 0.1);
	cursor: pointer;
`;

export const IMGStyled = styled.img`
	border: 5px solid #000; 
	display: block
	width: 220px;
`;

export const IMGdocStyled = styled.img`
	width: 100px;
`;

export const DivRowStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const DivdocStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0.7rem 0.1rem;
`;

export const DivContactStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
	width: 300px;
`;

export const DivPaddingStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.6rem 0;
	width: 300px;
`;

export const GapStyled = styled.div`
	width: 100%, 
	border-bottom: 1px solid #CFCFCF;
	margin: 1rem 0;
`;

// KYCBank

export const DivPBankSpaceStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		justify-content: center;
		align-items: left;
		text-align: left;
		width: 100%;
	}
`;

export const DivPBankStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		display: none;
		align-items: left;
		text-align: left;
		margin-left: 0.3rem;
		width: 100%;
	}
`;

export const DivPBankMobileStyled = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;

	@media (max-width: 1100px) {
		display: flex;
		align-items: left;
		text-align: left;
		margin-bottom: 1rem;
		width: 100%;
	}
`;

export const PBankStyled = styled.p`
	font-weight: 500;
	margin-right: 0.1rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px ${colors.primary} solid;

	@media (max-width: 1100px) {
		margin-left: 0.3rem;
		text-align: left;
		flex: 1;
		margin-bottom: 0.5rem;

		:before {
			content: '';
			margin-top: 5px;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			padding: 2px;
			display: inline-block;
			opacity: 0.5;
			background: #27bdcc;
	};
`;

export const PContactStyled = styled.p`
	font-weight: 400;
	cursor: pointer;
	text-align: center;
`;

export const DivUploadStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin: 1rem 1rem;

	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

export const TitleBankStyled = styled.h1`
	text-align: center;
	font-weight: 550;
	font-size: 24px;
	color: #000;
	margin: 0.5rem 0 0.5rem;

	@media (max-width: 900px) {
		font-size: 22px;
	}
`;

export const DivBankStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 0.5rem;

	@media (max-width: 1100px) {
		flex-direction: column;
		width: 400px;
	}
`;

// KYCThanks

export const TitleThanksStyled = styled.h1`
	text-align: center;
	font-weight: 550;
	font-size: 24px;
	color: #000;
	margin: 1.5rem 0 0.5rem;

	@media (max-width: 900px) {
		font-size: 22px;
	}
`;

export const ContainerThanksStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.9rem 0;
`;

export const DivThanksStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 30%;

	@media (max-width: 2200px) {
		width: 40%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 65%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}
`;

// KYCContact

export const CenterDivStyledTrackingStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 3.3rem 0 1.5rem;
`;

export const PSuccessStyled = styled.p`
	text-align: center;
	font-weight: 401;
	margin: 0.5rem 0;
	padding: 0.5rem 0;
	color: ${colors.successColor};
`;
