import { useEffect, useRef, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	TitleStyled,
	LabelBoldPStyled,
	CenteredDivStyled,
	MarginsDivStyled,
	PAlertStyled,
	LabelBluePStyled,
	InputsStyled,
	ClickStyled,
} from '../../styles';

import { changeDLController } from '../../controller';
import BilletService from '../../../order/services/billetService';

import { ComponentsProps } from '../../types/types';

import { ISelectOptions } from '../../../order/components/OrderSendingForm';
import OrderSendingValidator from '../../../order/utils/validator';

import {
	InputUncontrolled,
	Select,
	Button,
} from '../../../../shared/components/index';

import {
	dateMask,
	moneyMask,
	undoDateMask,
	undoMoneyMask,
} from '../../../../shared/utils/masks';

export const ChangeDL = ({
	changeValueLoading,
	returnStep,
	minAmount,
	maxAmount,
}: ComponentsProps) => {
	const [line, setLine] = useState('');
	const [isHiddenInputs, setIsHiddenInputs] = useState(true);
	const [amount, setAmount] = useState('');
	const [store, setStore] = useState(null);
	const [stores, setStores] = useState([]);
	const [validBillet, setValidBillet] = useState(false);
	const [, setDisplayStore] = useState();
	const [dueDate, setDueDate] = useState('');
	const [validStore, setValidStore] = useState(false);
	const [, setBoletoData] = useState();

	const { id } = useParams<{ id: string }>();

	const history = useHistory();

	const validator = new OrderSendingValidator();

	const BilletInputRef = useRef<HTMLInputElement>(null);
	const stateRef = useRef<string>('');
	stateRef.current = line;

	useEffect(() => {
		stores?.length > 0 && store && validateStore();
		return () => {
			setValidStore(false);
		};
	}, [store]);

	const formatBilletLine = (billetLine: string) => {
		return billetLine
			.replaceAll('_', '')
			.replaceAll('.', '')
			.trim()
			.replaceAll(' ', '');
	};

	const handleChangeLine = (e: any) => {
		if (formatBilletLine(line).length === 47 && !e.target.value.includes('_'))
			return;

		resetFields();

		const textLine = e.target.value;
		const textLineFormatted = formatBilletLine(textLine);
		setLine(textLine);

		if (textLineFormatted?.length === 47) {
			validateBillet(textLine);
		}
	};

	const submitBillet = async () => {
		changeValueLoading();
		if (
			stateRef.current !== null &&
			stateRef.current !== undefined &&
			validStore
		) {
			const payload = {
				due_date: undoDateMask(dueDate),
				total_amount: undoMoneyMask(amount).toString().replace('.', ','),

				loja: store,
				digitable_line: line,
			};

			const { result, error } = await changeDLController(id, payload);

			if (error) {
				changeValueLoading();
				setLine('');
				resetFields();
				return Swal.fire({
					icon: 'error',
					text:
						result?.data.message ??
						'Erro ao enviar os dados. Tente novamente em alguns minutos.',
					confirmButtonText: 'Fechar',
				});
			} else {
				changeValueLoading();
				setLine('');
				resetFields();
				return Swal.fire({
					icon: 'success',
					title: 'Boleto atualizado com sucesso!',
					text: 'Aguarde nosso e-mail com o comprovante de quitação. Se houver alguma necessidade adicional, nós entraremos em contato.',
					confirmButtonText: 'Fechar',
				}).then(() => {
					history.push('/taker');
				});
			}
		} else {
			changeValueLoading();
			return Swal.fire({
				icon: 'error',
				text: 'Preencha todos os campos corretamente',
				confirmButtonText: 'Fechar',
			});
		}
	};

	const validateBillet = async (textLine = line) => {
		changeValueLoading();
		const billetService = new BilletService();
		if (stateRef.current !== null && stateRef.current !== undefined) {
			const result = await billetService.validate(textLine);
			if (result?.status !== 200) {
				resetFields();
				changeValueLoading();

				return Swal.fire({
					icon: 'error',
					text: result.data.message ?? 'Não podemos parcelar esse boleto',
					confirmButtonText: 'Fechar',
				});
			}

			const data = result.data;

			const storesData = data.lojas;

			let storeData = null;
			let displayStoreData = null;
			let validStore = false;

			if (storesData?.length === 1) {
				storeData = storesData[0].opn;
				displayStoreData = storesData[0].fantasia;
				validStore = true;
			}

			const amountMask = moneyMask(data.boleto.valor);
			const amount = data.boleto.valor;
			const dueDateMask = dateMask(data.boleto.vencimento);

			if (
				amount >= Number(undoMoneyMask(maxAmount ?? '')) ||
				amount <= Number(undoMoneyMask(minAmount ?? ''))
			) {
				changeValueLoading();
				return Swal.fire({
					icon: 'error',
					text: 'Novo valor não é permitido',
				});
			}

			if (storeData === 'mercado_livre') {
				changeValueLoading();
				return Swal.fire({
					icon: 'error',
					text: 'No momento, não aceitamos boletos dessa loja',
				});
			}

			setIsHiddenInputs(false);
			setAmount(amountMask);
			setDueDate(dueDateMask);
			setStore(storeData);
			setDisplayStore(displayStoreData);
			setValidBillet(true);
			setStores(storesData);
			setValidStore(validStore);
			setBoletoData(data.boleto);
			changeValueLoading();
		} else {
			changeValueLoading();
			return Swal.fire({
				icon: 'error',
				text: 'Precisamos da linha digitável',
			});
		}
	};

	const validateStore = () => {
		const valid = validator.validateStore(store);

		return setValidStore(valid);
	};

	const RenderStoresOptions = () => {
		if (stores.length >= 2) {
			const storesOptions: ISelectOptions[] = [];

			stores.forEach((store: any) => {
				storesOptions.push({
					name:
						store.fantasia
							?.replace(' Ame', '')
							.replace(' Marketplace', '')
							.replace(' Pagamentos', '')
							.replace(' PagarMe', '') || store.opn,
					value: store.opn,
				});
			});

			return (
				<Select
					onBlur={validateStore}
					id="store"
					value={store}
					onChange={(e) => setStore(e.target.value)}
					label="Selecione uma loja aceita"
					options={storesOptions}
				/>
			);
		}

		return <></>;
	};

	const RenderContinueButton = () => {
		if (validBillet) {
			return (
				<Button
					// ref={ButtonContinueRef}
					onClick={submitBillet}
					className="has-gradient-blue"
					width="100%"
					textColor="#fff"
					text="Próximo"
					id="send-order-button"
				/>
			);
		}

		return <></>;
	};

	const resetFields = () => {
		setStore(null);
		setValidBillet(false);
		setAmount('');
		setStores([]);
		setStore(null);
		setValidBillet(false);
		setIsHiddenInputs(true);
		setDueDate('');
		setValidStore(false);
		setBoletoData(undefined);

		if (BilletInputRef.current)
			BilletInputRef.current.style.borderColor = 'red';
	};

	return (
		<CenteredDivStyled>
			<MarginsDivStyled style={{ margin: '1.2rem 0 2rem' }}>
				<TitleStyled>Envio do novo boleto!</TitleStyled>
				<InputUncontrolled
					ref={BilletInputRef}
					id="billet-input"
					mask="99999.99999 99999.999999 99999.999999 9 99999999999999"
					onChange={handleChangeLine}
					value={line}
					placeholder="Digite o novo código de barras da sua compra"
					type="tel"
				/>
				<PAlertStyled>
					<b>Atenção:</b>
					Em caso de valores diferentes do aprovado, aceitaremos até 3.5% de
					diferença do valor, para cima ou para baixo.
					<br />
					Não se preocupe! O boleto antigo será automaticamente desconsiderado e
					vamos reverter o pagamento para o novo boleto em sua proposta atual.
				</PAlertStyled>
				<div style={{ marginTop: '0.5rem' }}></div>
				<LabelBoldPStyled>
					Valor máximo para novo boleto:{' '}
					<LabelBluePStyled>{maxAmount}</LabelBluePStyled>
				</LabelBoldPStyled>
				<LabelBoldPStyled>
					Valor mínimo para novo boleto:{' '}
					<LabelBluePStyled>{minAmount}</LabelBluePStyled>
				</LabelBoldPStyled>
				<div style={{ marginBottom: '0.5rem' }}></div>
				<div className={`${isHiddenInputs && 'is-hidden'}`} id="dueDate">
					<LabelBoldPStyled>Vencimento do boleto:</LabelBoldPStyled>

					<InputUncontrolled
						id="due-date-input"
						disabled={true}
						value={dueDate}
						onChange={(e: any) => setDueDate(e)}
					/>
				</div>
				<div className={`${isHiddenInputs && 'is-hidden'}`} id="totalAmount">
					<LabelBoldPStyled>Valor do boleto:</LabelBoldPStyled>

					<InputUncontrolled
						id="amount-input"
						disabled={true}
						value={amount}
						onChange={() => setAmount}
						placeholder="Valor do boleto à vista:"
					/>
				</div>
				<InputsStyled>
					<RenderStoresOptions />
				</InputsStyled>
				<RenderContinueButton />
				<ClickStyled
					onClick={() => {
						returnStep();
						resetFields();
						setLine('');
					}}
				>
					Voltar
				</ClickStyled>
			</MarginsDivStyled>
		</CenteredDivStyled>
	);
};
