import { forwardRef, HTMLProps, ForwardedRef } from 'react';
import { ButtonStyled } from './styles';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
	// Props for Element
	text?: string;
	id?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	className?: string;
	padding?: string;
	width?: string;
	color?: string;
	textColor?: string;
	hoverColor?: string;
	border?: string;
	dataTestid?: string;
	disable?: boolean;
	onClick?: (event: any) => void;
	onMouseDown?: () => void;
}

function Button(
	{
		padding,
		width,
		color,
		textColor,
		hoverColor,
		type,
		id,
		className,
		text,
		border,
		dataTestid,
		onMouseDown,
		onClick,
	}: ButtonProps,
	ref: ForwardedRef<HTMLButtonElement>
) {
	return (
		<ButtonStyled
			padding={padding}
			width={width}
			border={border}
			color={color}
			textColor={textColor}
			hoverColor={hoverColor}
			data-testid={dataTestid || 'button'}
			className={className}
			id={id}
			onClick={onClick}
			onMouseDown={onMouseDown}
			type={type ?? 'button'}
			ref={ref}
		>
			{text}
		</ButtonStyled>
	);
}

export default forwardRef(Button);
