import styled from 'styled-components';

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	position: relative;
`;

export const ZIndex5Styled = styled.div`
	width: 100%;
	height: 5px;
	border-radius: 3px;
	background-color: #3da9fd;
	position: absolute;
	left: 0px;
`;

export const ZIndex6Styled = styled.div`
	height: 5px;
	border-radius: 3px;
	background-color: #ccc;
	position: absolute;
	right: 0px;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const PStyled = styled.p`
	font-size: 12px;
`;

export const BlueDivStyled = styled.div`
	height: 15px;
	width: 15px;
	background-color: #3da9fd;
	border-radius: 100%;
`;

export const GreyDivStyled = styled.div`
	height: 15px;
	width: 15px;
	background-color: #ccc;
	border-radius: 100%;
`;

export const DivTouchStyled = styled.div`
	display: block;

	@media (min-width: 1100px) {
		display: none;
	}
`;

export const DivDesktopStyled = styled.div`
	display: none;

	@media (min-width: 1100px) {
		display: block;
	}
`;
