import { OrderCheckoutRepository } from '../repositories/orderCheckoutRepository';

import redirect from '../../../shared/utils/redirect';

export class OrderCheckoutController {
	repository = new OrderCheckoutRepository();

	async submit(transaction: string, data: any) {
		const result = await this.repository.send(transaction, data);

		if (result.status !== 200)
			return { result, error: true, redirectPage: null };

		const { redirectPage } = redirect(
			result.data.next_step,
			result.data.transaction
		);

		return { result, error: false, redirectPage };
	}

	async get(transaction: string) {
		const result = await this.repository.get(transaction);

		if (result.status !== 200) return { result, error: true };

		if (!result.data.installments)
			return { result, noInstallments: true, error: false };

		return { result: result.data, noInstallments: false, error: false };
	}
}
