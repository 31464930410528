import axios from '../../../shared/http/customAxios';

import { IDTO } from '../types/types';

class KYCRepository {
	async getSteps(transaction: string) {
		const result = await axios().get(
			`/v1/order/${transaction}/formalizacao/steps`,
			{ headers: { noAuthorization: true } }
		);

		return result;
	}

	async submitSelfie(transaction: string, dto: IDTO) {
		const result = await axios().post(
			`/v1/order/${transaction}/acessobio/process/selfie`,
			dto,
			{ headers: { noAuthorization: true } }
		);

		return result;
	}

	async submitDocument(transaction: string, dto: IDTO) {
		const result = await axios().post(
			`/v1/order/${transaction}/acessobio/process/document`,
			dto,
			{ headers: { noAuthorization: true } }
		);

		return result;
	}
}

export default KYCRepository;
