import OrderEmailValidationRepository from '../repositories/orderEmailValidationRepository';

class OrderEmailValidationController {
	repository: OrderEmailValidationRepository;

	constructor() {
		this.repository = new OrderEmailValidationRepository();
	}

	async validation(transaction: string, token: string | null) {
		const result = await this.repository.validation(transaction, token);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}

export default OrderEmailValidationController;
