import Logo from '../../assets/images/logo_virtuspay_azulgrad_400.png';
import { ContainerStyled, TitleStyled, ParagraphStyled } from './styles';

import dotenv from 'dotenv';
dotenv.config();

export const NotFound = () => {
	return (
		<ContainerStyled>
			<img
				style={{ margin: '2rem 0 1rem 0' }}
				src={Logo}
				alt="Logo VirtusPay"
			/>
			<TitleStyled>Página não encontrada</TitleStyled>
			<ParagraphStyled>
				Certifique-se que o endereço digitado está correto.
			</ParagraphStyled>
		</ContainerStyled>
	);
};
