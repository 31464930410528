import { createContext, useContext, useState, ReactNode } from 'react';

import { VirtusLoader } from '../../shared/components/index';

interface LoadingProviderProps {
	children: ReactNode;
}

interface ILoading {
	setIsLoading: (status: boolean) => void;
	isLoading: boolean;
}

const LoadingContext = createContext({} as ILoading);

const LoadingProvider = ({ children }: LoadingProviderProps) => {
	const [isLoading, setIsLoadingState] = useState(false);

	const setIsLoading = (status: boolean) => setIsLoadingState(status);

	return (
		<LoadingContext.Provider value={{ setIsLoading, isLoading }}>
			{isLoading && <VirtusLoader />}
			{children}
		</LoadingContext.Provider>
	);
};

function useLoading() {
	const context = useContext(LoadingContext);

	return context;
}

export { LoadingProvider, useLoading };
