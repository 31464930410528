import ContactsRepository from '../repositories/contactsRepository';

import { IContacts } from '../types/types';

class ContactsController {
	repository: ContactsRepository;

	constructor() {
		this.repository = new ContactsRepository();
	}

	async getContacts(transaction: string) {
		const result = await this.repository.getContacts(transaction);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}

	async submit(transaction: string, contacts: IContacts[]) {
		const contactsFiltered = contacts.filter((e) => e !== null);

		contactsFiltered.map(async (contact) => {
			const result = await this.repository.submitContact(transaction, {
				contact_name: contact.name,
				contact_type: contact.type,
				contact_desc: contact.contact,
				relatives: contact.relationship,
			});
			if (result.status !== 201)
				return { result: result.data.message, error: true };
		});

		return {
			result: `/taker/order/${transaction}/formalizacao/thanks`,
			error: false,
		};
	}
}

export default ContactsController;
