import { useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { useHistory } from 'react-router-dom';

import {
	FirstDivStyled,
	AiOutlineArrowLeftStyled,
	SectionStyled,
	TitleStyled,
	SectionInputsStyled,
	CenterDivStyled,
	DivCenterCVStyled,
	DivAlignCenterStyled,
	DivAlignCenterCVStyled,
	TextAreaStyled,
	ErrorStyled,
	SpanStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import { InputUncontrolled, Select, Button } from '../../shared/components';
import { contactReasons } from '../../shared/variables';
import Validator from '../signUp/utils/validator';
import ContactValidator from './utils/validator';
import Swal from 'sweetalert2';
import ContactController from './controllers/contactController';
import { useLoading } from '../../hooks/loading';

export interface IFile {
	lastModified: number;
	lastModifiedDate: Date;
	name: string;
	size: number;
	type: string;
	webkitRelativePath: string;
}

export const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [phone, setPhone] = useState('');

	const [reason, setReason] = useState(null);

	const [captcha, setCaptcha] = useState(false);
	const [validName, setValidName] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	const [validPhone, setValidPhone] = useState(false);
	const [validCV, setValidCV] = useState(false);
	const [cv, setCv] = useState<IFile>({} as IFile);

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const validator = new Validator();
	const contactValidator = new ContactValidator();
	const controller = new ContactController();

	const handleChangeCv = (e: any) => {
		if (e.target.files.length > 0) {
			const valid = validator.validateCV(e.target.files[0]);
			if (valid) {
				setCv(e.target.files[0]);
				setValidCV(true);
				return;
			}
		}

		setCv(e.target.files[0]);
		setValidCV(false);
		return;
	};

	const validateName = () => {
		const valid = validator.validateName(name);
		setValidName(valid);
	};

	const validateEmail = () => {
		const valid: any = validator.validateEmailNormal(email);
		setValidEmail(valid);
	};

	const validatePhone = () => {
		const valid = validator.validatePhone(phone);
		setValidPhone(valid);
	};

	const renderCVBlock = () => {
		if (reason === 'trabalhe_conosco') {
			return (
				<DivCenterCVStyled>
					<CenterDivStyled>
						<InputUncontrolled
							id="phone"
							onBlur={validatePhone}
							onChange={(e) => setPhone(e.target.value)}
							placeholder="Celular"
							type="tel"
							mask="(99) 9 9999-9999"
							value={phone}
						/>
					</CenterDivStyled>
					<ErrorStyled id="phone-error-message"></ErrorStyled>

					<DivAlignCenterCVStyled>
						<label className="file-label">
							<input
								className="file-input"
								accept=".doc,.docx,.pdf"
								type="file"
								onChange={handleChangeCv}
							/>

							<span className="file-cta" id="cv">
								<span className="file-icon">
									<i className="fas fa-upload"></i>
								</span>

								<span className="file-label">Selecione o seu CV...</span>
							</span>
						</label>
						{cv?.name && <SpanStyled>{cv?.name}</SpanStyled>}
					</DivAlignCenterCVStyled>
				</DivCenterCVStyled>
			);
		}
	};

	const validateForm = async () => {
		if (!captcha || !validEmail || !validName || reason == null) {
			return Swal.fire({
				icon: 'error',
				text: 'Preencha todos os campos corretamente para enviar',
			});
		}

		if (reason === 'trabalhe_conosco' && !validPhone && !validCV) {
			return Swal.fire({
				icon: 'error',
				text: 'Preencha todos os campos corretamente para enviar',
			});
		}

		setIsLoading(true);

		setDtoFields();

		const { result, redirect, error } = await controller.submit();

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
				confirmButtonText: 'Entendi',
			});
		} else {
			return Swal.fire({
				icon: 'success',
				text: result.data.message,
				confirmButtonText: 'Okay',
			}).then(() => {
				if (redirect) {
					history.push(redirect);
				}
			});
		}
	};

	const setDtoFields = () => {
		controller.dto.name = name;
		controller.dto.email = email;
		controller.dto.message = message;
		controller.dto.phone = phone;
		controller.dto.subject = reason;
		controller.dto.captcha = captcha;
		controller.dto.cv = cv;
	};

	return (
		<div>
			<FirstDivStyled>
				<AiOutlineArrowLeftStyled size={22} onClick={() => history.push('/')} />

				<img src={Logo} alt="Logo VirtusPay" />

				<p></p>
			</FirstDivStyled>

			<SectionStyled>
				<TitleStyled>Fale conosco</TitleStyled>

				<SectionInputsStyled>
					<CenterDivStyled>
						<InputUncontrolled
							id="name"
							onBlur={validateName}
							onChange={(e) => setName(e.target.value)}
							placeholder="Nome Completo"
							value={name}
						/>

						<ErrorStyled id="name-error-message"></ErrorStyled>

						<InputUncontrolled
							id="email"
							onBlur={validateEmail}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="E-mail"
							type="email"
							value={email}
						/>

						<ErrorStyled id="email-error-message"></ErrorStyled>
						<Select
							id="reason"
							onBlur={() => {
								if (reason) {
									contactValidator.validateReason(reason);
								}
							}}
							onChange={(e: any) => setReason(e.target.value)}
							options={contactReasons}
							label="Motivo do contato"
							value={reason}
						/>

						{renderCVBlock()}

						<TextAreaStyled
							onChange={(e) => setMessage(e.target.value)}
							onBlur={() => contactValidator.validateMessage(message)}
							id="message"
							placeholder="Sua mensagem"
						/>
					</CenterDivStyled>
				</SectionInputsStyled>

				<DivAlignCenterStyled>
					<ReCAPTCHA
						sitekey="6Lcdfc0UAAAAAPjn8a78vgV2ndMZEGG2TV6TB1bB"
						onChange={(e: any) => setCaptcha(e)}
					/>
				</DivAlignCenterStyled>

				<DivAlignCenterStyled>
					<Button
						onClick={validateForm}
						text="Enviar"
						width="300px"
						textColor="#fff"
						className="has-gradient-blue"
					/>
				</DivAlignCenterStyled>
			</SectionStyled>
		</div>
	);
};
