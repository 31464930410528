import axios from '../../../shared/http/customAxios';

import dto from '../models/ccbModel';

class CCBRepository {
	async get(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/signature`, {
				headers: { noAuthorization: true },
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submit(dto: dto) {
		try {
			const result = await axios().post(
				`/v1/order/${dto.transaction}/signature`,
				dto,
				{ headers: { noAuthorization: true } }
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default CCBRepository;
