import axios from '../../../shared/http/customAxios';

import { RecoverPasswordI } from '../types';

export async function recoverPasswordRepository(payload: RecoverPasswordI) {
	const { captcha, rcptch_version, ...data } = payload;
	try {
		const result = await axios().post(`/password_reset/`, data, {
			headers: {
				noAuthorization: true,
				'X-Firebase-AppCheck': captcha,
				'X-Recaptcha-Version': rcptch_version
			},
		});
		return result;
	} catch (error: any) {
		return error.response;
	}
}
