import styled from 'styled-components';

import { colors } from '../../../../shared/styles/colors';

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
	cursor: pointer;
	border-radius: 14px;
	margin: 2px;

	@media (max-width: 710px) {
		align-items: center;
	}
`;

export const OrangeButtonStyled = styled.button`
	background-color: #ff9900;
	width: 150px;
	padding: 0.6rem 0.2rem;
	margin-bottom: 0.4rem;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;

export const WhiteButtonStyled = styled.button`
	background-color: #fff;
	width: 150px;
	padding: 0.6rem 0.2rem;
	margin-bottom: 0.4rem;
	color: ${colors.successColor};
	font-weight: 600;
	font-size: 13px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`;

export const OrangeSpanStyled = styled.span`
	color: ${colors.orangeColor};
	font-weight: 750;
	font-size: 20px;
`;

export const DangerSpanStyled = styled.span`
	color: ${colors.errorColor};
	font-weight: 750;
	font-size: 20px;
	width: 100px;
`;

export const SuccessSpanStyled = styled.span`
	color: ${colors.successColor};
	font-weight: 750;
	font-size: 20px;
`;

export const WarningSpanStyled = styled.span`
	color: ${colors.warningColor};
	font-weight: 750;
	font-size: 20px;
`;

export const BlueSpanStyled = styled.span`
	color: ${colors.infoColor};
	font-weight: 750;
	font-size: 20px;

	@media (max-width: 710px) {
		margin-right: 0.8rem;
	}
`;

export const SpanStyled = styled.span`
	font-weight: 750;
	font-size: 20px;

	@media (max-width: 710px) {
		margin-right: 0.8rem;
	}
`;

export const BlackSpanStyled = styled.span`
	color: #000;
	font-weight: 700;
	font-size: 12px;
`;

export const WhiteSpanStyled = styled.span`
	color: #fff;
	font-weight: 750;
	font-size: 20px;

	@media (max-width: 710px) {
		margin-right: 0.8rem;
		text-align: center;
	}
`;

export const WhitepStyled = styled.span`
	color: #fff;
	font-weight: 700;
	font-size: 12px;
`;

export const OrderDivStyled = styled.div`
	border-radius: 20px;
	background-color: #fff;
	padding: 2.2rem 1rem;
	width: 100%;

	@media (max-width: 710px) {
		display: none;
	}
`;

export const SuccessDivStyled = styled.div`
	border-radius: 20px;
	background-color: ${colors.successColor};
	padding: 2.2rem 1rem;
	width: 100%;

	@media (max-width: 710px) {
		display: none;
	}
`;

export const MobileOrderDivStyled = styled.div`
	display: none;
	border-radius: 20px;
	flex-direction: column;
	background-color: #fff;
	padding: 2rem 1rem;
	width: 300px;

	@media (max-width: 710px) {
		display: flex;
	}
`;

export const MobileSuccessDivStyled = styled.div`
	display: none;
	border-radius: 20px;
	flex-direction: column;
	background-color: ${colors.successColor};
	padding: 2rem 1rem;
	width: 300px;

	@media (max-width: 710px) {
		display: flex;
	}
`;

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: auto;
`;

export const DesktopDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	flex: 0 0 25%;
`;

export const Desktop2DivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 1;
`;

export const Desktop2DivStyledTop = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: space-between;
	flex: 1;
`;

export const GapDivStyled = styled.div`
	width: 2px;
	background-color: rgba(10, 10, 10, 0.2);
`;

export const SmallPStyled = styled.p`
	flex: 40%;
	font-size: 12px;
	margin-top: 0.3rem;
	text-align: left;

	@media (max-width: 710px) {
		margin-top: 0.8rem;
		text-align: center;
	}
`;

export const WhiteSmallPStyled = styled.p`
	flex: 40%;
	font-size: 12px;
	margin-top: 0.3rem;
	text-align: left;
	color: white;

	@media (max-width: 710px) {
		text-align: center;
	}
`;

export const GreyPStyled = styled.p`
	flex: 35%;
	font-size: 20px;
	font-weight: 700;
	color: grey;

	@media (max-width: 710px) {
		margin-left: 0.8rem;
	}
`;

export const WhitePStyled = styled.p`
	flex: 35%;
	font-size: 20px;
	font-weight: 700;
	color: white;

	@media (max-width: 710px) {
		margin-left: 0.8rem;
	}
`;

export const PendenciasDivStyled = styled.div`
	cursor: pointer;
	text-align: center;
	flex: 30%;
	font-size: 12px;
	margin-top: 0.8rem;

	@media (max-width: 710px) {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;

export const PendenciasWhiteDivStyled = styled.div`
	cursor: pointer;
	text-align: center;
	flex: 30%;
	font-size: 12px;
	margin-top: 0.8rem;
	color: #fff;

	@media (max-width: 710px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const GapMobileDivStyled = styled.div`
	width: 2px;
	background-color: rgba(10, 10, 10, 0.2);
	height: 50px;
`;
