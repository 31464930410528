import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const LabelStyled = styled.label`
	padding: 13px 18px;
	border: none;
	border-radius: 3px;
	font-size: 0.95rem;
	font-weight: bolder;
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	color: #fff;
	background: ${colors.gradient};
`;

export const SpanStyled = styled.span`
	font-family: 'Montserrat';
	font-size: 15px;
	display: flex;
	text-align: center;
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	width: 220px;
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const SpanNoneStyled = styled.span`
	display: none;
`;
