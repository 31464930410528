import axios from '../../../shared/http/customAxios';

import moment from 'moment';

import { config } from '../../../config.json';

class BilletService {
	helpersBaseUrl: any = config.helpers_api_host;

	async validate(line: string) {
		const configs = {
			headers: {
				Authorization: 'JWT ' + localStorage.getItem('token'),
			},
		};

		const data = {
			digitable_line: line,
			source: `WebApp-Tomador-${config.environment}`,
		};

		try {
			const result = await axios(true).post('/boleto/check', data, configs);

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	validDueDate(dateString: string) {
		const day = Number(dateString.split('/')[0]);
		const month = Number(dateString.split('/')[1]) - 1;
		const year = Number(dateString.split('/')[2]);
		const now = Date.now();
		const dueDate = new Date(year, month, day, 23, 59, 59);
		const numberDue = Number(dueDate);

		if (!moment(dueDate).isValid()) return false;

		if (numberDue < now) return false;

		// if (moment().add(1, 'days') > dueDate) return false;

		return true;
	}
}

export default BilletService;
