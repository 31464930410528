import { useState } from 'react';

import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

import { HeaderBackground, NavbarItem, Drawer, HeroBody } from './style';

import Logo from '../../../assets/logo_virtuspay_branco_200.png';
import Account from '../../../assets/account.webp';
import AccountWhite from '../../../assets/account-w.png';

interface INavLinks {
	href: string;
	text: string;
	onlyPhone?: boolean;
}

interface IHeader {
	backgroundImage?: string;
	secondaryImage?: string;
	navLinks: INavLinks[];
	subTitle: JSX.Element;
	title: JSX.Element;
	buttonsDesktop: JSX.Element;
	buttonsTouch: JSX.Element;
	classNameTitleDiv?: string;
	whichScreen: string;
	textColor: string;
}

export function Header({
	backgroundImage,
	secondaryImage,
	navLinks,
	subTitle,
	title,
	buttonsDesktop,
	classNameTitleDiv,
	buttonsTouch,
	whichScreen,
	textColor,
}: IHeader) {
	const [displayDrawer, setDisplayDrawer] = useState('none');

	return (
		<HeaderBackground
			whichScreen={whichScreen}
			backgroundColor="#5A69AA"
			image={backgroundImage}
		>
			<div className="pt-5 pb-0">
				<div className="container">
					<nav className="is-flex is-flex-direction-row container is-justify-content-space-around px-4 pt-5 mb-4 is-hidden-desktop">
						<div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
							<img className="lazyload" src={Logo} alt="Logo VirtusPay" />
							<p
								style={{ lineHeight: 0.9 }}
								className="is-align-self-center pt-3 my-0 has-text-weight-bold  has-text-white ml-1"
							>
								Realiza <br /> com você.
							</p>
						</div>
						<i
							id="nav-burger"
							className="fas fa-bars has-text-white is-align-self-center mt-2"
							onClick={() => setDisplayDrawer('block')}
						>
							<AiOutlineMenu size={30} />
						</i>
						<div className="nav-backdrop"></div>
						<Drawer
							style={{ display: displayDrawer }}
							className="px-5 py-4"
							id="drawer"
						>
							<div className="is-12 is-flex is-justify-content-flex-end is-align-items-flex-end">
								<i
									style={{ cursor: 'pointer' }}
									onClick={() => setDisplayDrawer('none')}
								>
									<AiOutlineClose />
								</i>
							</div>
							<div className="body mt-2 is-flex is-align-items-flex-start is-flex-direction-column">
								{navLinks.map((item) => (
									<a
										key={item.href}
										onClick={() => {
											if (item.href === '#como') {
												setDisplayDrawer('none');
											}
										}}
										href={item.href}
									>
										{item.text}
									</a>
								))}
								<div className="column mt-2 is-12-mobile is-flex is-justify-content-space-around is-align-items-center is-flex-direction-column">
									<a
										href="/"
										className="is-outlined is-primary d-flex flex-row justify-content-center align-items-center"
									>
										<img className="lazyload" src={Account} alt="" />
										Entrar
									</a>
									<p> ou </p>
									<a href="/register" className="is-outlined">
										Criar conta
									</a>
								</div>
							</div>
						</Drawer>
					</nav>

					<nav className="is-flex is-flex-direction-row container is-justify-content-space-around is-align-items-center px-4 pt-5 is-hidden-touch">
						<img src={Logo} alt="VirtusPay" />
						<div className="is-flex is-flex-direction-row is-justify-content-end is-align-items-center">
							<div className="is-size-7-desktop is-flex">
								{navLinks.map((item) => {
									if (!item.onlyPhone) {
										return (
											<NavbarItem>
												<a href={item.href}>{item.text}</a>
											</NavbarItem>
										);
									}

									return <></>;
								})}
							</div>

							<div className="navbar-item is-size-7">
								<a
									href="/"
									style={{ cursor: 'pointer', color: textColor }}
									className="has-text-weight-bold is-flex is-align-items-center"
								>
									<img
										className="lazyload mr-1"
										src={whichScreen === 'companies' ? AccountWhite : Account}
										alt=""
									/>
									Entrar
								</a>
							</div>
							<div className="navbar-item">
								<span
									style={{
										width: '2px',
										height: '20px',
										background: textColor,
									}}
								></span>
							</div>
							<div className="navbar-item is-size-7">
								<a
									href="/register'"
									style={{
										cursor: 'pointer',
										color: textColor,
										textDecoration: 'none',
									}}
									className="has-text-weight-bold"
								>
									Criar conta
								</a>
							</div>
						</div>
					</nav>
				</div>
			</div>

			<HeroBody
				className={`${!(whichScreen === 'assignor') && 'container'}`}
				whichScreen={whichScreen}
			>
				<div className="container mt-5 pb-0 is-flex-desktop is-align-items-flex-end">
					<div
						className="columns is-flex-desktop is-align-items-end is-justify-content-end is-flex-direction-row"
						style={{ height: '50%' }}
					>
						<div className={`column ${classNameTitleDiv} is-align-self-end`}>
							{title}
							{subTitle}
							<div>
								{buttonsDesktop}
								{buttonsTouch}
							</div>
						</div>
						{secondaryImage && (
							<div className="column is-align-self-baseline pb-0 mb-2">
								<img
									style={{
										marginBottom: whichScreen === 'taker' ? '-48px' : '0',
										width: '90%',
									}}
									src={secondaryImage}
									alt="Banner"
								/>
							</div>
						)}
					</div>
				</div>
			</HeroBody>
		</HeaderBackground>
	);
}
