import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const IMGStyled = styled.img`
	margin: 2rem 0;
	width: 150px;
`;

export const PStyled = styled.p`
	font-size: 16px;
	margin: 0.5rem 0;
	font-weight: 500;
	text-align: center;
`;
