import axios from '../../../shared/http/customAxios';

import bankDto from '../models/bankModel';

class UserRepository {
	async updateAddress(address: any) {
		try {
			const result = await axios().patch('/user', {
				address: address,
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async updateProfile(profile: any) {
		try {
			const result = await axios().put('/user/profile', profile);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async changePassword(dto: any) {
		try {
			const result = await axios().patch('/user/password', dto);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async inactivateAccount() {
		try {
			const result = await axios().patch('/user/inactivate');
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submitStepFiveRepository(dataRequest: bankDto) {
		try {
			const result = await axios().post('/assignor/bank', dataRequest);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async callBanksRepository() {
		try {
			const result = await axios().get(`v1/banks`);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default UserRepository;
