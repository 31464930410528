import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import AuthController from '../../controllers/authController';
import { VirtusLoader } from '../index';

export const LoginRedirect = () => {
	const { id } = useParams<{ id: string }>();

	const login = async () => {
		try {
			const authController = new AuthController();
			await authController.getUserFromCore(id);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		login();
	});

	return <VirtusLoader />;
};
