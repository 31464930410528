import styled from 'styled-components';

import { colors } from '../../styles';

export const PasswordDivStyled = styled.div`
	position: absolute;
	bottom: 6px;
	right: 15px;
`;

export const ContainerStyled = styled.div`
	position: relative;
	margin-bottom: 6px;
`;

export const ErrorDivStyled = styled.div`
	width: 190px;
	margin-bottom: 3px;
	white-space: pre-wrap;
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const TooltipDivStyled = styled.div`
	display: inline;
	flex-direction: row;
`;

export const GroupStyled = styled.div`
	flex-direction: row;
	white-space: nowrap;
`;

export const PasteCodeStyled = styled.img`
	display: inline-block;
	position: absolute;
	justify-content: center;
	right: 11px;
	top: 1.3rem;
	cursor: pointer;
	color: ${colors.primaryBlue};
`;
