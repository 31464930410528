import Swal from 'sweetalert2';

import { IResponseErrorUnico } from '../types/types';

const modalGenerator = (
	type: string,
	message: string,
	changeView: () => void
) => {
	return Swal.fire({
		icon: 'error',
		customClass: {
			title: 'is-size-4 is-size-12-mobile has-text-centered',
		},
		title: type,
		text: message,
		confirmButtonText: 'Fechar',
	}).then(() => {
		changeView();
	});
};

export const modalErrorUnico = (
	error: IResponseErrorUnico,
	call: string,
	changeView: () => void
) => {
	const { code, type, message, stack } = error;

	if (call === 'support') {
		return modalGenerator(
			type,
			`Os Browser suportados neste dispositivo são: ${stack[0].listBrowsersSupport.join(
				', '
			)}`,
			() => changeView()
		);
	}

	switch (code) {
		case 100:
			return modalGenerator(
				type,
				message +
					'. Certifique de estar em um aparelho com câmera funcionando!',
				() => changeView()
			);

		case 101:
			return modalGenerator(
				type,
				message + '. É necessário conceder permissão para funcionar.',
				() => changeView()
			);

		case 107:
			return;

		case 114:
			return modalGenerator(
				type,
				message + '. Encerro o outro processo e tente novamente.',
				() => changeView()
			);
	}

	return Swal.fire({
		icon: 'error',
		customClass: {
			title: 'is-size-4 is-size-12-mobile has-text-centered',
		},
		title: type === 'DefaultError' ? 'Câmera não encontrada' : type,
		text:
			code !== 100
				? message
				: `${message}. Certifique de estar em um aparelho com câmera funcionando!`,
		confirmButtonText: 'Fechar',
	}).then(() => {
		changeView();
	});
};
