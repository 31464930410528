import { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import { OrderSendingValidator } from '../utils/orderCheckoutValidator';

import { AClickStyled, PJafraStyled } from '../styles';

import { OrderProps } from '../interfaces';

import {
	InputControlledCurrency,
	Input,
	Button,
} from '../../../shared/components/index';

import { colors } from '../../../shared/styles/colors';
import { moneyMask, undoMoneyMask } from '../../../shared/utils/masks';

interface SecondSectionProps {
	order: OrderProps;

	handleNextSection: (secondSectionData: {
		income: string;
		cellphone: string;
	}) => void;
}

// import {
// 	getString,
// 	getRemoteConfig,
// 	fetchAndActivate,
// } from 'firebase/remote-config';

import dotenv from 'dotenv';
import { getRemoteConfig, getString } from 'firebase/remote-config';
dotenv.config();

export const schema = Yup.object().shape({
	income: Yup.string()
		.test('len', '', function (value) {
			const undoMoney = undoMoneyMask(value ?? '');

			if (undoMoney.length < 7)
				return this.createError({
					message: 'A renda deve ser maior que R$ 1.000,00 mensais',
				});

			if (undoMoney.length >= 9)
				return this.createError({
					message: 'A renda não pode ser maior que R$ 99.999,99 mensais',
				});
			return true;
		})
		.required('Digite sua renda'),
	cellphone: Yup.string()
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(11, 'Digite um número de telefone válido')
		.test('len', 'Digite um número de telefone válido', function (value) {
			if (value) {
				if (value?.length !== 11 || value[2] !== '9') return false;
				return true;
			}
			return false;
		})
		.required('Número de telefone é obrigatório'),
});

const SecondSection = ({ order, handleNextSection }: SecondSectionProps) => {
	// const [birthdate, setBirthdate] = useState(null);
	const [isCommsAccepted, setIsCommsAccepted] = useState(false);
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);

	const validator = new OrderSendingValidator();

	const remoteConfig = getRemoteConfig();

	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	const termsSCR = getString(remoteConfig, 'web_terms_scr_url');
	const termsPrivacy = getString(remoteConfig, 'web_privacy_policy_url');
	const termsUse = getString(remoteConfig, 'web_terms_and_conditions_url');

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({ resolver: yupResolver(schema) });

	useEffect(() => {
		// const birthdate = order.customer.birthdate;
		const income = order.customer.income;

		// if (birthdate !== '1900-01-01') setBirthdate(dateMask(order.customer.birthdate));
		if (income !== '1500.00')
			setValue(
				'income',
				undoMoneyMask(moneyMask(order.customer.income).replace('.', ','))
			);

		setValue('cellphone', order.customer.cellphone ?? null);
	}, [order]);

	const validateSecondSection = () => {
		const income = undoMoneyMask(getValues('income'));
		const cellphone = getValues('cellphone');

		// const validBirthdate = validator.validateBirthdate(birthdate);
		const validIsCommsAccepted =
			validator.validateIsCommsAccepted(isCommsAccepted);
		const validIsTermsAccepted =
			validator.validateIsTermsAccepted(isTermsAccepted);

		if (validIsCommsAccepted && validIsTermsAccepted) {
			handleNextSection({ income, cellphone });
		}
	};

	return (
		<section className="column px-0 pt-0 is-flex is-flex-direction-column is-12-mobile is-6-desktop is-offset-3-desktop">
			<div className="column px-0 is-flex is-flex-direction-column is-12-mobile is-8-tablet is-offset-2-tablet">
				<h2 className="is-size-4 has-text-black has-text-centered">
					Complete seus dados
				</h2>

				<div className="has-text-success my-5">
					<p className="is-size-6">
						<AiFillCheckCircle className="ml-5 mr-2" />
						Nome completo: {order.customer.full_name}
					</p>

					<p className="is-size-6">
						<AiFillCheckCircle className="ml-5 mr-2" />
						E-mail cadastro na loja: {order.customer.email}
					</p>

					<p className="is-size-6">
						<AiFillCheckCircle className="ml-5 mr-2" />
						CPF cadastrado na loja: {order.customer.cpf_masked}
					</p>
				</div>

				{/* <Input
          id="birthdate"
          value={birthdate}
          onChange={e => setBirthdate(e.target.value)}
          mask="99/99/9999"
          type="text"
          label="Digite sua data de nascimento"
        /> */}

				<InputControlledCurrency
					control={control}
					id="income"
					name="income"
					dataTestID="salary"
					type="tel"
					precision="2"
					decimalSeparator=","
					thousandSeparator="."
					prefix="R$"
					placeholder="Renda mensal (R$)"
					error={errors.salary && errors.salary.message}
				/>

				<p
					className="is-size-7 px-2 mb-3 mt-0 has-text-weight-medium"
					style={{ color: 'rgba(10,10,10,0.4)' }}
				>
					Renda Mensal (R$)
				</p>

				<Input
					control={control}
					name="cellphone"
					id="cellphone"
					dataTestID="phone"
					mask="(99) 9 9999-9999"
					type="tel"
					placeholder="Celular com DDD"
					error={errors.phone && errors.phone.message}
				/>

				<div className="is-12 is-flex my-3 is-justify-content-start is-align-items-start">
					<div className=" is-flex is-justify-content-flex-end is-2-mobile">
						<Button
							className="p-3 mx-3"
							color={isCommsAccepted ? colors.primaryBlue : colors.defaultGrey}
							onClick={() => setIsCommsAccepted(!isCommsAccepted)}
						/>
					</div>

					<PJafraStyled>
						Concordo em receber notificações sobre meu pedido de parcelamento
						por E-mail, SMS e WhatsApp.
					</PJafraStyled>
				</div>

				<div className="is-12 is-flex my-3 is-justify-content-start is-align-items-start">
					<div className=" is-flex is-justify-content-flex-end is-2-mobile">
						<Button
							className="p-3 mx-3"
							color={isTermsAccepted ? colors.primaryBlue : colors.defaultGrey}
							onClick={() => setIsTermsAccepted(!isTermsAccepted)}
						/>
					</div>

					<PJafraStyled>
						Li, compreendi e concordo com os
						<AClickStyled rel="noreferrer" target="_blank" href={termsUse}>
							Termos de uso
						</AClickStyled>
						e a
						<AClickStyled rel="noreferrer" target="_blank" href={termsPrivacy}>
							Política de Privacidade
						</AClickStyled>
						e autorizo a consulta de informações ao meu respeito e o
						compartilhamento com a VirtusPay de informações do
						<AClickStyled rel="noreferrer" target="_blank" href={termsSCR}>
							SCR - Banco Central do Brasil
						</AClickStyled>
						.
					</PJafraStyled>
				</div>

				<div className="is-flex is-justify-content-center">
					<Button
						onClick={handleSubmit(validateSecondSection)}
						className="my-3 has-gradient-blue has-text-white"
						width="80%"
						text="Próximo"
					/>
				</div>
			</div>
		</section>
	);
};

export default SecondSection;
