import Swal from 'sweetalert2';

import { couponsRepository } from '../repository';

export async function couponsController() {
	const result = await couponsRepository();
	if (result.status !== 200) {
		Swal.fire({
			icon: 'error',
			text: result?.data.message ?? 'Não foi possível recuperar os cupons',
			confirmButtonText: 'Fechar',
		});
		return { error: true };
	}
	return { result: result.data, error: false };
}
