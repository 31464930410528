import styled from 'styled-components';

import { AiFillQuestionCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import { colors } from '../../shared/styles/colors';

export const FirstDivStyled = styled.div`
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin: 1.5rem 1.2rem;
`;

export const ImgStyled = styled.img`
	margin-top: 1.5rem;
	width: 200px;
`;

export const SectionStyled = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const TitleStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 24px;
	margin-top: 3.3rem;
	text-align: center;
	color: ${colors.textBlue};
`;

export const InputsStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
`;

export const CenterDivStyled = styled.div`
	flex-direction: column;
	display: flex;
	width: 390px;

	@media (max-width: 450px) {
		width: 300px;
	}
`;

export const DivCenterCVStyled = styled.div`
	flex-direction: column;
	display: flex;
	width: 390px;
	margin: 1rem 0;

	@media (max-width: 450px) {
		width: 300px;
	}
`;

export const DivAlignCenterStyled = styled.div`
	flex-direction: column;
	display: flex;
	margin: 1rem 0;
	align-items: center;
	text-align: center;
`;

export const SpanStyled = styled.span`
	font-family: 'Montserrat';
	font-size: 16px;
	display: flex;
	text-align: center;
	margin-top: 1rem;
	font-weight: 500;
	width: 600px;
	line-height: 1.5rem;

	@media (max-width: 700px) {
		width: 400px;
	}

	@media (max-width: 500px) {
		width: 300px;
	}
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
	text-align: left !important;

	@media (min-width: 768px) {
		mix-width: 180px;
	}
`;

export const InfoStyled = styled(AiFillQuestionCircle)`
	margin-bottom: -0.45rem;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
`;
