import jwt_decode, { JwtPayload } from 'jwt-decode';

interface ITokenVirtus extends JwtPayload {
	perfil: string;
	exp: number;
}

export const isValidToken = () => {
	const token = localStorage.getItem('refresh');

	try {
		if (token) {
			const decoded = jwt_decode<ITokenVirtus>(token);
			const { exp, perfil } = decoded;

			const currentDate = new Date();

			if (perfil !== 'tomador') return false;

			if (exp) {
				if (exp * 1000 < currentDate.getTime()) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		}
	} catch (error) {
		return false;
	}
};
