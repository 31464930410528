import { useEffect, useState } from 'react';

import { Route } from 'react-router-dom';

import validator from 'validator';

import Swal from 'sweetalert2';

import { isValidToken } from '../../utils/isValidToken';

import { NotFound } from '../../../views/notFound';

import AuthController from '../../controllers/authController';

import { useAuth } from '../../../hooks/auth/auth';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
	const [authenticated, setAuthenticated] = useState(false);

	const { removeData } = useAuth();

	const transaction = rest.computedMatch.params.id;

	const authController = new AuthController();

	useEffect(() => {
		checkAuth();
	}, []);

	const checkAuth = () => {
		if (!isValidToken()) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Token expirado, refaça o login',
			}).then(() => {
				removeData();
				authController.logout(undefined, () => (window.location.href = '/'));
			});
		} else {
			return setAuthenticated(true);
		}
	};

	if (transaction) {
		if (!validator.isUUID(transaction)) {
			return <NotFound />;
		}
	}

	return (
		<>
			<Route
				{...rest}
				render={(props) => (authenticated ? <Component {...props} /> : '')}
			/>
		</>
	);
};
