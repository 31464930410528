import axios, { AxiosRequestConfig } from 'axios';

import Swal from 'sweetalert2';

import { deleteCookie } from '../utils/cookies';
import { config } from '../../config.json';

import packageJson from '../../../package.json';

const fetchClient = (
	helpers = false,
	timeout = Number(config.timeout_default)
) => {
	const REACT_APP_API_HOST = config.api_host;
	const REACT_APP_HELPERS_API_HOST = config.helpers_api_host;


	const defaultOptions: AxiosRequestConfig = {
		baseURL: helpers
			? REACT_APP_HELPERS_API_HOST
			: REACT_APP_API_HOST,
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			'X-App-Key': 'db1f6bf4-3b23-4798-9168-5440a6845f4c',
			VERSION: packageJson.version,
			PLATFORM: 'web-app',
		},
		timeout: timeout,
		timeoutErrorMessage: 'timeout',
	};

	const instance = axios.create(defaultOptions);

	instance.interceptors.response.use(
		async (response) => {
			if (
				response.config.url === '/token/' ||
				response.config.url === '/user/register' ||
				response.config.url?.includes('/user/register/verify?token=')
			) {
				const token = response.headers.token ?? response.data.access;
				const refresh = response.headers.refresh ?? response.data.refresh;
				deleteCookie('token');

				localStorage.setItem('token', token);
				instance.defaults.headers.Authorization =
					'JWT ' + localStorage.getItem('token');

				if (response.data.refresh !== null) {
					localStorage.setItem('refresh', refresh);
					deleteCookie('refresh');
				}
			}

			return response;
		},
		async (error) => {
			try {
				if (error.response.status === 401 && error.config.url !== '/elk') {
					if (error.config.url === '/token/refresh/') {
						return Swal.fire({
							icon: 'error',
							titleText: 'Oops',
							text: 'Seu token expirou, por favor,faça login novamente.',
						}).then(() => {
							localStorage.removeItem('refresh');
							localStorage.removeItem('token');
							localStorage.removeItem('user');
							deleteCookie('refresh');
							deleteCookie('token');
							window.location.href = '/login';
						});
					}
					if (error.config.url === '/token/') return Promise.reject(error);

					await refreshToken();

					error.response.config.headers.Authorization =
						'JWT ' + localStorage.getItem('token');

					return instance.request(error.response.config);
				} else if (error.response.status === undefined) {
					return Swal.fire({
						icon: 'error',
						titleText: 'Ops!',
						text: 'Ocorreu um erro no servidor, tente novamente mais tarde',
						confirmButtonText: 'Fechar',
					}).then(() => {
						window.location.href = '/login';
					});
				}
				return error.response;
			} catch (error) {
				return Swal.fire({
					icon: 'error',
					titleText: 'Ops!',
					text: 'Ocorreu um erro no servidor, tente novamente mais tarde',
					confirmButtonText: 'Fechar',
				}).then(() => {
					window.location.href = '/login';
				});
			}
		}
	);

	instance.interceptors.request.use(
		(request) => {
			if (
				request.url === '/token/' ||
				request.url === '/token/refresh' ||
				request.url === '/user/register' ||
				request.url === '/v1/partners' ||
				request.url?.includes('/user/auth/')
			) {
				delete request.headers.Authorization;
			} else {
				if (!request.headers.noAuthorization) {
					request.headers.Authorization =
						'JWT ' + localStorage.getItem('token');
				} else {
					delete request.headers.noAuthorization;
				}
				request.headers['X-App-Key'] = 'db1f6bf4-3b23-4798-9168-5440a6845f4c';
				request.headers.VERSION = packageJson.version;
			}
			return request;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	const refreshToken = async () => {
		instance.defaults.baseURL = REACT_APP_API_HOST;
		const refresh = await instance.post('/token/refresh/', {
			refresh: localStorage.getItem('refresh'),
		});
		localStorage.setItem('token', refresh.data.access);
		localStorage.setItem('refresh', refresh.data.refresh);
	};

	return instance;
};

export default fetchClient;
