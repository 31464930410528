import ContactDto from '../models/contactModel';
import ContactRepository from '../repositories/contactRepository';

class ContactController {
	repository: ContactRepository;
	dto: ContactDto;

	constructor() {
		this.repository = new ContactRepository();
		this.dto = new ContactDto();
	}

	async submit() {
		const result = await this.repository.submit(this.dto);

		const contacts_url = '/contact';

		if (result.status !== 200) return { result, error: true };
		else {
			return { redirect: contacts_url, result, success: true };
		}
	}
}

export default ContactController;
