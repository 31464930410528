import styled from 'styled-components';

import { colors } from '../../styles/colors';

export const TextAmountStyled = styled.p`
	font: normal normal normal 20px/24px Montserrat;
	align-items: center;
	color: #000000;
	opacity: 1;
	margin-top: 0.5rem;
	font-size: 1rem;
	margin-left: 0.4rem;

	@media (max-width: 780px) {
		margin-left: 20%;
	}

	@media (max-width: 770px) {
		margin-left: 20%;
	}

	@media (max-width: 760px) {
		margin-left: 20%;
	}

	@media (max-width: 470px) {
		margin-left: 6%;
	}
`;

export const ContainerStyled = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;

	@media (max-width: 1025px) {
		padding: 0 1rem;
	}
`;

export const CardDivStyled = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0.5rem 0;

	@media (max-width: 1025px) {
		flex-direction: column;
	}
`;

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	width: auto;
	margin: 1.2rem 0;
`;

export const BlueAmountStyled = styled.span`
	font-size: 1.1rem;
	font-weight: 501;
	text-align: center;
	color: ${colors.primaryBlue};
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0.5rem;
`;

export const InputsDivStyled = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: 1025px) {
		flex-direction: column;
	}
`;

export const HalfInputStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.3rem;
	width: 32%;

	@media (max-width: 1025px) {
		width: 21%;
	}

	@media (max-width: 770px) {
		width: 39%;
	}
`;

export const InputDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 32%;
	margin-top: 0.3rem;

	@media (max-width: 1025px) {
		width: 21%;
	}

	@media (max-width: 770px) {
		width: 59%;
	}
`;

export const WrapDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const InfoStyled = styled.small`
	color: grey;
	align-self: flex-end;
	display: none;

	@media (max-width: 1025px) {
		display: flex;
	}
`;
