import Swal from 'sweetalert2';

import { colors } from '../../../shared/styles/colors';
import { validPhone } from '../../../shared/utils/validators';

export default class Validator {
	validateReason = (reason: string) => {
		const input: HTMLElement | null = document.querySelector('#reason');

		if (!input) return;

		input.style.borderColor = colors.defaultGrey;

		if (reason == null) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'Selecione o motivo do contato',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validateMessage = (message: string) => {
		const input: HTMLElement | null = document.querySelector('#message');

		if (!input) return;

		input.style.borderColor = colors.defaultGrey;

		if (message.length < 15) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'A mensagem deve conter no mínimo 15 caracteres',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validatePhone = (phone: string) => {
		const input: HTMLElement | null = document.querySelector('#phone');

		if (!input) return;

		input.style.borderColor = colors.defaultGrey;

		if (!validPhone(phone)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'Digite um celular válido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};
}
