import { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	TitleStyled,
	PStyled,
	CenterMarginTopDivStyled,
	ClickStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import { Button } from '../../shared/components/index';
import ImageRefused from '../../assets/images/refused_2.0.png';

import { checkOrderNextStep } from '../../shared/utils/checkOrderNextStep';

import orderRefusedController from './controllers/orderRefusedController';

import { elkClickButtonLinkFinanzero } from './utils/elkServices';
import { useLoading } from '../../hooks/loading';

export const OrderRefused = () => {
	// const defaultReturnDate = new Date();
	// defaultReturnDate.setDate(defaultReturnDate.getDate() + 65);
	const [reasonCode, setReasonCode] = useState('');
	const [firstName, setFirstName] = useState('');
	const [companyUrl, setCompanyUrl] = useState('');
	const [hasGracePeriodGt0, setHasGracePeriodGt0] = useState(false);

	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const controller = new orderRefusedController();

		const getOrder = async () => {
			const { result, error } = await controller.getOrder(id);

			if (error) {
				return Swal.fire({
					icon: 'error',
					confirmButtonText: 'Fechar',
					text:
						result.data.message ??
						'Erro ao recuperar a proposta. Tente novamente em alguns minutos.',
				}).then(() => {
					window.location.href = '/';
				});
			}

			const nextStep = result.next_step;
			const actualPage = 'TakerOrderRefused';

			const { redirectPage } = checkOrderNextStep(nextStep, actualPage, id);
			if (redirectPage) history.push(redirectPage);

			setIsLoading(false);
			setReasonCode(result.reason_code);
			setFirstName(result.first_name);
			setCompanyUrl(result.company_url);

			setHasGracePeriodGt0(result.has_grace_period_gt_0);
		};

		getOrder(); // eslint-disable-next-line
	}, [id, reasonCode]);

	const templateDefault = () => {
		return (
			<>
				<img className="mb-3" src={ImageRefused} width="150px" alt="Refused" />

				<TitleStyled>Poxa, não foi dessa vez :(</TitleStyled>

				<PStyled>
					Agradecemos o interesse mas essa opção de{' '}
					{hasGracePeriodGt0 ? 'compra' : 'parcelamento'} ainda não está
					disponível para você no momento
				</PStyled>

				<CenterMarginTopDivStyled>
					<Button
						width="300px"
						className="has-gradient-blue"
						text="Voltar para o site"
						onClick={() =>
							companyUrl
								? (window.location.href = companyUrl)
								: history.push('/taker')
						}
					/>
				</CenterMarginTopDivStyled>
			</>
		);
	};

	const templateNoReturn = () => {
		return (
			<>
				<TitleStyled>Que pena, {firstName}!</TitleStyled>

				<PStyled>Hoje estamos sem uma oferta disponível para você. 😔</PStyled>

				<PStyled>
					Recomendamos que fique atento a sua saúde financeira antes de realizar
					um pedido de {hasGracePeriodGt0 ? 'compra' : 'parcelamento'} com a
					gente.
				</PStyled>

				<PStyled>
					Enquanto isso, se ainda precisar de crédito para parcelar essas
					compra, temos parcerias que podem te ajudar nesse momento.
				</PStyled>

				<CenterMarginTopDivStyled>
					<Button
						width="300px"
						className="has-gradient-blue"
						text="Ver Outras Opções de Crédito"
						onClick={() => {
							elkClickButtonLinkFinanzero(id);
							window.open(
								'https://finanzero.com.br/simule-seu-emprestimo/?utm_source=virtuspay&utm_medium=affiliate&utm_content=emprestimopessoal',
								'_blank'
							);
						}}
					/>
				</CenterMarginTopDivStyled>
			</>
		);
	};

	const templateReturn = () => {
		return (
			<>
				<TitleStyled>Não podemos seguir, {firstName}! 😔</TitleStyled>

				<PStyled>
					Nesse momento de pandemia, nossas ofertas de crédito estão reduzidas.
					Mas em breve esperamos atendê-lo novamente.
				</PStyled>

				<PStyled>
					Sempre recomendamos que fique atento a sua saúde financeira antes de
					realizar um pedido de parcelamento com a gente.
				</PStyled>

				<PStyled>
					Para não perder sua compra, você pode{' '}
					<ClickStyled target="__blank" href={companyUrl}>
						retornar ao site da loja e escolher outro meio de pagamento.
					</ClickStyled>
				</PStyled>
			</>
		);
	};

	const templateReturnDelay = () => {
		return (
			<>
				<TitleStyled>Calma aí, {firstName}!</TitleStyled>

				<PStyled>
					Você já tem um pedido de{' '}
					{hasGracePeriodGt0 ? 'crédito' : 'parcelamento'} em andamento conosco
					e, nesse momento, não será possível seguir com sua nova solicitação.
				</PStyled>

				<PStyled>
					Para não perder sua compra, você pode{' '}
					<ClickStyled target="__blank" href={companyUrl}>
						retornar ao site da loja e escolher outro meio de pagamento.
					</ClickStyled>
				</PStyled>

				<PStyled>Agradecemos por escolher a VirtusPay. 💙</PStyled>
			</>
		);
	};

	const templateBankingBilletRefuse = () => {
		return (
			<>
				<TitleStyled>Calma aí, {firstName}!</TitleStyled>

				<PStyled>
					Agradecemos seu interesse, mas esse tipo de boleto não é aceito por
					nós.
				</PStyled>

				<PStyled>
					Você pode tentar novamente usando uma de nossas lojas aceitas.
				</PStyled>

				<CenterMarginTopDivStyled>
					<Button
						width="300px"
						className="has-gradient-blue"
						text="Lojas Aceitas"
						onClick={() => (window.location.href = '/')}
					/>
				</CenterMarginTopDivStyled>
			</>
		);
	};

	const template = () => {
		let template = templateDefault();

		if (reasonCode.includes('BL005')) template = templateBankingBilletRefuse();
		else if (
			reasonCode.includes('V003') ||
			reasonCode.includes('NB003') ||
			reasonCode.includes('CD003')
		)
			template = templateNoReturn();
		else if (reasonCode.includes('RP015')) template = templateReturn();
		else if (reasonCode.includes('CA003')) template = templateReturnDelay();

		return (
			<CenterMarginTopDivStyled>
				<Link to="/taker">
					<img src={Logo} alt="Logo VirtusPay" />
				</Link>

				{template}
			</CenterMarginTopDivStyled>
		);
	};

	return <main>{template()}</main>;
};
