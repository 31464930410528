import Swal from 'sweetalert2';

import { ICupom } from '../interfaces';

import { IPostStore } from '../components/OrderSendingForm';

import OrderRepository from '../repositories/orderRepository';

import AuthRepository from '../../../shared/repositories/authRepository';

import redirect from '../../../shared/utils/redirect';
import { dataLayerPush } from '../../../shared/utils/dataLayer/dataLayerPush';

class OrderSendingController {
	repository = new OrderRepository();
	authRepository = new AuthRepository();

	async submit(orderData: any) {
		const result = await this.repository.send(orderData);

		if (result.status !== 200)
			return { result, error: true, redirectPage: null };

		dataLayerPush({
			event: 'conversion',
			data: {
				name: 'payslip_submitted',
				value: orderData.total_amount,
				currency: 'BRL',
				coupon: null,
				product: orderData.description,
				reason: orderData.installment_reason,
				transaction_id: result.data.transaction,
			},
		});

		const { redirectPage } = redirect(
			result.data.next_step,
			result.data.transaction
		);

		return { result, error: false, redirectPage };
	}

	async getInstallments(amount: number) {
		const result = await this.repository.installment(amount);

		if (result.status !== 200) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Erro ao recuperar o parcelamento. Tente novamente em alguns minutos.',
			});
		}

		return result.data;
	}

	async postCupom(orderData: ICupom) {
		const result = await this.repository.cupom(orderData);

		if (result.status !== 200) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text:
					result.data.message ??
					'Erro ao recuperar o parcelamento. Tente novamente em alguns minutos.',
			});
		}

		return result.data;
	}

	async postStore(payload: IPostStore) {
		await this.repository.postStore(payload);
		return;
	}
}

export default OrderSendingController;
