import Swal from 'sweetalert2';

import TokenRepository from '../repositories/tokenRepository';
import TokenDto from '../models/tokenModel';
import redirect from '../../../shared/utils/redirect';

declare global {
	interface HTMLElement {
		value: string;
		focus: () => void;
	}
}

class TokenController {
	repository: TokenRepository;
	dto: TokenDto;
	digits: string[];

	constructor() {
		this.repository = new TokenRepository();
		this.dto = new TokenDto();
		this.digits = ['digit-1', 'digit-2', 'digit-3', 'digit-4', 'digit-5'];
	}

	async send(phone: string, transaction: string, contactType: string) {
		await this.repository.send(phone, transaction, contactType);
	}

	async check(transaction: string) {
		const result = await this.repository.check(transaction);

		return { result: result.data };
	}

	cleanDigits() {
		this.digits.forEach((digit) => {
			const item = document?.getElementById(digit);
			if (item?.value) {
				item.value = '';
			}
			if (digit === 'digit-1') {
				item?.focus();
			}
		});
	}

	getTokenValue() {
		let token = '';
		this.digits.forEach((digit) => {
			token = token + document?.getElementById(digit)?.value;
		});
		if (token.length !== 5) {
			Swal.fire({
				icon: 'error',
				text: 'Preencha os dígitos do token',
				confirmButtonText: 'Fechar',
			});
			return false;
		}
		return token;
	}

	async submit() {
		const result = await this.repository.submit(this.dto);

		if (result.status !== 200)
			return { result, error: true, redirectPage: null };

		const { redirectPage } = redirect(
			result.data.next_step,
			this.dto.transaction
		);

		return { result, error: false, redirectPage };
	}
}

export default TokenController;
