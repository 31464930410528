import styled from 'styled-components';

export const ButtonDivStyled = styled.div`
	.new-button {
		padding: 8px 40px;
		font: 'Montserrat', sans-serif;
		color: white;
		border: none;
		cursor: pointer;
		border-radius: 14px;
	}

	.new-button:focus {
		outline: none;
	}

	.outline-button {
		border: 2px solid white;
		background: none;
	}
`;
