import styled from 'styled-components';

interface IHeroBody {
	whichScreen: string;
}

interface IHeaderBackground extends IHeroBody {
	backgroundColor: string;
	image?: string;
}

export const HeaderBackground = styled.header<IHeaderBackground>`
	background-image: ${({ whichScreen }) =>
			whichScreen === 'assignor' &&
			'linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), '}
		url(${({ image }) => image});
	background-size: cover;
	background-position: center center;

	background-color: ${({ backgroundColor }) => backgroundColor};

	display: flex;
	align-items: stretch;
	flex-direction: column;
	justify-content: space-between;
`;

export const NavbarItem = styled.div`
	margin-right: 1rem;

	a:hover {
		color: #fafafa !important;
	}

	a {
		color: #fafafa !important;
	}

	a::before {
		color: #28c0cd;
		font-size: 1rem;
	}
`;

export const Drawer = styled.div`
	z-index: 99;
	height: 100%;
	width: 60vw;
	background-color: white;
	position: fixed;
	top: 0px;
	right: 0px;
	display: block;
	padding: 20px;
	display: none;

	a {
		margin-top: 8px;
		font-weight: 500;
		margin-bottom: 8px;
		color: #707070;
	}

	a::before {
		content: ' » ';
		color: #00e9ee !important;
	}

	button {
		padding: 4px 15px;
		font-weight: 600;
		border: 1px solid;
		border: 2px solid #00e9ee;
		color: #00e9ee;
		background-color: transparent;
		border-radius: 8px;
	}
`;

export const ButtonDivStyled = styled.div`
	.new-button {
		padding: 8px 40px;
		font: 'Montserrat', sans-serif;
		color: white;
		border: none;
		cursor: pointer;
		border-radius: 14px;
	}

	.new-button:focus {
		outline: none;
	}

	.outline-button {
		border: 2px solid white;
		background: none;
	}
`;

export const HeroBody = styled.div<IHeroBody>`
	padding: ${({ whichScreen }) => {
		switch (whichScreen) {
			case 'assignor':
				return '9rem 1.5rem';

			case 'taker':
				return '3rem 1.5rem';

			case 'companies':
				return '3rem 1.5rem 0rem';

			default:
				return 0;
		}
	}};
`;
