import { FC, Dispatch, SetStateAction, useMemo, useEffect } from 'react';
import { IRenderInstallments } from '../../interfaces';

import {
	CenterDivStyled,
	SendingGreyPStyled,
	SendingGreyTitleStyled,
	InstallmentListStyled,
} from '../../styles';
import { getInstallmentsFromElement } from '../../utils/getInstallmentsFromElement';
import { InstallmentButton } from '../InstallmentButton/InstallmentButton';

export interface IInstallmentsOptions {
	tests: string;
	multipleInstallments: boolean;
	installments: any[];
	selectInstallment: (
		installments: number,
		entrada: string,
		restante: number,
		total: string,
		interest?: string
	) => void;
	setFirstRendering: Dispatch<SetStateAction<boolean>>;
	selectedInstallment: number;
	firstRendering: boolean;
	preApproved?: boolean;
}

const InstallmentsOptions: FC<IInstallmentsOptions> = ({
	tests,
	multipleInstallments,
	installments,
	preApproved,
	selectInstallment,
	setFirstRendering,
	selectedInstallment,
	firstRendering,
}) => {
	const list = useMemo(() => {
		if (installments?.length) {
			return installments.map((value, index) => {
				const installment: IRenderInstallments = getInstallmentsFromElement(
					value,
					preApproved ?? false
				);
				const firstButton = index === 0;
				return (
					<li key={'InstallmentOption' + index}>
						<InstallmentButton
							firstButton={firstButton && firstRendering}
							active={
								(firstButton && firstRendering) ||
								selectedInstallment === installment.installment
							}
							onSelect={selectInstallment}
							data-key={installment.installment}
							installments={installment.installment}
							entrada={installment.down_payment}
							restante={installment.installment - 1}
							valorRestante={installment.outstanding_balance}
							total={installment.total}
							interest={installment.interest}
						/>
					</li>
				);
			});
		}
		return [];
	}, [installments, selectedInstallment]);

	useEffect(() => {
		setFirstRendering(true);
		if (list?.length) setFirstRendering(false);
	}, [list]);

	return (
		<CenterDivStyled>
			{tests.includes('preapproved') ? (
				<>
					<SendingGreyTitleStyled>
						PARABÉNS!!! Você foi pré-aprovado. 😀
					</SendingGreyTitleStyled>
					<SendingGreyPStyled>
						Veja abaixo a{multipleInstallments && 's'} oferta
						{multipleInstallments && 's'} que temos para você:
					</SendingGreyPStyled>
				</>
			) : (
				multipleInstallments && (
					<SendingGreyPStyled>Selecione abaixo as parcelas:</SendingGreyPStyled>
				)
			)}
			<InstallmentListStyled data-testid="installment-list">
				{installments?.length ? list : null}
			</InstallmentListStyled>
		</CenterDivStyled>
	);
};

export default InstallmentsOptions;
