import { useEffect, useLayoutEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import dateFormat from 'dateformat';

import {
	MainStyled,
	ContainerStyled,
	AStyled,
	DownloadDivStyled,
	GapDivStyled,
	AiOutlineArrowLeftStyled,
	TileStyled,
	SuccessButtonStyled,
	CenterDivStyled,
	LightButtonStyled,
	DangerOrOrangeStyled,
	TableStyled,
	TemplateDivStyled,
	GapVertDivStyled,
	SuccessTitleStyled,
	DivStyled,
	VertDivStyled,
	PaddingPStyled,
	TopDivStyled,
	AmountPStyled,
	IoLogoWhatsappStyled,
	TemplateMobileDivStyled,
	AncStyled,
	AroundDivStyled,
	OrderPStyled,
	MarginTopPStyled,
	AcordoTitleStyled,
	AcordoDivStyled,
	AcordoMobileDivStyled,
} from './styles';

import { VirtusNavbar, Button } from '../../shared/components/index';

import OrderController from './controllers/orderController';

import { displayStatus } from '../../shared/utils/displayStatus';
import { moneyMask } from '../../shared/utils/masks';
import { useLoading } from '../../hooks/loading';
import { Desktop2DivStyledTop } from './components/OrderTile/styles';

export const OrderDetails = () => {
	const [statusLetter, setStatusLetter] = useState();
	const [status, setStatus] = useState();
	const [totalAmount, setTotalAmount] = useState();
	const [description, setDescription] = useState();
	const [installment, setInstallment] = useState();
	const [store, setStore] = useState();
	const [interest, setInterest] = useState();
	const [paidInstallments, setPaidInstallments] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [updatedAt, setUpdatedAt] = useState();
	const [ccbLink, setCcbLink] = useState();
	const [signature, setSignature] = useState();
	const [receivables, setReceivables] = useState();
	const [acordos, setAcordos] = useState([]);
	const [orderRef, setOrderRef] = useState(null);
	const [customerEmail, setCustomerEmail] = useState();

	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { setIsLoading } = useLoading();

	useEffect(() => {
		getOrder();
		// eslint-disable-next-line
	}, []);

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	const controller = new OrderController();

	const getOrder = async () => {
		const order = await controller.get(id);

		const ccbLinkResponse = controller.getCCBLink(order.links);

		setIsLoading(false);
		setStatusLetter(order.status);
		setStatus(order.get_status_for_taker);
		setTotalAmount(order.total_amount);
		setDescription(order.description);
		setInstallment(order.installment);
		setStore(order.company_pop);
		setInterest(order.interest);
		setPaidInstallments(order.paid_receivables);
		setCreatedAt(order.created_at);
		setUpdatedAt(order.updated_at);
		setCcbLink(ccbLinkResponse);
		setSignature(order.signature);
		setReceivables(order.receivables);
		setAcordos(order.acordos);
		setOrderRef(order.order_ref);
		setCustomerEmail(order.customer.email);

		if (
			order.get_status_for_taker !== 'Aprovada' &&
			order.get_status_for_taker !== 'Efetivada'
		)
			return history.push('/taker/order');
	};

	const renderStatusLabel = (status: string, link: URL) => {
		if (status === 'PAG') {
			return (
				<CenterDivStyled>
					<SuccessButtonStyled disabled>Paga</SuccessButtonStyled>
				</CenterDivStyled>
			);
		} else if (status === 'CAN') {
			return (
				<CenterDivStyled>
					<LightButtonStyled disabled>Acordo</LightButtonStyled>
				</CenterDivStyled>
			);
		}

		const displayStatus = status === 'VEN' ? 'Pagar Agora!' : 'Pagar';
		const displayHint = status === 'VEN' ? 'Vencida' : 'Em aberto';
		const displayColor = status === 'VEN' ? 'hsl(348, 100%, 61%)' : 'orange';

		return (
			<CenterDivStyled>
				<DangerOrOrangeStyled
					onClick={() => {
						window.open(link, '__blank');
						window.focus();
					}}
					style={{ backgroundColor: `${displayColor}` }}
				>
					{displayStatus}
				</DangerOrOrangeStyled>
				<small>{displayHint}</small>
			</CenterDivStyled>
		);
	};

	const renderReceivables = (receivables: any, installment: any) => {
		if (!receivables) return false;

		const receivablesList = [];

		for (let index = 0; index < receivables.length; index++) {
			if (
				receivables[index].installment === 1 &&
				receivables[index].status !== 'PAG' &&
				receivables[index].receivable_type === 'TRA'
			)
				continue;

			receivablesList.push(
				<tr key={receivables[index].due_date}>
					<td style={{ verticalAlign: 'middle' }}>
						<b>
							{receivables[index].installment} de {installment}{' '}
						</b>
					</td>

					<td style={{ verticalAlign: 'middle' }}>
						<b>
							{dateFormat(
								receivables[index].due_date,
								'dd/mm/yyyy',
								true,
								false
							)}{' '}
						</b>
					</td>

					<td style={{ verticalAlign: 'middle' }}>
						{renderStatusLabel(
							receivables[index].status,
							receivables[index].link
						)}
					</td>
				</tr>
			);
		}

		return receivablesList;
	};
	// eslint-disable-next-line
	const renderPendencyButton = () => {
		if (
			(statusLetter === 'A' || statusLetter === 'E' || statusLetter === 'N') &&
			!signature
		) {
			return (
				<Button
					text="Assinar contrato"
					onClick={() => history.push(`/taker/order/${id}/signature`)}
				/>
			);
		}
		return <Button text="Documentos Adicionais" />;
	};

	const renderInstallments = (receivables: any, installment: any) => {
		return (
			<CenterDivStyled>
				<TableStyled>
					<thead>
						<tr>
							<th>Parcela</th>

							<th>Data de vencimento</th>

							<th>Status</th>
						</tr>
					</thead>

					<tbody>{renderReceivables(receivables, installment)}</tbody>
				</TableStyled>
			</CenterDivStyled>
		);
	};

	const renderAcordos = () => {
		if (acordos?.length <= 0) return '';

		return (
			<CenterDivStyled>
				<CenterDivStyled>
					<GapVertDivStyled />
				</CenterDivStyled>

				<AcordoTitleStyled>Acordos</AcordoTitleStyled>

				{acordos?.map((acordo: any) => {
					return !acordo?.receivables ? (
						<></>
					) : (
						<>
							<AcordoDivStyled>
								<p>
									Acordo: <b>{acordo.acordo}</b>
								</p>

								<p>
									Status: <b>{displayStatus(acordo.status)}</b>
								</p>

								<p>
									Criado em:{' '}
									<b>
										{dateFormat(acordo.created_at, 'dd/mm/yyyy', true, false)}
									</b>
								</p>

								<p>
									Parcelas acordadas: <b>{acordo.parcelas_acordadas}</b>
								</p>

								<p>
									Parcelas geradas: <b>{acordo.parcelas_geradas}</b>
								</p>
							</AcordoDivStyled>

							<AcordoMobileDivStyled>
								<p>
									Acordo: <b>{acordo.acordo}</b>
								</p>

								<p>
									Status: <b>{acordo.status}</b>
								</p>

								<p>
									Criado em:{' '}
									<b>
										{dateFormat(acordo.created_at, 'dd/mm/yyyy', true, false)}
									</b>
								</p>

								<p>
									Parcelas acordadas: <b>{acordo.parcelas_acordadas}</b>
								</p>

								<p>
									Parcelas geradas: <b>{acordo.parcelas_geradas}</b>
								</p>
							</AcordoMobileDivStyled>

							{renderInstallments(acordo.receivables, acordo.parcelas_geradas)}

							<CenterDivStyled>
								<GapVertDivStyled />
							</CenterDivStyled>
						</>
					);
				})}
			</CenterDivStyled>
		);
	};

	const displayStore = (store: any) => {
		return store
			?.replace(' Marketplace', '')
			.replace(' PagarMe', '')
			.replace(' Pagamentos', '')
			.replace(' Ame', '');
	};

	const renderOrderInfo = () => {
		return (
			<CenterDivStyled>
				<TileStyled>
					<TemplateDivStyled>
						<CenterDivStyled>
							<SuccessTitleStyled>{status}</SuccessTitleStyled>
						</CenterDivStyled>

						<DivStyled>
							<GapDivStyled />

							<PaddingPStyled>
								Loja: <b>{displayStore(store)}</b>
								<br />
								Produto: <b>{description}</b>
								<br />
								Nº Pedido: <b>{orderRef}</b>
								<br />
								<br />
								Total de parcelas pagas:{' '}
								<b>
									{paidInstallments}/{installment}
								</b>
								<br />
								Juros: <b>{interest}% a.m.</b>
								<br />
								<br />
								Criado em: <b>{dateFormat(createdAt, 'dd/mm/yyyy')}</b>
								<br />
								Atualizado em: <b>{dateFormat(updatedAt, 'dd/mm/yyyy')}</b>
								{renderCCbLink()}
							</PaddingPStyled>
						</DivStyled>

						<TopDivStyled>
							<AmountPStyled>
								{totalAmount ? moneyMask(totalAmount) : ''}
							</AmountPStyled>
						</TopDivStyled>

						<Desktop2DivStyledTop>
							{statusLetter === 'E' ? (
								<AncStyled
									target="_blank"
									rel="noreferrer"
									href="https://b.link/virtuspay"
								>
									<IoLogoWhatsappStyled size={27} />
									Fale Conosco
								</AncStyled>
							) : statusLetter === 'A' ? (
								<p>
									Oba! Seu parcelamento foi aprovado!
									<br />
									<br />
									Enviamos o seu primeiro pagamento para {customerEmail}.
									Efetue o pagamento o quanto antes para concluir o seu pedido.
								</p>
							) : (
								''
							)}
						</Desktop2DivStyledTop>
					</TemplateDivStyled>

					<TemplateMobileDivStyled>
						<AroundDivStyled>
							<SuccessTitleStyled>{status}</SuccessTitleStyled>
							<VertDivStyled />
							<div>
								<CenterDivStyled>
									<AmountPStyled>
										{totalAmount ? moneyMask(totalAmount) : ''}
									</AmountPStyled>
								</CenterDivStyled>
							</div>
						</AroundDivStyled>

						<MarginTopPStyled>
							{statusLetter === 'E' ? (
								<AncStyled
									target="_blank"
									rel="noreferrer"
									href="https://b.link/virtuspay"
								>
									<IoLogoWhatsappStyled size={27} />
									Fale Conosco
								</AncStyled>
							) : statusLetter === 'A' ? (
								<p>
									Oba! Seu parcelamento foi aprovado!
									<br />
									<br />
									Enviamos o seu primeiro pagamento para {customerEmail}.
									Efetue o pagamento o quanto antes para concluir o seu pedido.
								</p>
							) : (
								''
							)}
						</MarginTopPStyled>

						<MarginTopPStyled>
							Loja: <b>{displayStore(store)}</b>
							<br />
							Produto: <b>{description}</b>
							<br />
							Nº Pedido: <b>{orderRef}</b>
						</MarginTopPStyled>

						<MarginTopPStyled>
							Total de parcelas pagas:{' '}
							<b>
								{paidInstallments}/{installment}
							</b>
							<br />
							Juros: <b>{interest}% a.m.</b>
						</MarginTopPStyled>

						<MarginTopPStyled>
							Criado em: <b>{dateFormat(createdAt, 'dd/mm/yyyy')}</b>
							<br />
							Atualizado em: <b>{dateFormat(updatedAt, 'dd/mm/yyyy')}</b>
						</MarginTopPStyled>

						<OrderPStyled>{renderCCbLink()}</OrderPStyled>
					</TemplateMobileDivStyled>

					<CenterDivStyled>
						<GapVertDivStyled />
					</CenterDivStyled>

					{renderInstallments(receivables, installment)}

					{renderAcordos()}
				</TileStyled>
			</CenterDivStyled>
		);
	};

	const renderCCbLink = () => {
		if (ccbLink !== undefined) {
			return (
				<DownloadDivStyled>
					<span>
						<b>Cédula de Crédito Bancário:</b>{' '}
						<AStyled rel="noreferrer" target="_blank" href={ccbLink}>
							Download
						</AStyled>
					</span>
				</DownloadDivStyled>
			);
		}
	};

	return (
		<MainStyled>
			<VirtusNavbar title="Minha conta" link="/user" />

			<ContainerStyled>
				<AiOutlineArrowLeftStyled
					size={33}
					onClick={() => history.push('/taker/order')}
				/>
			</ContainerStyled>

			{renderOrderInfo()}
		</MainStyled>
	);
};
