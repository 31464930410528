import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const TileStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 1.5rem 0;
	width: 100%;
	border-radius: 20px;
	background: #fefefe;
	color: #707070;
	overflow: hidden;
	user-select: none;
	padding: 40px 35px;
	box-shadow: 0px 0px 6px rgba(10, 10, 10, 0.1);
	@media (max-width: 1000px) {
		flex-direction: column;
		justify-content: center;
	}
`;

export const MainStyled = styled.main`
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ContainerStyled = styled.div`
	width: 50%;
	min-height: 90vh;

	@media (max-width: 900px) {
		width: 80%;
	}
`;

export const TitleStyled = styled.h3`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: 500px;
	font-size: 22px;
	color: ${colors.textBlue};
	font-weight: bold;

	@media (max-width: 1000px) {
		text-align: center;
		width: 300px;
	}
`;

export const TitleCanceledStyled = styled.h3`
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 22px;
	margin-bottom: 1.5rem;
	color: ${colors.textBlue};
	font-weight: bold;

	@media (max-width: 1000px) {
		text-align: center;
	}
`;

export const PStyled = styled.p`
	text-align: right;
	display: flex;

	@media (max-width: 1000px) {
		text-align: center;
		margin-top: 1rem;
	}
`;

export const CenterDivStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const MultilineDivStyled = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const InputDivStyled = styled.div`
	width: 48%;

	@media (max-width: 1000px) {
		width: 100%;
	}
`;

export const ButtonDivStyled = styled.div`
	width: 48%;
	display: flex;
	font-weight: bold;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	color: ${colors.textBlue};
	cursor: pointer;

	@media (max-width: 1000px) {
		width: 100%;
	}
`;

export const FinalButtonStyled = styled.div`
	width: 48%;
	display: flex;
	font-weight: bold;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-top: 0.2rem;
	margin-bottom: 1rem;
	color: ${colors.textBlue};
	cursor: pointer;

	@media (max-width: 1000px) {
		width: 100%;
	}
`;

export const DivCenteredStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const PCanceledStyled = styled.p`
	text-align: center;
	margin-top: 1rem;
	font-weight: 500;
`;

export const PCanceledBoldStyled = styled.p`
	text-align: center;
	margin-top: 1rem;
	font-weight: 505;
	margin-bottom: 1rem;
`;

export const AStyled = styled.a`
	text-decoration: underline;
	text-align: center;
	margin-top: 1rem;
	font-weight: 505;
	margin-bottom: 1rem;
`;

export const ASuccessStyled = styled.a`
	text-decoration: underline;
	text-align: center;
	font-weight: 505;
	margin-top: 0.1rem;
	color: ${colors.successColor};
`;

export const SelectStyled = styled.select`
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 4px;
	padding: 14px 20px;
	font-size: 0.85rem;
	width: 100%;
	background-color: white;
	margin-bottom: 10px;
`;
