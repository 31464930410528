import ValidatorRepository from '../repositories/validatorRepository';

export default class ValidatorController {
	repository = new ValidatorRepository();

	async validateEmail(email: string) {
		const result = await this.repository.validateEmail(email);

		return result;
	}

	async validateCPF(cpf: string) {
		const result = await this.repository.validateCPF(cpf);

		return result;
	}
}
