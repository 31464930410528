import {
	ContainerStyled,
	ContainerLogoVirtusPayPartner,
	ImgLogoPartner,
	ImgLogoVirtusPay,
} from './styles';

interface OrderNavbarProps {
	src: string;
	className?: string;
	partnerLogo?: string;
}

const OrderNavbar = ({ src, partnerLogo }: OrderNavbarProps) => {
	return (
		<ContainerStyled>
			{partnerLogo ? (
				<ContainerLogoVirtusPayPartner>
					<ImgLogoPartner src={partnerLogo} alt="Logo Parceiro" />
					<ImgLogoVirtusPay src={src} alt="Logo VirtusPay" />
				</ContainerLogoVirtusPayPartner>
			) : (
				<img src={src} alt="Logo VirtusPay" style={{ margin: '1rem auto' }} />
			)}
		</ContainerStyled>
	);
};

export default OrderNavbar;
