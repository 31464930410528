import * as Yup from 'yup';
import { formatBilletLine } from '../utils/formatBillet';

export const OrderSendingFormSchema = Yup.object().shape({
	billet: Yup.string()
		.test('is-valid-billet', 'Não é', async (value) => {
			if (!value) return false;

			const textLine = formatBilletLine(value as string);

			if (!textLine?.length) {
				return false;
			}

			return true;
		})
		.required(),
	dueDate: Yup.string().required(),
	amount: Yup.string().required(),
	amountCoupon: Yup.string().notRequired(),
	tickedFile: Yup.string().notRequired(),
	category: Yup.string().required(),
	otherCategory: Yup.string().notRequired(),
	store: Yup.string().required(),
	coupon: Yup.string().notRequired(),
	installment: Yup.string().notRequired(),
});
