import styled from 'styled-components';

export const ContainerStyled = styled.main`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const TitleStyled = styled.h2`
	color: #28c0cd;
	font-weight: 700;
	text-align: center;
	font-size: 2rem;
`;

export const ParagraphStyled = styled.h2`
	font-weight: 500;
	text-align: center;
	margin: 1rem 0;
`;
