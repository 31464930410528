import { useState, useEffect, useLayoutEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
	ContainerNoTileStyled,
	TitleStyled,
	MainStyled,
	CenterDivStyled,
	ArrowDivStyled,
	CouponsDivStyled,
	TitleCouponStyled,
	CupomStyled,
	RowDivStyled,
} from './styles';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { couponsController } from './controller';

import { VirtusNavbar } from '../../shared/components/index';

import { moneyMask } from '../../shared/utils/masks';

import Cupom from '../../assets/images/home/cupom2.png';

import { CouponTile } from './components/CouponTile/index';
import { useLoading } from '../../hooks/loading';

export const CouponList = () => {
	const [otherCoupon, setOtherCoupons] = useState('');

	const history = useHistory();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	// eslint-disable-next-line
	useEffect(() => {
		getCoupons();
	}, []);

	const getCoupons = async () => {
		const { result } = await couponsController();

		const userCoupons =
			result.other_coupons &&
			result.other_coupons.map((item: any) => {
				return (
					<CouponTile
						key={item.coupon}
						coupon={item.coupon}
						dute={item.validated_date}
						// haveShareButton={false}
						// textButton="Usar cupom"
						textButton="Utilizar cupom"
						text={
							<>
								Use o cupom e ganhe até <b />
								<b>{moneyMask(item.max_discount)}</b> e desconto no seu
								parcelamento
							</>
						}
					/>
				);
			});

		setIsLoading(false);
		setOtherCoupons(userCoupons);

		// Outros cupons, irá ser usado futuramente
		// const otherCoupons = result.other_coupons.map((item: any) => {
		// 	return (
		// 		<CouponTile
		// 			key={item.coupon}
		// 			coupon={item.coupon}
		// 			haveShareButton={true}
		// 			textButton="Usar Cupom"
		// 			functionButton={() => copyCoupon(item.coupon)}
		// 			text={<>Use o cupom e ganhe desconto no seu parcelamento</>}
		// 		/>
		// 	);
		// });
		// setOtherCoupons(otherCoupons);
	};

	// const renderUserCoupons = () => {
	// 	if (userCoupon?.length < 1) {
	// 		return (
	// 			<ContainerNoTileStyled>
	// 				<TitleStyled>
	// 					Não possuimos cupons válidos para compartilhar :(
	// 				</TitleStyled>
	// 			</ContainerNoTileStyled>
	// 		);
	// 	}

	// 	return userCoupon;
	// };

	const renderOtherCoupons = () => {
		if (otherCoupon?.length < 1) {
			return (
				<ContainerNoTileStyled>
					<TitleStyled>Não possuimos cupons válidos para você :(</TitleStyled>
				</ContainerNoTileStyled>
			);
		}

		return otherCoupon;
	};

	return (
		<MainStyled>
			<VirtusNavbar title="Minha conta" link="/user" />

			<ArrowDivStyled>
				<AiOutlineArrowLeft onClick={() => history.push('/taker')} />
			</ArrowDivStyled>
			<RowDivStyled>
				<CupomStyled alt="Ícone de cupom" src={Cupom} />
				<TitleCouponStyled style={{ textAlign: 'start', marginLeft: '0.3rem' }}>
					Cupons para usar e economizar
				</TitleCouponStyled>
			</RowDivStyled>
			<CenterDivStyled>
				<CouponsDivStyled>{renderOtherCoupons()}</CouponsDivStyled>
			</CenterDivStyled>
		</MainStyled>
	);
};
