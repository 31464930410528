import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Image from '../../assets/Companies/Grupo-258.png';
import PhoneComo from '../../assets/Companies/phone_landing.png';
import OK from '../../assets/Companies/icon_ok.svg';
import Shop from '../../assets/Companies/shop.png';
import Shop1 from '../../assets/Companies/shop-1.png';
import OKBlue from '../../assets/Companies/icon_ok_blue.svg';
import VTex from '../../assets/Companies/vtex.png';
import Magento from '../../assets/Companies/magento.png';
import WCommerce from '../../assets/Companies/woo.commerce.png';
import WStore from '../../assets/Companies/wap.store.png';
import Allya from '../../assets/Companies/allya.png';
import Mecanico from '../../assets/Companies/logo_loja_do_mecanico.png';
import Viajanet from '../../assets/Companies/logo_viajanet.png';
import PhotoForm from '../../assets/Companies/photo_contact .png';

import BoletoDD from '../../../../assets/images/landingPage/icons/boleto_dd.png';
import LogoPix from '../../../../assets/images/landingPage/icons/logo-pix.png';
import Boleto from '../../../../assets/images/landingPage/icons/boleto.png';
import Checklist from '../../../../assets/images/landingPage/icons/checklist.png';
import Fones from '../../../../assets/images/landingPage/icons/fones-de-ouvido.png';
import Ligaçao from '../../../../assets/images/landingPage/icons/ligacao.png';
import Smartphone from '../../../../assets/images/landingPage/icons/smartphone.png';

import { Header } from '../../shared/components/Header';
import { Cards } from '../../shared/components/Card';
import { SearchStore } from '../../shared/components/SearchStore';
import { Social } from '../../shared/components/Social';
import { Footer } from '../../shared/components/Footer';
import { CopyRight } from '../../shared/components/Copyright';

import { ButtonDivStyled, DivForm } from './styles';

export function LandingPageCompanies() {
	const navLinks = [
		{
			href: '#como',
			text: 'Como funciona?',
		},
		{
			href: 'https://blog.virtuspay.com.br/',
			text: 'Blog',
		},
	];

	const product = [
		{
			img: BoletoDD,
			titleCard: 'Boleto Parcelado',
			text: 'Risco 100% VirtusPay. Incremento de vendas e aumento de ticket médio para um novo público ',
			width: '70px',
			widthDiv: 'is-3-desktop',
		},
		{
			img: LogoPix,
			titleCard: 'PIX',
			text: 'Aumento de conversão com transferência instantânea',
			width: '70px',
			widthDiv: 'is-3-desktop',
		},
		{
			img: Boleto,
			titleCard: 'Boleto à vista',
			text: 'Solução de boleto à vista pela VirtusPay, facilitando a gestão dos seus meios de pagamento',
			width: '70px',
			widthDiv: 'is-3-desktop',
		},
	];

	const canais = [
		{
			img: Checklist,
			titleCard: 'Integração via Checkout',
			text: 'Para vendas no e-commerce',
			width: '57px',
			widthDiv: 'is-2-desktop',
		},
		{
			img: Fones,
			titleCard: 'Plataforma de televendas',
			text: 'Para vendas de balcão e telemarketing',
			width: '57px',
			widthDiv: 'is-2-desktop',
		},
		{
			img: Ligaçao,
			titleCard: 'Link de Pagamento',
			text: 'Para vendas no WhatsApp e redes sociais',
			width: '57px',
			widthDiv: 'is-2-desktop',
		},

		{
			img: Smartphone,
			titleCard: 'Aplicativo White label',
			text: 'Jornada mundo físico de crédito e compras simultâneas',
			width: '57px',
			widthDiv: 'is-2-desktop',
		},
	];

	const title = (
		<h1 className="is-size-3-desktop mb-3 pt-4 is-size-4-mobile is-size-5-tablet has-text-weight-normal has-text-centered-mobile	has-text-white">
			Solução de pagamento <br />e checkout para
			<span className="has-text-weight-semibold"> aumentar </span> <br />
			<b className="has-text-weight-semibold">suas vendas!</b>
		</h1>
	);

	const subTitle = (
		<p className="has-text-white my-2 pb-3 has-text-centered-mobile is-size-7-mobile">
			Com nossas soluções de vendas sua empresa <br />
			<b className="has-text-weight-semibold">
				aumentará seu faturamento sem nenhum risco
				<br />
			</b>
			e com facilidade de gestão!
		</p>
	);

	const buttonsTouch = (
		<ButtonDivStyled className="is-flex py-5 pb-6 is-justify-content-center is-hidden-desktop">
			<a href="#contact" className="is-10 button button-primary">
				<p className="has-text-weight-semibold">Tenho interesse!</p>
			</a>
		</ButtonDivStyled>
	);

	const buttonsDesktop = (
		<ButtonDivStyled className="is-hidden-touch pb-5">
			<a
				href="#contact"
				className="is-6 has-text-centered-mobile button button-primary"
			>
				<p className="has-text-weight-semibold">Tenho interesse!</p>
			</a>
		</ButtonDivStyled>
	);

	return (
		<>
			<Header
				whichScreen="companies"
				secondaryImage={Image}
				classNameTitleDiv="is-offset-1"
				navLinks={navLinks}
				title={title}
				subTitle={subTitle}
				buttonsTouch={buttonsTouch}
				buttonsDesktop={buttonsDesktop}
				textColor="#ffffff"
			/>

			<section id="como" className="column my-5 py-6 is-10 container">
				<div id="como" className="columns is-flex is-desktop is-5">
					<div className="column is-6-desktop mt-6 is-offset-1 is-align-self-baseline mb-0 pb-0 is-hidden-touch">
						<img
							style={{ width: '80%' }}
							alt="Foto de celular com promoções"
							src={PhoneComo}
						/>
					</div>

					<div className="column mt-5 ml-2 is-6-desktop">
						<h1
							className="is-size-4-desktop is-size-4-mobile has-text-weight-semibold has-text-black mb-4"
							style={{ font: 'normal normal 600 35px/63px Montserrat' }}
						>
							Por que a VirtusPay?
						</h1>

						<div className="mt-5">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Venda incremental
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Aumente seu faturamento vendendo para mais pessoas
							</p>
						</div>

						<div className="mt-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Aumento de ticket médio
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Aumente em média 30% o seu ticket médio
							</p>
						</div>

						<div className="mt-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Originação de tráfego
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Originação de tráfego novo e qualificado para seu site
							</p>
						</div>

						<div className="mt-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Experiência digital
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Experiência 100% digital e sustentável
							</p>
						</div>

						<div className="mt-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Pré-Aprovação de crédito
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Maior taxa de aprovação
							</p>
						</div>

						<div className="mt-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								Parcelamento competitivo
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Parcelamento mais competitivo do mercado
							</p>
						</div>

						<div className="my-4">
							<p className="is-size-6 has-text-weight-bold is-12 has-text-black">
								<img className="lazyload is-size-4 pr-2" src={OK} alt="" />
								RBP
							</p>
							<p className="has-text-white is-size-7 is-12 pl-3 ml-5 has-text-weight-normal has-text-black">
								Oferta de juros e parcelamento customizados <br /> por
								consumidor
							</p>
						</div>

						<div className="mt-6 ml-5 is-flex is-align-self-center mb-0 pb-0 is-hidden-desktop">
							<img
								style={{ width: '85%' }}
								src={PhoneComo}
								alt="Foto de celular com promoções"
							/>
						</div>
					</div>
				</div>
			</section>

			<section id="produtos">
				<Cards
					margin="is-8-desktop is-10-mobile"
					title="Produtos"
					background="has-background-light"
					product={product}
				/>
			</section>

			<section id="produtos">
				<Cards
					margin="is-8-desktop"
					title="Canais de Vendas"
					product={canais}
				/>
			</section>

			<section id="Lojas Parceiras">
				<SearchStore
					margin="is-8-desktop"
					titleColor="has-text-white"
					title="Nossos Parceiros"
					type="parceiro"
					color="#5A69AA"
				/>
			</section>

			<div id="modelo de negocio">
				<section className="column my-5 py-6 is-8 container">
					<h2
						className="has-text-black is-12 mt-3 mb-6 has-text-left is-size-4 has-text-weight-semibold"
						style={{ font: 'normal normal 600 35px/63px Montserrat' }}
					>
						Modelos de negócio
					</h2>

					<div className="columns mx-0 px-0 mt-4 container is-multiline is-flex is-hidden-touch is-flex-direction-row container is-justify-content-space-between">
						<div
							className="column is-6-desktop mt-2 is-12-mobile"
							style={{
								background: '#FAFAFA 0% 0% no-repeat padding-box',
								borderRadius: '24px',
								opacity: '1',
								width: '49%',
							}}
						>
							<div className="is-flex is-align-content-center is-justify-content-center is-align-self-center is-align-items-center">
								<img
									className="lazyload mt-4 "
									style={{ width: '40px' }}
									src={Shop1}
									alt=""
								/>
								<p
									className="is-size-6 mt-5 ml-1 has-text-weight-normal"
									style={{ color: '#5A69AA;' }}
								>
									Médias e grandes empresas
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-6 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Integração direta ou através das maiores plataformas de
									e-commerce
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Faturamento mínimo de 8 milhões por ano (667 mil mensal)
								</p>
							</div>

							<div style={{ height: '36px;' }}>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 mb-4 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Ticket médio acima de 150 reais
								</p>
							</div>

							<div className="">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Produto Boleto Parcelado, Boleto à vista, PIX
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Equipe de atendimento direto para gestão da operação e
									crescimento
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Desenvolvimento de estratégia e plano de comunicação em
									conjunto
								</p>
							</div>

							<div className="mb-6">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Preço sob consulta
								</p>
							</div>
						</div>

						<div
							className="column is-6-desktop mt-2 is-12-mobile"
							style={{
								background: '#FAFAFA 0% 0% no-repeat padding-box',
								borderRadius: '24px',
								opacity: '1',
								width: '49%',
							}}
						>
							<div className="is-flex is-align-content-center is-justify-content-center is-align-self-center is-align-items-center">
								<img
									className="lazyload mt-3 "
									style={{ width: '52px' }}
									src={Shop}
									alt=""
								/>
								<p
									className="is-size-6 mt-5 ml-2 has-text-weight-normal"
									style={{ color: '#5A69AA;' }}
								>
									Pequenas empresas
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-6 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Sem integração - indicação do BoletoPOP da VirtusPay
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Faturamento mínimo de 180 mil por ano (15 mil mensal)
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Ticket médio acima de 150 reais; Checkout no próprio site com
									boleto à vista; Tempo mínimo de empresa ativa 12 meses
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Produto Boleto Parcelado
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Atendimento automatizado por chamado via email
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Enxoval de comunicação padrão fornecido pela VirtusPay
								</p>
							</div>

							<div className="mb-6">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Taxa de serviço de 8,0% sobre vendas efetivadas com repasse em
									D+2
								</p>
							</div>
						</div>
					</div>

					<div className="columns mx-0 px-0 mt-4 container is-multiline is-hidden-desktop is-flex is-align-content-center is-justify-content-center is-align-self-center is-align-items-center">
						<div
							className="column mt-2 is-11-mobile"
							style={{
								background: '#FAFAFA 0% 0% no-repeat padding-box',
								borderRadius: '24px',
								opacity: '1',
							}}
						>
							<div className="is-flex is-align-content-center is-justify-content-center is-align-self-center is-align-items-center">
								<img
									className="lazyload mt-4 "
									style={{ width: '40px' }}
									src={Shop1}
									alt=""
								/>
								<p
									className="is-size-6 mt-5 ml-1 has-text-weight-normal"
									style={{ color: '#5A69AA' }}
								>
									Médias e grandes empresas
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-6 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Integração direta ou através das maiores plataformas de
									e-commerce
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Faturamento mínimo de 8 milhões por ano (667 mil mensal)
								</p>
							</div>

							<div style={{ height: '36px;' }}>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 mb-4 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Ticket médio acima de 150 reais
								</p>
							</div>

							<div className="mt-6">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Produto Boleto Parcelado, Boleto à vista, PIX
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Equipe de atendimento direto para gestão da operação e
									crescimento
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Desenvolvimento de estratégia e plano de comunicação em
									conjunto
								</p>
							</div>

							<div className="mb-6">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Preço sob consulta
								</p>
							</div>
						</div>

						<div
							className="column mt-2 is-11-mobile"
							style={{
								background: '#FAFAFA 0% 0% no-repeat padding-box',
								borderRadius: '24px',
								opacity: '1',
							}}
						>
							<div className="is-flex is-align-content-center is-justify-content-center is-align-self-center is-align-items-center">
								<img
									className="lazyload mt-3 "
									style={{ width: '52px;' }}
									src={Shop}
									alt=""
								/>
								<p
									className="is-size-6 mt-5 ml-2 has-text-weight-normal"
									style={{ color: '#5A69AA' }}
								>
									Pequenas empresas
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-6 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Sem integração - indicação do BoletoPOP da VirtusPay
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Faturamento mínimo de 180 mil por ano (15 mil mensal)
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Ticket médio acima de 150 reais; Checkout no próprio site com
									boleto à vista; Tempo mínimo de empresa ativa 12 meses
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Produto Boleto Parcelado
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Atendimento automatizado por chamado via email
								</p>
							</div>

							<div>
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Enxoval de comunicação padrão fornecido pela VirtusPay
								</p>
							</div>

							<div className="mb-6">
								<p className="is-size-6 has-text-weight-normal ml-2 mt-4 is-12 has-text-black">
									<img
										className="lazyload is-size-6 ml-2 pr-2"
										style={{ width: '30px;' }}
										src={OKBlue}
										alt=""
									/>
								</p>
								<p
									className="is-size-6 has-text-weight-normal ml-6 is-12 has-text-black"
									style={{
										marginTop: '-21px',
										marginLeft: '10px',
										font: 'normal normal normal 18px Montserrat',
									}}
								>
									Taxa de serviço de 8,0% sobre vendas efetivadas com repasse em
									D+2
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div id="plataformas" className="has-background-light">
				<section className="column my-5 py-6 is-8 container ">
					<h2
						className="has-text-black is-12 mt-3 mb-6 has-text-left is-size-4 has-text-weight-semibold"
						style={{ font: 'normal normal 600 35px/63px Montserrat;' }}
					>
						Plataformas já integradas
					</h2>

					<div className="columns is-hidden-touch is-12 col-12 mt-3 is-flex flex align-items-center is-justify-content-space-between">
						<div className="column">
							<a
								href="https://partnerportal.vtex.com/virtuspay/p"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={VTex}
									className="mb-3"
									width="190px"
									alt="Documentação Magento"
									data-aos="fade-up"
									data-aos-delay="300"
								/>
							</a>
						</div>

						<div className="column">
							<a href=" " target="_blank" rel="noreferrer">
								<img
									src={Magento}
									className="mb-3"
									width="190px"
									alt="Documentação Magento"
									data-aos="fade-up"
									data-aos-delay="300"
								/>
							</a>
						</div>

						<div className="column">
							<a href=" " target="_blank" rel="noreferrer">
								<img
									src={WCommerce}
									className="mb-3"
									width="190px"
									alt="Documentação Woo commerce"
									data-aos="fade-up"
									data-aos-delay="300"
								/>
							</a>
						</div>

						<div className="column">
							<a href=" " target="_blank" rel="noreferrer">
								<img
									src={WStore}
									className="mb-3"
									width="190px"
									alt="Documentação Wap Store"
									data-aos="fade-up"
									data-aos-delay="300"
								/>
							</a>
						</div>
					</div>

					<div className="is-hidden-desktop">
						<OwlCarousel
							className="owl-carousel owl-theme"
							items={1}
							dots={false}
							autoplay={true}
						>
							<div className="column item">
								<a
									href="https://partnerportal.vtex.com/virtuspay/p"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={VTex}
										className="mb-3"
										alt="Documentação Magento"
										data-aos="fade-up"
										data-aos-delay="300"
									/>
								</a>
							</div>

							<div className="column item">
								<a href=" " target="_blank" rel="noreferrer">
									<img
										src={Magento}
										className="mb-3"
										alt="Documentação WooCommerce"
										data-aos="fade-up"
										data-aos-delay="300"
									/>
								</a>
							</div>

							<div className="column item">
								<a href=" " target="_blank" rel="noreferrer">
									<img
										src={WCommerce}
										className="mb-3"
										alt="Documentação SAP Hybris"
										data-aos="fade-up"
										data-aos-delay="300"
									/>
								</a>
							</div>

							<div className="column item">
								<a href=" " target="_blank" rel="noreferrer">
									<img
										src={WStore}
										className="mb-3"
										alt="Documentação SAP Hybris"
										data-aos="fade-up"
										data-aos-delay="300"
									/>
								</a>
							</div>
						</OwlCarousel>
					</div>

					<h2
						className="has-text-black is-12 mt-6 mb-6 has-text-left is-size-4 has-text-weight-semibold"
						style={{ font: 'normal normal 600 35px/63px Montserrat;' }}
					>
						Parcerias homologadas
					</h2>

					<div className="column is-hidden-touch is-12 col-12 mt-3 is-flex flex align-items-center is-justify-content-space-between">
						<a href=" " target="_blank" rel="noreferrer">
							<img
								src={Allya}
								className="mb-3"
								width="190px"
								alt="Documentação Magento"
								data-aos="fade-up"
								data-aos-delay="300"
							/>
						</a>
					</div>

					<div className="is-hidden-desktop">
						<OwlCarousel
							className="owl-carousel owl-theme"
							items={1}
							dots={false}
							autoplay={true}
						>
							<div className="column item">
								<a href=" " target="_blank" rel="noreferrer">
									<img
										src={Allya}
										className="mb-3"
										width="190px"
										alt="Documentação Magento"
										data-aos="fade-up"
										data-aos-delay="300"
									/>
								</a>
							</div>
						</OwlCarousel>
					</div>
				</section>
			</div>

			<div id="depoimentos">
				<section className="column is-multiline mt-5 mb-1 py-6 is-8 container">
					<h2
						className="has-text-black is-12 mt-3 mb-3 has-text-left is-size-4 has-text-weight-semibold"
						style={{ font: 'normal normal 600 35px/63px Montserrat' }}
					>
						Depoimentos
					</h2>

					<div className="columns is-hidden-touch is-flex is-flex-direction-center container  mt-5 is-12">
						<OwlCarousel className="owl-carousel owl-theme ">
							<div
								className="column is-4 is-5-desktop is-flex is-justify-content-center is-flex-direction-column"
								style={{ width: '240px' }}
							>
								<img
									className="lazyload mt-4 is-justify-content-start"
									style={{ width: '90px' }}
									src={Viajanet}
									alt=""
								/>
								<p className="is-size-7 is-12 mt-4 has-text-weight-normal has-text-black">
									&lsquo;Nós do ViajaNet estamos muito felizes com nossa
									parceria com a VirtusPay, agora conseguimos ofertar aos nossos
									clientes mais uma opção de pagamento vantajosa, com liberdade
									e comodidade na hora de escolher suas passagens aéreas,
									podendo parcelar em até 24x, sem cartão!&lsquo;
								</p>
							</div>

							{/* <div className="column is-4 is-5-desktop is-flex is-justify-content-center is-flex-direction-column" style={{width: "240px"}}>
          <img className="lazyload mt-3 is-justify-content-start" style={{width: "90px"}} src="{% static 'images/logos_parceiros/logo_c_c.png' %}" alt="" />
          <p className="is-size-7 is-12 mt-3 has-text-weight-normal has-text-black">
            "Minha experiência foi simples e tranquila. Achei o site objetivo e as instruções deixaram o processo bem claro para mim."
          </p>
        </div>  */}

							{/* <div className="column is-4 is-5-desktop is-flex is-justify-content-start is-flex-direction-column" style={{width: "240px"}}>
          <img className="lazyload  mt-3 is-justify-content-start" style={{width: "90px"}} src="{% static 'images/logos_parceiros/logo_sono_show.png' %}" alt="" />
          <p className="is-size-7 is-12 mt-4 has-text-weight-normal has-text-black">
            "VirtusPay é espetacular e rápida! O pagamento é feito direitinho antes do vencimento da fatura de forma que não dá trabalho algum e acelera o acúmulo de milhas."
          </p>
        </div>  */}

							<div
								className="column is-4 is-5-desktop is-flex is-justify-content-start is-flex-direction-column"
								style={{ width: '240px' }}
							>
								<img
									className="lazyload  mt-5 is-justify-content-start"
									style={{ width: '160px' }}
									src={Mecanico}
									alt=""
								/>
								<p className="is-size-7 is-12 mt-4 has-text-weight-normal has-text-black">
									&lsquo;A loja do Mecânico só tem agradecer a VirtusPay pela
									parceria, nossos clientes estão tendo a oportunidade de
									comprar seus produtos e realizar seu sonho de ter tão sonhada
									Máquina ou Ferramenta para o seu negócio comprando em até 24x,
									sem Cartão.&lsquo;
								</p>
							</div>
						</OwlCarousel>
					</div>

					<div className="is-hidden-desktop">
						<OwlCarousel
							className="owl-carousel owl-theme"
							items={1}
							dots={false}
							autoplay={true}
						>
							<div className="column is-4 is-12-mobile is-flex is-justify-content-center is-align-content-center is-flex-direction-column">
								<img
									className="lazyload mt-6 is-flex is-justify-content-center is-align-content-center is-align-self-center"
									style={{ width: '120px' }}
									src={Viajanet}
									alt=""
								/>
								<p className="is-size-7 is-12 mt-5 has-text-weight-normal is-flex has-text-centered is-align-items-center has-text-black">
									&lsquo;Nós do ViajaNet estamos muito felizes com nossa
									parceria com a VirtusPay, agora conseguimos ofertar aos nossos
									clientes mais uma opção de pagamento vantajosa, com liberdade
									e comodidade na hora de escolher suas passagens aéreas,
									podendo parcelar em até 24x, sem cartão!&lsquo;
								</p>
							</div>

							{/* <div className="column is-12-mobile is-4-desktop is-flex is-justify-content-center is-align-content-center is-flex-direction-column">
              <img className="lazyload mt-6 is-flex is-justify-content-center is-align-content-center is-align-self-center" style={{width: "120px"}} src="{% static 'images/logos_parceiros/logo_c_c.png' %}" alt="" />
              <p className="is-size-7 is-12 mt-4 has-text-weight-normal has-text-centered has-text-black">
                "Minha experiência foi simples e tranquila. Achei o site objetivo e as instruções deixaram o processo bem claro para mim."
              </p>
            </div>  */}

							{/* <div className="column is-12-mobile is-4-desktop is-flex is-justify-content-center is-align-content-center is-flex-direction-column">
              <img className="lazyload mt-6 is-flex is-justify-content-center is-align-content-center is-align-self-center" style={{width: "120px"}} src="{% static 'images/logos_parceiros/logo_sono_show.png' %}" alt="" />
              <p className="is-size-7 is-12 mt-4 has-text-weight-normal has-text-centered has-text-black">
                "VirtusPay é espetacular e rápida! O pagamento é feito direitinho antes do vencimento da fatura de forma que não dá trabalho algum e acelera o acúmulo de milhas."
              </p>
            </div>  */}

							<div className="column is-4 mt-5 is-12-mobile is-4-desktop is-flex is-justify-content-center is-align-content-center is-flex-direction-column">
								<img
									className="lazyload mt-6 is-flex is-justify-content-center is-align-content-center is-align-self-center"
									style={{ width: '160px' }}
									src={Mecanico}
									alt=""
								/>
								<p className="is-size-7 is-12 mt-5 has-text-weight-normal has-text-centered has-text-black">
									&lsquo;A loja do Mecânico só tem agradecer a VirtusPay pela
									parceria, nossos clientes estão tendo a oportunidade de
									comprar seus produtos e realizar seu sonho de ter tão sonhada
									Máquina ou Ferramenta para o seu negócio comprando em até 24x,
									sem Cartão.&lsquo;
								</p>
							</div>
						</OwlCarousel>
					</div>
				</section>
			</div>

			<div id="contact">
				<section className="column is-multiline mb-3 py-2 is-8 container">
					<h2
						className="has-text-black is-12 mb-3 has-text-left is-size-4 has-text-weight-semibold"
						style={{ font: 'normal normal 600 35px/63px Montserrat' }}
					>
						Quero me cadastrar
					</h2>

					<div className="columns is-flex is-justify-content-center is-align-content-center is-align-self-center">
						<div className="column is-5-desktop is-10-mobile ">
							{/* <form className="mt-6" action="https://docs.google.com/forms/d/e/1FAIpQLSfPGRxY11ZJKnSX88lF0C8G7iI8C5ifItre8IJBnn4NI0fh8A/formResponse"
                      target="_self"
                      id="bootstrapForm"
                      method="post">
                  <fieldset>
                      <legend className="mt-6 is-size-5" for="">Email</legend>
                      <div className="form-group">
                          <input id="emailAddress" type="email" className="form-control is-12" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="1286947659">Nome completo</legend>
                      <div className="form-group">
                          <input id="1342234154" type="text" name="entry.1342234154" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="2098310190">Celular/ WhatsApp</legend>
                      <div className="form-group">
                          <input id="861302610" type="text" name="entry.861302610" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="880250097">E-mail corporativo</legend>
                      <div className="form-group">
                          <input id="192767185" type="text" name="entry.192767185" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="1817663954">Nome da sua empresa</legend>
                      <div className="form-group">
                          <input id="2110924121" type="text" name="entry.2110924121" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="733310853">CNPJ da sua loja</legend>
                      <div className="form-group">
                          <input id="488110814" type="text" name="entry.488110814" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="826649675">Site da sua loja</legend>
                      <div className="form-group">
                          <input id="1518658491" type="text" name="entry.1518658491" className="form-control" required>
                      </div>
                  </fieldset>

                  <fieldset>
                      <legend className="is-size-5" for="1521764343">Segmento da sua loja</legend>
                      <div className="form-group">
                          <select id="1521764343" name="entry.1140779206" value="" className="form-control">
                              <option value="">Escolha uma opção</option>
                              <option value="Automotivo">Automotivo</option>
                              <option value="Home Center">Home Center</option>
                              <option value="Esportivo">Esportivo</option>
                              <option value="Farma">Farma</option>
                              <option value="Informática">Informática</option>
                              <option value="Belezas e Cosméticos">Belezas e Cosméticos</option>
                              <option value="Pet">Pet</option>
                              <option value="Roupas e Calçados">Roupas e Calçados</option>
                              <option value="Eletroeletrônicos">Eletroeletrônicos</option>
                              <option value="Construção e Ferramentas">Construção e Ferramentas</option>
                              <option value="Telefonia">Telefonia</option>
                              <option value="Serviços de Saúde">Serviços de Saúde</option>
                              <option value="Turismo">Turismo</option>
                              <option value="Papelaria">Papelaria</option>
                              <option value="Livraria">Livraria</option>
                              <option value="Mercado">Mercado</option>
                              <option value="Infantil">Infantil</option>
                              <option value="Indústria">Indústria</option>
                              <option value="Outro">Outro</option>
                              <option value="Educação">Educação</option>
                              <option value="Magazine/ Departamento">Magazine/ Departamento</option>
                              <option value="Casa e Decoração">Casa e Decoração</option>
                              <option value="Marketplace">Marketplace</option>
                          </select>
                      </div>
                  </fieldset>

                  <div className="column is-12 mt-4 is-flex is-justify-content-center is-align-content-center is-align-self-center">
                    <button className="button is-12 has-text-weight-semibold" type="submit" style="color: #000; background-color: #00E8EC; border: none;">Enviar</button>
                  </div>
          
                </form> */}
							<div
								className="pipedriveWebForms"
								data-pd-webforms={
									'https://webforms.pipedrive.com/f/1EjILr9PzJk5cavfB73ibYyGg9NVQbedzryuW663HB9qfSXJMC3ca8OMC1ESxpKvN'
								}
							>
								<DivForm
									src={
										'https://webforms.pipedrive.com/f/1EjILr9PzJk5cavfB73ibYyGg9NVQbedzryuW663HB9qfSXJMC3ca8OMC1ESxpKvN'
									}
									frameBorder="0"
									height="1060px"
									width="100%"
									scrolling="no"
									style={{
										border: 0,
										boxSizing: 'content-box',
										boxShadow: '0',
										padding: 0,
									}}
								/>
							</div>
						</div>

						<div className="column is-6-desktop is-offset-1 is-align-self-baseline mb-0 pb-0 is-hidden-touch">
							<img
								className="lazyload"
								alt="Duas mulheres sorrindo ao conversar"
								style={{ marginTop: '13px', width: '90%', height: '100%' }}
								src={PhotoForm}
							/>
						</div>
					</div>
				</section>
			</div>

			<div id="Social">
				<Social margin="is-8-desktop" color="#fafafa" />
			</div>

			<div id="Footer">
				<Footer margin="is-8-desktop" color="#303030" type="companies" />
			</div>

			<div id="CopyRight">
				<CopyRight
					margin="is-8-desktop"
					textColor="rgba(204, 204, 204, 0.659)"
					color="#000"
				/>
			</div>
		</>
	);
}
