import facebookLogo from '../../../../../assets/images/landingPage/facebookblack.png';
import youtubeLogo from '../../../../../assets/images/landingPage/youtubeblack.png';
import instaLogo from '../../../../../assets/images/landingPage/instablack.png';
import linkedinLogo from '../../../../../assets/images/landingPage/linkedinblack.png';
import LogoVirtus from '../../../../../assets/images/landingPage/logo_virtuspay.png';

interface ISocial {
	color: string;
	margin: string;
}

export function Social({ color, margin }: ISocial) {
	return (
		<div id="midias" style={{ backgroundColor: color }}>
			<section
				style={{ boxShadow: 'none' }}
				className={`column is-multiline py-5 ${margin} container`}
			>
				<div className="column has-text-centered is-hidden-desktop is-12 is-align-items-center is-justify-content-center">
					<img
						className="lazyload"
						src={LogoVirtus}
						width="180px"
						alt="Logo VirtusPay"
					/>
					<div className="is-flex mt-4 is-flex-direction-row is-justify-content-center">
						<a
							className="mx-2"
							target="_blank"
							rel="noreferrer"
							href="https://pt-br.facebook.com/virtuspay/"
						>
							<img className="lazyload" src={facebookLogo} alt="facebook" />
						</a>
						<a
							className="mx-2"
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/virtuspay/"
						>
							<img className="lazyload" src={instaLogo} alt="instagram" />
						</a>
						<a
							className="mx-2"
							target="_blank"
							rel="noreferrer"
							href="https://www.linkedin.com/company/virtuspay/"
						>
							<img className="lazyload" src={linkedinLogo} alt="linkedIn" />
						</a>
						<a
							className="mx-2"
							target="_blank"
							rel="noreferrer"
							href="https://www.youtube.com/channel/UCHE_NnSHC2RVrkD17U0qr8w"
						>
							<img className="lazyload" src={youtubeLogo} alt="youtube" />
						</a>
					</div>
				</div>

				<div className="columns is-multiline is-hidden-touch is-flex is-flex-direction-row is-justify-content-space-between">
					<div className="column is-align-items-center is-flex is-12-mobile">
						<img
							className="lazyload"
							src={LogoVirtus}
							width="180px"
							alt="Logo VirtusPay"
						/>
					</div>
					<div className="column">
						<div className="is-flex is-flex-direction-row is-justify-content-flex-end">
							<a
								className="mx-2"
								target="_blank"
								rel="noreferrer"
								href="https://pt-br.facebook.com/virtuspay/"
							>
								<img className="lazyload" src={facebookLogo} alt="facebook" />
							</a>
							<a
								className="mx-2"
								target="_blank"
								rel="noreferrer"
								href="https://www.instagram.com/virtuspay/"
							>
								<img className="lazyload" src={instaLogo} alt="instagram" />
							</a>
							<a
								className="mx-2"
								target="_blank"
								rel="noreferrer"
								href="https://www.linkedin.com/company/virtuspay/"
							>
								<img className="lazyload" src={linkedinLogo} alt="linkedIn" />
							</a>
							<a
								className="mx-2"
								target="_blank"
								rel="noreferrer"
								href="https://www.youtube.com/channel/UCHE_NnSHC2RVrkD17U0qr8w"
							>
								<img className="lazyload" src={youtubeLogo} alt="youtube" />
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
