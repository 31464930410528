// export const TextSheetGracePeriodGt0 = `
// Cláusula Primeira – O Credor concedeu ao Emitente um emprés mo no valor e nas demais condições indicadas no preâmbulo, cujo
// importe líquido, deduzido de despesas, tarifas e Imposto sobre Operações de Crédito (“IOF”) cobrado antecipadamente, será liberado
// por meio de crédito na conta do Emitente, conforme indicada no preâmbulo, observado o disposto nesta CCB.
// § Primeiro – O Emitente declara-se plenamente ciente e de acordo com o fato de que a liberação pelo Credor do valor mencionado na
// Cláusula Primeira acima está condicionada à verificação da situação prevista como Condição Suspensiva (conforme definido na Cláusula
// Décima Quarta abaixo), havendo, portanto, a possibilidade de esta Cédula não produzir efeitos caso tal Condição Suspensiva não seja
// sa sfeita dentro do prazo estabelecido no parágrafo segundo da Cláusula Décima Quarta abaixo.
// § Segundo - O Credor colocará (ou fará com que seja colocado) à disposição do Emitente, mediante sua solicitação, extratos bancários
// e/ou planilha de cálculo demonstra va de seu saldo devedor e respec vas movimentações.
// § Terceiro – O Emitente reconhece que os extratos e planilhas de cálculo mencionados no parágrafo acima fazem parte desta Cédula e
// que, salvo erro material, os valores deles constantes, apurados de acordo com os termos desta CCB, são líquidos, certos e determinados, e
// evidenciarão, a qualquer tempo, o saldo devedor da presente Cédula.
// § Quarto – O Emitente reconhece como válida a emissão desta Cédula de forma eletrônica e declara, para todos os fins, que sua
// assinatura eletrônica é prova de sua concordância com este formato de contratação, nos termos do ar go 10º, parágrafo 2º, da Medida
// Provisória nº. 2.200-2/2001.

// Cláusula Segunda – O Emitente e o Avalista declaram-se cientes e de acordo, bem como se obrigam a res tuir o valor mutuado ao Credor
// ou a quem este indicar, acrescido dos encargos, taxas e prazos estabelecidos no preâmbulo, sem prejuízo da incidência de Desconto de
// Pontualidade. Os juros ajustados nesta Cédula serão calculados de forma exponencial e capitalizados diariamente, com base em um ano
// de 365 (trezentos e sessenta e cinco) dias, observada a Condição Suspensiva prevista abaixo.
// § Primeiro – Neste ato, em caráter irrevogável e irretratável, o Emitente e o Avalista desde já autorizam expressamente o Credor,
// outorgando todos os poderes necessários para tanto, nos termos dos ar gos 683 e 684 da Lei nº 10.406, de 10 de janeiro de 2002
// (“Código Civil”), a debitar de qualquer conta ou aplicação de  tularidade do Emitente e/ou do Avalista, caso não haja saldo suficiente nas
// contas ou aplicações de  tularidade do Emitente, em favor do Credor, o montante suficiente para quitar a quan a referente à parcela
// mensal de principal acrescido dos encargos, calculados com base no Campo IV do preâmbulo desta Cédula, nos dias de vencimento de
// cada parcela mensal.
// § Segundo – O Emitente e o Avalista declaram ter ciência que (i) o Credor integra o Sistema Financeiro Nacional, submetendo-se à
// disciplina e regras ditadas pelo Conselho Monetário Nacional (“CMN”) e Banco Central do Brasil (“Banco Central”); e (ii) as taxas de juros
// cobradas nas operações financeiras realizadas pelo Credor, incluindo a presente CCB, não estão subme das ao limite de 12% (doze por
// cento) ao ano, como já decidiu o Supremo Tribunal Federal, sendo legí ma a cobrança de juros e encargos superiores a esse percentual.
// § Terceiro – Fica certo e ajustado que nenhuma medida governamental, legisla va ou regulamentar, que venha a impedir ou restringir ou
// determinar de forma diversa da estabelecida nesta Cédula para o cálculo dos encargos incidentes sobre a quan a mutuada, terá aplicação
// entre as partes aqui contratantes, devendo as relações emergentes desta Cédula permanecerem regidas pelas regras expressas neste
// título, bem como pela legislação ora vigente.
// § Quarto – Caso a aplicação das regras previstas nesta Cédula eventualmente se tornar impossível, seja por força de eventual caráter
// cogente de impera vos legais que venham a ser baixados, seja em decorrência de ausência de consenso entre as partes, considerar-se-á
// rescindida esta Cédula e, em consequência, a dívida dela oriunda se considerará antecipadamente vencida, da mesma forma e com os
// mesmos efeitos previstos, efe vando-se a cobrança de juros pro-rata temporis.

// Cláusula Terceira – Aval. Desde a Data de Emissão até a integral quitação da presente Cédula, nos termos aqui previstos, esta CCB será
// garan da por aval do Avalista até o limite de 100% (cem por cento) do saldo devedor da presente Cédula (“Aval”). O Aval é cons tuído
// neste ato e por esta Cédula, obrigando-se o Avalista perante o Credor na qualidade de devedor e principal pagador, solidariamente
// responsável com o Emitente, por todas e quaisquer obrigações do Emitente decorrentes desta CCB.
// § Primeiro - Todos e quaisquer pagamentos realizados pelo Avalista em relação ao Aval serão efetuados livres e líquidos, sem a dedução
// do IOF ou quaisquer outros tributos, impostos, taxas, contribuições de qualquer natureza, encargos ou retenções, presentes ou futuros,
// bem como de quaisquer juros, multas ou demais exigibilidades fiscais, devendo o Avalista pagar as quan as adicionais que sejam
// necessárias para que o Credor receba, após tais deduções, recolhimentos ou pagamentos, uma quan a equivalente à que teria sido
// recebida se tais deduções, recolhimentos ou pagamentos não fossem aplicáveis. O pagamento deverá ser realizado segundo os
// procedimentos estabelecidos nesta CCB e de acordo com instruções recebidas do Credor.
// § Segundo - O Aval aqui cons tuído é prestado pelo Avalista em caráter irrevogável e irretratável e será automa camente liberado
// quando esta CCB for integralmente quitada. § Terceiro – Nenhuma objeção ou oposição do Emitente poderá ser admi da ou invocada
// pelo Avalista com o fito de escusar-se do cumprimento das obrigações estabelecidas nesta Cédula.
// § Quarto – O Avalista concorda e obriga-se a somente exigir e/ou demandar o Emitente por qualquer valor desembolsado por conta do
// Aval depois de o Credor ter recebido todos os valores a ele devidos nos termos desta CCB.
// § Quinto – O Aval poderá ser excu do e exigido pelo Credor quantas vezes forem necessárias até a integral e efe va liquidação desta
// Cédula.
// § Sexto – O Avalista se obriga a quitar esta CCB no prazo de até 2 (dois) Dias Úteis contados a par r de comunicação por escrito enviada
// pelo Credor informando o não pagamento de qualquer das obrigações estabelecidas nesta Cédula, independentemente de qualquer
// pretensão, ação, disputa ou reclamação que o Emitente venha a ter ou exercer em relação à esta CCB.
// § Sétimo –Sobre os valores pagos pelo Avalista ao Credor na forma desta cláusula não será aplicável o Desconto de Pontualidade, sendo
// devidos os Juros Remuneratórios.

// Cláusula Quarta – Encargos Moratórios - O atraso no pagamento de quaisquer importâncias devidas, vencidas e não pagas na época em
// que forem exigíveis por força do disposto nesta Cédula, ou nas hipóteses de vencimento antecipado da dívida adiante previstas, implicará
// automaticamente na mora, ficando o débito sujeito, do vencimento ao efe vo pagamento a:
// (a) juros moratórios de 7% a.m. (sete por cento ao mês) ou fração (pro rata temporis);
// (b) juros remuneratórios às taxas indicadas no Campo IV, itens 6 a 10, ou à taxa média de mercado vigente na data do efe vo
// pagamento es pulada pelo Banco Central para as operações da mesma modalidade, prevalecendo a que resultar em maior
// valor e, aplicáveis sobre o capital devidamente corrigido; e
// (c) multa de 2% (dois por cento) sobre o total do débito não pago, incluindo encargos moratórios e remuneratórios.
// § Primeiro – Além dos encargos mencionados na Cláusula Quarta acima, o Emitente e o Avalista serão responsáveis: (i) na fase
// extrajudicial, pelas despesas de cobrança e honorários advocaticios limitados a 10% (dez por cento) do valor total devido; e (ii) pelas custas
// e honorários advocaticios na fase judicial, a serem arbitrados pelo juiz.
// Configuração da Mora - Para efeitos desta CCB, entende-se por mora o não pagamento no prazo e na forma devidos, de qualquer
// quan a, de principal ou encargos, ou qualquer outra obrigação, contraídas junto ao Credor em decorrência desta Cédula. A configuração
// da mora independerá de qualquer aviso, no ficação ou interpelação, resultando do simples inadimplemento das obrigações assumidas
// nesta Cédula.
// § Terceiro – O Emitente e o Avalista declaram ter conhecimento que, para qualquer amor zação e/ou liquidação, seja de principal e/ou
// de juros, mediante débito em conta corrente ou a entrega de recursos ao Credor, tais recursos deverão corresponder a recursos livres, de
// procedência lícita, desbloqueados, transferíveis e disponíveis em reservas bancárias, para comportar o débito ou crédito, nas datas dos
// vencimentos das obrigações assumidas. Assim, enquanto não es ver disponível a importância necessária para a liquidação pretendida, o
// Credor cobrará pelos dias que decorrerem até a efetiva disponibilização dos recursos, os mesmos encargos ajustados nesta Cédula.

// Cláusula Quinta – Desconto de Pontualidade – Caso o Emitente realize o pagamento das parcelas e eventuais encargos decorrentes
// desta CCB até as respec vas datas de vencimento, será afastada a incidência dos Juros Remuneratórios (“Desconto de Pontualidade”).
// § Primeiro – Caso o Emitente (a) não realize o pagamento integral de qualquer parcela devida até o seu vencimento, e/ou (b) incorra em
// quaisquer das hipóteses de Mora previstas no Parágrafo Segundo da Cláusula Quarta acima, perderá o direito ao Desconto de
// Pontualidade e serão devidos os Encargos Moratórios nos termos da Cláusula Quarta acima, inclusive juros remuneratórios.
// § Segundo – Por se tratar de um bene[cio que permite a contratação de emprés mo sem juros remuneratórios, o Emitente concorda
// que, caso perca o direito ao Desconto de Pontualidade na forma do Parágrafo Primeiro acima, não será possível recuperá-lo, ainda que
// cumpra tempes vamente todas as suas demais obrigações decorrentes desta CCB.

// Cláusula Sexta - Vencimento Antecipado – Observados os prazos de cura aplicáveis, o presente título vencerá antecipadamente,
// permi ndo ao Credor exigir de imediato o pagamento do Valor de Principal, conforme indicado no Campo IV do preâmbulo, e de todos os
// encargos contratuais, independentemente de interpelação ou no ficação judicial ou extrajudicial, nos casos previstos em lei,
// especialmente nos ar gos 333 e 1.425 do Código Civil, e ainda na ocorrência de qualquer das seguintes hipóteses:
// § Único – Caso ocorra o vencimento antecipado desta CCB (i) nas hipóteses dos itens (a) e/ou (e) acima; e/ou (ii) a que o Emitente tenha
// dado causa, o Emitente perderá o Direito ao Desconto de Pontualidade, sendo devidos os Juros Remuneratórios.
// (a) caso o Emitente deixe de cumprir quaisquer das obrigações de pagamento ou acessórias desta CCB, no tempo e modo
// convencionados neste título;
// (b) caso o Emitente ou o Avalista tenham título levado a protesto e/ou nome inserido em qualquer órgão de proteção ao
// crédito, em valor igual ou superior a 30% (trinta por cento) do Valor de Principal e/ou R$5.000,00 (cinco mil reais), o que for
// menor, sem a devida regularização no prazo de 25 (vinte e cinco) dias da data do referido apontamento e/ou inserção,
// conforme aplicável;
// (c) caso o Emitente ou o Avalista sejam inscritos no Cadastro de Emitente de Cheques sem Fundos (CCF) após a data de
// emissão desta Cédula, sem a devida regularização no prazo de 15 (quinze) dias a contar da data de inscrição;
// (d) se for interposta, por terceiro, execução judicial em valor superior a 30% (trinta por cento) do Valor de Principal e/ou R$
// 5.000,00 (cinco mil reais), o que for menor, sem a devida quitação do valor executado no prazo de 15 (quinze) dias a contar da
// data de citação; e
// (e) no caso de apuração de falsidade, fraude ou inexa dão de qualquer declaração, informação ou documento que houverem
// sido prestados, firmados ou entregues ao Credor.

// Cláusula Sétima – Fica ajustado entre as partes que qualquer tolerância por parte do Credor, assim como a não exigência imediata de
// qualquer crédito, ou o recebimento após o vencimento, antecipado ou tempes vo, de qualquer débito, não cons tuirá novação, nem
// modificação dos termos da presente Cédula, nem qualquer precedente ou expecta va de direito a ser invocado pelo Emitente, nem
// tampouco, importará na renúncia ao direito do Credor de execução imediata.

// Cláusula Oitava – Da Compensação – O Emitente autoriza, desde já e expressamente, em caráter irrevogável e irretratável, o Credor a
// proceder à compensação de que trata o ar go 368 do Código Civil entre o débito decorrente desta Cédula e qualquer crédito do qual seja
//  tular, existente ou que venha a exis r.

// Cláusula Nona – Todas as despesas oriundas desta CCB, inclusive tributos, contribuições, depósitos compulsórios e demais despesas que
// incidam ou venham a incidir sobre ela, ou sobre os recursos u lizados pelo Credor para a sua viabilização ou manutenção, incluindo
// eventuais ônus ou custas, despesas com seus registros cartoriais e quaisquer outros gastos, judiciais ou extrajudiciais (incluindo honorários
// advocaticios) com a cobrança do crédito, protestos, elaboração de cadastros, bem como qualquer outro dispêndio necessário à segurança,
// manutenção, comprovação da existência e regularidade do crédito, serão suportadas integralmente pelo Emitente.

// Cláusula Décima – O Emitente e o Avalista, atendendo ao disposto na regulamentação editada pelo CMN, autorizam expressamente o
// Credor a consultar dados pessoais ou rela vos às suas empresas, sócios ou acionistas, eventualmente encontrados no Sistema de
// Informações Consolidadas do Banco Central – SISBACEN, não cons tuindo tal consulta violação ao sigilo bancário destes.

// Cláusula Décima Primeira – Declarações e Obrigações Adicionais - O Emitente declara e garante que:
// (a) Possui plena capacidade e legi midade para emi r a presente CCB, realizar todas as operações e cumprir todas as
// obrigações aqui assumidas, tendo tomado todas as medidas necessárias para implementação e cumprimento de todas as
// obrigações cons tuídas;
// (b) Está apto a cumprir as obrigações ora previstas nesta CCB e agirá em relação a estas de boa-fé e com lealdade;
// (c) Não se encontra em estado de necessidade ou sob coação para emi r esta CCB e/ou quaisquer contratos e compromissos
// a ela relacionados e acessórios;
// (d) Está ciente e de acordo que o presente título é emi do unilateralmente pelo Emitente e que sua eficácia está sujeita à
// verificação da Condição Suspensiva mencionada na Cláusula Décima Quarta abaixo. Dessa forma, sendo atendida a Condição
// Suspensiva, esta CCB ganhará eficácia e será endossada pelo Credor nos termos da Carta de Endosso anexa a esta CCB, a qual
// é assinada pelo Emitente juntamente com a presente CCB, sendo certo que todos estão plenamente cientes e de acordo que
// referido endosso não se trata de distribuição pública de valores mobiliários assim entendida nos termos da Lei nº 6.385, de 7
// de dezembro de 1976;
// (e) Está ciente e de acordo que, a par r da data do endosso da presente CCB, o terceiro endossatário passará a ser o credor
// efe vo desta CCB, ficando sub-rogado em todos os direitos e obrigações do Credor e desobrigando o credor-endossante de
// qualquer responsabilidade com relação à CCB;
// (f) Não se opõe aos encargos cobrados nesta CCB;
// (g) Adotará todas as providências para manter válidas e eficazes as declarações con das nesta Cédula, mantendo o Credor
// informado de qualquer ato ou fato que possa afetar a validade de qualquer das referidas declarações e adotando as medidas
// cabíveis para sanar ou evitar a invalidade de tais declarações;
// (h) Permanecerá adimplente com suas obrigações fiscais, trabalhistas e previdenciárias, exceção feita às que eventualmente
// estejam sendo contestadas de boa-fé, judicial ou administra vamente nos termos da legislação então em vigor;
// (i) Não é uma pessoa poli camente exposta. Para efeitos da presente disposição uma “pessoa poli camente exposta” significa
// uma pessoa que é ou foi nomeada nos úl mos 5 (cinco) anos, bem como seus representantes, familiares e pessoas de seu
// relacionamento próximo, no Brasil ou em qualquer outro país, território e dependências com qualquer cargo, função pública
// proeminente, ou posição, incluindo, sem limitação, chefe de estado ou de governo, altas nomeações polí cas, altos cargos do
// serviço civil, altos postos judiciais ou militares e chefes de qualquer empresa de da por órgãos governamentais ou par dos
// polí cos (“Pessoa Poli camente Exposta”);
// (j) Manterá seu endereço constantemente atualizado perante o Credor, para efeito de comunicação sobre qualquer ato ou
// fato decorrente desta Cédula;
// (k) Manterá válidas e eficazes as declarações prestadas nesta CCB, mantendo o Credor, no prazo de até 3 (três) Dias Úteis
// contados da data de ocorrência, informado de qualquer ato ou fato que possa afetar a validade ou a eficácia de qualquer
// destas declarações e adotando as medidas cabíveis para sanar ou evitar a invalidade ou a ineficácia de qualquer de tais
// declarações;
// (l) Tem plena ciência e concorda integralmente com as taxas de juros, dos encargos e demais condições financeiras desta CCB,
// sendo que reconhece que a forma de cálculo de tais condições foi acordada por sua livre vontade, em observância ao princípio
// da boa-fé;
// (m) Está ciente e de acordo com o as condições do Desconto de Pontualidade previstas nesta CCB;
// (n) As informações prestadas ao Credor por meio desta Cédula ou em momento anterior à sua assinatura, são verdadeiras,
// especialmente acerca da licitude da origem de sua renda e patrimônio, bem como está ciente do art. 11, II, da Lei n° 9.613, de
// 03 de março de 1998, e dos arts. 297, 298 e 299 do Decreto-Lei nº 2.848, de 7 de dezembro de 1940 (“Código Penal”); e (n)
// Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.
// (o) Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.

// § Primeiro – O Avalista declara e garante que:
// (a) Possui plena capacidade e legi midade para prestar o Aval e cumprir todas as obrigações aqui assumidas, tendo tomado
// todas as medidas necessárias para implementação e cumprimento de todas as obrigações cons tuídas;
// (b) Está apto a cumprir as obrigações ora previstas nesta CCB e agirá em relação a estas de boa-fé e com lealdade;
// (c) Não se encontra em estado de necessidade ou sob coação para prestar o Aval;
// (d) Está ciente e de acordo que, a par r da data do endosso da presente CCB, o terceiro endossatário passará a ser o credor
// efe vo desta CCB, ficando sub-rogado em todos os direitos e obrigações do Credor e desobrigando o credor-endossante de
// qualquer responsabilidade com relação à CCB;
// (e) Não se opõe aos encargos cobrados nesta CCB;
// (f) Adotará todas as providências para manter válidas e eficazes as declarações con das nesta Cédula, mantendo o Credor
// informado de qualquer ato ou fato que possa afetar a validade de qualquer das referidas declarações e adotando as medidas
// cabíveis para sanar ou evitar a invalidade de tais declarações;
// (g) Permanecerá adimplente com suas obrigações fiscais, trabalhistas e previdenciárias, exceção feita às que eventualmente
// estejam sendo contestadas
// de boa-fé, judicial ou administra vamente nos termos da legislação então em vigor; (h) Não é uma Pessoa Poli camente
// Exposta;
// (i) Manterá seu endereço constantemente atualizado perante o Credor, para efeito de comunicação sobre qualquer ato ou
// fato decorrente desta Cédula;
// (j) Manterá válidas e eficazes as declarações prestadas nesta CCB, mantendo o Credor, no prazo de até 3 (três) Dias Úteis
// contados da data de ocorrência, informado de qualquer ato ou fato que possa afetar a validade ou a eficácia de qualquer
// destas declarações e adotando as medidas cabíveis para sanar ou evitar a invalidade ou a ineficácia de qualquer de tais
// declarações;
// (k) Tem plena ciência e concorda integralmente com as taxas de juros, dos encargos e demais condições financeiras desta
// CCB, sendo que reconhece que a forma de cálculo de tais condições foi acordada por sua livre vontade, em observância ao
// princípio da boa-fé;
// (l) Está ciente e de acordo que não terá direito ao Desconto de Pontualidade, o qual é oferecido exclusivamente ao Emitente,
// observadas as condições desta CCB;
// (m) Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.

// § Segundo - Até a integral liquidação de todas as obrigações oriundas desta CCB, o Emitente e o Avalista se comprometem a manter as
// declarações acima prestadas sempre corretas e verdadeiras, obrigando-se a comprovar tal situação no prazo de 48 (quarenta e oito) horas
// a contar da solicitação feita pelo Credor originário ou endossatário, mediante o envio das cer dões e dos documentos comprobatórios
// correspondentes que forem necessários, sem prejuízo da eventual incidência de Desconto de Pontualidade.

// § Terceiro - Caso quaisquer das declarações acima prestadas sejam ou venham a se tornar, a qualquer momento, inverídicas ou incorretas,
// o Credor originário ou endossatário deverá no ficar o Emitente ou o Avalista, conforme o caso, para que tome as medidas necessárias
// para corrigir tal situação, no prazo de 72 (setenta e duas) horas, contadas do recebimento da referida no ficação, sem prejuízo do
// vencimento antecipado desta CCB.

// Cláusula Décima Segunda - O Emitente declara que tomou ciência e concordou com o Custo Efe vo Total (“CET”), na qual foram
// explicitados, além do valor em reais de cada componente do fluxo da operação, os respec vos percentuais em relação ao valor total
// devido, conforme estabelecido nas Resoluções CMN n.º 3.517/2007 e 4.197/2013.

// Cláusula Décima Terceira– Nos termos da legislação vigente, o Emitente autoriza o Credor a emi r Cer ficado de Cédulas de Crédito
// Bancário – CCCB, com lastro no presente título, podendo negociá-los no mercado, de acordo com o disposto na legislação vigente,
// inclusive observadas as normas emi das pelo CMN e pelo Banco Central.

// Cláusula Décima Quarta – Após o endosso pelo Credor desta Cédula, o Emitente, o Avalista e o novo credor-endossatário, desde já, (a)
// exoneram o credor-endossante de toda e qualquer responsabilidade em relação (i) à veracidade e exa dão das informações e
// documentação fornecidas pelo Emitente e demais partes signatárias, e (ii) ao acompanhamento do cumprimento das obrigações
// assumidas nesta Cédula; e (b) reconhecem a validade da emissão e do endosso desta CCB de forma eletrônica, o que é feito com base no
// art. 889, §3º, do Código Civil.

// § Primeiro – O Emitente e o Avalista estão integralmente cientes e de acordo com o seguinte: (i) qualquer liXgio ou ques onamento,
// judicial ou extrajudicial, que possa vir a ser ajuizado, deverá ser ajuizado, àquele portador endossatário da CCB na data do ajuizamento do
// liXgio ou ques onamento; e (ii) o ajuizamento de qualquer ação, judicial ou extrajudicial, pelo Emitente ou pelo Avalista contra a MONEY
// PLUS SCMEPP LTDA, na qualidade de Credor, após a MONEY PLUS SCMEPP LTDA ter endossado esta Cédula para terceiro, acarretará
// na responsabilidade pelo pagamento de indenização por perdas e danos e ressarcimento de todo e qualquer custo e despesas que a
// MONEY PLUS SCMEPP LTDA venha a incorrer (incluindo de honorários advocatícios) para defesa de seus direitos no respec vo liXgio.

// § Segundo – Após o endosso desta Cédula, na eventualidade do pagamento ou vencimento antecipado do presente título, o endossatário
// ficará obrigado a receber antecipadamente os recursos des nados à quitação da dívida no montante equivalente ao valor integral e
// atualizado do principal da dívida desta Cédula, acrescido da incidência dos respec vos encargos até a data da ocorrência do seu efe vo
// pagamento.

// § Terceiro – Com o endosso desta Cédula, o Emitente e o Avalista autorizam o Credor, desde já, de forma irretratável e irrevogável, a
// informar e fornecer ao endossatário, informações sobre a presente Cédula, bem como sobre a estrutura, documentação e fluxo de
// garan as cons tuídas, seja através de extratos bancários da conta corrente indicada no preâmbulo, na forma do art. 28 da Lei nº 10.931,
// e/ou relatórios, reconhecendo que estes procedimentos não cons tuem infrações às regras que disciplinam o sigilo bancário.

// § Quarto – O Emitente e o Avalista somente poderão ceder suas obrigações e direitos decorrentes desta Cédula, com autorização prévia e
// expressa do Credor.
// Cláusula Décima Quinta – O Emitente e o Avalista autorizam o Credor, em caráter irrevogável e irretratável e na forma da
// regulamentação aplicável: a (i) transmi r e consultar informações sobre o Emitente e/ou sobre o Avalista, e rela vas a esta operação ao
// Sistema de Informação de Crédito (SCR) man do pelo Banco Central, u lizando tais informações, inclusive, para análise da capacidade de
// crédito dos mesmos, bem como fornecer tais informações a terceiros que sejam contratados para prestar serviços de controle e cobrança,
// por quaisquer meios, das obrigações assumidas nesta CCB; (ii) levar a registro esta Cédula em quaisquer órgãos públicos, cartórios e
// ins tuições de custódia e liquidação financeira de título, especialmente a CETIP S/A – Mercados Organizados; e (iii) em caso de
// inadimplemento, inserir o nome do Emitente e/ou do Avalista em bancos públicos ou privados de restrição cadastral.

// Cláusula Décima Sexta – Observado o condicionamento da eficácia da presente CCB conforme parágrafo primeiro abaixo, esta Cédula,
// inclusive no que diz respeito à cobrança de juros, encargos e tributos a ela aplicáveis, é válida a par r da data de sua emissão e vigorará
// até o pagamento integral do seu saldo devedor, nos termos ora estabelecidos por esta CCB.

// § Primeiro – CONSTITUI CONDIÇÃO SUSPENSIVA PARA A EFICÁCIA DESTA CÉDULA, nos termos do ar go 125 do Código Civil, A
// DISPONIBILIZAÇÃO PELO CREDOR AO EMITENTE DO VALOR DE PRINCIPAL indicado no Campo IV acima (“Condição Suspensiva”),
// observado que eventual valor re do pela MONEY PLUS SCMEPP LTDA por conta e ordem e a pedido do Emitente, não deve
// descaracterizar o Valor de Principal para os fins da Condição Suspensiva.

// § Segundo – Na hipótese de a CONDIÇÃO SUSPENSIVA NÃO OCORRER EM ATÉ 10 (DEZ) DIAS APÓS A EMISSÃO DESTA CCB, a
// PRESENTE CÉDULANÃO SERÁ REVESTIDA DE EFICÁCIA E SE EXTINGUIRÁ DE PLENO DIREITO, sem qualquer ônus ou
// penalidade para qualquer das partes.

// Cláusula Décima Sétima – A presente Cédula é emi da em caráter irrevogável e irretratável, e obriga todos os seus signatários e seus
// eventuais sucessores a qualquer título.

// Cláusula Décima Oitava – Eventuais Nulidades – Caso alguma disposição desta CCB venha a ser considerada ilegal, inexequível ou nula, as
// demais disposições permanecerão válidas. Nesta hipótese, os signatários e o Credor de comum acordo, deverão alterar esta Cédula,
// modificando a referida disposição, na medida necessária para torná-la legal e exequível, ao mesmo tempo preservando seu obje vo, ou se
// isso não for possível, subs tuindo-a por outra disposição que seja legal e exequível, e que a nja o mesmo obje vo.

// Cláusula Décima Nona – Em comum acordo com o Credor, esta Cédula poderá ser renovada, aditada, re ficada e ra ficada mediante
// documento escrito e datado, no qual constará todas as condições a serem introduzidas e uma vez assinado pelas partes, passará a integrar
// esta Cédula para todos os fins e efeitos de direito.

// Cláusula Vigésima – Quitação Antecipada do Saldo Devedor desta Cédula – A presente Cédula poderá ser quitada antecipadamente,
// aplicando-se a redução proporcional de juros e demais acréscimos, em atenção à regra em vigor para pessoas [sicas, incluindo, mas não se
// limitando, a Resolução nº 3.516, de 6 de dezembro de 2007, conforme alterada.

// § Parágrafo Primeiro – Na hipótese de quitação antecipada desta CCB nos termos da Cláusula Décima Oitava acima, o cálculo do valor
// objeto da liquidação antecipada será feito com base na taxa estabelecida no Campo IV, itens 9 e 10 acima.

// § Parágrafo Segundo – Na hipótese de quitação antecipada desta CCB o Emitente não terá direito ao Desconto de Pontualidade, sendo
// devidos os juros remuneratórios.

// Cláusula Vigésima Primeira– Fica eleito o foro da Comarca de São Paulo-SP, ressalvado ao Credor o direito de optar pelo do domicílio do
// Emitente para dirimir quaisquer questões oriundas desta Cédula.

// Cláusula Vigésima Segunda – A MONEY PLUS SCMEPP LTDA, ora Credor, fica desde já autorizado a prestar informações sobre as partes
// signatárias ou a movimentação financeira, nas hipóteses de recebimento de requisições oriundas da Receita Federal, o[cios des nados à
// apuração de ilícito ou ainda por ordem judicial, nos termos da Lei Complementar nº 105, de 10 de janeiro de 2001, e Decreto nº 4.489, de
// 28 de novembro de 2002.

// Cláusula Vigésima Terceira - O Emitente e o Avalista declaram, ainda, ter lido previamente a presente Cédula e não ter dúvidas sobre
// qualquer de suas condições. O Emitente declara também que está na posse de uma via eletrônica não negociável desta cédula e emi u a
// via negociável eletrônica ao Credor, assim como declara ter ciência, nos termos da Lei nº 10.931, de que esta CCB é um título de crédito
// emi do unilateralmente e, consequentemente, não há necessidade de assinatura do Credor e de testemunhas, sendo considerado título
// execu vo extrajudicial nos termos da mencionada lei, sendo comprovada sua aceitação mediante assinatura eletrônica desta CCB,
// observado que sua eficácia está sujeita à verificação da Condição Suspensiva, conforme Cláusula Décima Quarta acima.

// Cláusula Vigésima Quarta - Se a data de vencimento de qualquer pagamento devido nos termos desta Cédula coincidir com um dia não
// ú l, o pagamento deverá ser efetuado no primeiro Dia Ú l subsequente, conforme definição a seguir, sendo certo que quaisquer juros ou
// encargos nos termos desta CCB incidirão até a data do efe vo pagamento. Para fins do disposto neste instrumento, entende-se por dias
// úteis todos os dias, exceto sábados, domingos e feriados bancários nacionais (“Dia Ú l”)`;

export const TextSheetGracePeriodGt0 = `
Cláusula Primeira – O Credor concedeu ao Emitente um emprés mo no valor e nas demais condições indicadas no preâmbulo, cujo
importe líquido, deduzido de despesas, tarifas e Imposto sobre Operações de Crédito (“IOF”) cobrado antecipadamente, será liberado
por meio de crédito na conta do Emitente, conforme indicada no preâmbulo, observado o disposto nesta CCB.
§ Primeiro – O Emitente declara-se plenamente ciente e de acordo com o fato de que a liberação pelo Credor do valor mencionado na
Cláusula Primeira acima está condicionada à verificação da situação prevista como Condição Suspensiva (conforme definido na Cláusula
Décima Quarta abaixo), havendo, portanto, a possibilidade de esta Cédula não produzir efeitos caso tal Condição Suspensiva não seja
sa sfeita dentro do prazo estabelecido no parágrafo segundo da Cláusula Décima Quarta abaixo.
§ Segundo - O Credor colocará (ou fará com que seja colocado) à disposição do Emitente, mediante sua solicitação, extratos bancários
e/ou planilha de cálculo demonstra va de seu saldo devedor e respec vas movimentações.
§ Terceiro – O Emitente reconhece que os extratos e planilhas de cálculo mencionados no parágrafo acima fazem parte desta Cédula e
que, salvo erro material, os valores deles constantes, apurados de acordo com os termos desta CCB, são líquidos, certos e determinados, e
evidenciarão, a qualquer tempo, o saldo devedor da presente Cédula.
§ Quarto – O Emitente reconhece como válida a emissão desta Cédula de forma eletrônica e declara, para todos os fins, que sua
assinatura eletrônica é prova de sua concordância com este formato de contratação, nos termos do ar go 10º, parágrafo 2º, da Medida
Provisória nº. 2.200-2/2001.

Cláusula Segunda – O Emitente e o Avalista declaram-se cientes e de acordo, bem como se obrigam a res tuir o valor mutuado ao Credor
ou a quem este indicar, acrescido dos encargos, taxas e prazos estabelecidos no preâmbulo, sem prejuízo da incidência de Desconto de
Pontualidade. Os juros ajustados nesta Cédula serão calculados de forma exponencial e capitalizados diariamente, com base em um ano
de 365 (trezentos e sessenta e cinco) dias, observada a Condição Suspensiva prevista abaixo.
§ Primeiro – Neste ato, em caráter irrevogável e irretratável, o Emitente e o Avalista desde já autorizam expressamente o Credor,
outorgando todos os poderes necessários para tanto, nos termos dos ar gos 683 e 684 da Lei nº 10.406, de 10 de janeiro de 2002
(“Código Civil”), a debitar de qualquer conta ou aplicação de  tularidade do Emitente e/ou do Avalista, caso não haja saldo suficiente nas
contas ou aplicações de  tularidade do Emitente, em favor do Credor, o montante suficiente para quitar a quan a referente à parcela
mensal de principal acrescido dos encargos, calculados com base no Campo IV do preâmbulo desta Cédula, nos dias de vencimento de
cada parcela mensal.
§ Segundo – O Emitente e o Avalista declaram ter ciência que (i) o Credor integra o Sistema Financeiro Nacional, submetendo-se à
disciplina e regras ditadas pelo Conselho Monetário Nacional (“CMN”) e Banco Central do Brasil (“Banco Central”); e (ii) as taxas de juros
cobradas nas operações financeiras realizadas pelo Credor, incluindo a presente CCB, não estão subme das ao limite de 12% (doze por
cento) ao ano, como já decidiu o Supremo Tribunal Federal, sendo legí ma a cobrança de juros e encargos superiores a esse percentual.
§ Terceiro – Fica certo e ajustado que nenhuma medida governamental, legisla va ou regulamentar, que venha a impedir ou restringir ou
determinar de forma diversa da estabelecida nesta Cédula para o cálculo dos encargos incidentes sobre a quan a mutuada, terá aplicação
entre as partes aqui contratantes, devendo as relações emergentes desta Cédula permanecerem regidas pelas regras expressas neste
título, bem como pela legislação ora vigente.
§ Quarto – Caso a aplicação das regras previstas nesta Cédula eventualmente se tornar impossível, seja por força de eventual caráter
cogente de impera vos legais que venham a ser baixados, seja em decorrência de ausência de consenso entre as partes, considerar-se-á
rescindida esta Cédula e, em consequência, a dívida dela oriunda se considerará antecipadamente vencida, da mesma forma e com os
mesmos efeitos previstos, efe vando-se a cobrança de juros pro-rata temporis.

Cláusula Terceira – Aval. Desde a Data de Emissão até a integral quitação da presente Cédula, nos termos aqui previstos, esta CCB será
garan da por aval do Avalista até o limite de 100% (cem por cento) do saldo devedor da presente Cédula (“Aval”). O Aval é cons tuído
neste ato e por esta Cédula, obrigando-se o Avalista perante o Credor na qualidade de devedor e principal pagador, solidariamente
responsável com o Emitente, por todas e quaisquer obrigações do Emitente decorrentes desta CCB.
§ Primeiro - Todos e quaisquer pagamentos realizados pelo Avalista em relação ao Aval serão efetuados livres e líquidos, sem a dedução
do IOF ou quaisquer outros tributos, impostos, taxas, contribuições de qualquer natureza, encargos ou retenções, presentes ou futuros,
bem como de quaisquer juros, multas ou demais exigibilidades fiscais, devendo o Avalista pagar as quan as adicionais que sejam
necessárias para que o Credor receba, após tais deduções, recolhimentos ou pagamentos, uma quan a equivalente à que teria sido
recebida se tais deduções, recolhimentos ou pagamentos não fossem aplicáveis. O pagamento deverá ser realizado segundo os
procedimentos estabelecidos nesta CCB e de acordo com instruções recebidas do Credor.
§ Segundo - O Aval aqui cons tuído é prestado pelo Avalista em caráter irrevogável e irretratável e será automa camente liberado
quando esta CCB for integralmente quitada. § Terceiro – Nenhuma objeção ou oposição do Emitente poderá ser admi da ou invocada
pelo Avalista com o fito de escusar-se do cumprimento das obrigações estabelecidas nesta Cédula.
§ Quarto – O Avalista concorda e obriga-se a somente exigir e/ou demandar o Emitente por qualquer valor desembolsado por conta do
Aval depois de o Credor ter recebido todos os valores a ele devidos nos termos desta CCB.
§ Quinto – O Aval poderá ser excu do e exigido pelo Credor quantas vezes forem necessárias até a integral e efe va liquidação desta
Cédula.
§ Sexto – O Avalista se obriga a quitar esta CCB no prazo de até 2 (dois) Dias Úteis contados a par r de comunicação por escrito enviada
pelo Credor informando o não pagamento de qualquer das obrigações estabelecidas nesta Cédula, independentemente de qualquer
pretensão, ação, disputa ou reclamação que o Emitente venha a ter ou exercer em relação à esta CCB.
§ Sétimo –Sobre os valores pagos pelo Avalista ao Credor na forma desta cláusula não será aplicável o Desconto de Pontualidade, sendo
devidos os Juros Remuneratórios.

Cláusula Quarta – Encargos Moratórios - O atraso no pagamento de quaisquer importâncias devidas, vencidas e não pagas na época em
que forem exigíveis por força do disposto nesta Cédula, ou nas hipóteses de vencimento antecipado da dívida adiante previstas, implicará
automaticamente na mora, ficando o débito sujeito, do vencimento ao efe vo pagamento a:
(a) juros moratórios de 7% a.m. (sete por cento ao mês) ou fração (pro rata temporis);
(b) juros remuneratórios às taxas indicadas no Campo IV, itens 6 a 10, ou à taxa média de mercado vigente na data do efe vo
pagamento es pulada pelo Banco Central para as operações da mesma modalidade, prevalecendo a que resultar em maior
valor e, aplicáveis sobre o capital devidamente corrigido; e
(c) multa de 2% (dois por cento) sobre o total do débito não pago, incluindo encargos moratórios e remuneratórios.
§ Primeiro – Além dos encargos mencionados na Cláusula Quarta acima, o Emitente e o Avalista serão responsáveis: (i) na fase
extrajudicial, pelas despesas de cobrança e honorários advocaticios limitados a 10% (dez por cento) do valor total devido; e (ii) pelas custas
e honorários advocaticios na fase judicial, a serem arbitrados pelo juiz.
Configuração da Mora - Para efeitos desta CCB, entende-se por mora o não pagamento no prazo e na forma devidos, de qualquer
quan a, de principal ou encargos, ou qualquer outra obrigação, contraídas junto ao Credor em decorrência desta Cédula. A configuração
da mora independerá de qualquer aviso, no ficação ou interpelação, resultando do simples inadimplemento das obrigações assumidas
nesta Cédula.
§ Terceiro – O Emitente e o Avalista declaram ter conhecimento que, para qualquer amor zação e/ou liquidação, seja de principal e/ou
de juros, mediante débito em conta corrente ou a entrega de recursos ao Credor, tais recursos deverão corresponder a recursos livres, de
procedência lícita, desbloqueados, transferíveis e disponíveis em reservas bancárias, para comportar o débito ou crédito, nas datas dos
vencimentos das obrigações assumidas. Assim, enquanto não es ver disponível a importância necessária para a liquidação pretendida, o
Credor cobrará pelos dias que decorrerem até a efetiva disponibilização dos recursos, os mesmos encargos ajustados nesta Cédula.

Cláusula Quinta – Desconto de Pontualidade – Caso o Emitente realize o pagamento das parcelas e eventuais encargos decorrentes
desta CCB até as respec vas datas de vencimento, será afastada a incidência dos Juros Remuneratórios (“Desconto de Pontualidade”).
§ Primeiro – Caso o Emitente (a) não realize o pagamento integral de qualquer parcela devida até o seu vencimento, e/ou (b) incorra em
quaisquer das hipóteses de Mora previstas no Parágrafo Segundo da Cláusula Quarta acima, perderá o direito ao Desconto de
Pontualidade e serão devidos os Encargos Moratórios nos termos da Cláusula Quarta acima, inclusive juros remuneratórios.
§ Segundo – Por se tratar de um bene[cio que permite a contratação de emprés mo sem juros remuneratórios, o Emitente concorda
que, caso perca o direito ao Desconto de Pontualidade na forma do Parágrafo Primeiro acima, não será possível recuperá-lo, ainda que
cumpra tempes vamente todas as suas demais obrigações decorrentes desta CCB.

Cláusula Sexta - Vencimento Antecipado – Observados os prazos de cura aplicáveis, o presente título vencerá antecipadamente,
permi ndo ao Credor exigir de imediato o pagamento do Valor de Principal, conforme indicado no Campo IV do preâmbulo, e de todos os
encargos contratuais, independentemente de interpelação ou no ficação judicial ou extrajudicial, nos casos previstos em lei,
especialmente nos ar gos 333 e 1.425 do Código Civil, e ainda na ocorrência de qualquer das seguintes hipóteses:
§ Único – Caso ocorra o vencimento antecipado desta CCB (i) nas hipóteses dos itens (a) e/ou (e) acima; e/ou (ii) a que o Emitente tenha
dado causa, o Emitente perderá o Direito ao Desconto de Pontualidade, sendo devidos os Juros Remuneratórios.
(a) caso o Emitente deixe de cumprir quaisquer das obrigações de pagamento ou acessórias desta CCB, no tempo e modo
convencionados neste título;
(b) caso o Emitente ou o Avalista tenham título levado a protesto e/ou nome inserido em qualquer órgão de proteção ao
crédito, em valor igual ou superior a 30% (trinta por cento) do Valor de Principal e/ou R$5.000,00 (cinco mil reais), o que for
menor, sem a devida regularização no prazo de 25 (vinte e cinco) dias da data do referido apontamento e/ou inserção,
conforme aplicável;
(c) caso o Emitente ou o Avalista sejam inscritos no Cadastro de Emitente de Cheques sem Fundos (CCF) após a data de
emissão desta Cédula, sem a devida regularização no prazo de 15 (quinze) dias a contar da data de inscrição;
(d) se for interposta, por terceiro, execução judicial em valor superior a 30% (trinta por cento) do Valor de Principal e/ou R$
5.000,00 (cinco mil reais), o que for menor, sem a devida quitação do valor executado no prazo de 15 (quinze) dias a contar da
data de citação; e
(e) no caso de apuração de falsidade, fraude ou inexa dão de qualquer declaração, informação ou documento que houverem
sido prestados, firmados ou entregues ao Credor.

Cláusula Sétima – Fica ajustado entre as partes que qualquer tolerância por parte do Credor, assim como a não exigência imediata de
qualquer crédito, ou o recebimento após o vencimento, antecipado ou tempes vo, de qualquer débito, não cons tuirá novação, nem
modificação dos termos da presente Cédula, nem qualquer precedente ou expecta va de direito a ser invocado pelo Emitente, nem
tampouco, importará na renúncia ao direito do Credor de execução imediata.

Cláusula Oitava – Da Compensação – O Emitente autoriza, desde já e expressamente, em caráter irrevogável e irretratável, o Credor a
proceder à compensação de que trata o ar go 368 do Código Civil entre o débito decorrente desta Cédula e qualquer crédito do qual seja
 tular, existente ou que venha a exis r.

Cláusula Nona – Todas as despesas oriundas desta CCB, inclusive tributos, contribuições, depósitos compulsórios e demais despesas que
incidam ou venham a incidir sobre ela, ou sobre os recursos u lizados pelo Credor para a sua viabilização ou manutenção, incluindo
eventuais ônus ou custas, despesas com seus registros cartoriais e quaisquer outros gastos, judiciais ou extrajudiciais (incluindo honorários
advocaticios) com a cobrança do crédito, protestos, elaboração de cadastros, bem como qualquer outro dispêndio necessário à segurança,
manutenção, comprovação da existência e regularidade do crédito, serão suportadas integralmente pelo Emitente.

Cláusula Décima – O Emitente e o Avalista, atendendo ao disposto na regulamentação editada pelo CMN, autorizam expressamente o
Credor a consultar dados pessoais ou rela vos às suas empresas, sócios ou acionistas, eventualmente encontrados no Sistema de
Informações Consolidadas do Banco Central – SISBACEN, não cons tuindo tal consulta violação ao sigilo bancário destes.

Cláusula Décima Primeira – Declarações e Obrigações Adicionais - O Emitente declara e garante que:
(a) Possui plena capacidade e legi midade para emi r a presente CCB, realizar todas as operações e cumprir todas as
obrigações aqui assumidas, tendo tomado todas as medidas necessárias para implementação e cumprimento de todas as
obrigações cons tuídas;
(b) Está apto a cumprir as obrigações ora previstas nesta CCB e agirá em relação a estas de boa-fé e com lealdade;
(c) Não se encontra em estado de necessidade ou sob coação para emi r esta CCB e/ou quaisquer contratos e compromissos
a ela relacionados e acessórios;
(d) Está ciente e de acordo que o presente título é emi do unilateralmente pelo Emitente e que sua eficácia está sujeita à
verificação da Condição Suspensiva mencionada na Cláusula Décima Quarta abaixo. Dessa forma, sendo atendida a Condição
Suspensiva, esta CCB ganhará eficácia e será endossada pelo Credor nos termos da Carta de Endosso anexa a esta CCB, a qual
é assinada pelo Emitente juntamente com a presente CCB, sendo certo que todos estão plenamente cientes e de acordo que
referido endosso não se trata de distribuição pública de valores mobiliários assim entendida nos termos da Lei nº 6.385, de 7
de dezembro de 1976;
(e) Está ciente e de acordo que, a par r da data do endosso da presente CCB, o terceiro endossatário passará a ser o credor
efe vo desta CCB, ficando sub-rogado em todos os direitos e obrigações do Credor e desobrigando o credor-endossante de
qualquer responsabilidade com relação à CCB;
(f) Não se opõe aos encargos cobrados nesta CCB;
(g) Adotará todas as providências para manter válidas e eficazes as declarações con das nesta Cédula, mantendo o Credor
informado de qualquer ato ou fato que possa afetar a validade de qualquer das referidas declarações e adotando as medidas
cabíveis para sanar ou evitar a invalidade de tais declarações;
(h) Permanecerá adimplente com suas obrigações fiscais, trabalhistas e previdenciárias, exceção feita às que eventualmente
estejam sendo contestadas de boa-fé, judicial ou administra vamente nos termos da legislação então em vigor;
(i) Não é uma pessoa poli camente exposta. Para efeitos da presente disposição uma “pessoa poli camente exposta” significa
uma pessoa que é ou foi nomeada nos úl mos 5 (cinco) anos, bem como seus representantes, familiares e pessoas de seu
relacionamento próximo, no Brasil ou em qualquer outro país, território e dependências com qualquer cargo, função pública
proeminente, ou posição, incluindo, sem limitação, chefe de estado ou de governo, altas nomeações polí cas, altos cargos do
serviço civil, altos postos judiciais ou militares e chefes de qualquer empresa de da por órgãos governamentais ou par dos
polí cos (“Pessoa Poli camente Exposta”);
(j) Manterá seu endereço constantemente atualizado perante o Credor, para efeito de comunicação sobre qualquer ato ou
fato decorrente desta Cédula;
(k) Manterá válidas e eficazes as declarações prestadas nesta CCB, mantendo o Credor, no prazo de até 3 (três) Dias Úteis
contados da data de ocorrência, informado de qualquer ato ou fato que possa afetar a validade ou a eficácia de qualquer
destas declarações e adotando as medidas cabíveis para sanar ou evitar a invalidade ou a ineficácia de qualquer de tais
declarações;
(l) Tem plena ciência e concorda integralmente com as taxas de juros, dos encargos e demais condições financeiras desta CCB,
sendo que reconhece que a forma de cálculo de tais condições foi acordada por sua livre vontade, em observância ao princípio
da boa-fé;
(m) Está ciente e de acordo com o as condições do Desconto de Pontualidade previstas nesta CCB;
(n) As informações prestadas ao Credor por meio desta Cédula ou em momento anterior à sua assinatura, são verdadeiras,
especialmente acerca da licitude da origem de sua renda e patrimônio, bem como está ciente do art. 11, II, da Lei n° 9.613, de
03 de março de 1998, e dos arts. 297, 298 e 299 do Decreto-Lei nº 2.848, de 7 de dezembro de 1940 (“Código Penal”); e (n)
Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.
(o) Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.

§ Primeiro – O Avalista declara e garante que:
(a) Possui plena capacidade e legi midade para prestar o Aval e cumprir todas as obrigações aqui assumidas, tendo tomado
todas as medidas necessárias para implementação e cumprimento de todas as obrigações cons tuídas;
(b) Está apto a cumprir as obrigações ora previstas nesta CCB e agirá em relação a estas de boa-fé e com lealdade;
(c) Não se encontra em estado de necessidade ou sob coação para prestar o Aval;
(d) Está ciente e de acordo que, a par r da data do endosso da presente CCB, o terceiro endossatário passará a ser o credor
efe vo desta CCB, ficando sub-rogado em todos os direitos e obrigações do Credor e desobrigando o credor-endossante de
qualquer responsabilidade com relação à CCB;
(e) Não se opõe aos encargos cobrados nesta CCB;
(f) Adotará todas as providências para manter válidas e eficazes as declarações con das nesta Cédula, mantendo o Credor
informado de qualquer ato ou fato que possa afetar a validade de qualquer das referidas declarações e adotando as medidas
cabíveis para sanar ou evitar a invalidade de tais declarações;
(g) Permanecerá adimplente com suas obrigações fiscais, trabalhistas e previdenciárias, exceção feita às que eventualmente
estejam sendo contestadas
de boa-fé, judicial ou administra vamente nos termos da legislação então em vigor; (h) Não é uma Pessoa Poli camente
Exposta;
(i) Manterá seu endereço constantemente atualizado perante o Credor, para efeito de comunicação sobre qualquer ato ou
fato decorrente desta Cédula;
(j) Manterá válidas e eficazes as declarações prestadas nesta CCB, mantendo o Credor, no prazo de até 3 (três) Dias Úteis
contados da data de ocorrência, informado de qualquer ato ou fato que possa afetar a validade ou a eficácia de qualquer
destas declarações e adotando as medidas cabíveis para sanar ou evitar a invalidade ou a ineficácia de qualquer de tais
declarações;
(k) Tem plena ciência e concorda integralmente com as taxas de juros, dos encargos e demais condições financeiras desta
CCB, sendo que reconhece que a forma de cálculo de tais condições foi acordada por sua livre vontade, em observância ao
princípio da boa-fé;
(l) Está ciente e de acordo que não terá direito ao Desconto de Pontualidade, o qual é oferecido exclusivamente ao Emitente,
observadas as condições desta CCB;
(m) Leu a presente Cédula e não tem dúvidas sobre qualquer de suas condições.

§ Segundo - Até a integral liquidação de todas as obrigações oriundas desta CCB, o Emitente e o Avalista se comprometem a manter as
declarações acima prestadas sempre corretas e verdadeiras, obrigando-se a comprovar tal situação no prazo de 48 (quarenta e oito) horas
a contar da solicitação feita pelo Credor originário ou endossatário, mediante o envio das cer dões e dos documentos comprobatórios
correspondentes que forem necessários, sem prejuízo da eventual incidência de Desconto de Pontualidade.

§ Terceiro - Caso quaisquer das declarações acima prestadas sejam ou venham a se tornar, a qualquer momento, inverídicas ou incorretas,
o Credor originário ou endossatário deverá no ficar o Emitente ou o Avalista, conforme o caso, para que tome as medidas necessárias
para corrigir tal situação, no prazo de 72 (setenta e duas) horas, contadas do recebimento da referida no ficação, sem prejuízo do
vencimento antecipado desta CCB.

Cláusula Décima Segunda - O Emitente declara que tomou ciência e concordou com o Custo Efe vo Total (“CET”), na qual foram
explicitados, além do valor em reais de cada componente do fluxo da operação, os respec vos percentuais em relação ao valor total
devido, conforme estabelecido nas Resoluções CMN n.º 3.517/2007 e 4.197/2013.

Cláusula Décima Terceira– Nos termos da legislação vigente, o Emitente autoriza o Credor a emi r Cer ficado de Cédulas de Crédito
Bancário – CCCB, com lastro no presente título, podendo negociá-los no mercado, de acordo com o disposto na legislação vigente,
inclusive observadas as normas emi das pelo CMN e pelo Banco Central.

Cláusula Décima Quarta – Após o endosso pelo Credor desta Cédula, o Emitente, o Avalista e o novo credor-endossatário, desde já, (a)
exoneram o credor-endossante de toda e qualquer responsabilidade em relação (i) à veracidade e exa dão das informações e
documentação fornecidas pelo Emitente e demais partes signatárias, e (ii) ao acompanhamento do cumprimento das obrigações
assumidas nesta Cédula; e (b) reconhecem a validade da emissão e do endosso desta CCB de forma eletrônica, o que é feito com base no
art. 889, §3º, do Código Civil.

§ Primeiro – O Emitente e o Avalista estão integralmente cientes e de acordo com o seguinte: (i) qualquer liXgio ou ques onamento,
judicial ou extrajudicial, que possa vir a ser ajuizado, deverá ser ajuizado, àquele portador endossatário da CCB na data do ajuizamento do
liXgio ou ques onamento; e (ii) o ajuizamento de qualquer ação, judicial ou extrajudicial, pelo Emitente ou pelo Avalista contra a MONEY
PLUS SCMEPP LTDA, na qualidade de Credor, após a SCMEPP LTDA ter endossado esta Cédula para terceiro, acarretará
na responsabilidade pelo pagamento de indenização por perdas e danos e ressarcimento de todo e qualquer custo e despesas que a SCMEPP LTDA venha a incorrer (incluindo de honorários advocatícios) para defesa de seus direitos no respec vo liXgio.

§ Segundo – Após o endosso desta Cédula, na eventualidade do pagamento ou vencimento antecipado do presente título, o endossatário
ficará obrigado a receber antecipadamente os recursos des nados à quitação da dívida no montante equivalente ao valor integral e
atualizado do principal da dívida desta Cédula, acrescido da incidência dos respec vos encargos até a data da ocorrência do seu efe vo
pagamento.

§ Terceiro – Com o endosso desta Cédula, o Emitente e o Avalista autorizam o Credor, desde já, de forma irretratável e irrevogável, a
informar e fornecer ao endossatário, informações sobre a presente Cédula, bem como sobre a estrutura, documentação e fluxo de
garan as cons tuídas, seja através de extratos bancários da conta corrente indicada no preâmbulo, na forma do art. 28 da Lei nº 10.931,
e/ou relatórios, reconhecendo que estes procedimentos não cons tuem infrações às regras que disciplinam o sigilo bancário.

§ Quarto – O Emitente e o Avalista somente poderão ceder suas obrigações e direitos decorrentes desta Cédula, com autorização prévia e
expressa do Credor.
Cláusula Décima Quinta – O Emitente e o Avalista autorizam o Credor, em caráter irrevogável e irretratável e na forma da
regulamentação aplicável: a (i) transmi r e consultar informações sobre o Emitente e/ou sobre o Avalista, e rela vas a esta operação ao
Sistema de Informação de Crédito (SCR) man do pelo Banco Central, u lizando tais informações, inclusive, para análise da capacidade de
crédito dos mesmos, bem como fornecer tais informações a terceiros que sejam contratados para prestar serviços de controle e cobrança,
por quaisquer meios, das obrigações assumidas nesta CCB; (ii) levar a registro esta Cédula em quaisquer órgãos públicos, cartórios e
ins tuições de custódia e liquidação financeira de título, especialmente a CETIP S/A – Mercados Organizados; e (iii) em caso de
inadimplemento, inserir o nome do Emitente e/ou do Avalista em bancos públicos ou privados de restrição cadastral.

Cláusula Décima Sexta – Observado o condicionamento da eficácia da presente CCB conforme parágrafo primeiro abaixo, esta Cédula,
inclusive no que diz respeito à cobrança de juros, encargos e tributos a ela aplicáveis, é válida a par r da data de sua emissão e vigorará
até o pagamento integral do seu saldo devedor, nos termos ora estabelecidos por esta CCB.

§ Primeiro – CONSTITUI CONDIÇÃO SUSPENSIVA PARA A EFICÁCIA DESTA CÉDULA, nos termos do ar go 125 do Código Civil, A
DISPONIBILIZAÇÃO PELO CREDOR AO EMITENTE DO VALOR DE PRINCIPAL indicado no Campo IV acima (“Condição Suspensiva”),
observado que eventual valor re do pela SCMEPP LTDA por conta e ordem e a pedido do Emitente, não deve
descaracterizar o Valor de Principal para os fins da Condição Suspensiva.

§ Segundo – Na hipótese de a CONDIÇÃO SUSPENSIVA NÃO OCORRER EM ATÉ 10 (DEZ) DIAS APÓS A EMISSÃO DESTA CCB, a
PRESENTE CÉDULANÃO SERÁ REVESTIDA DE EFICÁCIA E SE EXTINGUIRÁ DE PLENO DIREITO, sem qualquer ônus ou
penalidade para qualquer das partes.

Cláusula Décima Sétima – A presente Cédula é emi da em caráter irrevogável e irretratável, e obriga todos os seus signatários e seus
eventuais sucessores a qualquer título.

Cláusula Décima Oitava – Eventuais Nulidades – Caso alguma disposição desta CCB venha a ser considerada ilegal, inexequível ou nula, as
demais disposições permanecerão válidas. Nesta hipótese, os signatários e o Credor de comum acordo, deverão alterar esta Cédula,
modificando a referida disposição, na medida necessária para torná-la legal e exequível, ao mesmo tempo preservando seu obje vo, ou se
isso não for possível, subs tuindo-a por outra disposição que seja legal e exequível, e que a nja o mesmo obje vo.

Cláusula Décima Nona – Em comum acordo com o Credor, esta Cédula poderá ser renovada, aditada, re ficada e ra ficada mediante
documento escrito e datado, no qual constará todas as condições a serem introduzidas e uma vez assinado pelas partes, passará a integrar
esta Cédula para todos os fins e efeitos de direito.

Cláusula Vigésima – Quitação Antecipada do Saldo Devedor desta Cédula – A presente Cédula poderá ser quitada antecipadamente,
aplicando-se a redução proporcional de juros e demais acréscimos, em atenção à regra em vigor para pessoas [sicas, incluindo, mas não se
limitando, a Resolução nº 3.516, de 6 de dezembro de 2007, conforme alterada.

§ Parágrafo Primeiro – Na hipótese de quitação antecipada desta CCB nos termos da Cláusula Décima Oitava acima, o cálculo do valor
objeto da liquidação antecipada será feito com base na taxa estabelecida no Campo IV, itens 9 e 10 acima.

§ Parágrafo Segundo – Na hipótese de quitação antecipada desta CCB o Emitente não terá direito ao Desconto de Pontualidade, sendo
devidos os juros remuneratórios.

Cláusula Vigésima Primeira– Fica eleito o foro da Comarca de São Paulo-SP, ressalvado ao Credor o direito de optar pelo do domicílio do
Emitente para dirimir quaisquer questões oriundas desta Cédula.

Cláusula Vigésima Segunda – A SCMEPP LTDA, ora Credor, fica desde já autorizado a prestar informações sobre as partes
signatárias ou a movimentação financeira, nas hipóteses de recebimento de requisições oriundas da Receita Federal, o[cios des nados à
apuração de ilícito ou ainda por ordem judicial, nos termos da Lei Complementar nº 105, de 10 de janeiro de 2001, e Decreto nº 4.489, de
28 de novembro de 2002.

Cláusula Vigésima Terceira - O Emitente e o Avalista declaram, ainda, ter lido previamente a presente Cédula e não ter dúvidas sobre
qualquer de suas condições. O Emitente declara também que está na posse de uma via eletrônica não negociável desta cédula e emi u a
via negociável eletrônica ao Credor, assim como declara ter ciência, nos termos da Lei nº 10.931, de que esta CCB é um título de crédito
emi do unilateralmente e, consequentemente, não há necessidade de assinatura do Credor e de testemunhas, sendo considerado título
execu vo extrajudicial nos termos da mencionada lei, sendo comprovada sua aceitação mediante assinatura eletrônica desta CCB,
observado que sua eficácia está sujeita à verificação da Condição Suspensiva, conforme Cláusula Décima Quarta acima.

Cláusula Vigésima Quarta - Se a data de vencimento de qualquer pagamento devido nos termos desta Cédula coincidir com um dia não
ú l, o pagamento deverá ser efetuado no primeiro Dia Ú l subsequente, conforme definição a seguir, sendo certo que quaisquer juros ou
encargos nos termos desta CCB incidirão até a data do efe vo pagamento. Para fins do disposto neste instrumento, entende-se por dias
úteis todos os dias, exceto sábados, domingos e feriados bancários nacionais (“Dia Ú l”)`;
