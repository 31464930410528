import styled from 'styled-components';

import { colors } from '../../../shared/styles/colors';

export const DigitStyled = styled.input`
	width: 40px;
	height: 55px;
	border-radius: 4px;
	border: 2px solid ${colors.primaryHover};
	padding: 10px;
	font-size: 1.2rem;
	font-weight: 600;
	margin: 10px;
`;

export const DivRowStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
`;
