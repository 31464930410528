import { HTMLProps } from 'react';

import { Control, Controller } from 'react-hook-form';

import { LabelStyled, ErrorStyled, SpanStyled, SpanNoneStyled } from './styles';

export interface InputProps extends HTMLProps<HTMLInputElement> {
	// Props for Element
	label?: string;
	placeholder?: string;
	currency?: boolean;
	mask?: string;
	maskChar?: string;
	type?: string;
	value?: any;
	id?: string;
	disabled?: boolean;
	className?: string;
	dataTestID?: string;
	toolTip?: HTMLElement | undefined;
	inputRef?: React.RefObject<HTMLInputElement>;
	accept?: string;
	// Is it controlled?
	controlled?: boolean;

	// Functions
	onBlur?: (event: any) => void;
	onChange?: (event: any) => void;
	onFocus?: (value: any) => void;
	onKeyPress?: (value: any) => void;

	// React-hook-form
	name?: string;
	control?: Control;
	customValue?: string;
	error?: string;
}

export function InputFile({
	placeholder,
	disabled,
	name,
	id,
	dataTestID,
	onBlur,
	onChange,
	control,
	error,
	inputRef,
	accept,
	className,
}: InputProps) {
	if (control && name) {
		return (
			<>
				<LabelStyled>
					<Controller
						data-testid="controlInputFile"
						control={control}
						name={name}
						render={({
							field: { onChange: onChangeForm, onBlur: onBlurForm },
						}) => (
							<input
								disabled={disabled}
								name={name}
								id={id}
								data-testid={dataTestID}
								ref={inputRef}
								className={className}
								accept={accept}
								type="file"
								onChange={(e) => {
									onChangeForm(e);
									if (onChange) {
										onChange(e);
									}
								}}
								onBlur={(e) => {
									onBlurForm();
									if (onBlur) {
										onBlur(e);
									}
								}}
							/>
						)}
					/>{' '}
					<SpanNoneStyled className="file-cta" id="cv">
						<SpanNoneStyled className="file-icon">
							<i className="fas fa-upload"></i>
						</SpanNoneStyled>
					</SpanNoneStyled>
					<SpanStyled>{placeholder}</SpanStyled>
				</LabelStyled>
				{error && <ErrorStyled>{error}</ErrorStyled>}
			</>
		);
	}
	return (
		<LabelStyled>
			<input
				disabled={disabled}
				name={name}
				id={id}
				data-testid={dataTestID}
				ref={inputRef}
				className={className}
				accept={accept}
				type="file"
				onBlur={onBlur}
				onChange={onChange}
			/>
			<SpanStyled>{placeholder}</SpanStyled>
		</LabelStyled>
	);
}
