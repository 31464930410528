import styled from 'styled-components';
import { colors } from '../../styles';

export const DivStyled = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${colors.white};
	align-items: center;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9999;
`;
