import * as Yup from 'yup';
import { undoMoneyMask } from '../../../shared/utils/masks';

export const PasswordSchema = Yup.object().shape({
	password: Yup.string()
		.required('Informe sua senha atual')
		.min(8, 'Informe sua senha atual')
		.matches(/([a-zA-Z])/, 'Informe sua senha atual')
		.matches(/([0-9])/, 'Informe sua senha atual'),
	new_password: Yup.string()
		.required('Digite a nova senha')
		.matches(/[a-z]/, 'Sua senha precisa ter no mínimo um caractere minúsculo.')
		.matches(/[A-Z]/, 'Sua senha precisa ter no mínimo um caractere maiúsculo.')
		.matches(/[0-9]/, 'Sua senha precisa ter no mínimo um caractere numérico.')
		.notOneOf(
			[Yup.ref('password'), null],
			'A nova senha deve ser diferente da senha atual'
		)
		.min(8, 'Sua senha precisa ter no mínimo 8 caracteres.'),
	new_password_confirm: Yup.string()
		.required('Repita a nova senha')
		.oneOf([Yup.ref('new_password'), null], 'As senhas devem ser idênticas'),
});

export const PersonalDataSchema = Yup.object().shape({
	full_name: Yup.string()
		.required('Nome é obrigatório')
		.trim()
		.matches(
			/^([a-zA-ZÀ-ú]{2,}[ ])+([a-zA-ZÀ-ú]{1,})+(?: [a-zA-Z]+){0,4}$/,
			'Digite nome e sobrenome, números são proibidos!'
		)
		.nullable(),
	salary: Yup.string()
		.test('len', '', function (value) {
			const undoMoney = undoMoneyMask(value ?? '');

			if (undoMoney.length < 7)
				return this.createError({
					message: 'A renda deve ser maior que R$ 1.000,00 mensais',
				});

			if (undoMoney.length >= 9)
				return this.createError({
					message: 'A renda não pode ser maior que R$ 99.999,99 mensais',
				});
			return true;
		})
		.required('Digite sua renda'),
	phone: Yup.string()
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(11, 'Digite um número de telefone válido')
		.test('len', 'Digite um número de telefone válido', function (value) {
			if (value) {
				if (value?.length !== 11 || value[2] !== '9') return false;
				return true;
			}
			return false;
		})
		.required('Número de telefone é obrigatório'),
});

export const AdressSchema = Yup.object().shape({
	street: Yup.string().required('Nome da rua obrigatório'),
	neighborhood: Yup.string().required(
		'Preencha com o nome do bairro que você mora'
	),
	city: Yup.string().required('Cidade é obrigatória'),
	state: Yup.string()
		.required('Estado é obrigatório')
		.transform((value) => value.replace('_', ''))
		.length(2, 'Digite a sigla do estado. Ex: SP'),
	number: Yup.string()
		.max(9999999999, 'Digite um número válido')
		.required('Número é obrigatório'),
	complement: Yup.string().notRequired(),
});
