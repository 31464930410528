import axios from '../../../shared/http/customAxios';

class OrderThanksRepository {
	async checkKyc(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/kyc`, {
				headers: { noAuthorization: true },
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async checkWhatsapp(transaction: string) {
		try {
			const result = await axios().get(
				`/v1/order/${transaction}/check_whatsapp`,
				{ headers: { noAuthorization: true } }
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default OrderThanksRepository;
