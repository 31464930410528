import { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import { moneyMask } from '../../../shared/utils/masks';
import { installmentReason } from '../../../shared/variables';
import { OrderSendingValidator } from '../utils/orderCheckoutValidator';

import { SelectedInstallmentProps, OrderProps } from '../interfaces';

import { Button, SelectControlled } from '../../../shared/components/index';

import RenderInstallments from './RenderInstallment';

import { useLoading } from '../../../hooks/loading';

interface FirstSectionProps {
	order: OrderProps;
	installments: [];
	cet: string;

	handleNextSection: ({ reason, selectedInstallmentState }: any) => void;
}

const FirstSection = ({
	order,
	installments,
	cet,
	handleNextSection,
}: FirstSectionProps) => {
	const [reason, setReason] = useState<string | null>(null);
	const [selectedInstallmentState, setSelectedInstallmentState] =
		useState<SelectedInstallmentProps | null>(null);

	const validator = new OrderSendingValidator();

	const { setIsLoading } = useLoading();

	useEffect(() => {
		if (installments?.length) {
			const installment = installments.filter(
				(item: any) => item.parcelas === order.installment
			)[0];
			setSelectedInstallmentState(installment);
		}
		setIsLoading(false);
	}, [order, installments]);

	const validateSection = () => {
		const validReason = validator.validateReason(reason);
		const validSelectedInstallment = validator.validateSelectedInstallment(
			selectedInstallmentState
		);

		if (validReason && validSelectedInstallment)
			handleNextSection({ reason, selectedInstallmentState });
	};

	const labelSelect = order.has_grace_period_gt_0
		? 'Por que está comprando com a gente?'
		: 'Por que está parcelando com a gente?';

	const onChangeSelect = (e: any) => {
		setReason(e.target.value);
	};

	return (
		<section className="column my-2 pt-0 py-0 is-flex is-flex-direction-column is-12-mobile is-4-desktop is-offset-4-desktop">
			<h2 className="is-size-4 has-text-black has-text-centered">
				Você está {order.has_grace_period_gt_0 ? 'comprando' : 'parcelando'}:
			</h2>

			<div className="has-text-success my-5">
				<p className="is-size-6">
					<AiFillCheckCircle className="ml-5 mr-2" />
					Produto: {order.description}
				</p>
				<p className="is-size-6">
					<AiFillCheckCircle className="ml-5 mr-2" />
					Valor da compra: {moneyMask(order.total_amount)}
				</p>
			</div>

			<h5 className="is-size-6 has-text-centered">
				Selecione abaixo as parcelas:
			</h5>

			<RenderInstallments
				installments={installments}
				defaultInstallment={order.installment}
				cet={cet}
				selectedInstallment={selectedInstallmentState}
				setSelectedInstallment={setSelectedInstallmentState}
				hasGracePeriodGt0={order.has_grace_period_gt_0}
				totalAmount={order.total_amount}
			/>

			<div>
				<SelectControlled
					onChange={onChangeSelect}
					id="installment-reason-input"
					label={labelSelect}
					options={installmentReason}
					dataTestID="installment-reason-input"
				/>
			</div>

			<ReactTooltip />

			<hr />

			<div className="is-flex is-justify-content-center">
				<Button
					onClick={validateSection}
					className="my-3 has-gradient-blue has-text-white"
					width="80%"
					text="Próximo"
				/>
			</div>

			{order.return_url && (
				<a href={order.return_url} className="has-text-centered has-text-blue">
					Desistir e voltar para loja
				</a>
			)}
		</section>
	);
};

export default FirstSection;
