import axios from '../../../shared/http/customAxios';

class orderRefusedRepository {
	async getOrder(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/refused`, {
				headers: { noAuthorization: true },
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default orderRefusedRepository;
