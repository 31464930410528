import 'firebase/remote-config';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { config } from './config.json';

const firebaseConfig = {
	apiKey: config.firebase_api_key,
	authDomain: config.firebase_auth_domain,
	projectId: config.firebase_project_id,
	storageBucket: config.firebase_storage_bucket,
	messagingSenderId: config.firebase_measurement_id,
	appId: config.firebase_app_id,
	measurementId: config.firebase_measurement_id,
};

export const firebase = initializeApp(firebaseConfig);

export const firebaseEventPush = getAnalytics(firebase);
