import styled from 'styled-components';

export const IMGAStyled = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 130px;
	height: 130px;
	background-color: #ffffff;
`;

export const IMGStyled = styled.img`
	max-width: 100px;
	height: auto;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 1rem 0;
`;

export const TitleStyled = styled.h2`
	text-align: left;

	font-size: 1.4rem;
	font-weight: 600;
	font-family: Montserrat, sans-serif;
	color: #000;
	margin: 1rem 0 0.5rem;
	font: normal normal 600 35px/63px Montserrat;
`;
