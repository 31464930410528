import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { AiFillInfoCircle } from 'react-icons/ai';

import { AiOutlineArrowLeft, AiFillQuestionCircle } from 'react-icons/ai';

import ReactTooltip from 'react-tooltip';

import { Button } from '../../shared/components';

import { colors } from '../../shared/styles/colors';

export const BlueTitleStyled = styled.h1`
	text-align: center;
	font-weight: 500;
	font-size: 27px;
	color: ${colors.textBlue};
	margin-top: -2rem;

	@media (max-width: 900px) {
		font-size: 24px;
	}
`;

export const ContainerStyled = styled.div`
	flex-direction: column;
	align-items: center;

	@media (max-width: 500px) {
		margin: 0 24px;
	}
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 1.8rem;
`;

export const FormStyled = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 3rem 0;
`;

export const InstructionsDivStyled = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 3rem 0;
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0 0.5rem;
`;

export const PStyled = styled.p`
	text-align: center;
	font-weight: 400;
	font-size: 12px;
`;

export const BlueLinkStyled = styled(Link)`
	color: ${colors.textBlue};
	text-align: center;
	font-weight: 601;
	font-size: 13px;
`;

export const FaceDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;
`;

export const SpanStyled = styled.span`
	color: ${colors.textBlue};
	text-align: center;
	font-weight: 601;
	font-size: 13px;
	cursor: pointer;
`;

export const InputsDivStyled = styled.div`
	display: flex;
	flex-direction: row;

	@media (max-width: 1200px) {
		flex-direction: column;
		width: 300px;
	}
`;

export const InputDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0.1rem 0.1rem;
	width: 100%;
`;

export const RendaPStyled = styled.p`
	text-align: left;
	font-weight: 400;
	font-size: 12px;
	color: rgba(10, 10, 10, 0.4);
`;

export const NegativeDivStyled = styled.div`
	display: flex;
	flex-direction: row;
  text-align: center
  align-items: start;
  justify-content: start;
  width: 40%;
  margin: 1rem 0.4rem 0.2rem;

  @media (max-width: 2700px) {
    width: 18%;
  };

  @media (max-width: 2400px) {
    width: 20%;
  };

  @media (max-width: 1700px) {
    width: 35%;
  };

  @media (max-width: 675px) {
    width: 50%;
  };

  @media (max-width: 500px) {
    width: 80%;
    margin: 0.9rem 0.4rem 0;

  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 28%;
	margin: 0.5rem 0;

	@media (max-width: 2700px) {
		width: 18%;
	}

	@media (max-width: 2500px) {
		width: 20%;
	}

	@media (max-width: 2400px) {
		width: 23%;
	}

	@media (max-width: 1700px) {
		width: 33%;
	}

	@media (max-width: 675px) {
		width: 50%;
	}

	@media (max-width: 500px) {
		width: 60%;
		margin: 0.9rem 0.4rem 0;
	}

	@media (max-width: 360px) {
		width: 80%;
	}
`;

export const AClickStyled = styled.a`
	text-align: center;
	font-weight: 401;
	margin: 0 0.2rem;
	cursor: pointer;
	text-decoration: underline;
	color: ${colors.textBlue};
`;

export const AClickFourStyled = styled.a`
	text-align: center;
	font-weight: 550;
	margin: 1rem 0.2rem;
	cursor: pointer;
	color: ${colors.textBlue};
`;

export const NegativePStyled = styled.p`
	text-align: center;
	font-weight: 400;
	font-size: 12px;
	margin: 0.1rem 0 0 0.5rem;
`;

export const NegativesDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;
`;

export const BlackPStyled = styled.p`
	text-align: center;
	font-weight: 700;
	font-size: 12px;
	& a + .__react_component_tooltip {
		width: 50%;
	}
`;

export const ErrorAiFillInfoCircleStyled = styled(AiFillInfoCircle)`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const CircleButtonStyled = styled(Button)`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border: 1px solid rgba(10, 10, 10, 0.4);
	display: flex;
	align-items: top;
	justify-content: top;
	border-radius: 50px;
	margin-left: 0.2rem;
`;

export const CircleButtonDivStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AiOutlineArrowLeftGreyStyled = styled(AiOutlineArrowLeft)`
	color: grey;
	text-align: center;
	font-weight: 601;
	cursor: pointer;
	font-size: 24px;
`;

export const FourDivStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
`;

export const InstructionsDivFourStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60%;

	@media (max-width: 2700px) {
		width: 30%;
	}

	@media (max-width: 2500px) {
		width: 35%;
	}

	@media (max-width: 2400px) {
		width: 38%;
	}

	@media (max-width: 2400px) {
		width: 40%;
	}

	@media (max-width: 1700px) {
		width: 60%;
	}

	@media (max-width: 675px) {
		width: 50%;
	}

	@media (max-width: 500px) {
		width: 99%;
	}

	@media (max-width: 360px) {
		width: 100%;
	}
`;

export const StoreDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`;

export const SpanFourStyled = styled.span`
	cursor: pointer;
	font-size: 12px;
`;

export const BonusDivStyled = styled.div`
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
`;

export const InfoStyled = styled(AiFillQuestionCircle)`
	margin-bottom: -0.45rem;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
`;

export const ReactTooltipStyled = styled(ReactTooltip)`
	text-align: left !important;

	@media (min-width: 768px) {
		mix-width: 180px;
	}
`;

export const GapDivStyled = styled.div`
	margin: 1.2rem auto 0.8rem;
	width: 300px;
	height: 2px;
	border-radius: 5px;
	background-color: rgba(10, 10, 10, 0.1);
`;

export const H1Styled = styled.p`
	margin-top: 1rem;
	text-align: center;
	font-weight: 500;
	font-size: 15px;
	width: 90%;

	@media (max-width: 900px) {
		font-size: 13px;
	}
`;
