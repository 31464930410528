import { useHistory } from 'react-router-dom';

import {
	PCanceledBoldStyled,
	DivCenteredStyled,
	ContainerStyled,
	TitleCanceledStyled,
	PCanceledStyled,
	CenterDivStyled,
	AStyled,
	// ASuccessStyled,
} from '../../styles';

import { Button } from '../../../../shared/components';

export function CanceledAccount() {
	const history = useHistory();

	return (
		<main>
			<CenterDivStyled>
				<DivCenteredStyled>
					<ContainerStyled>
						<TitleCanceledStyled>Sua conta foi cancelada!</TitleCanceledStyled>
						<PCanceledStyled>
							Suas informações irão ficar preservadas em nosso banco de dados
							mas de forma anônima, de acordo com as leis de sigilo, ou seja,
							sem identificação de que é você, fique tranquilo.
						</PCanceledStyled>
						<PCanceledBoldStyled>
							Lembre-se! Estaremos sempre por aqui caso queira parcelar suas
							compras no futuro! <br />
							Até breve, <br />
							Equipe VirtusPay
						</PCanceledBoldStyled>

						<p>
							Caso precise reativar sua conta, entre em contato com nosso
							atendimento em{' '}
							<AStyled
								target="__blank"
								rel="noreferrer"
								href="mailto:atendimento@usevirtus.com.br"
							>
								atendimento@usevirtus.com.br
							</AStyled>{' '}
							{/* <br />
							ou pelo{' '}
							<ASuccessStyled
								target="_blank"
								rel="noreferrer"
								href="https://api.whatsapp.com/send?phone=5511989442146&text=Para%20facilitar%20seu%20contato%20conosco,%20envie%20essa%20mensagem%20e%20nos%20adicione%20em%20seus%20contatos.%20Obrigado!%20Equipe%20VirtusPay&source=&data=#."
							>
								WhatsApp (11) 98944-2146
							</ASuccessStyled> */}
						</p>
						<CenterDivStyled>
							<Button
								onClick={() => history.push('/')}
								text="Voltar para o site"
								width="300px"
								className="has-gradient-blue"
								textColor="#fff"
							/>
						</CenterDivStyled>
					</ContainerStyled>
				</DivCenteredStyled>
			</CenterDivStyled>
		</main>
	);
}
