import { useEffect } from 'react';

import { VirtusLoader } from '../index';

export const RegisterRedirect = () => {
	useEffect(() => {
		const typeUser = {
			profile: 'tomador',
		};

		localStorage.setItem('user', JSON.stringify(typeUser));
		window.location.href = '/register';
	});

	return <VirtusLoader />;
};
