import {
	BetweenDivStyled,
	CenterDivStyled,
	BulletStyled,
	CenterStyled,
	TitleStyled,
	PStyled,
	PSemiBoldStyled,
	SpanStyled,
	ButtonStyled,
} from './styles';

import MobileApp from '../../../assets/images/mobile_with_app.png';
import IconPlayStore from '../../../assets/images/icon_playstore.png';

interface IProps {
	hasGracePeriodGt0?: boolean;
}

function DownloadApp(props: IProps) {
	const hasGracePeriodGt0 = props.hasGracePeriodGt0;

	return (
		<div style={{ backgroundColor: '#fafafa' }}>
			<CenterStyled>
				<BetweenDivStyled>
					<TitleStyled>Acompanhe seu pedido pelo App VirtusPay</TitleStyled>
					<div>
						<img src={MobileApp} alt="Celular com App VirtusPay" />
					</div>
					<div>
						<PStyled>
							Acesse ou crie sua conta no App VirtusPay usando o mesmo CPF de
							sua compra e receba:
						</PStyled>
						<CenterDivStyled>
							<div style={{ paddingRight: '0.2rem' }}>
								<BulletStyled></BulletStyled>
							</div>
							<PSemiBoldStyled>
								Status
								{hasGracePeriodGt0 ? ' da sua compra' : ' do seu parcelamento'}
							</PSemiBoldStyled>
						</CenterDivStyled>
						<CenterDivStyled>
							<div style={{ paddingRight: '0.2rem' }}>
								<BulletStyled></BulletStyled>
							</div>
							<PSemiBoldStyled>
								Avisos sobre vencimento
								{hasGracePeriodGt0 ? ' da parcela' : ' das parcelas'}
							</PSemiBoldStyled>
						</CenterDivStyled>
						<CenterDivStyled>
							<div style={{ paddingRight: '0.2rem' }}>
								<BulletStyled></BulletStyled>
							</div>
							<PSemiBoldStyled>
								Ofertas imperdíveis para parcelar no boleto
							</PSemiBoldStyled>
						</CenterDivStyled>
						<h3 style={{ fontSize: '1rem' }}>Baixe o App</h3>
						<div style={{ margin: '0.8rem 0' }}>
							<ButtonStyled
								onClick={() => {
									window.open(
										'https://play.google.com/store/apps/details?id=com.virtuspay.virtus_app_tomador&hl=pt_BR',
										'_blank'
									);
								}}
							>
								<img src={IconPlayStore} alt="Ícone PlayStore" />
								<SpanStyled>Play Store</SpanStyled>
							</ButtonStyled>
						</div>
						<PSemiBoldStyled>
							Você também pode criar sua conta no
							<SpanStyled onClick={() => (window.location.href = '/register')}>
								{' '}
								site
							</SpanStyled>
						</PSemiBoldStyled>
					</div>
				</BetweenDivStyled>
			</CenterStyled>
		</div>
	);
}

export { DownloadApp };
