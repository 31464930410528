import CCBRepository from '../repositories/ccbRepository';
import CCBDto from '../models/ccbModel';
import redirect from '../../../shared/utils/redirect';

class CCBController {
	repository: CCBRepository;
	dto: CCBDto;

	constructor() {
		this.repository = new CCBRepository();
		this.dto = new CCBDto();
	}

	async get() {
		const result = await this.repository.get(this.dto.transaction);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}

	async submit() {
		const result = await this.repository.submit(this.dto);

		if (result.status !== 200)
			return { resultSubmit: result, errorSubmit: true, redirect: null };

		const { redirectPage } = redirect(
			result.data.next_step,
			this.dto.transaction
		);

		return { resultSubmit: result, errorSubmit: false, redirect: redirectPage };
	}
}

export default CCBController;
