import { AiOutlineRight } from 'react-icons/ai';

import Logo from '../../assets/logo_virtuspay_branco_200.png';

import Background from '../../assets/Taker/fundo-blackfrinday.jpg';
import Girl from '../../assets/Taker/girl.webp';

import SocialMedia from '../../assets/Taker/queroParcelar/social-media.png';
import OnlineShopping from '../../assets/Taker/queroParcelar/online-shopping.png';
import Tiles from '../../assets/Taker/queroParcelar/tiles.png';
import Trackpad from '../../assets/Taker/queroParcelar/trackpad.png';

import GooglePlay from '../../assets/Taker/boletoPOP/google-play-badge.png';
import Cellphone from '../../assets/Taker/boletoPOP/what-is-sectio-app.webp';

import Vantagem01 from '../../assets/Taker/vantagens/vantagens-01.webp';
import Vantagem02 from '../../assets/Taker/vantagens/vantagens-02.webp';
import Vantagem03 from '../../assets/Taker/vantagens/vantagens-03.webp';
import Vantagem04 from '../../assets/Taker/vantagens/vantagens-04.webp';

import Depoimento01 from '../../assets/Taker/depoimentos/depoimento-tomador-simony.png';
import Depoimento02 from '../../assets/Taker/depoimentos/depoimento-tomador-danrlley.png';
import Depoimento03 from '../../assets/Taker/depoimentos/depoimento-tomador-priene.png';

import BlackFriday from '../../assets/Taker/blog/Black-Friday.webp';
import BNPL from '../../assets/Taker/blog/BNPL.webp';
import PagamentoOnline from '../../assets/Taker/blog/Pagamento-online-1.webp';
import SemanaBrasil from '../../assets/Taker/blog/semana-do-brasil.webp';

import MediaR7 from '../../assets/media/logo_r7.png';
import MediaStartSE from '../../assets/media/logo_startse.png';
import MediaVisa from '../../assets/media/logo_visa.png';
import MediaFolha from '../../assets/media/logo_folha.png';
import MediaExame from '../../assets/media/logo_exame.png';

import Facebook from '../../assets/Taker/socialMedia/Facebook.png';
import Instagram from '../../assets/Taker/socialMedia/Instagram.png';
import Linkedin from '../../assets/Taker/socialMedia/Linkedin.png';
import Youtube from '../../assets/Taker/socialMedia/Youtube.png';

import Check from '../../assets/Taker/depoimentos/check.png';

import { Header } from '../../shared/components/Header';

import { ButtonDivStyled } from './styles';

import { SearchStore } from '../../shared/components/SearchStore';
import { Carousel } from '../../shared/components/Carousel';
import { CopyRight } from '../../shared/components/Copyright';
import { Accordion } from '../../shared/components/Accordion';
import { Footer } from '../../shared/components/Footer';

export function LandingPageTaker() {
	const navLinks = [
		{
			href: '#como',
			text: 'Como funciona?',
		},
		{
			href: '/companies',
			text: 'Para empresas',
		},
		{
			href: '/assignor/sobre',
			text: 'Oferecer limite',
		},
		{
			href: 'https://blog.virtuspay.com.br/',
			text: 'Blog',
		},
		{
			href: 'https://ajuda.usevirtus.com.br',
			text: 'FAQ',
		},
		{
			href: 'https://ajuda.usevirtus.com.br/support/solutions',
			text: 'Precisa de ajuda?',
			onlyPhone: true,
		},
	];

	const title = (
		<h1
			style={{ color: '#FFFFFF' }}
			className="is-size-2-desktop is-size-4-mobile has-text-weight-bold"
		>
			Compre parcelado sem <br /> cartão de crédito!
		</h1>
	);

	const subTitle = (
		<p className="has-text-white my-5 is-size-7-mobile">
			Aqui na VirtusPay você consegue comprar parcelado <br /> na internet,{' '}
			<b className="has-text-weight-semibold">sem cartão de crédito</b> e em
			<b className="has-text-weight-semibold"> até 15x no boleto!</b>
		</p>
	);

	const buttonsTouch = (
		<ButtonDivStyled className="is-flex is-flex-direction-column is-hidden-desktop mt-4 is-align-items-center is-justify-content-center">
			<a
				href="/taker/register"
				style={{ width: '100%' }}
				className="new-button blue-gradient"
			>
				<strong className="text-white">Quero parcelar!</strong>
			</a>
			<button
				onClick={() => (document.location = '#como')}
				style={{ width: '100%' }}
				className="new-button mt-3 outline-button"
			>
				Mas como?
			</button>
		</ButtonDivStyled>
	);

	const buttonsDesktop = (
		<ButtonDivStyled className="is-flex is-flex-direction-row is-hidden-touch">
			<a href="/taker/register" className="new-button blue-gradient">
				<strong className="text-white">Quero parcelar!</strong>
			</a>
			<button
				onClick={() => (document.location = '#como')}
				className="new-button ml-2 outline-button"
			>
				Mas como?
			</button>
		</ButtonDivStyled>
	);

	return (
		<>
			<Header
				whichScreen="taker"
				backgroundImage={Background}
				secondaryImage={Girl}
				navLinks={navLinks}
				subTitle={subTitle}
				title={title}
				buttonsTouch={buttonsTouch}
				buttonsDesktop={buttonsDesktop}
				textColor="#00e8ec"
			/>

			<section id="como" className="column py-6 is-8-desktop container">
				<div className="container">
					<h2 className="has-text-blue bitter-font mt-4 is-size-4 has-text-weight-bold">
						Como parcelar?
					</h2>
					<p>
						Em poucos passos você pode parcelar sua compra{' '}
						<b className="has-text-weight-semibold">sem cartão de crédito!</b>
					</p>

					<div className="columns mt-4">
						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={SocialMedia}
								alt="Crie sua conta"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold has-text-centered">
								1. Crie sua conta na <br /> VirtusPay
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Você precisa ser maior de idade, não estar negativado e ter um
								E-mail válido
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={OnlineShopping}
								alt="Compre em uma das lojas aceitas"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold has-text-centered">
								2. Compre em uma <br /> das lojas aceitas
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Compre no site das lojas aceitas e gere um boleto à vista
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={Tiles}
								alt="Escolha em quantas vezes quer parcelar"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold has-text-centered">
								3. Escolha em quantas <br /> vezes quer parcelar
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Aqui na VirtusPay, envie seu boleto e escolha em quantas vezes
								quer parcelar
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={Trackpad}
								alt="Após aprovação, pague a entrada"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold has-text-centered">
								4. Após aprovação, <br /> pague a entrada
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Se aprovado, pague a entrada e pronto! A loja que você comprou
								vai enviar seu pedido
							</p>
						</div>
					</div>
				</div>
			</section>

			<div id="boleto-pop" className="has-background-light">
				<section className="column is-8-desktop container px-4 py-6">
					<div className="container">
						<div className="columns">
							<div
								style={{ lineHeight: '1.5' }}
								className="column is-flex is-justify-content-center is-flex-direction-column"
							>
								<h2 className="has-text-blue has-text-left bitter-font is-size-4 has-text-weight-bold">
									O que é BoletoPOP?
								</h2>
								<p className="my-3">
									O BoletoPOP é um{' '}
									<b className="has-text-weight-semibold">
										novo meio de pagamento
									</b>{' '}
									para suas compras online.
								</p>
								<p className="mb-3">
									O BoletoPOP é o boleto parcelado para sua compra online.{' '}
									<br />
									Pensando em você que tem baixo limite no cartão ou até mesmo
									não tem cartão de crédito, criamos o BoletoPOP!
								</p>
								<p>
									Você pode enviar o Boleto à vista de sua compra
									<a style={{ color: '#288CC8' }} href="/taker/register">
										{' '}
										aqui no site
									</a>
									, ou pelo o App VirtusPay na PlayStore.
								</p>
								<a
									className="mt-3"
									href="https://play.google.com/store/apps/details?id=com.virtuspay.virtus_app_tomador&hl=pt_BR"
								>
									<img
										width="180px"
										src={GooglePlay}
										className="lazyload"
										alt="Logo GooglePlay"
									/>
								</a>
							</div>
							<div className="column is-flex is-justify-content-center is-align-items-center">
								<img
									className="lazyload"
									src={Cellphone}
									alt="O que é BoletoPOP?"
									style={{ width: '80%' }}
								/>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className="column is-8-desktop container py-6">
				<div className="container">
					<h2 className="has-text-blue has-text-left bitter-font is-size-4 has-text-weight-bold">
						Vantagens de parcelar com BoletoPOP
					</h2>
					<p className="my-3">
						Sem cartão de crédito,{' '}
						<b className="has-text-weight-semibold">sem dor de cabeça</b>
					</p>

					<div className="columns mt-6">
						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img className="lazyload" src={Vantagem01} alt="Crie sua conta" />
							<p className="has-text-blue bitter-font has-text-weight-bold mt-3 has-text-centered">
								Controle seus <br /> pagamentos
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Tenha acesso aos seus boletos parcelados a hora que quiser em
								sua conta.
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={Vantagem02}
								alt="Compre em uma das lojas aceitas"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold mt-3 has-text-centered">
								Pague como <br /> quiser
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Nossos boletos parcelados são aceitos em qualquer banco ou
								lotéricas
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={Vantagem03}
								alt="Escolha em quantas vezes quer parcelar"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold mt-3 has-text-centered">
								Lembre-se do <br /> vencimento
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Não se preocupe, a cada vencimento te mandaremos lembretes por
								e-mail e/ou whatsapp.
							</p>
						</div>

						<div className="column is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-3-desktop">
							<img
								className="lazyload"
								src={Vantagem04}
								alt="Após aprovação, pague a entrada"
							/>
							<p className="has-text-blue bitter-font has-text-weight-bold mt-3 has-text-centered">
								Precisa de ajuda com <br /> os pagamentos?
							</p>
							<p className="is-size-7 mt-2 has-text-centered">
								Sem problema! Nosso atendimento está aqui para te ajudar =)
							</p>
						</div>
					</div>
				</div>
			</section>

			<SearchStore
				type="aceita"
				margin="mt-6 is-8-desktop"
				color="#f5f5f5"
				titleColor="has-text-blue"
				title="Lojas aceitas"
			/>

			<Carousel
				type="parceiro"
				margin="is-8-desktop"
				color="#FFFFFF"
				title="Lojas Parceiras"
			/>

			<div className="has-background-light">
				<section className="column container is-8-desktop py-6">
					<div className="container">
						<h2 className="has-text-blue has-text-left bitter-font is-size-4 has-text-weight-bold">
							Depoimentos
						</h2>
						<p className="my-3">
							Veja quem já{' '}
							<b className="has-text-weight-semibold">parcelou com a gente!</b>
						</p>
						<div className="columns mt-3">
							<div className="column is-desktop-4">
								<div className="is-flex is-flex-direction-row is-align-items-center">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												alt="Depoimento da Simony"
												src={Depoimento01}
											/>
										</div>
										<div>
											Simony Rosa <br />
											<b className="has-text-weight-semibold">
												Parcelou um Smartphone
											</b>
										</div>
									</p>
								</div>
								<blockquote className="p-5 testimonial-quote mt-3">
									<p className="is-size-7-desktop">
										É um site realmente confiável. E rápido também! Minha compra
										já foi confirmada na loja! Obrigada VirtusPay! 😊👏
									</p>
									<div className="testimonial-quote-drop"></div>
									<div className="check-wpp">
										<span className="is-size-7 has-text-dark">15:28</span>
										<img className="lazyload" src={Check} alt="" />
									</div>
								</blockquote>
							</div>

							<div className="column is-desktop-4">
								<div className="is-flex is-flex-direction-row is-align-items-center">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												src={Depoimento02}
												alt="Depoimento do Danrlley"
											/>
										</div>
										<div>
											Danrlley Santos <br />
											<b className="has-text-weight-semibold">
												Parcelou diversas compras
											</b>
										</div>
									</p>
								</div>
								<blockquote className="p-5 testimonial-quote mt-3 is-align-self-flex-end">
									<p className="is-size-7-desktop">
										Já comprei celular, relógio, tênis… Recomendo demais! Muito
										Bom!
									</p>
									<div className="testimonial-quote-drop"></div>
									<div className="check-wpp">
										<span className="is-size-7 has-text-dark">18:20</span>
										<img className="lazyload" src={Check} alt="" />
									</div>
								</blockquote>
							</div>

							<div className="column is-desktop-4">
								<div className="is-flex is-flex-direction-row is-align-items-center">
									<p className="ml-2 has-text-weight-medium is-align-self-center">
										<div className="mr-3">
											<img
												className="lazyload"
												src={Depoimento03}
												alt="Depoimento da Priene"
											/>
										</div>
										<div>
											Priene Faria <br />
											<b className="has-text-weight-semibold">
												Parcelou uma Máquina de Costura
											</b>
										</div>
									</p>
								</div>
								<blockquote className="p-5 testimonial-quote mt-3 is-align-self-flex-end">
									<p className="is-size-7-desktop">
										Super tranquilo, super rápido, muito, muito bom! Já estou na
										segunda compra e não me arrependo nenhum um pouco! Estou
										amando a VirtusPay 👏💕
									</p>
									<div className="testimonial-quote-drop"></div>
									<div className="check-wpp">
										<span className="is-size-7 has-text-dark">14:12</span>
										<img className="lazyload" src={Check} alt="" />
									</div>
								</blockquote>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className="column is-8-desktop container py-6">
				<div className="container">
					<h2 className="has-text-blue has-text-left bitter-font is-size-4 has-text-weight-bold">
						Dúvidas Comuns
					</h2>
					<p className="my-3">
						As dúvidas mais comuns sobre o{' '}
						<span className="has-text-weight-semibold">
							Boleto Parcelado VirtusPay
						</span>
					</p>

					<Accordion
						ask={
							<p className="accordion is-size-5 my-2 is-flex is-align-items-center">
								<AiOutlineRight className="has-text-blue" /> O que preciso saber
								antes de parcelar?
							</p>
						}
					>
						<div className="panel" style={{ display: 'block' }}>
							<p>
								O Boleto Parcelado da VirtusPay é o seu novo meio de pagamento.{' '}
								<br />
								Com ele você pode comprar online qualquer produto de um das
								lojas aceitas e parcelar em até 15x. <br /> <br />
								<b className="has-text-weight-semibold">
									Sem cartão de crédito!
								</b>{' '}
								<br /> <br />
								<ul>
									<li>
										Aceitamos apenas{' '}
										<b className="has-text-weight-semibold">
											maiores de idade, CPF sem restrições
										</b>
										, com{' '}
										<b className="has-text-weight-semibold">
											RG ou CNH em dia.
										</b>
									</li>
									<li>
										Parcelamos apenas{' '}
										<b className="has-text-weight-semibold">
											uma compra por vez por CPF.
										</b>
									</li>
									<li>
										Sua compra deve ter{' '}
										<b className="has-text-weight-semibold">
											valor mínimo de R$ 150 e máximo de R$ 10.000.
										</b>
									</li>
									<li>
										Você pode escolher{' '}
										<b className="has-text-weight-semibold">
											entre 3 a 15 parcelas
										</b>
										, a depender do valor da compra.
									</li>
									<li>
										<b className="has-text-weight-semibold">
											Data de vencimento do boleto
										</b>{' '}
										parcelado será referente a data de aprovação do seu crédito.
									</li>
								</ul>
							</p>
						</div>
					</Accordion>

					<Accordion
						ask={
							<p className="accordion is-size-5 my-2 is-flex is-align-items-center">
								<AiOutlineRight className="has-text-blue" /> Como parcelar minha
								compra?
							</p>
						}
					>
						<div className="panel">
							<p>
								<b className="has-text-weight-semibold">
									{' '}
									Se você já realizou sua compra,
								</b>{' '}
								<br />
								1° No site da VirtusPay,{' '}
								<a href="{{ WEBAPP_TAKER_URL }}/taker/register">
									realize seu cadastro ou entre em sua conta.
								</a>{' '}
								<br />
								2° Compre no site das{' '}
								<b className="has-text-weight-semibold">lojas aceitas</b> e gere
								um boleto à vista. <br />
								3°{' '}
								<b className="has-text-weight-semibold">
									Envie no site da VirtusPay
								</b>{' '}
								o boleto à vista e escolha a quantidade de parcelas e siga as
								instruções. <br />
								4° Agora{' '}
								<b className="has-text-weight-semibold">
									aguarde sua aprovação
								</b>
								. Se aprovado,{' '}
								<b className="has-text-weight-semibold">pague a 1a parcela</b> e
								pronto, seu pedido sairá para entrega! <br />
								<b className="has-text-weight-semibold">
									Se você ainda não realizou sua compra,
								</b>{' '}
								veja as lojas aceitas aqui, e siga as mesmas instruções acima.{' '}
								<br />
								Agora, caso você encontrou a{' '}
								<b className="has-text-weight-semibold">
									VirtusPay como meio de pagamento na loja
								</b>
								, apenas siga as instruções para análise de sua compra, e também
								aguarde aprovação para pagar sua primeira parcela. <br />
								Os boletos das{' '}
								<b className="has-text-weight-semibold">demais parcelas</b>{' '}
								ficarão disponíveis em sua conta, e para você não se esquecer,
								enviaremos lembretes próximo a data de vencimento ;)
							</p>
						</div>
					</Accordion>

					<Accordion
						ask={
							<p className="accordion is-size-5 my-2 is-flex is-align-items-center">
								<AiOutlineRight className="has-text-blue" /> Estou negativado,
								posso parcelar?
							</p>
						}
					>
						<div className="panel" style={{ display: 'block' }}>
							<p>
								A VirtusPay busca sempre te ajudar, inclusive sobre sua saúde
								financeira. Com seu CPF e envio do documento de identidade
								iremos identificar se há restrições em seu nome, pois{' '}
								<b className="has-text-weight-semibold">
									só podemos realizar o parcelamento para quem não está
									negativado.
								</b>
								Mas nada impede que após limpar o seu nome, você volte a
								parcelar com a gente.
							</p>
						</div>
					</Accordion>

					<Accordion
						ask={
							<p className="accordion is-size-5 my-2 is-flex is-align-items-center">
								<AiOutlineRight className="has-text-blue" /> É gratuito? Quanto
								é a taxa de juros?
							</p>
						}
					>
						<div className="panel" style={{ display: 'block' }}>
							<p>
								<b className="has-text-weight-semibold">
									A criação de sua conta na VirtusPay é totalmente gratuita
								</b>
								, assim como o envio e análise de qualquer proposta de
								parcelamento. Caso o crédito para sua compra seja aprovado, de
								acordo com o número de parcelas escolhidas, serão aplicados os
								juros de 3,9 e 7,5% ao mês.{' '}
								<b className="has-text-weight-semibold">
									Na hora da compra, você saberá exatamente o valor de seus
									boletos parcelados, sem taxas escondidas =)
								</b>
							</p>
						</div>
					</Accordion>

					<Accordion
						ask={
							<p className="accordion is-size-5 my-2 is-flex is-align-items-center">
								<AiOutlineRight className="has-text-blue" /> Quais lojas são
								aceitas?
							</p>
						}
					>
						<div className="panel" style={{ display: 'block' }}>
							<p>
								Veja nesse <a href="#lojas">link</a> a lista de lojas aceitas.{' '}
								<b className="has-text-weight-semibold">
									Em algumas lojas já estamos como forma de pagamento
								</b>
								, você só precisa seguir as instruções para descobrir seu
								crédito disponível.
								<b className="has-text-weight-semibold">
									Caso não encontre a VirtusPay como meio de pagamento
								</b>
								, pague sua compra em uma loja aceita com boleto à vista,{' '}
								<a href="{{ WEBAPP_TAKER_URL }}/taker/register">
									faça o cadastro
								</a>{' '}
								em nosso site e envie uma proposta de parcelamento para
								descobrir seu crédito disponível.
							</p>
						</div>
					</Accordion>
				</div>
				<div className="is-12 has-text-centered mt-6">
					<p>
						<i className="fas fa-question-circle"></i>Veja todas as dúvidas{' '}
						<b>
							<a
								className="bitter-font has-text-info underline"
								href="https://ajuda.usevirtus.com.br"
							>
								CLIQUE AQUI
							</a>
						</b>
					</p>
				</div>
			</section>

			<div className="has-background-light">
				<section className="column container is-8 py-6">
					<div className="container">
						<h2 className="has-text-blue has-text-left is-size-4 has-text-weight-semibold">
							Nosso Blog
						</h2>
						<p className="my-3">
							Artigos que te ajudam a aproveitar o seu{' '}
							<strong className="has-text-weight-semibold">
								boleto parcelado
							</strong>
						</p>

						<div className="columns">
							<div className="column is-3-desktop">
								<a href="https://blog.virtuspay.com.br/black-friday-2021/">
									<figure className="image is-16by9">
										<img
											className="lazyload"
											src={PagamentoOnline}
											alt="Pagamentos online"
										/>
									</figure>
									<h4 className="has-text-blue has-text-weight-medium has-text-centered my-3">
										Meios de pagamento online: quais são os mais usados e os
										melhores?
									</h4>
								</a>
							</div>

							<div className="column is-3-desktop">
								<a href="https://blog.virtuspay.com.br/da-para-parcelar-no-boleto/">
									<figure className="image is-16by9">
										<img className="lazyload" src={BNPL} alt="O que é bnpl" />
									</figure>
									<h4 className="has-text-blue has-text-weight-medium has-text-centered my-3">
										Já ouviu falar em BNPL? Saiba o que é e o porquê do seu
										crescimento
									</h4>
								</a>
							</div>

							<div className="column is-3-desktop">
								<a href="https://blog.virtuspay.com.br/black-friday-2021/">
									<figure className="image is-16by9">
										<img
											className="lazyload"
											src={BlackFriday}
											alt="Black Frinday"
										/>
									</figure>
									<h4 className="has-text-blue has-text-weight-medium has-text-centered my-3">
										Black Friday 2021: saiba o que escolher e como comprar
									</h4>
								</a>
							</div>

							<div className="column is-3-desktop">
								<a href="https://blog.virtuspay.com.br/semana-do-brasil/">
									<figure className="image is-16by9">
										<img
											className="lazyload"
											src={SemanaBrasil}
											alt="Semana do Brasil"
										/>
									</figure>
									<h4 className="has-text-blue has-text-weight-medium has-text-centered my-3">
										Semana do Brasil 2021: prepare-se para a Black Friday
										brasileira
									</h4>
								</a>
							</div>
						</div>
					</div>
					<div className="is-12 has-text-centered mt-6">
						<p>
							<i className="far fa-newspaper mx-1"></i> Veja mais artigos{' '}
							<b className="has-text-weight-semibold">
								<a
									className=" has-text-info underline"
									href="https://blog.virtuspay.com.br/"
									target="____blank"
								>
									CLIQUE AQUI
								</a>
							</b>
						</p>
					</div>
				</section>
			</div>

			<div>
				<section className="column is-8 container py-6" id="media">
					<div className="columns is-flex is-5 is-hidden-touch">
						<div className="column is-12-desktop">
							<h2 className="has-text-secondary-blue has-text-left is-size-4 has-text-weight-semibold">
								Mídia
							</h2>
							<p className="my-3">
								O que a mídia está{' '}
								<span className="has-text-weight-semibold">
									falando da VirtusPay
								</span>
							</p>
							<div className="columns mt-5  is-flex flex align-items-center is-justify-content-space-between is-hidden-touch is-align-items-center is-justify-content-center">
								<div className="column is-2-desktop">
									<a href="https://noticias.r7.com/tecnologia-e-ciencia/programa-inova-360/virtuspay-boleto-parcelado-para-a-sua-loja-virtual-vender-mais-19062020">
										<img
											alt="Notícia R7"
											className="lazyload"
											src={MediaR7}
											width="100px"
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a href="https://www.startse.com/noticia/nova-economia/corporate/startups-aceleradas-visa-experiencia-trabalhar-empresa">
										<img
											alt="Notícia StartSe"
											className="lazyload"
											src={MediaStartSE}
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a href="https://www.visa.com.br/sobre-a-visa/noticias-visa/sala-de-imprensa/visa-anuncia-as-fintechs-cloudwalk-e-virtus-como-finalistas-do-visa-everywhere-initiative-no-brasil-por-suas-solucoes-inovadoras.html">
										<img
											alt="Notícia Visa"
											className="lazyload ml-3"
											src={MediaVisa}
											style={{ maxHeight: '60px' }}
											width="100px"
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a href="https://www1.folha.uol.com.br/mpme/2021/05/pagamentos-digitais-facilitam-a-operacao-de-pequenos-negocios.shtml">
										<img
											alt="Notícia Folha"
											className="noticia-folha lazyload"
											src={MediaFolha}
											width="100%"
										/>
									</a>
								</div>

								<div className="column is-2-desktop">
									<a href="https://exame.com/pme/dinheiro-sobrando-startup-troca-limite-no-cartao-de-credito-por-milhas/">
										<img
											alt="Notícia Exame"
											className="lazyload"
											src={MediaExame}
											width="100px"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="columns is-flex is-5 is-hidden-desktop">
						<div className="column is-12-touch">
							<h2 className="has-text-secondary-blue has-text-left is-size-4 has-text-weight-semibold">
								Mídia
							</h2>
							<p className="my-3">
								O que a mídia está{' '}
								<span className="has-text-weight-semibold">
									falando da VirtusPay
								</span>
							</p>
							<div className="columns mt-5  is-flex flex align-items-center is-justify-content-space-between is-align-items-center is-justify-content-center">
								<div className="column is-2-touch">
									<a href="https://noticias.r7.com/tecnologia-e-ciencia/programa-inova-360/virtuspay-boleto-parcelado-para-a-sua-loja-virtual-vender-mais-19062020">
										<img
											alt="Notícia R7"
											className="lazyload"
											src={MediaR7}
											width="100px"
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a href="https://www.startse.com/noticia/nova-economia/corporate/startups-aceleradas-visa-experiencia-trabalhar-empresa">
										<img
											alt="Notícia StartSe"
											className="lazyload"
											src={MediaStartSE}
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a href="https://www.visa.com.br/sobre-a-visa/noticias-visa/sala-de-imprensa/visa-anuncia-as-fintechs-cloudwalk-e-virtus-como-finalistas-do-visa-everywhere-initiative-no-brasil-por-suas-solucoes-inovadoras.html">
										<img
											alt="Notícia Visa"
											className="lazyload ml-3"
											src={MediaVisa}
											style={{ maxHeight: '60px' }}
											width="100px"
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a href="https://www1.folha.uol.com.br/mpme/2021/05/pagamentos-digitais-facilitam-a-operacao-de-pequenos-negocios.shtml">
										<img
											alt="Notícia Folha"
											className="noticia-folha lazyload"
											src={MediaFolha}
											width="100%"
										/>
									</a>
								</div>

								<div className="column is-2-touch">
									<a href="https://exame.com/pme/dinheiro-sobrando-startup-troca-limite-no-cartao-de-credito-por-milhas/">
										<img
											alt="Notícia Exame"
											className="lazyload"
											src={MediaExame}
											width="100px"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div id="midias" className="blue-gradient">
				<section
					style={{ boxShadow: 'none' }}
					className="column is-multiline py-2 is-8 container"
				>
					<div className="column has-text-centered is-hidden-desktop is-12 is-align-items-center is-justify-content-center">
						<img
							className="lazyload"
							src={Logo}
							width="180px"
							alt="Logo VirtusPay"
						/>
						<div className="is-flex mt-4 is-flex-direction-row is-justify-content-center">
							<a className="mx-2" href="https://pt-br.facebook.com/virtuspay/">
								<img className="lazyload" src={Facebook} alt="facebook" />
							</a>
							<a className="mx-2" href="https://www.instagram.com/virtuspay/">
								<img className="lazyload" src={Instagram} alt="instagram" />
							</a>
							<a
								className="mx-2"
								href="https://www.youtube.com/channel/UCHE_NnSHC2RVrkD17U0qr8w"
							>
								<img className="lazyload" src={Youtube} alt="youtube" />
							</a>
							<a
								className="mx-2"
								href="https://www.linkedin.com/company/virtuspay/"
							>
								<img className="lazyload" src={Linkedin} alt="linkedin" />
							</a>
						</div>
					</div>

					<div className="columns is-multiline is-hidden-touch is-flex is-flex-direction-row is-justify-content-space-between">
						<div className="column is-align-items-center is-flex is-12-mobile">
							<img
								className="lazyload"
								src={Logo}
								width="180px"
								alt="Logo VirtusPay"
							/>
						</div>
						<div className="column">
							<div className="is-flex is-flex-direction-row is-justify-content-flex-end">
								<a
									className="mx-2"
									href="https://pt-br.facebook.com/virtuspay/"
								>
									<img className="lazyload" src={Facebook} alt="facebook" />
								</a>
								<a className="mx-2" href="https://www.instagram.com/virtuspay/">
									<img className="lazyload" src={Instagram} alt="instagram" />
								</a>
								<a
									className="mx-2"
									href="https://www.youtube.com/channel/UCHE_NnSHC2RVrkD17U0qr8w"
								>
									<img className="lazyload" src={Youtube} alt="youtube" />
								</a>
								<a
									className="mx-2"
									href="https://www.linkedin.com/company/virtuspay/"
								>
									<img className="lazyload" src={Linkedin} alt="linkedin" />
								</a>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer margin="is-8-desktop" color="#000000" type="taker" />

			<CopyRight color="#242424" margin="is-8-desktop" textColor="#FFFFFF" />
		</>
	);
}
