import { useEffect, useState } from 'react';

import { OrderCheckoutRepository } from '../repositories/orderCheckoutRepository';

export const InsufficientAmount = ({ result }: any) => {
	const [isLoading, setIsLoading] = useState(true);
	const [urlRedirect, setUrlRedirect] = useState();

	const { order, min_installment_value } = result.data;

	useEffect(() => {
		const getURL = async () => {
			const repository = new OrderCheckoutRepository();
			const result = await repository.getURL(order.transaction);

			const { url } = result.data;

			setUrlRedirect(url);
			setIsLoading(false);
		};

		getURL();

		setTimeout(() => {
			const url: HTMLElement | null = document.querySelector('url-link');

			url?.click();
		}, 15000); // eslint-disable-next-line
	}, []);

	return (
		<main className="container-vp">
			<section
				style={{ minHeight: '100vh' }}
				className="d-flex flex-column justify-content-top align-items-center"
			>
				<div className="pt-0 pb-1 py-md-1 text-center logo_virtus_iframe">
					<img
						src={order.brand_logo_url}
						className="img-fluid"
						alt="Logo Virtus"
						style={{ maxWidth: '95%' }}
					/>
				</div>
				<div className="col-12 col-md-10 mx-auto px-0">
					<h2 className="bolder-text text-black bitter-font text-center my-4">
						Poxa {order.customer.full_name.split(' ')[0]}, este parcelamento não
						está disponível =/
					</h2>
					<h5 className="has-warning-text bolder-text bitter-font text-center my-4">
						O valor mínimo da parcela deve ser de R$
						{min_installment_value}.
					</h5>
					<h5 className="text-black bitter-font text-center my-4">
						Volte a loja e continue comprando para atingir esse valor e parcelar
						com a gente!
					</h5>
					{isLoading ? (
						<></>
					) : (
						<div className="col-12 text-center">
							<a
								href={urlRedirect}
								rel="noreferrer nofollow"
								id="url-link"
								style={{ textDecoration: 'underline' }}
							>
								Ou escolha outro meio de pagamento para essa compra.
							</a>
						</div>
					)}
				</div>
			</section>
		</main>
	);
};
