import axios from '../../../shared/http/customAxios';

class ContactsRepository {
	async getContacts(transaction: string) {
		const result = await axios().get(`/v1/order/${transaction}/contacts`, {
			headers: { noAuthorization: true },
		});

		return result;
	}

	async submitContact(
		transaction: string,
		dto: {
			contact_name: string;
			contact_type: string | null;
			contact_desc: string;
			relatives: string | null;
		}
	) {
		const result = await axios().post(`v1/order/${transaction}/contacts`, dto, {
			headers: { noAuthorization: true },
		});

		return result;
	}
}

export default ContactsRepository;
