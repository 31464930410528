import axios from '../../../shared/http/customAxios';

import { RedefinePasswordI } from '../types';

export async function redefinePasswordRepository(payload: RedefinePasswordI) {
	try {
		const result = await axios().post(`/password_reset/confirm/`, payload, {
			headers: { noAuthorization: true },
		});
		return result;
	} catch (error: any) {
		return error.response;
	}
}

export async function tokenRedefinePasswordRepository(
	token: RedefinePasswordI
) {
	try {
		const result = await axios().post(
			`/password_reset/validate_token/`,
			token,
			{
				headers: { noAuthorization: true },
			}
		);
		return result;
	} catch (error: any) {
		return error.response;
	}
}
