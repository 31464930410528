// import axios from 'axios';
import axios from '../../../shared/http/customAxios';

import { config } from '../../../config.json';
class PreApprovedService {
	preApprovedBaseUrl: any = config.preapproved_api_host;

	async preApproved(data: any) {
		try {
			const result = await axios(false, 10000).post(
				this.preApprovedBaseUrl,
				data
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default PreApprovedService;
