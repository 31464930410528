import {
	UnicoCheckBuilder,
	SelfieCameraTypes,
	UnicoThemeBuilder,
	DocumentCameraTypes,
} from 'unico-webframe';

import {
	IResponseErrorUnico,
	IResponseUnico,
} from '../../../views/kyc/types/types';

import { config } from '../../../config.json';
interface UnicoProps {
	handlePicture: (base64: string, encrypted: string) => void;
	handleError: (obj: IResponseErrorUnico, call: string) => void;
	init?: () => void;
	camera: 'selfie' | 'document';
	type: number;
}

interface TranslateSelfieTypes {
	[x: number]: any;
}

interface TranslateDocumentTypes {
	[x: number]: any;
}

interface TranslateEnvironment {
	[x: string]: string;
}

const TRANSLATE_STATUS_SELFIE: TranslateSelfieTypes = {
	1: SelfieCameraTypes.NORMAL,
	2: SelfieCameraTypes.SMART,
};

const TRANSLATE_STATUS_DOCUMENT: TranslateDocumentTypes = {
	1: DocumentCameraTypes.CNH,
	2: DocumentCameraTypes.RG_FRENTE,
	3: DocumentCameraTypes.CPF,
	4: DocumentCameraTypes.RG_FRENTE_NOVO,
	6: DocumentCameraTypes.RG_FRENTE,
	7: DocumentCameraTypes.RG_VERSO,
	8: DocumentCameraTypes.RG_FRENTE_NOVO,
	9: DocumentCameraTypes.RG_VERSO_NOVO,
};

const TRANSLATE_ENVIRONMENT_TO_PATH: TranslateEnvironment = {
	DEV: '/unico/unicoKeyDev.json',
	HML: '/unico/unicoKeyHml.json',
	PROD: '/unico/unicoKeyProd.json',
};

const unicoKeyPath = TRANSLATE_ENVIRONMENT_TO_PATH[config.environment];

export function UnicoFrame({
	handlePicture,
	handleError,
	camera,
	type,
}: UnicoProps) {
	const callback = {
		on: {
			success: function (obj: IResponseUnico) {
				handlePicture(obj.base64, obj.encrypted);
			},
			error: function (err: any) {
				console.log(err);
				handleError(err, 'error');
			},
			support: function (err: IResponseErrorUnico) {
				console.log(err);
				handleError(err, 'support');
			},
		},
	};

	const urlPathModels = '/unico/models';
	const unicoTheme = new UnicoThemeBuilder()
		.setColorSilhouetteSuccess('#288CC8')
		.setColorSilhouetteError('#288CC8')
		.setColorSilhouetteNeutral('#288CC8')
		.setBackgroundColor('#288CC8')
		.setColorText('#fff')
		.setBackgroundColorComponents('#288CC8')
		.setColorTextComponents('#fff')
		.setBackgroundColorButtons('#288CC8')
		.setColorTextButtons('#fff')
		.setBackgroundColorBoxMessage('#288CC8')
		.setColorTextBoxMessage('#fff')
		.setHtmlPopupLoading(
			`<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregando...</div>`
		)
		.build();

	const unicoCamera = new UnicoCheckBuilder()
		.setTheme(unicoTheme)
		.setModelsPath(urlPathModels)
		.build();

	if (camera === 'selfie') {
		unicoCamera
			.prepareSelfieCamera(unicoKeyPath, TRANSLATE_STATUS_SELFIE[type])
			.then(({ open }) => open(callback))
			.catch((error) => console.log(error));
	} else if (camera === 'document') {
		unicoCamera
			.prepareDocumentCamera(unicoKeyPath, TRANSLATE_STATUS_DOCUMENT[type])
			.then(({ open }) => open(callback))
			.catch((error) => console.log(error));
	}

	return (
		<div
			data-testid="div"
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<div data-testid="box-camera" id="box-camera"></div>
		</div>
	);
}
