import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import SelectSearch, {
	fuzzySearch,
	SelectSearchOption,
} from 'react-select-search';

import { MultilineDivStyled, ErrorStyled, SelectStyled } from '../../styles';

import { Accordion, Button, Input } from '../../../../shared/components/index';

import UserController from '../../controllers/userController';

import { BankProps, BanksSelectProps } from '../../types/types';

import BankDto from '../../models/bankModel';

import { useAuth } from '../../../../hooks/auth/auth';
import { useLoading } from '../../../../hooks/loading';
import { BANK_TYPES } from '../../../../shared/variables';

const schema = Yup.object().shape({
	agency: Yup.string()
		.required('Digite o número da sua agência')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(4, 'Digite um número de agência válido'),
	account: Yup.string()
		.required('Digite o número da sua conta')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(7, 'Digite um número de conta válido'),
});

interface PersonalProps {
	bankData: BankProps;
}

interface FormData {
	bank: string;
	bank_branch: string;
	bank_account: string;
}

export const BankFields = ({ bankData }: PersonalProps) => {
	const [accountTypes, setAccountTypes] = useState<string>('');
	const [errorAccountTypes, setErrorAccountTypes] = useState<string>();

	const [bankSelected, setBankSelected] = useState<string>('');
	const [banksSelect, setBanksSelect] = useState<SelectSearchOption[]>(
		[] as SelectSearchOption[]
	);
	const [errorBank, setErrorBank] = useState<string>();

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({ resolver: yupResolver(schema) });

	const { setData } = useAuth();

	const { setIsLoading } = useLoading();

	const searchInput: MutableRefObject<any> = useRef();

	const dto = new BankDto();
	const userController = new UserController();

	useEffect(() => {
		getBanks();
	}, []);

	useEffect(() => {
		setAccountTypes(bankData?.bank_account_type);
		setBankSelected(bankData?.bank_code);
		reset(bankData);
		getBanks();
	}, [bankData]);

	const getBanks = async () => {
		const { result } = await userController.callBanksController();

		const bankList = result.map((item: BanksSelectProps) => {
			return { name: item.name, value: item.name, key: item.code };
		});

		setBanksSelect(bankList);
	};

	async function handleOnSubmit(form: FormData) {
		if (bankSelected === '') {
			setErrorBank('Selecione algum banco');
			return;
		}

		if (accountTypes === '') {
			setErrorAccountTypes('Selecione qual o tipo da conta');
			return;
		}

		setIsLoading(true);

		dto.bank_code = bankSelected;
		dto.bank_account = form.bank_account;
		dto.bank_branch = form.bank_branch;
		dto.bank_account_type = accountTypes;

		const { error } = await userController.submitStepFiveController(dto);

		if (error) {
			setIsLoading(false);
		} else {
			setData();
		}
	}
	const handleChange = (...args: any[]) => {
		setBankSelected(args[1].name);
		setErrorBank('');
	};

	return (
		<Accordion title="Atualizar meus dados bancários">
			<form onSubmit={handleSubmit(handleOnSubmit)}>
				<MultilineDivStyled>
					<SelectStyled
						placeholder="Tipo da conta"
						onChange={(event) => {
							setAccountTypes(event.target.value);
							setErrorAccountTypes('');
						}}
						value={accountTypes}
					>
						{BANK_TYPES.map((item) => (
							<option key={item.value} value={item.value}>
								{item.name}
							</option>
						))}
					</SelectStyled>
					<ErrorStyled>{errorAccountTypes}</ErrorStyled>
					<SelectSearch
						ref={searchInput}
						options={banksSelect}
						value={bankSelected}
						placeholder="Selecione o Banco"
						search
						emptyMessage="Banco não encontrado"
						filterOptions={fuzzySearch}
						onChange={handleChange}
					/>
					<ErrorStyled>{errorBank}</ErrorStyled>
					<MultilineDivStyled>
						<Input
							control={control}
							name="bank_branch"
							type="text"
							placeholder="Agência"
							mask="9999"
							width="100%"
							error={errors.bank_branch && errors.bank_branch.message}
						/>
						<ErrorStyled></ErrorStyled>
						<Input
							control={control}
							name="bank_account"
							type="text"
							placeholder="Conta"
							mask="999999-9"
							width="100%"
							error={errors.bank_account && errors.bank_account.message}
						/>
					</MultilineDivStyled>
				</MultilineDivStyled>
				<Button disable={false} type="submit" text="Salvar alterações" />
			</form>
		</Accordion>
	);
};
