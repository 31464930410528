import axios from '../../shared/http/customAxios';

import { config } from '../../config.json';
export class ElkService {
	helpersBaseUrl = config.helpers_api_host;

	async send(level: string, msg: string, extras: any) {
		const configs = {
			headers: {
				Authorization: 'JWT ' + localStorage.getItem('token'),
			},
		};

		const data = {
			level,
			msg,
			application: 'Taker WebApp',
			extras,
		};

		try {
			const result = await axios(true).post('/elk', data, configs);

			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}
