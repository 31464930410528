const getGeolocation = () => {
	if (!navigator.geolocation) return '';

	const geolocation: any = {};

	navigator.geolocation.getCurrentPosition((position) => {
		geolocation['latitude'] = position.coords.latitude;
		geolocation['longitude'] = position.coords.longitude;
	});

	return geolocation;
};

export { getGeolocation };
