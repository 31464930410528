import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import {
	MultilineDivStyled,
	InputDivStyled,
	ButtonDivStyled,
} from '../../styles';

import {
	InputControlledCurrency,
	Input,
	Accordion,
	Button,
	InputUncontrolled,
} from '../../../../shared/components/index';

import { moneyMask, undoMoneyMask } from '../../../../shared/utils/masks';

import { UserProps } from '../../types/types';

import UserController from '../../controllers/userController';

import { useAuth } from '../../../../hooks/auth/auth';
import { useLoading } from '../../../../hooks/loading';
import { PersonalDataSchema } from '../../validation';
export interface PersonalProps {
	userData: UserProps;
}

export const DataFields = ({ userData }: PersonalProps) => {
	const [dataObject, setDataObject] = useState<UserProps>({} as UserProps);

	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({ resolver: yupResolver(PersonalDataSchema) });

	const { user, setData, removeData } = useAuth();

	const history = useHistory();

	const { setIsLoading } = useLoading();

	const userController = new UserController();

	useEffect(() => {
		reset(userData);
	}, [userData]);

	useEffect(() => {
		getUpdatedUser();
	}, [user]);

	const getUpdatedUser = () => {
		const { full_name, email, cellphone, cpf, income } = user;

		setValue('full_name', full_name, { shouldValidate: true });
		setValue('email', email, { shouldValidate: true });
		setValue('phone', cellphone?.replace('+55', ''), { shouldValidate: true });
		setValue('cpf', cpf, { shouldValidate: true });
		setValue('salary', undoMoneyMask(moneyMask(income).replace('.', ',')), {
			shouldValidate: true,
		});

		const dataUser = {
			full_name: full_name,
			email: email,
			phone: cellphone?.replace('+55', ''),
			cpf: cpf,
			salary: income ?? '',
		};

		setDataObject(dataUser);
	};

	const inactivateAccount = async () => {
		const { result, error, redirect }: any =
			await userController.inactivateAccount();

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
				confirmButtonText: 'Fechar',
			});
		}

		return Swal.fire({
			icon: 'success',
			text: result.data.message,
			confirmButtonText: 'Continuar',
		}).then(() => {
			removeData();
			userController.logout(false, () => history.push('/'));
			history.push(redirect);
		});
	};

	const handleOnSubmit = async (form: UserProps) => {
		if (!form.salary) return;

		setIsLoading(true);

		const payload = {
			full_name: form.full_name,
			email: form.email,
			phone: form.phone,
			cpf: form.cpf,
			salary: undoMoneyMask(form.salary),
		};

		const { result, error } = await userController.updateProfile(
			payload,
			(response: any) => setData(response)
		);

		if (error) {
			Swal.fire({
				icon: 'error',
				text: result.data.message,
				confirmButtonText: 'Fechar',
			});
		} else {
			Swal.fire({
				icon: 'success',
				text: 'Dados alterados com sucesso!',
				confirmButtonText: 'Continuar',
			}).then(() => {
				setData(result);
			});
		}

		setIsLoading(false);
	};

	return (
		<Accordion dataTestId="Navbar" title="Atualizar meus dados">
			<MultilineDivStyled>
				<InputDivStyled>
					<InputUncontrolled
						disabled={true}
						mask="999.999.999-99"
						id="cpf"
						value={dataObject.cpf}
						placeholder="CPF"
						dataTestId="cpf"
					/>
				</InputDivStyled>
				<InputDivStyled>
					<InputUncontrolled
						disabled={true}
						id="email"
						mask=""
						value={dataObject.email}
						placeholder="E-mail"
						dataTestId="email"
					/>
				</InputDivStyled>
				<InputDivStyled>
					<Input
						control={control}
						name="full_name"
						mask=""
						id="full_name"
						dataTestID="full_name"
						type="text"
						placeholder="Nome completo"
						error={errors.full_name && errors.full_name.message}
					/>
				</InputDivStyled>
				<InputDivStyled style={{ marginBottom: '10px' }}>
					<InputControlledCurrency
						control={control}
						id="salary"
						name="salary"
						dataTestId="salary"
						type="tel"
						precision="2"
						decimalSeparator=","
						thousandSeparator="."
						prefix="R$"
						placeholder="Renda mensal (R$)"
						value={dataObject.salary}
						error={errors.salary && errors.salary.message}
					/>
				</InputDivStyled>
				<InputDivStyled>
					<Input
						control={control}
						name="phone"
						id="phone"
						dataTestID="phone"
						mask="(99) 9 9999-9999"
						type="tel"
						placeholder="Celular com DDD"
						error={errors.phone && errors.phone.message}
					/>
				</InputDivStyled>
				<InputDivStyled></InputDivStyled>

				<ButtonDivStyled>
					<span onClick={inactivateAccount}>Inativar conta</span>
				</ButtonDivStyled>

				<ButtonDivStyled>
					<Button
						onClick={handleSubmit(handleOnSubmit)}
						text="Salvar alterações"
						className="has-gradient-blue"
						textColor="#fff"
						dataTestid="submitButton"
						id="submitButton"
					/>
				</ButtonDivStyled>
			</MultilineDivStyled>
		</Accordion>
	);
};
