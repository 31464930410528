import Swal from 'sweetalert2';

import {
	validBirthdate,
	validPhone,
	validSalary,
	emptyValidation,
} from '../../../shared/utils/validators';

import { SelectedInstallmentProps } from '../interfaces';

import { colors } from '../../../shared/styles/colors';

export class OrderSendingValidator {
	validateReason(reason: string | null) {
		const reasonInput: HTMLElement | null = document.querySelector(
			'#installment-reason-input'
		);

		if (!reasonInput) {
			return false;
		}

		if (!emptyValidation(reason)) {
			Swal.fire({
				text: 'Selecione um motivo para o parcelamento',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			reasonInput.style.borderColor = colors.errorColor;

			return false;
		}

		reasonInput.style.borderColor = colors.successColor;

		return true;
	}

	validatePhone = (phone: string) => {
		const input: HTMLElement | null = document.querySelector('#phone');

		if (!input) {
			return false;
		}

		input.style.borderColor = colors.defaultGrey;

		if (!validPhone(phone)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'Digite um celular válido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validateIncome = (income: string) => {
		const input: HTMLElement | null = document.querySelector('#income');

		if (!input) {
			return false;
		}

		input.style.borderColor = colors.defaultGrey;

		if (!validSalary(income)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'A renda deve ser maior que R$1.000,00 mensais',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validateBirthdate = (date: string) => {
		const input: HTMLElement | null = document.querySelector('#birthdate');

		if (!input) {
			return false;
		}

		input.style.borderColor = colors.defaultGrey;

		if (!validBirthdate(date)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'Data de nascimento inválida',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validateIsNegative = (isNegative: boolean) => {
		if (isNegative === null) {
			Swal.fire({
				text: 'Você deve dizer se está negativado',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		return true;
	};

	validateSelectedInstallment = (
		selectedInstallment: SelectedInstallmentProps | null
	) => {
		if (selectedInstallment === null || selectedInstallment === undefined) {
			Swal.fire({
				text: 'Você deve escolher a quantidade de parcelas',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		return true;
	};

	validateCellphone = (cellphone: string) => {
		const input: HTMLElement | null = document.querySelector('#cellphone');

		if (!input) {
			return false;
		}

		input.style.borderColor = colors.defaultGrey;

		if (!validPhone(cellphone)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				text: 'Digite um celular válido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		input.style.borderColor = colors.successColor;
		return true;
	};

	validateIsCommsAccepted = (isCommsAccepted: boolean) => {
		if (!isCommsAccepted) {
			Swal.fire({
				text: 'Você deve concordar com o recebimento de notificações.',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		return true;
	};

	validateIsTermsAccepted = (isTermsAccepted: boolean) => {
		if (!isTermsAccepted) {
			Swal.fire({
				text: 'Você deve concordar com os termos de uso e a política de privacidade.',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		return true;
	};
}
