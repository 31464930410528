import dateFormat from 'dateformat';
import Sheet from 'react-modal-sheet';

import {
	FirstDivStyled,
	AiOutlineArrowLeftStyled,
	TitleStyled,
	TitleSectionStyled,
	GapStyled,
	DivSectionStyled,
	DivDetailsStyled,
	TextStyled,
	TextAreaStyled,
	ButtonDivStyled,
	TextJustificationStyled,
	SpanStyled,
} from './styles';
import { ContractSheetProps } from './sheet.interface';

import Logo200 from '../../../../assets/images/logo_virtuspay_azulgrad_200.png';
import { cpfMask, moneyMask, phoneMask } from '../../../../shared/utils/masks';
import { Button } from '../../../../shared/components/index';

import { TextSheet } from '../TextSheet/textSheet';
import { TextSheetGracePeriodGt0 } from '../TextSheetGracePeriodGt0/TextSheetGracePeriodGt0';

export const ContractSheet = ({
	customer,
	isOpened,
	onClose,
	state,
}: ContractSheetProps) => {
	const {
		orderAmount,
		cet,
		dueDate,
		installment,
		installmentValue,
		interest,
		iof,
		amountInstallments,
		hasGracePeriodGt0,
	} = state;
	return (
		<Sheet isOpen={isOpened} onClose={onClose}>
			<Sheet.Container onUnmount>
				<Sheet.Content>
					<FirstDivStyled>
						<AiOutlineArrowLeftStyled size={30} onClick={onClose} />
						<img src={Logo200} alt="Logo VirtusPay" />

						<TitleStyled>Cédula de Crédito Bancário</TitleStyled>
						<hr />
						<TitleSectionStyled>Emitente/Devedor</TitleSectionStyled>
						<GapStyled />
						<DivSectionStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Nome:</strong> {customer.full_name}
								</TextStyled>
								{customer.birthdate ? (
									<TextStyled>
										<strong>Dt. Nascimento:</strong>{' '}
										{dateFormat(customer.birthdate, 'dd/mm/yyyy')}
									</TextStyled>
								) : (
									''
								)}
								<TextStyled>
									<strong>E-mail:</strong> {customer.email}
								</TextStyled>
								<TextStyled>
									<strong>Telefone:</strong> {phoneMask(customer.cellphone)}
								</TextStyled>
							</DivDetailsStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>CPF:</strong> {cpfMask(customer.cpf)}
								</TextStyled>

								<TextStyled>
									<strong>Endereço Res:</strong>
									{customer.customer_address.street +
										', ' +
										customer.customer_address.number +
										', ' +
										customer.customer_address.complement}
								</TextStyled>

								<TextStyled>
									<strong>Bairro:</strong>{' '}
									{customer.customer_address.neighborhood}
								</TextStyled>
							</DivDetailsStyled>

							<DivDetailsStyled>
								<TextStyled>
									<strong>Cidade:</strong> {customer.customer_address.city}
								</TextStyled>
								<TextStyled>
									<strong>UF:</strong> {customer.customer_address.state}
								</TextStyled>
								<TextStyled>
									<strong>CEP:</strong> {customer.customer_address.cep}
								</TextStyled>
							</DivDetailsStyled>
						</DivSectionStyled>

						<TitleSectionStyled>Especificações do Crédito</TitleSectionStyled>
						<GapStyled />
						<DivSectionStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Valor líquido do crédito:</strong>{' '}
									{moneyMask(orderAmount)}
								</TextStyled>
								<TextStyled>
									<strong>CET:</strong> {cet} a.m.
								</TextStyled>
								<TextStyled>
									<strong>Vencimento da primeira parcela:</strong>{' '}
									{dateFormat(dueDate, 'dd/mm/yyyy')}
								</TextStyled>
							</DivDetailsStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Qt de parcelas:</strong> {installment}
								</TextStyled>
								<TextStyled>
									<strong>Valor da Parcela:</strong>{' '}
									{moneyMask(installmentValue)}
								</TextStyled>
								<TextStyled>
									<strong>Taxa de Juros:</strong> {interest} % ao mês
								</TextStyled>
							</DivDetailsStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Valor do IOF:</strong> {moneyMask(iof)}
								</TextStyled>

								<TextStyled>
									<strong>Periodicidade das parcelas:</strong> Mensal
								</TextStyled>
								<TextStyled>
									<strong>Valor total do crédito:</strong>{' '}
									{moneyMask(amountInstallments)}
								</TextStyled>
							</DivDetailsStyled>

							<DivDetailsStyled>
								<TextStyled>
									<strong>Forma de pagto.:</strong> Boleto Bancário
								</TextStyled>
							</DivDetailsStyled>
						</DivSectionStyled>

						<TitleSectionStyled>Encargos Monetários</TitleSectionStyled>
						<GapStyled />
						<DivSectionStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Multa:</strong> 2%
								</TextStyled>
								<TextStyled>
									<strong>Juros:</strong> 1% a.m.
								</TextStyled>
							</DivDetailsStyled>
						</DivSectionStyled>

						<TitleSectionStyled>Dados do correspondente</TitleSectionStyled>
						<GapStyled />
						<DivSectionStyled>
							<DivDetailsStyled>
								<TextStyled>
									<strong>Razão Social:</strong> VIRTUS TECH Tecnologia e
									Serviços S.A
								</TextStyled>
								<TextStyled>
									<strong>CNPJ:</strong> 27.148.375/0001-50
								</TextStyled>
							</DivDetailsStyled>
						</DivSectionStyled>

						<TitleSectionStyled>
							Condições Gerais da Cédula de Crédito Bancário
						</TitleSectionStyled>
						<GapStyled />
						<DivSectionStyled>
							<TextAreaStyled
								value={hasGracePeriodGt0 ? TextSheetGracePeriodGt0 : TextSheet}
								rows={10}
							/>
						</DivSectionStyled>

						<TitleSectionStyled>
							Condições Gerais da Cédula de Crédito Bancário
						</TitleSectionStyled>
						<GapStyled />
						<hr />
						<TextJustificationStyled>
							O EMITENTE, neste ato, emite esta Cédula de Crédito Bancário - CCB
							eletronicamente, em caráter irrevogável e irretratável, em favor
							do CREDOR, obrigando o EMITENTE e seus sucessores, a qualquer
							título, nos seus exatos termos e condições. <br />
							<SpanStyled>CONCORDO</SpanStyled> com todas as cláusulas desta
							Cédula de Crédito Bancário - CCB, me obrigando, desde já, a
							cumprir com todos os seus termos e condições, em especial os que
							dizem respeito ao pagamento dos valores nas datas e condições
							previstas no instrumento.{' '}
							{hasGracePeriodGt0 ??
								'Concordo, ainda, que é condição suspensiva para a eficácia desta CCB o pagamento da primeira parcela previsto acima na Especificação do Crédito.'}
							<br />
							<SpanStyled>AUTORIZO</SpanStyled> expressamente o e a VIRTUS TECH
							Tecnologia e Serviços S.A a divulgarem, compartilharem e trocarem
							suas informações com qualquer terceiro relacionado às operações
							objeto desta CCB, inclusive para fins de análise de risco de
							crédito (bureaus de crédito, cadastro positivo, etc.) e
							oferecimento de produtos e serviços de parceiros de e-commerce,
							conforme previsto nos Termos e Condições e na Política de
							Privacidade da VIRTUS TECH Tecnologia e Serviços S.A.
						</TextJustificationStyled>
						<ButtonDivStyled>
							<Button
								width="100%"
								className="has-gradient-blue"
								textColor="#fff"
								text="Fechar"
								onClick={onClose}
							/>
						</ButtonDivStyled>
					</FirstDivStyled>
				</Sheet.Content>
			</Sheet.Container>

			<Sheet.Backdrop onUnmount />
		</Sheet>
	);
};

export default ContractSheet;
