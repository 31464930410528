import { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	TitleStyled,
	PStyled,
	CenterMarginTopDivStyled,
	ApprovedClickStyled,
	CenterDivStyled,
} from './styles';

import { config } from '../../config.json';

import ApprovedImage from '../../assets/images/approved_2.0.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';

import { copyToClipboard } from '../../shared/utils/toClipboard';

import { CreditPayment, Button } from '../../shared/components/index';

import OrderApprovedController from './controllers/orderApprovedController';
import { useLoading } from '../../hooks/loading';

export function OrderApproved() {
	const [isPaid, setIsPaid] = useState(false);
	const [show, setShow] = useState(false);
	const [pixBarCode, setPixBarCode] = useState('');
	const [amount, setAmount] = useState('');
	const [chargeId, setChargeId] = useState('');
	const [bankingBilletLink, setBankingBilletLink] = useState('');
	// const [receivableDueDate, setReceivableDueDate] = useState('');
	const [hasCupom, setHasCupom] = useState(false);
	const [hasGracePeriodGt0, setHasGracePeriodGt0] = useState(false);

	// States que cuidarão do aparecimento das respetivas DIV's na tela
	const [isHiddenPreview, setIsHiddenPreview] = useState(false);

	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const controller = new OrderApprovedController();

		const checkReceivables = async () => {
			const { result, error } = await controller.checkReceivables(id);

			if (error) {
				return Swal.fire({
					icon: 'error',
					confirmButtonText: 'Fechar',
					text:
						result.data.message ??
						'Erro ao recuperar a proposta. Tente novamente em alguns minutos.',
				}).then(() => {
					window.location.href = '/';
				});
			}

			setIsPaid(result.paid_first_receivable);
			setBankingBilletLink(result.banking_billet_link);
			setPixBarCode(result.pix_barcode);
			setChargeId(result.banking_billet_charge_id);
			setAmount(result.receivable_amount);
			// setReceivableDueDate(result.receivable_due_date ?? null);
			setHasGracePeriodGt0(result.has_grace_period_gt_0);
			setHasCupom(result.has_coupon);
			setIsLoading(false);
		};

		checkReceivables(); // eslint-disable-next-line
	}, [id]);

	const copyPixBarCode = () => {
		copyToClipboard(pixBarCode);

		return Swal.fire({
			icon: 'success',
			text: 'Chave PIX copiada com sucesso.',
			confirmButtonText: 'Continuar',
		});
	};

	const creditCardPaid = () => {
		setIsHiddenPreview(false);
		setIsPaid(true);
		setShow(false);
	};

	const handleShowCredit = () => {
		setIsHiddenPreview(true);

		setShow(true);
	};

	const ReverseShowCredit = () => {
		setIsHiddenPreview(false);

		setShow(false);
	};

	const creditCard = () => {
		if (config.environment === 'PROD') return '';

		if (show) {
			return (
				<>
					<CreditPayment
						id={chargeId}
						transaction={id}
						creditCardPaid={creditCardPaid}
						receivableAmount={amount}
					/>

					<ApprovedClickStyled onClick={ReverseShowCredit}>
						Voltar
					</ApprovedClickStyled>
				</>
			);
		}
	};

	const templateApproved = () => {
		return (
			<>
				<TitleStyled className={`${isHiddenPreview && 'hidden'}`}>
					{hasGracePeriodGt0
						? 'Oferta De Crédito Aprovada'
						: 'Parcelamento Aprovado'}
					!
				</TitleStyled>

				{hasCupom ? (
					<PStyled className={`${isHiddenPreview && 'hidden'}`}>
						E tem <strong>desconto na sua primeira parcela!</strong> Pague agora
						para receber seu pedido :)
					</PStyled>
				) : hasGracePeriodGt0 ? (
					<PStyled className={`${isHiddenPreview && 'hidden'}`}>
						Siga as instruções da loja para recebimento do pedido! Não esqueça
						de pagar sua compra em até 30 dias.
					</PStyled>
				) : (
					<PStyled className={`${isHiddenPreview && 'hidden'}`}>
						{/* Pague o boleto até {receivableDueDate} para garantir a sua compra :) */}
						Pague a entrada para garantir a sua compra :)
					</PStyled>
				)}

				<CenterMarginTopDivStyled className={`${isHiddenPreview && 'hidden'}`}>
					{pixBarCode && (
						<Button
							width="300px"
							onClick={copyPixBarCode}
							className="has-gradient-blue"
							text="Pagar com PIX"
						/>
					)}

					{!pixBarCode && (
						<ApprovedClickStyled
							onClick={() => (window.location.href = bankingBilletLink)}
						>
							Pagar com boleto
						</ApprovedClickStyled>
					)}

					{/* <ApprovedClickStyled
						onClick={() => (window.location.href = bankingBilletLink)}
					>
						Pagar com boleto
					</ApprovedClickStyled> */}

					{config.environment !== 'PROD' ? (
						<ApprovedClickStyled onClick={handleShowCredit}>
							Pagar com o cartão à vista
						</ApprovedClickStyled>
					) : (
						''
					)}
				</CenterMarginTopDivStyled>

				{creditCard()}
			</>
		);
	};

	const templatePaid = () => {
		return (
			<>
				<TitleStyled>
					{hasGracePeriodGt0 ? 'Pagamento' : 'Parcelamento'} Já Efetuado!
				</TitleStyled>

				<PStyled>
					Fique de olho em seu email. <br />
					Você deve receber nossa confirmação em breve.
				</PStyled>

				<PStyled>A compensação do seu pagamento pode demorar um pouco.</PStyled>

				<CenterMarginTopDivStyled>
					<Button
						width="300px"
						onClick={() => history.push(`/taker/order/${id}`)}
						className="has-gradient-blue"
						text="Visualizar Proposta"
					/>
					{/* <ApprovedClickStyled
						onClick={() => {
							window.open(
								'https://api.whatsapp.com/send?phone=5511989442146&text=Para%20facilitar%20seu%20contato%20conosco,%20envie%20essa%20mensagem%20e%20nos%20adicione%20em%20seus%20contatos.%20Obrigado!%20Equipe%20VirtusPay&source=&data=#.',
								'_blank'
							);
						}}
					>
						Caso precise de mais informações, fale com a gente!
					</ApprovedClickStyled> */}
				</CenterMarginTopDivStyled>
			</>
		);
	};

	return (
		<CenterDivStyled>
			<CenterMarginTopDivStyled>
				<img
					className={`${isHiddenPreview && 'hidden'}`}
					src={Logo}
					alt="Logo VirtusPay"
				/>

				<CenterMarginTopDivStyled>
					<img
						className={`${isHiddenPreview && 'hidden'}`}
						src={ApprovedImage}
						width="150px"
						alt="Aprovado"
					/>
				</CenterMarginTopDivStyled>

				{isPaid ? templatePaid() : templateApproved()}
			</CenterMarginTopDivStyled>
		</CenterDivStyled>
	);
}
