import { useEffect, useLayoutEffect, useState } from 'react';

import {
	TileStyled,
	MainStyled,
	ContainerStyled,
	TitleStyled,
	PStyled,
} from './styles';

import { PasswordFields } from './components/Password';
import { DataFields } from './components/PersonalData';
import { AddressFields } from './components/Address';
import { BankFields } from './components/Bank';

import { VirtusNavbar } from '../../shared/components/index';

import {
	PasswordProps,
	UserProps,
	AddressProps,
	BankProps,
} from './types/types';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';

export function UserAccount() {
	const [password] = useState({} as PasswordProps);
	const [userData] = useState({} as UserProps);
	const [address] = useState({} as AddressProps);
	const [bank] = useState({} as BankProps);

	// Informacoes pessoas
	const [name, setName] = useState('');

	// Informacoes de endereco

	const { user } = useAuth();

	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		getUpdatedUser(); // eslint-disable-next-line
	}, [user]);

	const getUpdatedUser = () => {
		setName(user?.full_name);
		setIsLoading(false);
	};

	return (
		<>
			<VirtusNavbar
				title="Página inicial"
				link={user.profile === 'tomador' ? '/taker' : '/assignor'}
			/>
			<MainStyled>
				<ContainerStyled>
					<TileStyled>
						<TitleStyled>{name}</TitleStyled>
						<PStyled>Atualizar conta</PStyled>
					</TileStyled>

					<PasswordFields userPassword={password} />

					<DataFields userData={userData} />

					<AddressFields userAddress={address} />

					{user.profile === 'cedente' ? <BankFields bankData={bank} /> : ''}
				</ContainerStyled>
			</MainStyled>
		</>
	);
}
