import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

interface DivProps {
	props: boolean;
}

export const ContainerStyled = styled.div`
	display: flex;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: initial;
	justify-content: center;
	overflow-x: hidden;
`;

export const MainStyled = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const DetailsStyled = styled.p`
	text-align: left;
	font: normal normal normal 15px/20px Montserrat;
	letter-spacing: 0px;
	color: #616161;
`;

export const ContainerDetailsStyled = styled.div`
	display: flex;
	margin-top: 1rem;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 800px) {
		width: 90%;
		justify-content: center;
		align-items: center;
		text-align: left;
		flex-direction: column;
		margin: 1rem auto;
	}
`;

export const DivDetailsStyled = styled.div<DivProps>`
	display: flex;
	margin: 1rem 0 1rem;
	align-items: start;
	flex-direction: column;
	text-align: left;
	justify-content: start;
	width: 100%;
	margin-right: ${({ props }) => (props ? '10%' : '0')};

	@media (max-width: 800px) {
		margin-right: 0%;
		margin: 0.2rem;
	}
`;

export const FirstDivDetailsStyled = styled.div<DivProps>`
	display: flex;
	margin: 1rem auto 1rem;
	align-items: start;
	flex-direction: column;
	text-align: left;
	justify-content: start;
	margin-left: ${({ props }) => (props ? '23%' : '8%')};
	width: ${({ props }) => (props ? '100%' : '90%')};

	@media (max-width: 800px) {
		margin-left: 0%;
	}
`;

export const ContainerRenderAllStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin: 4rem auto 1rem auto;
	align-items: initial;
	justify-content: center;
`;

export const ButtonDivStyled = styled.div`
	display: flex;
	margin: 0.5rem auto;
	width: 100%;
	align-items: center;
	justify-content: center;
	max-width: 600px;

	@media (max-width: 800px) {
		margin: 1rem auto;
		width: 80%;
	}
`;

export const TitleStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 24px;
	text-align: center;
	color: ${colors.textBlue};
`;

export const DivBoxStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	margin: 1rem 0;
	padding: 1rem 0;
	width: 50%;
	max-width: 500px;
	border: 1px solid #28c0cd;
	border-radius: 5px;

	@media (max-width: 800px) {
		width: 80%;
	}
`;

export const SpanGreyStyled = styled.span`
	font-size: 13px;
	text-align: center;
	color: hsl(0, 0%, 48%);
	font-weight: bold;
	text-decoration: line-through;
`;

export const SpanGreenStyled = styled.span`
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	color: hsl(141, 71%, 48%);
`;

export const CenterDivStyled = styled.div`
	display: flex;
	margin: 1rem auto;
	flex-direction: column;
	width: 90%;
	align-items: center;
	justify-content: center;
`;

export const RenderDivStyled = styled.div`
	display: flex;
	margin: 1rem auto 0.2rem;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;

	@media (max-width: 800px) {
		width: 90%;
	}
`;

export const RenderDivTermsStyled = styled.div`
	display: flex;
	margin-top: 1rem;
	flex-direction: column;
	align-items: center;
	width: 50%;
	justify-content: center;
`;

export const TitleViajaNetStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 24px;
	margin: 1rem 0.1rem;
	text-align: center;
	color: black;
`;

export const Title2ViajaNetStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 24px;
	text-align: center;
	color: ${colors.primaryBlue};
`;

export const SpanViajaNetStyled = styled.span`
	font-family: 'Montserrat';
	font-weight: 650;
	font-size: 24px;
	text-align: center;
	color: ${colors.primaryBlue};
`;

export const H2ViajaNetStyled = styled.h2`
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 18px;
	text-align: center;
`;

export const PViajaNetStyled = styled.p`
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	margin-bottom: 0.9rem;
	color: hsl(0, 0%, 71%);
`;

export const P2ViajaNetStyled = styled.p`
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 19px;
	text-align: center;
`;

export const TextRenderStyled = styled.p`
	text-align: left;
	margin-top: 1rem;
	letter-spacing: 0px;
	max-width: 800px;
`;

export const PRenderStyled = styled.p`
	text-align: center;
	margin-bottom: 1rem;
	width: 80%;
	letter-spacing: 0px;
`;

export const SpanRenderStyled = styled.span`
	text-align: left;
	margin: 1rem 0.2rem auto auto;
	letter-spacing: 0px;
	text-decoration: underline;
	cursor: pointer;
	color: ${colors.primaryBlue};
`;
