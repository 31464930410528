import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Home } from './views/home';

import {
	OrderCheckout,
	OrderSending,
	OrderThanks,
	OrderRefused,
	OrderList,
	OrderDetails,
	OrderApproved,
} from './views/order';

import { PaymentError } from './views/paymentError';

import { Token } from './views/token';

import { Ccb } from './views/ccb';

import {
	KycBankExt,
	KycContacts,
	KycDocBack,
	KycDocFront,
	KycRedirect,
	KycSelfie,
	KycThanks,
} from './views/kyc';

import { CouponList } from './views/coupon';

import { OrderEmailValidation } from './views/emailValidation';

import { NotFound } from './views/notFound';

import {
	PrivateRoute,
	TransactionRoute,
	LoginRedirect,
	RegisterRedirect,
} from './shared/components/index';

import { LandingPageTaker } from './views/landingPages/views/Taker';
import { LandingPageCompanies } from './views/landingPages/views/Companies';
import { LandingPageAssignor } from './views/landingPages/views/Assignor';

import { Login } from './views/login';

import {
	SignUpStepOne,
	SignUpStepTwo,
	SignUpStepThree,
	SignUpStepFour,
} from './views/signUp';

// import { CelcoinLP } from './views/LandingPages/views/Celcoin';

import { UserAccount, CanceledAccount } from './views/profile';

import { Contact } from './views/contact';

import { RecoverPassword } from './views/recoverPassword';

import { RedefinePassword } from './views/redefinePassword';

import { TakerStores } from './views/takerStores';

import './main.scss';

import dotenv from 'dotenv';
dotenv.config();

export default function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={Login} />
				<Route path="/login" exact component={Login} />
				<Route path="/auth/:id" exact component={LoginRedirect} />

				<Route path="/register" exact component={SignUpStepOne} />
				<Route path="/register/2/:token?" exact component={SignUpStepTwo} />
				{/* <PrivateRoute path="/register/2" exact component={SignUpStepTwo} /> */}

				<PrivateRoute path="/register/3" exact component={SignUpStepThree} />

				<PrivateRoute path="/user" exact component={UserAccount} />
				<Route path="/user/canceled" exact component={CanceledAccount} />

				<Route path="/contact" exact component={Contact} />

				<Route path="/recover_password" exact component={RecoverPassword} />

				<Route
					path="/reset/:token/set-password"
					exact
					component={RedefinePassword}
				/>

				{/* <Route path="/celcoin" exact component={CelcoinLP} /> */}
				<Route
					path="/celcoin"
					exact
					component={() => {
						window.location.replace('https://www.usevirtus.com.br');
						return null;
					}}
				/>

				<Route path="/landing-page/taker" exact component={LandingPageTaker} />
				<Route
					path="/landing-page/companies"
					exact
					component={LandingPageCompanies}
				/>
				<Route
					path="/landing-page/assignor"
					exact
					component={LandingPageAssignor}
				/>

				<Route path="/stores" exact component={TakerStores} />

				{/* Rotas temporárias até validarmos redirects para "/taker/"
				<Route path="/taker/register" exact component={SignUpStepOne} />
				<PrivateRoute
					path="/taker/register/2"
					exact
					component={SignUpStepTwo}
				/>
				<PrivateRoute
					path="/taker/register/3"
					exact
					component={SignUpStepThree}
				/> */}

				<Route path="/auth/:id" exact component={LoginRedirect} />
				<Route path="/taker/register" exact component={RegisterRedirect} />
				<PrivateRoute
					path="/taker/register/finish"
					exact
					component={SignUpStepFour}
				/>
				<PrivateRoute path="/taker" exact component={Home} />
				<PrivateRoute path="/taker/order/add" exact component={OrderSending} />
				<TransactionRoute
					path="/taker/order/:id/accept"
					exact
					component={OrderCheckout}
				/>
				<TransactionRoute
					path="/taker/order/:id/token"
					exact
					component={Token}
				/>
				<TransactionRoute
					path="/taker/order/:id/signature"
					exact
					component={Ccb}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao"
					exact
					component={KycRedirect}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/selfie"
					exact
					component={KycSelfie}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/doc_front"
					exact
					component={KycDocFront}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/doc_back"
					exact
					component={KycDocBack}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/bank_ext"
					exact
					component={KycBankExt}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/contacts"
					exact
					component={KycContacts}
				/>
				<TransactionRoute
					path="/taker/order/:id/formalizacao/thanks"
					exact
					component={KycThanks}
				/>
				<TransactionRoute
					path="/taker/order/:id/thanks"
					exact
					component={OrderThanks}
				/>
				<TransactionRoute
					path="/taker/order/:id/refused"
					exact
					component={OrderRefused}
				/>
				<TransactionRoute
					path="/taker/order/:id/approved"
					exact
					component={OrderApproved}
				/>
				{process.env.REACT_APP_ENVIRONMENT !== 'PROD' ? (
					<PrivateRoute
						path="/taker/order/:id/payment-error"
						exact
						component={PaymentError}
					/>
				) : (
					''
				)}

				<PrivateRoute path="/taker/order" exact component={OrderList} />
				<PrivateRoute path="/taker/order/:id" exact component={OrderDetails} />

				<PrivateRoute path="/taker/coupon" exact component={CouponList} />

				<TransactionRoute
					path="/taker/order/:id/email/validation"
					exact
					component={OrderEmailValidation}
				/>

				<Route component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
}
