import { useState, useEffect, ReactNode } from 'react';
import LazyLoad from 'react-lazyload';

import Swal from 'sweetalert2';
import { BsSearch } from 'react-icons/bs';

import {
	SearchBarStyled,
	DivLogoStyled,
	ContainerStyled,
	DivSearchStyled,
	BarStyled,
	RenderDivStyled,
} from './styles';

import PartnerController from '../controllers/partnerController';
import { useLoading } from '../../../hooks/loading';

interface IPartners {
	partners: ReactNode[];
	loading: boolean;
	filteredPartners?: ReactNode[];
	queryString?: string;
}

export function SearchStore() {
	const { setIsLoading } = useLoading();

	const [filter, setFilter] = useState<IPartners>({
		loading: true,
		partners: [],
		filteredPartners: [],
		queryString: '',
	});

	// eslint-disable-next-line
	useEffect(() => {
		getPartners();
		setIsLoading(filter.loading);
	}, []);

	useEffect(() => {
		setIsLoading(filter.loading);
	}, [filter.loading]);

	const controller = new PartnerController();

	const getPartners = async () => {
		const { result, error } = await controller.get();

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
			});
		}

		const stores = [];

		for (const store in result) {
			stores.push(
				<DivLogoStyled
					onClick={() => {
						window.open(result[store].link_loja, '_blank')?.focus();
					}}
					key={result[store].display_loja}
				>
					<LazyLoad once>
						<img src={result[store].logo_loja} alt="Logo Loja" style={{maxWidth: '200px', maxHeight: '200px'}} />
					</LazyLoad>
				</DivLogoStyled>
			);
		}

		setFilter({
			partners: stores,
			loading: false,
		});
	};

	const renderStores = () => {
		return filter.filteredPartners ?? filter.partners;
	};

	const applyFilter = (e: any) => {
		const filterResult = e.target.value;
		setFilter({
			...filter,
			filteredPartners: filter.partners?.filter((e: any) =>
				e.key.toLowerCase().includes(filterResult.toLowerCase())
			),
		});
	};

	return (
		<ContainerStyled>
			<SearchBarStyled
				value={filter.queryString}
				onChange={applyFilter}
				placeholder="Buscar lojas, Ex: Magalu, Extra, Dafiti, Kanui"
			></SearchBarStyled>

			<DivSearchStyled>
				<BarStyled />
				<BsSearch />
			</DivSearchStyled>
			<RenderDivStyled>{renderStores()}</RenderDivStyled>
		</ContainerStyled>
	);
}
