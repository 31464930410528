class SignupDto {
	full_name: string | null = '';
	email = '';
	password = '';
	cpf: string | null = '';
	salary = '';
	cellphone: string | null = '';
	profile = 'tomador';
	whatsapp = true;
	negativado: boolean | undefined = true;
	nickname = '';
	offers: string[] = [];
	cupom?: string | null;
	ad_id: string | null = '';
	epar: string | null = '';
	captcha: string | null = '';
}

export default SignupDto;
