import AddressRepository from '../repositories/addressRepository';
import SignupRepository from '../repositories/signupRepository';
import AuthController from '../../../shared/controllers/authController';
import { FormData } from '../SignUpStepThree';
class AddressController {
	repository: AddressRepository;
	signupRepository: SignupRepository;
	authController: AuthController;

	constructor() {
		this.repository = new AddressRepository();
		this.signupRepository = new SignupRepository();
		this.authController = new AuthController();
	}
	async getAddress(cep: string) {
		const data = await this.repository.getAddress(cep);
		return data;
	}

	async submit(payload: FormData) {
		const result = await this.signupRepository.submitAddress(payload);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}

export default AddressController;
