import axios from '../http/customAxios';

export default class ValidatorPageRepository {
	async validateEmail(email: string) {
		try {
			const result = await axios().get(`/user/email/verify?email=${email}`, {
				headers: { noAuthorization: true },
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async validateCPF(cpf: string) {
		try {
			const result = await axios().get(`/user/cpf/verify?cpf=${cpf}`, {
				headers: { noAuthorization: true },
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}
