import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	TitleStyled,
	CenterDivStyledTrackingStyled,
	ContainerStyled,
	InstructionsDivStyled,
	IMGDivStyled,
	CenterDivStyled,
	PStyled,
	PClickStyled,
	CamDivStyled,
	ArrowStyled,
	ButtonDivStyled,
	IMGStyled,
	ButtonHiddenDesktopStyled,
} from './styles';

import Swal from 'sweetalert2';

import Canvas from './components/Canvas/canvas';

import KYCController from './controllers/kycController';

import { modalErrorUnico } from './utils/errorUnico';

import RgBack from '../../assets/images/KYC/rg-back.png';
import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/KYC/tracking-doc.png';

import { UnicoFrame, Button } from '../../shared/components/index';

import fileToBase64 from '../../shared/utils/fileToBase64';
import { clearBase64 } from '../../shared/utils/clearBase64';
import { validFileExtension } from '../../shared/utils/validators';

import OrderNavbar from '../order/components/OrderNavbar';

import { IParams, IResponseErrorUnico } from './types/types';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';

export const KycDocBack = () => {
	const [docBase64, setDocBase64] = useState('');
	const [docEncrypted, setDocEncrypted] = useState('');
	const [showCamera, setShowCamera] = useState(false);

	const [isHiddenSelect, setIsHiddenSelect] = useState(false);
	const [isHiddenPreview, setIsHiddenPreview] = useState(true);
	const [isHiddenInstructions, setIsHiddenInstructions] = useState(false);

	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		checkStep(); // eslint-disable-next-line
	}, []);

	const controller = new KYCController();

	const checkStep = async () => {
		const { result, error, redirect } = await controller.getSteps(id);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.string, // No controller, esse é o result.data.message
				confirmButtonText: 'Fechar',
			});
		}

		if (redirect) {
			return history.push(redirect);
		}

		const { next_step } = result;

		if (next_step !== 'doc_back') {
			return history.push(`/taker/order/${id}/formalizacao/${next_step}`);
		}
	};

	const handleInput = () => {
		const input: HTMLElement | null = document.querySelector('#file');
		input?.click();
	};

	const handleShowCamera = () => {
		hideAll();

		setIsHiddenInstructions(true);

		setShowCamera(true);
	};

	const showPreview = () => {
		hideAll();

		setIsHiddenInstructions(false);
		setIsHiddenPreview(false);
	};

	const hideAll = () => {
		setIsHiddenSelect(true);
		setIsHiddenPreview(true);
		setIsHiddenInstructions(false);
		// setDocBase64('');

		setShowCamera(false);
	};

	const fileSelectorHandler = async (event: any) => {
		const file = event.target.files[0];

		if (!validFileExtension(file)) {
			setDocBase64('');
			setDocEncrypted('');

			return Swal.fire({
				icon: 'error',
				text: 'Formato de arquivo inválido. Só aceitamos imagens.',
			});
		}

		const image = await fileToBase64(file);

		setDocBase64('data:image/jpeg;base64,' + image);
		setDocEncrypted('data:image/jpeg;base64,' + image);

		showPreview();
	};

	const handleSetDocBase64 = async (base64: string, encrypted: string) => {
		setDocBase64(base64);
		setDocEncrypted(encrypted);
		showPreview();
	};

	const setSelectStep = () => {
		hideAll();

		setIsHiddenInstructions(false);
		setIsHiddenSelect(false);

		const fileEl = document.getElementById('file');
		if (fileEl) {
			fileEl.value = '';
		}
	};

	const submit = async () => {
		setIsLoading(true);

		controller.dto.image_base_64 = clearBase64(docBase64);
		controller.dto.image_encrypted = docEncrypted;
		controller.dto.num_type = 502;

		eventPush('web_rg_verso_conf_sim_enviar', 'INFO', {
			ld: id,
		});

		const { result, error, expiredProcess, redirect } =
			await controller.submitRGBack(id);

		if (expiredProcess.errorExpired) {
			Swal.fire({
				icon: 'error',
				text: expiredProcess.resultExpired.data.message,
				confirmButtonText: 'Refazer Processo',
			}).then(() => {
				setIsLoading(false);
				history.push(redirect);
			});
		}

		if (error) {
			Swal.fire({
				icon: 'error',
				text: result.data.message ?? 'Erro ao enviar o RG. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			}).then(() => {
				hideAll();
				checkStep();
			});
		}

		if (redirect) {
			setIsLoading(false);
			return history.push(redirect);
		}

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const renderPreview = () => {
		return <Canvas base64={docBase64} />;
	};

	const handleErrorUnico = (error: IResponseErrorUnico, call: string) => {
		modalErrorUnico(error, call, () =>
			history.push(`/taker/order/${id}/formalizacao`)
		);
	};

	const camera = () => {
		if (showCamera) {
			return (
				<div>
					<CamDivStyled>
						<ArrowStyled size={30} onClick={setSelectStep} />
					</CamDivStyled>

					<UnicoFrame
						handleError={(error: IResponseErrorUnico, call: string) =>
							handleErrorUnico(error, call)
						}
						handlePicture={handleSetDocBase64}
						camera="document"
						type={7}
					/>
				</div>
			);
		}
	};

	return (
		<main>
			<div id="instructions" className={`${isHiddenInstructions && 'hidden'}`}>
				<OrderNavbar src={Logo} />
				<ContainerStyled>
					<input
						onChange={fileSelectorHandler}
						id="file"
						accept=".png,.jpeg,.jpg"
						className="hidden"
						type="file"
					/>

					<InstructionsDivStyled isHidden={isHiddenSelect} id="select">
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<TitleStyled>Envie uma foto do verso do seu RG</TitleStyled>

						<IMGDivStyled>
							<IMGStyled src={RgBack} alt="RG" />
						</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Não corte o documento, ajuste a iluminação e cuidado com o
							foco e reflexos
						</PStyled>

						<ButtonHiddenDesktopStyled>
							<Button
								className="has-gradient-blue"
								textColor="#fff"
								text="Tirar Foto"
								padding="9px 110px"
								onClick={handleShowCamera}
								width="300px"
							/>
						</ButtonHiddenDesktopStyled>

						<PClickStyled
							style={{ marginBottom: '2rem' }}
							onClick={() => {
								eventPush('web_rg_verso_imp_foto', 'INFO', {
									ld: id,
								});
								handleInput();
							}}
						>
							Importar uma foto
						</PClickStyled>
					</InstructionsDivStyled>

					<InstructionsDivStyled isHidden={isHiddenPreview} id="preview">
						<TitleStyled>E ai, a foto ficou boa?</TitleStyled>

						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<CenterDivStyled>{renderPreview()}</CenterDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Verifique se o documento está com boa iluminação e bem
							centralizado.
						</PStyled>

						<ButtonDivStyled>
							<Button
								text="Sim, enviar"
								className="has-gradient-blue step_enviardocumentos"
								textColor="#fff"
								width="300px"
								onClick={submit}
							/>
						</ButtonDivStyled>

						<PClickStyled
							onClick={() => {
								eventPush('web_rg_verso_conf_tirar_outra', 'INFO', {
									ld: id,
								});
								setSelectStep();
							}}
						>
							Quero tirar outra
						</PClickStyled>
					</InstructionsDivStyled>
				</ContainerStyled>
			</div>

			{camera()}
		</main>
	);
};
