import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import dateFormat from 'dateformat';

import Swal from 'sweetalert2';

import {
	ContainerStyled,
	OrangeButtonStyled,
	SpanStyled,
	OrangeSpanStyled,
	SuccessSpanStyled,
	DangerSpanStyled,
	BlackSpanStyled,
	WhiteSpanStyled,
	WarningSpanStyled,
	OrderDivStyled,
	RowDivStyled,
	MobileOrderDivStyled,
	DesktopDivStyled,
	BlueSpanStyled,
	Desktop2DivStyled,
	GapDivStyled,
	SmallPStyled,
	GreyPStyled,
	PendenciasDivStyled,
	GapMobileDivStyled,
	SuccessDivStyled,
	WhitePStyled,
	WhitepStyled,
	WhiteSmallPStyled,
	PendenciasWhiteDivStyled,
	WhiteButtonStyled,
	MobileSuccessDivStyled,
} from './styles';

import { moneyMask } from '../../../../shared/utils/masks';

import { OrderTracking } from '../OrderTracking/orderTracking';

import OrderController from '../../controllers/orderController';
import { useLoading } from '../../../../hooks/loading';

export interface OrderTileProps {
	transaction: string;
	href: string;
	status: string;
	store: string;
	description: string;
	amount: string;
	paidInstallments: string;
	installments: number;
	interest: string;
	createdAt: string;
	updatedAt?: string;
	orderRef: string;
	paymentError: string;
}

export function OrderTile({
	transaction,
	href,
	status,
	store,
	description,
	amount,
	paidInstallments,
	installments,
	interest,
	createdAt,
	updatedAt,
	orderRef,
	paymentError,
}: OrderTileProps) {
	const [opened, setOpened] = useState(false);

	const history = useHistory();

	const { setIsLoading, isLoading } = useLoading();

	const orderController = new OrderController();

	const checkPendency = async () => {
		setIsLoading(true);

		const order = await orderController.get(transaction);

		setIsLoading(false);

		if (order.redirect_to !== null) {
			const redirect = order.redirect_to;

			if (redirect?.includes('token')) {
				return history.push(`/taker/order/${order.transaction}/token`);
			}
		}

		if (paymentError) {
			return history.push(`/taker/order/${order.transaction}/payment-error`);
		}

		if (
			(order.status === 'N' || order.status === 'P' || order.status === 'A') &&
			!order.signature
		) {
			return history.push(`/taker/order/${order.transaction}/signature`);
		}

		let pendentKYC = false;

		for (const step in order.steps) {
			if (order.steps[step]) {
				pendentKYC = true;
				break;
			}
		}

		if (pendentKYC)
			return history.push(`/taker/order/${order.transaction}/formalizacao`);

		return Swal.fire({
			icon: 'success',
			titleText: 'Parabéns!',
			text: 'Essa proposta não possui pendências',
			confirmButtonText: 'Entendi',
		});
	};

	const redirect = () =>
		paymentError
			? history.push(`/taker/order/${transaction}/payment-error`)
			: history.push(href);

	const setLabel = (statusLabel: string) => {
		if (statusLabel === 'Pendente') {
			return <OrangeSpanStyled>{status}</OrangeSpanStyled>;
		} else if (statusLabel === 'Efetivada') {
			return <SuccessSpanStyled>{status}</SuccessSpanStyled>;
		} else if (statusLabel === 'Recusada') {
			return <DangerSpanStyled>{status}</DangerSpanStyled>;
		} else if (statusLabel === 'Cancelada') {
			return <WarningSpanStyled>{status}</WarningSpanStyled>;
		}
	};

	const renderDetails = () => {
		if (opened)
			return (
				<span>
					<br />
					<br />
					Total de parcelas pagas:{' '}
					<b>
						{paidInstallments} de {installments}
					</b>
					<br />
					Juros: <b>{interest}% a.m.</b>
					<br />
					<br />
					Criado em: <b>{dateFormat(createdAt, 'dd/mm/yyyy')}</b>
					<br />
					Atualizado em: <b>{dateFormat(updatedAt, 'dd/mm/yyyy')}</b>
				</span>
			);
	};

	const renderDetailsButton = () => {
		return <>{opened ? '-' : '+'} Detalhes</>;
	};

	const displayStore = (store: string) => {
		return store
			.replace(' Marketplace', '')
			.replace(' PagarMe', '')
			.replace(' Pagamentos', '')
			.replace(' Ame', '');
	};

	const setDescription = (e: string) => {
		if (e.length > 30) return e.substring(0, 24) + '...';

		return e;
	};

	const setPendencyButton = () => {
		if (!isLoading) {
			if (
				status === 'Pendente' ||
				status === 'Em análise' ||
				status === 'Analisada'
			) {
				return (
					<div>
						<OrangeButtonStyled
							data-testid="checkPendency"
							onClick={checkPendency}
						>
							Checar pendência
						</OrangeButtonStyled>
						<br />
					</div>
				);
			}
		}
	};

	const mountTile = () => {
		if (status === 'Analisada' || status === 'Em análise') {
			return (
				<div>
					<OrderDivStyled>
						<OrderTracking />

						<RowDivStyled>
							<DesktopDivStyled>
								<BlueSpanStyled>Em análise</BlueSpanStyled>

								<GapDivStyled style={{ height: opened ? '115px' : '50px' }} />
							</DesktopDivStyled>

							<Desktop2DivStyled>
								<SmallPStyled>
									Loja: <BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
									<br />
									Produto:{' '}
									<BlackSpanStyled>
										{setDescription(description)}
									</BlackSpanStyled>
									<br />
									Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
									{renderDetails()}
								</SmallPStyled>

								<GreyPStyled>{moneyMask(amount)}</GreyPStyled>

								<PendenciasDivStyled>
									{setPendencyButton()}

									{renderDetailsButton()}
								</PendenciasDivStyled>
							</Desktop2DivStyled>
						</RowDivStyled>
					</OrderDivStyled>

					<MobileOrderDivStyled>
						<OrderTracking />

						<RowDivStyled>
							<BlueSpanStyled>Em análise</BlueSpanStyled>

							<GapMobileDivStyled />

							<GreyPStyled>{moneyMask(amount)} </GreyPStyled>
						</RowDivStyled>

						<SmallPStyled>
							Loja: <BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
							<br />
							Produto:{' '}
							<BlackSpanStyled>{setDescription(description)}</BlackSpanStyled>
							<br />
							Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
							{renderDetails()}
						</SmallPStyled>

						<PendenciasDivStyled>
							{setPendencyButton()}

							{renderDetailsButton()}
						</PendenciasDivStyled>
					</MobileOrderDivStyled>
				</div>
			);
		}

		if (status === 'Aprovada' || status === 'Efetivada') {
			if (!paymentError) {
				return (
					<div>
						<SuccessDivStyled>
							<RowDivStyled>
								<DesktopDivStyled>
									<WhiteSpanStyled>{status}</WhiteSpanStyled>

									<GapDivStyled
										style={{
											backgroundColor: '#fff',
											height: opened ? '115px' : '50px',
										}}
									/>
								</DesktopDivStyled>

								<Desktop2DivStyled>
									<WhiteSmallPStyled>
										Loja: <WhitepStyled>{displayStore(store)}</WhitepStyled>
										<br />
										Produto:{' '}
										<WhitepStyled>{setDescription(description)}</WhitepStyled>
										<br />
										Nº Pedido: <WhitepStyled>{orderRef}</WhitepStyled>
										{renderDetails()}
									</WhiteSmallPStyled>

									<WhitePStyled>{moneyMask(amount)}</WhitePStyled>

									<PendenciasWhiteDivStyled>
										<WhiteButtonStyled onClick={redirect}>
											Ver parcelas
										</WhiteButtonStyled>

										<br />

										{renderDetailsButton()}
									</PendenciasWhiteDivStyled>
								</Desktop2DivStyled>
							</RowDivStyled>
						</SuccessDivStyled>

						<MobileSuccessDivStyled>
							<RowDivStyled>
								<WhiteSpanStyled>{status}</WhiteSpanStyled>

								<GapMobileDivStyled style={{ backgroundColor: '#fff' }} />

								<WhitePStyled>{moneyMask(amount)}</WhitePStyled>
							</RowDivStyled>

							<WhiteSmallPStyled>
								Loja: <WhitepStyled>{displayStore(store)}</WhitepStyled>
								<br />
								Produto:{' '}
								<WhitepStyled>{setDescription(description)}</WhitepStyled>
								<br />
								Nº Pedido: <WhitepStyled>{orderRef}</WhitepStyled>
								{renderDetails()}
							</WhiteSmallPStyled>

							<PendenciasDivStyled>
								<WhiteButtonStyled onClick={redirect}>
									Ver parcelas
								</WhiteButtonStyled>

								{renderDetailsButton()}
							</PendenciasDivStyled>
						</MobileSuccessDivStyled>
					</div>
				);
			} else {
				return (
					<div>
						<OrderDivStyled>
							<RowDivStyled>
								<DesktopDivStyled>
									<DangerSpanStyled>Pagamento com erro</DangerSpanStyled>

									<GapDivStyled style={{ height: opened ? '115px' : '50px' }} />
								</DesktopDivStyled>

								<Desktop2DivStyled>
									<SmallPStyled>
										Loja:{' '}
										<BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
										<br />
										Produto:{' '}
										<BlackSpanStyled>
											{setDescription(description)}
										</BlackSpanStyled>
										<br />
										Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
										{renderDetails()}
									</SmallPStyled>
									<GreyPStyled>{moneyMask(amount)}</GreyPStyled>
									<PendenciasDivStyled>
										<OrangeButtonStyled onClick={redirect}>
											Checar pendência
										</OrangeButtonStyled>
										<br />

										{renderDetailsButton()}
									</PendenciasDivStyled>
								</Desktop2DivStyled>
							</RowDivStyled>
						</OrderDivStyled>

						<MobileOrderDivStyled>
							<RowDivStyled>
								<DangerSpanStyled style={{ fontSize: '15px' }}>
									Pagamento com erro
								</DangerSpanStyled>

								<GapMobileDivStyled />

								<GreyPStyled>{moneyMask(amount)} </GreyPStyled>
							</RowDivStyled>

							<SmallPStyled>
								Loja: <BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
								<br />
								Produto:{' '}
								<BlackSpanStyled>{setDescription(description)}</BlackSpanStyled>
								<br />
								Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
								{renderDetails()}
							</SmallPStyled>

							<PendenciasDivStyled>
								<OrangeButtonStyled onClick={redirect}>
									Checar pendência
								</OrangeButtonStyled>
								{renderDetailsButton()}
							</PendenciasDivStyled>
						</MobileOrderDivStyled>
					</div>
				);
			}
		}

		return (
			<div>
				<OrderDivStyled>
					<RowDivStyled>
						<DesktopDivStyled>
							<p>{setLabel(status)}</p>

							<GapDivStyled style={{ height: opened ? '115px' : '50px' }} />
						</DesktopDivStyled>

						<Desktop2DivStyled>
							<SmallPStyled>
								Loja: <BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
								<br />
								Produto:{' '}
								<BlackSpanStyled>{setDescription(description)}</BlackSpanStyled>
								<br />
								Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
								{renderDetails()}
							</SmallPStyled>

							<GreyPStyled>{moneyMask(amount)}</GreyPStyled>

							<PendenciasDivStyled>
								{setPendencyButton()}

								{renderDetailsButton()}
							</PendenciasDivStyled>
						</Desktop2DivStyled>
					</RowDivStyled>
				</OrderDivStyled>

				<MobileOrderDivStyled>
					<RowDivStyled>
						<SpanStyled>{setLabel(status)}</SpanStyled>

						<GapMobileDivStyled />

						<GreyPStyled>{moneyMask(amount)} </GreyPStyled>
					</RowDivStyled>

					<SmallPStyled>
						Loja: <BlackSpanStyled>{displayStore(store)}</BlackSpanStyled>
						<br />
						Produto:{' '}
						<BlackSpanStyled>{setDescription(description)}</BlackSpanStyled>
						<br />
						Nº Pedido: <BlackSpanStyled>{orderRef}</BlackSpanStyled>
						{renderDetails()}
					</SmallPStyled>

					<PendenciasDivStyled>
						{setPendencyButton()}

						{renderDetailsButton()}
					</PendenciasDivStyled>
				</MobileOrderDivStyled>
			</div>
		);
	};

	return (
		<ContainerStyled onClick={() => setOpened(!opened)}>
			{mountTile()}
		</ContainerStyled>
	);
}
