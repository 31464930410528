import styled from 'styled-components';

export const BetweenDivStyled = styled.div`
	display: flex;
	justify-content: center;
	align-content: space-between;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
`;

export const CenterStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
`;

export const BulletStyled = styled.span`
	height: 10px;
	width: 10px;
	border-radius: 100%;
	padding: 2px;
	display: inline-block;
	opacity: 0.5;
	background: #27bdcc;
`;

export const TitleStyled = styled.h2`
	font-size: 1.2rem;
	margin: 2rem 0;
`;

export const PStyled = styled.p`
	font-size: 0.9rem;
`;

export const SpanStyled = styled.span`
	font-size: 1rem;
`;

export const PSemiBoldStyled = styled.p`
	font-size: 0.9rem;
	font-weight: 500;
	margin: 0.5rem 0;
`;

export const ButtonStyled = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: row;
	width: 150px;
	border: 1px solid;
	padding: 0.5rem 0.7rem;
	border-radius: 5px;
`;
