import { InputHTMLAttributes } from 'react';

import { Control, Controller } from 'react-hook-form';

import { ContainerStyled, RawCheckboxStyled } from './style';

export interface InputProps extends InputHTMLAttributes<any> {
	// Props for Element
	mask?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
	useExternalValue?: boolean;
	dataTestId?: string;

	areTerms: boolean;

	/*
		Prop util para casos de switches como os checkboxes. 
		exemplo em src/views/SignUp/SignUpStepTwo.tsx
	*/
	forceFalse?: boolean;

	// React-hook-form
	name: string;
	control?: Control;
}

export function InputCheckbox({
	name,
	control,
	dataTestId,
	inputRef,
	forceFalse,
	areTerms,
	...rest
}: InputProps) {
	const { defaultValue, onChange } = rest;

	return (
		<>
			<ContainerStyled>
				<Controller
					control={control}
					name={name}
					defaultValue={defaultValue}
					render={({ field: { onChange: onChangeForm, value: valueForm } }) => (
						<RawCheckboxStyled
							areTerms={areTerms}
							type="checkbox"
							name={name}
							data-testid={dataTestId}
							checked={forceFalse ? false : valueForm}
							onChange={(e) => {
								onChangeForm(e);
								if (onChange) {
									onChange(e);
								}
							}}
							ref={inputRef}
						/>
					)}
				/>
			</ContainerStyled>
		</>
	);
}
