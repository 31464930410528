import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import ReactTooltip from 'react-tooltip';

import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getString, getRemoteConfig } from 'firebase/remote-config';

import {
	ContainerStyled,
	CenterDivStyled,
	FormStyled,
	ButtonDivStyled,
	PStyled,
	InputDivStyled,
	SpanStyled,
	InputsDivStyled,
	RendaPStyled,
	NegativeDivStyled,
	NegativesDivStyled,
	RowDivStyled,
	AClickStyled,
	NegativePStyled,
	BlackPStyled,
	ErrorAiFillInfoCircleStyled,
	CircleButtonDivStyled,
	BlueTitleStyled,
	GapDivStyled,
	FourDivStyled,
} from './styles';

import { EmailToken } from './components/EmailToken';
import SignUpController from './controllers/signUpController';

import { config } from '../../config.json';

import { hashCpfMd5, hashEmailSha256 } from '../../shared/utils/hashes';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/signUp/step-2.png';

import {
	Button,
	Input,
	InputControlledCurrency,
	InputCheckbox,
} from '../../shared/components';

import AuthController from '../../shared/controllers/authController';
import ValidatorController from '../../shared/controllers/validatorController';

import { dataLayerUserData } from '../../shared/utils/dataLayer/dataLayerUserData';
import { setRubIdCookie } from '../../shared/utils/setRubIdCookie';

import { undoMoneyMask } from '../../shared/utils/masks';
import { validCPF } from '../../shared/utils/validators';
import eventPush from '../../shared/utils/eventPush';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';

import { isValidToken } from '../../shared/utils/isValidToken';

interface FormData {
	cpf: string | null;
	name: string | null;
	salary: string | null;
	phone: string | null;
	termsAccepted?: boolean;
	termsPubliAccepted?: boolean;
	termsInternalAccepted?: boolean;
}

export interface PayloadStepTwo {
	whatsapp: boolean;
	salary: string;
	// cpf: celcoin ? celcoinData.cpf : form.cpf,
	cpf: string;
	// cellphone: celcoin ? celcoinData.phone : form.phone,
	cellphone: string | null;
	negativado: boolean;
	// full_name: celcoin ? celcoinData.name : form.name,
	full_name: string | null;
	ad_id: string | null;
	epar: string | null;
}

const schema = Yup.object().shape({
	cpf: Yup.string()
		.required('CPF é obrigatório')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.test('len', 'Digite um CPF válido', function (value) {
			if (!value) return false;
			if (!validCPF(value)) return false;
			return true;
		})
		.min(11, 'Digite um CPF válido'),
	name: Yup.string()
		.required('Nome é obrigatório')
		.trim()
		.matches(
			/^([a-zA-ZÀ-ú]{2,}[ ])+([a-zA-ZÀ-ú]{1,})+(?: [a-zA-Z]+){0,4}$/,
			'Digite nome e sobrenome, números são proibidos!'
		)
		.nullable(),
	salary: Yup.string()
		.required('Digite sua renda')
		.test('len', '', function (value) {
			if (!value) return false;
			const undoMoney = undoMoneyMask(value);

			if (undoMoney === '0.00')
				return this.createError({
					message: 'Digite sua renda',
				});

			if (undoMoney.length < 7)
				return this.createError({
					message: 'A renda deve ser maior que R$ 1.000,00 mensais',
				});

			if (undoMoney.length >= 9)
				return this.createError({
					message: 'A renda não pode ser maior que R$ 99.999,99 mensais',
				});
			return true;
		}),
	phone: Yup.string()
		.transform((value) => value.replace(/[^\d]/g, ''))
		.min(11, 'Digite um número de telefone válido')
		.test('len', 'Digite um número de telefone válido', function (value) {
			if (!value) return false;
			if (value.length !== 11 || value[2] !== '9') return false;
			return true;
		})
		.required('Número de telefone é obrigatório'),
});

const validatorController = new ValidatorController();

const validateCPF = async (cpf: string) => {
	const result = await validatorController.validateCPF(hashCpfMd5(cpf));

	if (result.status !== 200) {
		return { errorCPF: true, message: 'Digite um cpf válido' };
	} else if (result.data.has_user) {
		return {
			errorCPF: true,
			message: `Já existe um cadastro neste CPF com o e-mail ${result.data.email}`,
		};
	}

	return { errorCPF: false, message: '' };
};

export const SignUpStepTwo = () => {
	const [isNegative, setIsNegative] = useState<boolean>(false);
	// const [celcoinData, setCelcoinData] = useState<FormData>({} as FormData);
	// const [celcoin, setIsCelcoin] = useState(false);
	const [, setAuthenticated] = useState(false);

	const [isTestAB, setIsTestAB] = useState(false);

	const controller = new SignUpController();
	const authController = new AuthController();

	const { user, setData, removeData } = useAuth();

	const history = useHistory();

	const { setIsLoading } = useLoading();

	const remoteConfig = getRemoteConfig();

	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	const termsSCR = getString(remoteConfig, 'terms_scr_url');
	const termsPrivacy = getString(remoteConfig, 'privacy_policy_url');
	const termsUse = getString(remoteConfig, 'terms_and_conditions_url');

	const { token } = useParams<{ token: string }>();

	const {
		control,
		handleSubmit,
		formState: { errors },
		// reset,
		// setValue,
	} = useForm({ resolver: yupResolver(schema) });

	useLayoutEffect(() => {
		setIsLoading(true);
		if (token) {
			checkTicket();
			setIsTestAB(true);
		} else {
			checkAuth();
		}
	}, []);

	// useEffect(() => {
	// 	isCelcoin();
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1000);
	// }, []);

	useEffect(() => {
		const checkUser = () => {
			if (user?.cpf !== null && user?.cpf !== undefined)
				history.push('/register/3');
			setRubIdCookie(user.email);
			dataLayerUserData(user);
		};

		checkUser();
	}, [user]);

	const checkAuth = async () => {
		if (user?.email === null || user?.email === undefined)
			return history.push('/register');

		setIsLoading(false);
		if (!isValidToken()) {
			return Swal.fire({
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Token expirado, refaça o login',
			}).then(() => {
				removeData();
				authController.logout(undefined, () => history.push('/'));
			});
		} else {
			return setAuthenticated(true);
		}
	};

	const checkTicket = async () => {
		setIsLoading(true);

		const { error } = await controller.tokenSignUpStepTwoController(token);
		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: 'Você não tem acesso esse contéudo.',
				confirmButtonText: 'Fechar',
			}).then(() => history.push('/'));
		} else {
			setData();
		}
	};

	const submit = async (form: FormData) => {
		if (!form?.cpf) return;
		eventPush('web_cad_comp_proximo', 'INFO', {
			email_hash: hashEmailSha256(user.email),
			cpf_hash: hashCpfMd5(form.cpf),
			is_negative: isNegative,
		});

		const eparStorage = localStorage.getItem('epar');
		const adIdStorage = localStorage.getItem('ad_id');

		if (!form?.salary) return;

		setIsLoading(true);

		const payload: PayloadStepTwo = {
			salary: undoMoneyMask(form?.salary),
			whatsapp: true,
			// cpf: celcoin ? celcoinData.cpf : form.cpf,
			cpf: form.cpf,
			// cellphone: celcoin ? celcoinData.phone : form.phone,
			cellphone: form.phone,
			negativado: isNegative,
			// full_name: celcoin ? celcoinData.name : form.name,
			full_name: form.name,
			ad_id: adIdStorage,
			epar: eparStorage,
		};

		const { result, error } = await controller.submitStepTwo(payload);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text:
					result.data.message ??
					'Erro enviar os dados. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});
		}

		setData();
		history.push('/register/3');
	};

	const validateFields = async (form: FormData) => {
		const { termsAccepted, termsPubliAccepted, termsInternalAccepted } = form;

		if (isNegative === undefined) {
			Swal.fire({
				text: 'Você deve dizer se está negativado',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		if (!termsAccepted) {
			Swal.fire({
				text: 'Você deve aceitar os termos de uso',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		if (!termsPubliAccepted) {
			Swal.fire({
				text: 'Você deve preencher os termos de contato',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		if (!termsInternalAccepted && config.environment !== 'PROD') {
			Swal.fire({
				text: 'Você deve preencher os termos de dados pessoais em HML',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		if (!form.cpf) {
			return false;
		}

		const { errorCPF, message } = await validateCPF(form.cpf);

		if (errorCPF) {
			setIsLoading(false);

			return Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}

		if (isNegative === true) {
			setIsLoading(false);
			return Swal.fire({
				text: 'Você confirma que está negativado? (com o nome sujo)',
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: 'Voltar',
				confirmButtonText: 'Confirmo',
			}).then((result) => {
				if (result.isConfirmed) {
					submit(form);
				}
			});
		}

		submit(form);
	};

	// const isCelcoin = () => {
	// 	const name = localStorage.getItem('full_name') ?? '';
	// 	const cpf = localStorage.getItem('cpf') ?? '';
	// 	const phone = localStorage.getItem('phone_number') ?? '';

	// 	setValue('name', name, { shouldValidate: false });
	// 	setValue('phone', phone, { shouldValidate: false });
	// 	setValue('cpf', cpf, { shouldValidate: false });

	// 	const celcoin = {
	// 		cpf: cpf,
	// 		phone: phone,
	// 		name: name,
	// 		salary: '',
	// 	};

	// 	if (celcoin.cpf.length >= 3) setIsCelcoin(true);

	// 	reset(celcoin);
	// 	setCelcoinData(celcoin);
	// };

	const RenderNegativeButton = () => {
		return (
			<NegativesDivStyled>
				<BlackPStyled>
					Está negativado?
					<a data-tip="A VirtusPay realiza o financiamento de sua compra, e por isso essa informação é necessária para concluirmos sua análise de crédito.">
						<ErrorAiFillInfoCircleStyled size={12} />
					</a>
					<ReactTooltip place="left" type="error" effect="float" />
				</BlackPStyled>

				<CircleButtonDivStyled>
					<InputCheckbox
						areTerms={false}
						control={control}
						name="isNegative"
						data-testid="isNegative"
						forceFalse={!isNegative}
						onChange={() => {
							if (isNegative) {
								return setIsNegative(false);
							}
							setIsNegative(true);
						}}
					/>

					<NegativePStyled>Sim</NegativePStyled>
				</CircleButtonDivStyled>

				<CircleButtonDivStyled>
					<InputCheckbox
						areTerms={false}
						control={control}
						name="isNegative"
						data-testid="isNotNegative"
						forceFalse={isNegative}
						onChange={() => setIsNegative(false)}
					/>
					<NegativePStyled>Não</NegativePStyled>
				</CircleButtonDivStyled>
			</NegativesDivStyled>
		);
	};

	return (
		<>
			{isTestAB ? (
				<EmailToken
					title="Conta criada com sucesso!"
					isSignUpStepOne={false}
					button={
						// eslint-disable-next-line react/jsx-no-undef
						<FourDivStyled>
							<Button
								className="has-gradient-blue step_dadoscomplementares"
								textColor="#fff"
								width="300px"
								text="Próximo"
								onClick={() => {
									setIsTestAB(false);
									checkAuth();
								}}
							/>
						</FourDivStyled>
					}
				/>
			) : (
				<ContainerStyled>
					<CenterDivStyled>
						<img src={Logo} alt="Logo VirtusPay" />
					</CenterDivStyled>

					<FormStyled onSubmit={handleSubmit(validateFields)}>
						<div>
							<BlueTitleStyled>Preencha os seus dados</BlueTitleStyled>
						</div>
						<CenterDivStyled>
							<img src={Tracking} width="300px" alt="Tracking" />
						</CenterDivStyled>
						<InputsDivStyled>
							<InputDivStyled>
								<Input
									control={control}
									name="cpf"
									dataTestID="cpf"
									id="cpf"
									// disabled={!!celcoinData.cpf}
									mask="999.999.999-99"
									type="tel"
									placeholder="CPF"
									error={errors.cpf && errors.cpf.message}
								/>
							</InputDivStyled>

							<InputDivStyled>
								<Input
									control={control}
									name="name"
									dataTestID="name"
									mask=""
									id="name"
									// disabled={!!celcoinData.name}
									type="text"
									placeholder="Nome completo"
									error={errors.name && errors.name.message}
								/>
							</InputDivStyled>
						</InputsDivStyled>
						<InputsDivStyled>
							<InputDivStyled>
								<Input
									control={control}
									name="phone"
									dataTestID="phone"
									id="phone"
									mask="(99) 9 9999-9999"
									// disabled={!!celcoinData.phone}
									type="tel"
									placeholder="Celular com DDD"
									error={errors.phone && errors.phone.message}
								/>
							</InputDivStyled>

							<InputDivStyled>
								<InputControlledCurrency
									control={control}
									id="salary"
									dataTestId="salary"
									name="salary"
									type="tel"
									placeholder="Renda mensal (R$)"
									precision="2"
									decimalSeparator=","
									thousandSeparator="."
									prefix="R$"
									error={errors.salary && errors.salary.message}
								/>

								<RendaPStyled>Renda Mensal (R$)</RendaPStyled>
							</InputDivStyled>
						</InputsDivStyled>
						{user.profile === 'tomador' ? (
							<NegativeDivStyled>
								<RenderNegativeButton />
							</NegativeDivStyled>
						) : (
							''
						)}
						<GapDivStyled />
						<ReactTooltip />
						<RowDivStyled>
							<InputCheckbox
								areTerms={true}
								control={control}
								name="termsPubliAccepted"
								dataTestId="termsPubliAccepted"
							/>
							<PStyled style={{ textAlign: 'left', marginLeft: '0.4rem' }}>
								Concordo em receber notificações sobre meu pedido de
								parcelamento por E-mail, SMS e WhatsApp.
							</PStyled>
						</RowDivStyled>
						<RowDivStyled style={{ marginTop: '0rem' }}>
							<InputCheckbox
								areTerms={true}
								control={control}
								name="termsAccepted"
								dataTestId="termsAccepted"
							/>
							<PStyled
								style={{
									textAlign: 'left',
									marginLeft: '0.4rem',
									marginTop: '1rem',
								}}
							>
								Li, compreendi e concordo com os
								<AClickStyled rel="noreferrer" target="_blank" href={termsUse}>
									Termos de uso
								</AClickStyled>
								e a
								<AClickStyled
									rel="noreferrer"
									target="_blank"
									href={termsPrivacy}
								>
									Política de Privacidade
								</AClickStyled>
								e autorizo a consulta de informações ao meu respeito e o
								compartilhamento com a VirtusPay de informações do
								<AClickStyled rel="noreferrer" target="_blank" href={termsSCR}>
									SCR - Banco Central do Brasil
								</AClickStyled>
								.
							</PStyled>
						</RowDivStyled>

						{config.environment !== 'PROD' ? (
							<RowDivStyled>
								<InputCheckbox
									areTerms={true}
									control={control}
									name="termsInternalAccepted"
									dataTestId="termsInternalAccepted"
								/>
								<PStyled style={{ textAlign: 'left', marginLeft: '0.4rem' }}>
									Li e estou de acordo com o
									<AClickStyled
										rel="noreferrer"
										target="_blank"
										href={termsUse}
									>
										Termo
									</AClickStyled>
									de consentimento com funcionário da VirtusPay.
								</PStyled>
							</RowDivStyled>
						) : (
							''
						)}

						<ButtonDivStyled>
							<Button
								className="has-gradient-blue step_dadoscomplementares"
								textColor="#fff"
								type="submit"
								dataTestid="submit"
								width="300px"
								text="Próximo"
							/>
						</ButtonDivStyled>
						<PStyled>
							Já possui conta?
							<SpanStyled
								onClick={() => {
									eventPush('web_cad_comp_fazer_login', 'INFO', {
										email_hash: hashEmailSha256(user.email),
										is_negative: isNegative,
									});
									removeData();
									authController.logout(undefined, () => history.push('/'));
								}}
							>
								{' '}
								Fazer login
							</SpanStyled>
						</PStyled>
					</FormStyled>
				</ContainerStyled>
			)}
		</>
	);
};
