import styled from 'styled-components';

import { colors } from '../../../../shared/styles/colors';

export const Button = styled.button`
	height: 60px;
	width: 60px;
	background: ${colors.white};
	border-radius: 100%;
	margin: 5px;
	font-size: 1.3rem;
	border: 3px solid ${colors.primaryBlue};
	color: ${colors.primaryBlue};
	cursor: pointer;
`;
