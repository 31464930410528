import styled from 'styled-components';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { colors } from '../../shared/styles/colors';

export const FirstDivStyled = styled.div`
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin: 1.5rem 1.2rem;
`;

export const AiOutlineArrowLeftStyled = styled(AiOutlineArrowLeft)`
	display: flex;
	justify-content: start;
	align-items: start;
	cursor: pointer;
	color: grey;
`;

export const SectionStyled = styled.section`
	display: flex;
	flex-direction: column;
`;

export const TitleStyled = styled.h1`
	font-family: 'Montserrat';
	font-weight: 600;
	font-size: 24px;
	margin-top: 1.2rem;
	text-align: center;
	color: ${colors.textBlue};
`;

export const SectionInputsStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1.5rem;
`;

export const CenterDivStyled = styled.div`
	flex-direction: column;
	display: flex;
	width: 390px;

	@media (max-width: 450px) {
		width: 300px;
	}
`;

export const DivCenterCVStyled = styled.div`
	flex-direction: column;
	display: flex;
	width: 390px;
	margin: 1rem 0;

	@media (max-width: 450px) {
		width: 300px;
	}
`;

export const DivAlignCenterStyled = styled.div`
	flex-direction: column;
	display: flex;
	margin: 1rem 0;
	align-items: center;
	text-align: center;
`;

export const TextAreaStyled = styled.textarea`
	font-family: 'Montserrat';
	font-size: 17px;
	text-align: start;
	margin-top: 0.5rem;
	border: 1px solid rgba(10, 10, 10, 0.1);
	display: block;
	border-radius: 3px;
	max-height: 130px;
	min-height: 130px;
	max-width: 100%;
	min-width: 100%;
	padding: calc(0.75em - 1px);
`;

export const ErrorStyled = styled.small`
	font-family: 'Montserrat';
	font-size: 15px;
	text-align: start;
	color: hsl(348, 100%, 61%);
`;

export const SpanStyled = styled.span`
	font-family: 'Montserrat';
	font-size: 15px;
	display: flex;
	text-align: center;
	margin-top: 0.6rem;
`;

export const DivAlignCenterCVStyled = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	text-align: center;
`;
