import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import Swal from 'sweetalert2';

import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { RecoverPasswordController } from './controller';

import { RecoverPasswordI } from './types';

import {
	TitleStyled,
	InputsStyled,
	SectionStyled,
	CenterDivStyled,
	DivAlignCenterStyled,
	ImgStyled,
	SpanStyled,
	LinkStyled,
	ErrorStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_400.png';

import { useLoading } from '../../hooks/loading';

import ValidatorController from '../../shared/controllers/validatorController';

import {
	Input,
	Button,
	InputUncontrolled,
} from '../../shared/components/index';

import { useAuth } from '../../hooks/auth/auth';

import { hashCpfMd5, hashEmailMd5 } from '../../shared/utils/hashes';
import { validCPF } from '../../shared/utils/validators';
import eventPush from '../../shared/utils/eventPush';

const schema = Yup.object().shape({
	cpf: Yup.string()
		.required('CPF é obrigatório')
		.transform((value) => value.replace(/[^\d]/g, ''))
		.test('len', 'Digite um CPF válido', function (value) {
			if (!value) return false;
			if (!validCPF(value)) return false;
			return true;
		})
		.min(11, 'Digite um CPF válido'),
});

const validatorController = new ValidatorController();

const validateEmail = async (email: string) => {
	const result = await validatorController.validateEmail(email);

	const data = result.data;

	if (!data.valid) {
		return { errorEmail: true, message: 'Digite um email válido' };
	}

	return { errorEmail: false, message: '' };
};

const validateCPF = async (cpf: string) => {
	const result = await validatorController.validateCPF(hashCpfMd5(cpf));

	if (result.status !== 200) {
		return { errorCPF: true, messageCPF: 'Digite um cpf válido' };
	} else if (result.data.has_user) {
		return { errorCPF: true, messageCPF: 'CPF já cadastrado' };
	}

	return { errorCPF: false, messageCPF: '' };
};

export const RecoverPassword = () => {
	const [inputType, setInputType] = useState('cpf');
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [captcha, setCaptcha] = useState('');

	const history = useHistory();

	const { user } = useAuth();

	const { setIsLoading } = useLoading();

	useEffect(() => {
		setIsLoading(false);
	}, []);

	const {
		control,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const submitCPF = async () => {
		setIsLoading(true);

		if (!captcha) {
			setIsLoading(false);
			return Swal.fire({
				text: 'Refaça o reCaptcha',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}

		const cpf = hashCpfMd5(getValues('cpf'));

		eventPush('web_esq_senha_enviar', 'INFO', {
			email_hash: hashEmailMd5(user.email),
		});

		const { errorCPF, messageCPF } = await validateCPF(cpf);

		if (errorCPF) {
			setIsLoading(false);
			return Swal.fire({
				text: messageCPF,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}

		const payload: RecoverPasswordI = {
			cpf_hashed: cpf,
			captcha: captcha,
			rcptch_version: 'v2',
		};

		const { result, error } = await RecoverPasswordController(payload);

		if (!error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'success',
				text: result.message,
				confirmButtonText: 'Fechar',
			});
		}

		setIsLoading(false);
	};

	const submitEmail = async () => {
		setIsLoading(true);

		if (!email) {
			setIsLoading(false);
			return setError('Digite um E-mail');
		}

		if (!captcha) {
			setIsLoading(false);
			return Swal.fire({
				text: 'Refaça o reCaptcha',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
		}

		const { errorEmail, message } = await validateEmail(email);

		if (errorEmail) {
			setIsLoading(false);
			return setError(message);
		}

		const payload: RecoverPasswordI = {
			email: email,
			captcha: captcha,
			rcptch_version: 'v2',
		};

		const { result, error } = await RecoverPasswordController(payload);

		if (!error) {
			setIsLoading(false);
			return Swal.fire({
				icon: 'success',
				text: result.message,
				confirmButtonText: 'Fechar',
			});
		}

		setIsLoading(false);
	};

	const changeInputType = () => {
		if (inputType === 'cpf') {
			return setInputType('email');
		}

		setInputType('cpf');
	};

	return (
		<div>
			<form onSubmit={handleSubmit(submitCPF)}>
				<SectionStyled>
					<ImgStyled
						onClick={() => history.push('/')}
						src={Logo}
						alt="Logo VirtusPay"
					/>
					<TitleStyled>Foi aqui que pediram uma nova senha?</TitleStyled>

					<SpanStyled>
						{inputType === 'cpf' ? (
							<strong>Informe o seu CPF</strong>
						) : (
							<strong>Informe o seu Email</strong>
						)}{' '}
						no campo abaixo e clique em enviar. Você receberá um e-mail com o
						link para criar uma nova senha.
					</SpanStyled>
					<InputsStyled>
						<CenterDivStyled>
							{inputType === 'cpf' ? (
								<Input
									control={control}
									name="cpf"
									id="cpf"
									mask="999.999.999-99"
									type="tel"
									placeholder="Informe o CPF cadastrado"
									error={errors.cpf && errors.cpf.message}
								/>
							) : (
								<>
									<InputUncontrolled
										name="email"
										id="email"
										type="email"
										placeholder="Informe o Email cadastrado"
										onChange={(e) => {
											setError('');
											setEmail(e.target.value);
										}}
									/>
									{error && <ErrorStyled>{error}</ErrorStyled>}
								</>
							)}
						</CenterDivStyled>
					</InputsStyled>

					<LinkStyled
						onClick={() => {
							changeInputType();
						}}
					>
						{inputType === 'cpf'
							? 'Recuperar senha através do e-mail'
							: 'Recuperar senha através do CPF'}
					</LinkStyled>

					<DivAlignCenterStyled>
						<ReCAPTCHA
							sitekey="6Lcdfc0UAAAAAPjn8a78vgV2ndMZEGG2TV6TB1bB"
							onChange={(e: any) => setCaptcha(e)}
						/>
					</DivAlignCenterStyled>
					<DivAlignCenterStyled>
						{inputType === 'cpf' ? (
							<Button
								type="submit"
								text="Enviar"
								width="300px"
								textColor="#fff"
								className="has-gradient-blue"
							/>
						) : (
							<Button
								text="Enviar"
								width="300px"
								textColor="#fff"
								onClick={() => submitEmail()}
								className="has-gradient-blue"
							/>
						)}
					</DivAlignCenterStyled>
				</SectionStyled>
			</form>
		</div>
	);
};
