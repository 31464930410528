import axios from '../../../shared/http/customAxios';

export class OrderCheckoutRepository {
	async send(transaction: string, data: any) {
		// any pois esse método não é chamado em nenhum lugar
		try {
			// então não tem como saber os dados enviados no Data
			const result = await axios().post(
				`/v1/order/${transaction}/checkout`,
				data,
				{ headers: { noAuthorization: true } }
			);

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async get(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/checkout`, {
				headers: { noAuthorization: true },
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async getURL(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/b2b/cancel`, {
				headers: { noAuthorization: true },
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}
