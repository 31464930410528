import { useState, useEffect } from 'react';

import Swal from 'sweetalert2';
import LazyLoad from 'react-lazyload';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { IMGAStyled, IMGStyled, CenterDivStyled, TitleStyled } from './styles';

import StoresController from '../../controllers/storesController';
import { useLoading } from '../../../../../hooks/loading';

interface ICarousel {
	type: string;
	color?: string;
	title?: string;
	margin?: string;
}

interface IStoreState {
	partners: JSX.Element[];
	loading: boolean;
}

export function Carousel({ type, color, title, margin }: ICarousel) {
	const { setIsLoading } = useLoading();

	const [storeList, setStoreList] = useState<IStoreState>({
		loading: true,
		partners: [],
	});

	const responsiveOwl = {
		responsive: {
			0: {
				items: 2,
				center: true,
			},
			461: {
				items: 3,
			},
			1024: {
				items: 4,
			},
			1224: {
				items: 6,
			},
			1424: {
				items: 7,
			},
			1624: {
				items: 8,
			},
		},
	};

	useEffect(() => {
		getPartners(); // eslint-disable-next-line
		setIsLoading(storeList.loading);
	}, []);

	useEffect(() => {
		setIsLoading(storeList.loading);
	}, [storeList.loading]);

	const controller = new StoresController();

	const getPartners = async () => {
		const { result, error } = await controller.getStores();

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.data.message,
			});
		}

		const stores: JSX.Element[] = [];

		// eslint-disable-next-line
		for (const store in result) {
			if (type === 'aceita' && result[store].boleto_pop) {
				stores.push(
					<LazyLoad once>
						<div key={result[store].display_loja}>
							<IMGAStyled
								href={result[store].link_loja}
								target="_blank"
								rel="noreferrer"
							>
								<IMGStyled
									src={result[store].logo_loja}
									alt={`${result[store].nome_loja.toUpperCase()}`}
								/>
							</IMGAStyled>
						</div>
					</LazyLoad>
				);
			} else if (type === 'parceiro') {
				stores.push(
					<LazyLoad once>
						<div key={result[store].display_loja}>
							<IMGAStyled
								href={result[store].link_loja}
								target="_blank"
								rel="noreferrer"
							>
								<IMGStyled
									src={result[store].logo_loja}
									alt={`${result[store].nome_loja.toUpperCase()}`}
								/>
							</IMGAStyled>
						</div>
					</LazyLoad>
				);
			}
		}

		setStoreList({
			partners: stores,
			loading: false,
		});
	};

	const renderStores = () => {
		return storeList.partners;
	};

	return (
		<div style={{ backgroundColor: color }}>
			<div className={`${margin}`}>
				<TitleStyled>{title}</TitleStyled>
				<div style={{ position: 'relative' }}>
					<CenterDivStyled>
						<OwlCarousel
							className="owl-carousel owl-theme"
							items={6}
							dots={false}
							autoplay={true}
							margin={45}
							rewind={true}
							responsive={responsiveOwl.responsive}
						>
							{renderStores()}
						</OwlCarousel>
					</CenterDivStyled>
				</div>
			</div>
		</div>
	);
}
