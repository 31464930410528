import { forwardRef, InputHTMLAttributes, ForwardedRef } from 'react';

import InputMask from 'react-input-mask';

import '../input/style.css';
import { ContainerStyled, GroupStyled } from '../input/style';

export interface InputProps extends InputHTMLAttributes<any> {
	mask?: string;
	ref?: React.RefObject<HTMLInputElement>;
	dataTestId?: string;
	onKeyPress?: (value: any) => void;
}

function InputUncontrolled(
	{ mask = '', onKeyPress, ...rest }: InputProps,
	ref: ForwardedRef<HTMLInputElement>
) {
	return (
		<GroupStyled>
			<ContainerStyled>
				<InputMask
					style={{ width: rest.width, fontSize: '0.8rem' }}
					mask={mask}
					onKeyUp={onKeyPress}
					placeholder={rest.placeholder}
					disabled={rest.disabled}
					value={rest.value}
					onChange={rest.onChange}
					inputRef={ref}
				/>
			</ContainerStyled>
		</GroupStyled>
	);
}

export default forwardRef(InputUncontrolled);
