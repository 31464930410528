import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	IMGStyled,
	TitleStyled,
	PStyled,
	CenterMarginTopDivStyled,
	CenterDivStyled,
	PClickStyled,
	GapThanksStyled,
	BlackTitleStyled,
	BlackPStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import ThanksImage from '../../assets/images/thanks_2.0.png';

import { Button, DownloadApp } from '../../shared/components/index';

import OrderThanksController from './controllers/orderThanksController';

import { checkOrderNextStep } from '../../shared/utils/checkOrderNextStep';
import OrderNavbar from './components/OrderNavbar';
import { useLoading } from '../../hooks/loading';

const OrderThanks = () => {
	const [companyName, setCompanyName] = useState(null);
	const [channelType, setChannelType] = useState('');
	const [returnUrl, setReturnUrl] = useState(null);
	const [hasGracePeriodGt0, setHasGracePeriodGt0] = useState(false);

	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const controller = new OrderThanksController();

		const checkOrder = async () => {
			const { result, redirect } = await controller.checkKyc(id);

			if (redirect) return history.push(redirect);

			const { next_step } = result;
			const actualPage = 'TakerOrderThanks';

			const { redirectPage } = checkOrderNextStep(next_step, actualPage, id);
			if (redirectPage) history.push(redirectPage);

			setIsLoading(false);
			setCompanyName(result.company_name);
			setChannelType(result.channel_type);
			setReturnUrl(result.return_url);
			setHasGracePeriodGt0(result.has_grace_period_gt_0);
		};

		checkOrder(); // eslint-disable-next-line
	}, [id]);

	const handleFollowOrder = async () => {
		const controller = new OrderThanksController();
		await controller.checkWhatsapp(id);

		window.location.href = '/register';
	};

	const SectionViajaNet = () => {
		return (
			<CenterDivStyled>
				<IMGStyled src={ThanksImage} alt="Obrigado" />
				<BlackTitleStyled>
					Pronto! <br />
					Agora é só aguardar :)
				</BlackTitleStyled>
				<PStyled>
					As informações do seu pedido já estão com nosso time de análise de
					crédito
				</PStyled>
				<PStyled>
					Importante: nossa mesa de crédito funciona de segunda a sexta, das
					08:00 até 20:00.
				</PStyled>
				<GapThanksStyled />
				<CenterDivStyled>
					<BlackPStyled>
						Quer receber informações sobre o andamento do seu parcelamento?
					</BlackPStyled>

					<Button
						width="300px"
						padding="15px"
						onClick={() => handleFollowOrder()}
						className="has-gradient-blue"
						text="Acompanhar meu parcelamento"
					/>
				</CenterDivStyled>
				<PClickStyled
					onClick={() =>
						returnUrl
							? (window.location.href = returnUrl)
							: history.push('/taker')
					}
				>
					{`Voltar ao site ${returnUrl ? 'da loja' : ''}`}
				</PClickStyled>
			</CenterDivStyled>
		);
	};

	const SectionAll = () => {
		return (
			<CenterDivStyled>
				<IMGStyled src={ThanksImage} alt="Aprovado" />
				<TitleStyled>
					{hasGracePeriodGt0
						? 'Oferta De Crédito Pré-Aprovada'
						: 'Parcelamento Pré-Aprovado'}{' '}
					:D
				</TitleStyled>
				<PStyled>
					Seu pedido já está com o nosso time de análise de crédito.
				</PStyled>
				<PStyled>Fique atento ao seu e-mail e WhatsApp!</PStyled>
				<PStyled>
					Você irá descobrir se seu crédito foi aprovado por lá.
				</PStyled>
				<PStyled>
					Importante: nossa mesa de crédito funciona de segunda à sexta, das 08:00 até 20:00.
				</PStyled>
				<CenterMarginTopDivStyled>
					<Button
						width="300px"
						onClick={() =>
							returnUrl
								? (window.location.href = returnUrl)
								: history.push(`${returnUrl ?? '/taker'}`)
						}
						className="has-gradient-blue"
						text={`Voltar ao site ${returnUrl ? 'da loja' : ''}`}
					/>
					<PClickStyled onClick={() => history.push('/taker/order')}>
						{hasGracePeriodGt0 ? 'Minhas compras' : 'Meus Parcelamentos'}
					</PClickStyled>
				</CenterMarginTopDivStyled>
			</CenterDivStyled>
		);
	};

	return (
		<>
			<OrderNavbar src={Logo} />
			<main>
				{companyName === 'ViajaNet' ? <SectionViajaNet /> : <SectionAll />}
				{channelType === 'b2b' && <DownloadApp hasGracePeriodGt0 />}
			</main>
		</>
	);
};

export { OrderThanks };
