import { InputHTMLAttributes } from 'react';

import { Control, Controller } from 'react-hook-form';

import InputMask from 'react-input-mask';

import {
	ContainerStyled,
	GroupStyled,
	ErrorDivStyled,
	ErrorStyled,
	PasteCodeStyled,
} from './style';

import './style.css';

import PasteIMG from '../../../assets/images/past-cod.png';

export interface InputProps extends InputHTMLAttributes<any> {
	// Props for Element
	mask?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
	useExternalValue?: boolean;
	dataTestID?: string;
	type?: string;
	whichIcon?: boolean;

	// Functions
	pasteLd?: (event: any) => void;

	// React-hook-form
	name: string;
	control?: Control;
	error?: string;
}

export function Input({
	mask = '',
	name,
	control,
	error,
	dataTestID,
	inputRef,
	useExternalValue,
	type,
	pasteLd,
	...rest
}: InputProps) {
	let showIcon = null;

	if (type === 'ld') {
		showIcon = <PasteCodeStyled src={PasteIMG} onClick={pasteLd} />;
	}

	const setInputRef = (element: any) => {
		if (inputRef && !inputRef.current) {
			(inputRef as any).current = element;
		}
	};

	return (
		<>
			<GroupStyled>
				<ContainerStyled>
					<Controller
						control={control}
						name={name}
						defaultValue={rest.defaultValue}
						render={({
							field: { onChange: onChangeForm, value: valueForm },
						}) => (
							<InputMask
								{...rest}
								style={{ width: rest.width, fontSize: '0.8rem' }}
								mask={mask}
								value={useExternalValue ? rest.value : valueForm}
								onChange={(e) => {
									onChangeForm(e);
									if (rest.onChange) {
										rest.onChange(e);
									}
								}}
								inputRef={(el) => setInputRef(el)}
							/>
						)}
						data-testid={dataTestID}
					/>
					{type === 'ld' ? showIcon : null}
				</ContainerStyled>
			</GroupStyled>
			<ErrorDivStyled>
				{error && <ErrorStyled>{error}</ErrorStyled>}
			</ErrorDivStyled>
		</>
	);
}
