import {
	createContext,
	ReactNode,
	useState,
	useContext,
	useEffect,
} from 'react';

import AuthController from '../../shared/controllers/authController';

interface AuthProviderProps {
	children: ReactNode;
}

interface IAddress {
	cep: string;
	city: string;
	complement: string;
	neighborhood: string;
	number: string;
	state: string;
	street: string;
}

interface IExtraData {
	nickname: string;
	offers_categories: string;
	source: {
		platform: string;
		version: string;
	};
}

export interface IUserAuth {
	address: IAddress;
	birthdate: null | string;
	cellphone: string;
	cpf: string;
	email: string;
	extra_data: IExtraData;
	full_name: string;
	income: number;
	incomplete_step: null | string;
	is_negative: null | boolean;
	profile: string;
	tests: string;
}

interface IAuthContextData {
	user: IUserAuth;
	isLoadingUser: boolean;
	getUser: () => any;
	setData: (response?: any) => void;
	setTypeUser: (type?: string) => void;
	removeData: () => void;
}

const AuthContext = createContext({} as IAuthContextData);

function AuthProvider({ children }: AuthProviderProps) {
	const [user, setUser] = useState<IUserAuth>({} as IUserAuth);
	const [isLoadingUser, setIsLoadingUser] = useState(true);

	useEffect(() => {
		const userFromLocalStorage = localStorage.getItem('user');
		if (userFromLocalStorage) {
			const userJson = JSON.parse(userFromLocalStorage) as IUserAuth;
			setUser(userJson);
		}

		setIsLoadingUser(false);
	}, []);

	const authController = new AuthController();

	function putDataOnUser(response: any) {
		const userFromApi: IUserAuth = response.data;

		if (response.status === 200) {
			localStorage.setItem('user', JSON.stringify(userFromApi));
			setUser(userFromApi);
		} else {
			return;
		}
	}

	const setData = async (response?: any) => {
		if (response) {
			// Só vai existir esse Response quando estiver sendo chamado pela tela de login
			putDataOnUser(response);
		} else {
			const result = await authController.getUser();
			putDataOnUser(result);
		}
	};

	const setTypeUser = (type?: string) => {
		if (type) {
			const typeUser = {
				profile: type,
			};
			localStorage.setItem('user', JSON.stringify(typeUser));
		} else {
			return;
		}
	};

	const removeData = () => {
		setUser({} as IUserAuth);
		localStorage.removeItem('user');
		localStorage.removeItem('refresh');
		localStorage.removeItem('token');
	};

	const getUser = () => {
		const user = JSON.parse(localStorage.getItem('user') ?? '{}');

		return user;
	};

	return (
		<AuthContext.Provider
			value={{ user, isLoadingUser, setData, removeData, getUser, setTypeUser }}
		>
			{children}
		</AuthContext.Provider>
	);
}

function useAuth() {
	const context = useContext(AuthContext);

	return context;
}

export { AuthProvider, useAuth };
