import {
	useState,
	useEffect,
	useLayoutEffect,
	ChangeEvent,
	useRef,
} from 'react';

import { useHistory } from 'react-router';

import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	getBoolean,
	getRemoteConfig,
	fetchAndActivate,
	getString,
} from 'firebase/remote-config';

import {
	ContainerStyled,
	ContainerReturnStyled,
	ContainerTemplateStyled,
	FirstDivStyled,
	SecondDivStyled,
	FigureStyled,
	LinkStyled,
	TitleFirstDivStyled,
	TextFirstDivStyled,
	BlueBoxStyled,
	TextDivStyled,
	PSecondDivStyled,
	FourthDivStyled,
	ItemsDivStyled,
	PItemsStyled,
	CenterDivStyled,
	NegativeDivStyled,
	NegativeInfoDivStyled,
	NegativeInfoH2Styled,
	NegativeButtonDivStyled,
	// NegativeAncStyled,
	IconNegativeStyled,
	WhiteBoxStyled,
	ShoppingBasketStyled,
	SubPSecondDivStyled,
	CaminhoStyled,
	CupomStyled,
	StoreStyled,
	DownloadStyled,
	WhiteFirstBoxStyled,
	FirstDivABStyled,
	TextFirstDivABStyled,
	BlueBoxABStyled,
	InputLDDivStyled,
	TitleBlueABStyled,
	GroupSecondDivStyled,
	CenterIconDivStyled,
	TitleFirstDivABStyled,
	SecondABDivStyled,
	CupomABStyled,
	DownloadABStyled,
	FigureABStyled,
} from './styles';

import Bot from '../../assets/images/home/vitor.png';
import onlineShopping from '../../assets/images/home/online-shopping.png';
import ShoppingBasket from '../../assets/images/home/shopping-basket.png';
import AppStore from '../../assets/images/home/app-store-badge.png';
import GooglePlay from '../../assets/images/home/disponivel-google-play-badge.png';
import Cupom from '../../assets/images/home/cupom2.png';
import Shopping from '../../assets/images/home/down-arrow.png';
import Parcelas from '../../assets/images/home/parcelas.png';
import Caminho from '../../assets/images/home/Caminho 12.png';
import CaminhoBlue from '../../assets/images/home/Caminho 11.png';
import tiles from '../../assets/images/home/tiles.png';
import trackPad from '../../assets/images/home/trackpad.png';

import {
	DisplayVersion,
	VirtusNavbar,
	Button,
	Input,
} from '../../shared/components/index';

import { dataLayerUserData } from '../../shared/utils/dataLayer/dataLayerUserData';
import { setRubIdCookie } from '../../shared/utils/setRubIdCookie';

import { SearchStore } from './components/searchStore';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';

export const LDSchema = Yup.object().shape({
	billet: Yup.string()
		.test('is-valid-billet', 'Não é', async (value) => {
			if (!value) return false;

			const textLine = formatBilletLine(value as string);

			if (!textLine?.length) {
				return false;
			}

			return true;
		})
		.required(),
});

import dotenv from 'dotenv';
import { formatBilletLine } from '../order/utils/formatBillet';
import { setCookie } from '../../shared/utils/cookies';
import eventPush from '../../shared/utils/eventPush';

dotenv.config();

export function Home() {
	const { control, getValues, setValue, reset } = useForm({
		mode: 'onChange',
		resolver: yupResolver(LDSchema),
	});

	const [isNegative, setIsNegative] = useState<boolean | null>(false);
	const [name, setName] = useState('');
	const [nickname, setNickname] = useState<string>();
	const [isSendOrderActive, setIsSendOrderActive] = useState<boolean>(false);
	const [textSendOrderInactive, setTextSendOrderInactive] =
		useState<string>('');

	const { removeData, user } = useAuth();
	const { setIsLoading } = useLoading();

	const remoteConfig = getRemoteConfig();
	fetchAndActivate(remoteConfig);
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	const BilletInputRef = useRef<HTMLInputElement>(null);
	const stateRef = useRef<string>('');
	stateRef.current = getValues('billet');
	const stateBefore = useRef<string>('');

	useLayoutEffect(() => {
		loadTest();
		setIsLoading(true);

		fetchAndActivate(remoteConfig)
			.then(() => {
				const isSendOrderActiveValue = getBoolean(
					remoteConfig,
					'is_send_order_active'
				);
				setIsSendOrderActive(isSendOrderActiveValue);
				const textSendOrderInactiveValue = getString(
					remoteConfig,
					'text_order_send_inactive'
				);
				setTextSendOrderInactive(textSendOrderInactiveValue);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		loadUser(); // eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (!isSendOrderActive && textSendOrderInactive) modalSendOrderInactive();
	}, [textSendOrderInactive]);

	// useEffect(() => {
	// 	modalAlert();
	// }, []);

	const history = useHistory();

	const loadUser = () => {
		if (!user?.email) {
			removeData();
			window.location.href = '/';
		} else if (user?.incomplete_step === 'complementar') {
			window.location.href = '/register/2';
		} else if (user?.incomplete_step === 'endereco') {
			window.location.href = '/register/3';
		}

		setRubIdCookie(user.email);
		dataLayerUserData(user);

		setIsLoading(false);
		setName(user?.full_name?.split(' ')[0]);
		setIsNegative(user?.is_negative);
		if (user?.extra_data?.nickname !== '')
			setNickname(user?.extra_data?.nickname);
	};

	const setTest = () => {
		const isTestLdHome = getBoolean(remoteConfig, 'teste_ld_home');
		const testFromFirebase = {
			teste_ld_home: isTestLdHome,
		};
		localStorage.setItem('testAB', JSON.stringify(testFromFirebase));
	};

	const loadTest = () => {
		const testFromLocalStorage = localStorage.getItem('testAB');

		if (!testFromLocalStorage) {
			return setTest();
		} else {
			const testJson = JSON.parse(testFromLocalStorage);
			if (
				testJson.teste_ld_home === undefined ||
				testJson.teste_ld_home === null
			) {
				return setTest();
			}
		}
		return;
	};

	// const modalAlert = () => {
	// 	Swal.fire({
	// 		icon: 'warning',
	// 		iconColor: '#00E8EC',
	// 		title: '<h5 style="color: #00E8EC">Importante</h5>',
	// 		html: `
	//     <p>
	//       Não haverá compensação bancária entre os dias <b>26/02</b> e <b>01/03</b>. Os pagamentos dos boletos da loja serão efetuados no dia útil seguinte, <b>02/03</b>.
	//     </p><br>
	//     <p>
	//       Não se preocupe! Os respectivos comprovantes estarão disponíveis a partir de <b>03/03</b>.
	//     </p><br>
	//     <p>
	//       VirtusPay
	//     </p>
	//     `,
	// 		customClass: {
	// 			cancelButton: 'width-80',
	// 			confirmButton: 'width-80',
	// 		},
	// 		confirmButtonText: 'Fechar',
	// 		confirmButtonColor: '#00E8EC',
	// 		showCancelButton: true,
	// 		cancelButtonText: 'Tenho Dúvidas',
	// 	}).then((answer) => {
	// 		const dismiss: any = answer.dismiss;

	// 		if (dismiss === 'cancel') {
	// 			window.open('https://ajuda.usevirtus.com.br/support/solutions', '_blank');
	// 		}
	// 	});
	// };

	const modalSendOrderInactive = () => {
		Swal.fire({
			icon: 'warning',
			iconColor: '#00E8EC',
			title: '<h5 style="color: #00E8EC">Importante</h5>',
			html: `<p style="white-space: pre-line;">${textSendOrderInactive}</p>`,
			customClass: {
				cancelButton: 'width-80',
				confirmButton: 'width-80',
			},
			timer: 30000,
			confirmButtonText: 'Fechar',
			confirmButtonColor: '#00E8EC',
		});
	};

	const validateBillet = (billet: string) => {
		if (billet) {
			const formatValue = formatBilletLine(billet);
			if (formatValue?.length === 47) {
				setIsLoading(true);
				eventPush('web_home_teste_digitou_ld_A', 'INFO');

				setCookie('billet', formatValue, 0, 0, 0, 20);
				history.push('/taker/order/add');
			} else {
				reset();
				return;
			}
		}
	};

	const pasteLd = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		reset();
		setValue('billet', '');
		navigator.clipboard.readText().then((clipText) => {
			setValue('billet', clipText, { shouldTouch: true });
			validateBillet(clipText);
		});
	};

	const templateNegativeUser = () => (
		<ContainerStyled>
			<NegativeDivStyled>
				<IconNegativeStyled>
					<div className="icon-content">!</div>
				</IconNegativeStyled>

				<NegativeInfoDivStyled>
					<NegativeInfoH2Styled>
						Poxa, {nickname ?? name}, que pena!
					</NegativeInfoH2Styled>

					<p>
						Para parcelar uma compra online, você irá enviar um pedido de
						parcelamento que passará por uma análise de crédito. <br />
						Infelizmente, cliente com restrições no nome terão seu crédito
						reprovado automaticamente.
					</p>
					<br />
					<p>
						Se ainda precisar parcelar sem seu cartão de crédito, temos
						parcerias que podem te ajudar nesse momento.
					</p>

					<NegativeButtonDivStyled>
						<Button
							className="has-gradient-blue"
							text="Ver Outras Opções de Crédito"
							textColor="#fff"
							onClick={() =>
								window.open(
									'https://finanzero.com.br/simule-seu-emprestimo/?utm_source=virtuspay&utm_medium=affiliate&utm_content=emprestimopessoal',
									'_blank'
								)
							}
						/>
					</NegativeButtonDivStyled>

					{/* <p>
						Assim que resolver sua situação, você pode{' '}
						<NegativeAncStyled
							target="_blank"
							rel="noreferrer"
							href="https://api.whatsapp.com/send?phone=5511989442146&text=Para%20facilitar%20seu%20contato%20conosco,%20envie%20essa%20mensagem%20e%20nos%20adicione%20em%20seus%20contatos.%20Obrigado!%20Equipe%20VirtusPay&source=&data=#."
						>
							mudar seus status no atendimento
						</NegativeAncStyled>{' '}
						para parcelar aqui no App VirtusPay 💙
					</p> */}
				</NegativeInfoDivStyled>
			</NegativeDivStyled>
		</ContainerStyled>
	);

	const templateTestA = () => (
		<ContainerStyled>
			<ContainerTemplateStyled>
				<FirstDivABStyled>
					<figure>
						<FigureABStyled src={Bot} alt="Vitor, assistente virtual" />
					</figure>

					<div>
						<div>
							<TitleFirstDivABStyled>
								Olá, {nickname ?? name}, sou o Vitor da VirtusPay
							</TitleFirstDivABStyled>

							<TextFirstDivABStyled>
								Pronto(a) para parcelar sua compra no boleto?
							</TextFirstDivABStyled>
						</div>
					</div>
				</FirstDivABStyled>

				{isSendOrderActive ? (
					<InputLDDivStyled>
						<BlueBoxABStyled>
							<TitleBlueABStyled>Vamos parcelar?</TitleBlueABStyled>
							<SubPSecondDivStyled style={{ marginTop: '0.5rem' }}>
								Descubra a sua oferta =)
							</SubPSecondDivStyled>
							<div style={{ margin: '1rem 0', width: '90%' }}>
								<Input
									name="billet"
									control={control}
									inputRef={BilletInputRef}
									id="billet-input"
									type="ld"
									className="input-ld"
									mask="99999.99999 99999.999999 99999.999999 9 99999999999999"
									placeholder="Digite o código de barras da sua compra"
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										const value = e.target.value;
										stateBefore.current = formatBilletLine(value);
										if (stateRef.current !== stateBefore.current)
											validateBillet(value);
									}}
									defaultValue=""
									pasteLd={pasteLd}
									multiple
								/>
							</div>
						</BlueBoxABStyled>
					</InputLDDivStyled>
				) : null}

				<GroupSecondDivStyled>
					<SecondABDivStyled>
						<WhiteFirstBoxStyled
							onClick={() => {
								eventPush('web_hp_meus_cupons', 'INFO');
								history.push('/taker/coupon');
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<CenterIconDivStyled>
									<CupomABStyled alt="Ícone de cupom" src={Cupom} />
								</CenterIconDivStyled>
								<TextDivStyled style={{ marginLeft: '1rem' }}>
									<PSecondDivStyled>Meus cupons</PSecondDivStyled>
									<SubPSecondDivStyled>
										Aproveite os seus cupons de desconto
									</SubPSecondDivStyled>
								</TextDivStyled>
							</div>
							<CaminhoStyled
								alt="Seta para direita"
								src={CaminhoBlue}
							></CaminhoStyled>
						</WhiteFirstBoxStyled>
					</SecondABDivStyled>

					<SecondABDivStyled>
						<WhiteFirstBoxStyled
							onClick={() => {
								eventPush('web_hp_meus_parc', 'INFO');
								history.push('/taker/order');
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<CenterIconDivStyled>
									<CupomABStyled alt="Ícone de contratos" src={Parcelas} />
								</CenterIconDivStyled>
								<TextDivStyled style={{ marginLeft: '1rem' }}>
									<PSecondDivStyled>Meus parcelamentos</PSecondDivStyled>
									<SubPSecondDivStyled>
										Veja aqui a lista dos seus parcelamentos
									</SubPSecondDivStyled>
								</TextDivStyled>
							</div>
							<CaminhoStyled
								alt="Seta para direita"
								src={CaminhoBlue}
							></CaminhoStyled>
						</WhiteFirstBoxStyled>
					</SecondABDivStyled>
				</GroupSecondDivStyled>

				<SecondABDivStyled>
					<WhiteBoxStyled>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<CenterIconDivStyled>
								<DownloadABStyled alt="Ícone de download" src={Shopping} />
							</CenterIconDivStyled>
							<TextDivStyled style={{ marginLeft: '1rem' }}>
								<PSecondDivStyled>Baixe o App VirtusPay</PSecondDivStyled>
								<SubPSecondDivStyled>
									Compre e parcele suas compras direto pelo app
								</SubPSecondDivStyled>
							</TextDivStyled>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<StoreStyled
								src={AppStore}
								alt="Imagem da apple store. Link para loja"
								onClick={() => {
									eventPush('web_hp_baixar_app_ios', 'INFO');
									const win: Window | null = window.open(
										'https://apps.apple.com/br/app/virtuspay-boleto-parcelado/id1516303566',
										'_blank'
									);
									win?.focus();
								}}
								style={{ marginRight: '0.3rem' }}
							/>
							<StoreStyled
								src={GooglePlay}
								alt="Imagem da Google play. Link para loja"
								onClick={() => {
									eventPush('web_hp_baixar_app_android', 'INFO');
									const win: Window | null = window.open(
										'https://play.google.com/store/apps/details?id=com.virtuspay.virtus_app_tomador&hl=pt_BR',
										'_blank'
									);
									win?.focus();
								}}
							/>
						</div>
					</WhiteBoxStyled>
				</SecondABDivStyled>
				<FourthDivStyled>
					<ItemsDivStyled>
						<img src={onlineShopping} alt="Compras Online" />

						<PItemsStyled>
							Faça uma compra de <br /> <b>R$ 150</b> a <b>R$ 10.000</b> nas
							lojas aceitas.
						</PItemsStyled>
					</ItemsDivStyled>

					<ItemsDivStyled>
						<img src={tiles} alt="Compras Online" />

						<PItemsStyled>
							Escolha pagar sua compra com <b>boleto à vista</b>
						</PItemsStyled>
					</ItemsDivStyled>

					<ItemsDivStyled>
						<img src={trackPad} alt="Compras Online" />

						<PItemsStyled>
							Envie o boleto para <br /> <b>parcelar com a gente!</b>
						</PItemsStyled>
					</ItemsDivStyled>
				</FourthDivStyled>

				<CenterDivStyled>
					<p>
						Compre em uma das <b> lojas abaixo</b>
					</p>

					<SearchStore />
				</CenterDivStyled>
			</ContainerTemplateStyled>
		</ContainerStyled>
	);

	const templateTestB = () => (
		<ContainerStyled>
			<ContainerTemplateStyled>
				<FirstDivStyled>
					<figure>
						<FigureStyled src={Bot} alt="Vitor, assistente virtual" />
					</figure>

					<div>
						<div>
							<TitleFirstDivStyled>
								Olá, {nickname ?? name}, sou o Vitor da VirtusPay
							</TitleFirstDivStyled>

							<TextFirstDivStyled>
								Pronto(a) para <b>parcelar</b> sua compra no boleto?
							</TextFirstDivStyled>
						</div>

						<LinkStyled
							onClick={() => {
								eventPush('web_hp_meus_parc', 'INFO');
								history.push('/taker/order');
							}}
						>
							Meus Parcelamentos &gt;{' '}
						</LinkStyled>
					</div>
				</FirstDivStyled>

				{isSendOrderActive ? (
					<SecondDivStyled>
						<BlueBoxStyled
							onClick={() => {
								eventPush('web_hp_parc_bol', 'INFO');
								history.push('/taker/order/add');
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<ShoppingBasketStyled
									alt="Ícone de cesta"
									src={ShoppingBasket}
								/>
								<TextDivStyled style={{ marginLeft: '1rem' }}>
									<PSecondDivStyled style={{ color: '#fff' }}>
										Parcele suas compras no boleto
									</PSecondDivStyled>
									<SubPSecondDivStyled style={{ color: '#fff' }}>
										Parcele em até 15x sem cartão de crédito
									</SubPSecondDivStyled>
								</TextDivStyled>
							</div>
							<CaminhoStyled
								alt="Seta para direita"
								src={Caminho}
							></CaminhoStyled>
						</BlueBoxStyled>
					</SecondDivStyled>
				) : null}

				<SecondDivStyled>
					<WhiteFirstBoxStyled
						onClick={() => {
							eventPush('web_hp_meus_cupons', 'INFO');
							history.push('/taker/coupon');
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<CupomStyled alt="Ícone de cupom" src={Cupom} />
							<TextDivStyled style={{ marginLeft: '1rem' }}>
								<PSecondDivStyled>Meus cupons</PSecondDivStyled>
								<SubPSecondDivStyled>
									Aproveite os seus cupons de desconto
								</SubPSecondDivStyled>
							</TextDivStyled>
						</div>
						<CaminhoStyled
							alt="Seta para direita"
							src={CaminhoBlue}
						></CaminhoStyled>
					</WhiteFirstBoxStyled>
				</SecondDivStyled>

				<SecondDivStyled>
					<WhiteBoxStyled>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<DownloadStyled alt="Ícone de download" src={Shopping} />
							<TextDivStyled style={{ marginLeft: '1rem' }}>
								<PSecondDivStyled>Baixe o App VirtusPay</PSecondDivStyled>
								<SubPSecondDivStyled>
									Compre e parcele suas compras direto pelo app
								</SubPSecondDivStyled>
							</TextDivStyled>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<StoreStyled
								src={AppStore}
								alt="Imagem da apple store. Link para loja"
								onClick={() => {
									eventPush('web_hp_baixar_app_ios', 'INFO');
									const win: Window | null = window.open(
										'https://apps.apple.com/br/app/virtuspay-boleto-parcelado/id1516303566',
										'_blank'
									);
									win?.focus();
								}}
								style={{ marginRight: '0.3rem' }}
							/>
							<StoreStyled
								src={GooglePlay}
								alt="Imagem da Google play. Link para loja"
								onClick={() => {
									eventPush('web_hp_baixar_app_android', 'INFO');
									const win: Window | null = window.open(
										'https://play.google.com/store/apps/details?id=com.virtuspay.virtus_app_tomador&hl=pt_BR',
										'_blank'
									);
									win?.focus();
								}}
							/>
						</div>
					</WhiteBoxStyled>
				</SecondDivStyled>
				<FourthDivStyled>
					<ItemsDivStyled>
						<img src={onlineShopping} alt="Compras Online" />

						<PItemsStyled>
							Faça uma compra de <br /> <b>R$ 150</b> a <b>R$ 10.000</b> nas
							lojas aceitas.
						</PItemsStyled>
					</ItemsDivStyled>

					<ItemsDivStyled>
						<img src={tiles} alt="Compras Online" />

						<PItemsStyled>
							Escolha pagar sua compra com <b>boleto à vista</b>
						</PItemsStyled>
					</ItemsDivStyled>

					<ItemsDivStyled>
						<img src={trackPad} alt="Compras Online" />

						<PItemsStyled>
							Envie o boleto para <br /> <b>parcelar com a gente!</b>
						</PItemsStyled>
					</ItemsDivStyled>
				</FourthDivStyled>

				<CenterDivStyled>
					<p>
						Compre em uma das <b> lojas abaixo</b>
					</p>

					<SearchStore />
				</CenterDivStyled>
			</ContainerTemplateStyled>
		</ContainerStyled>
	);

	const templateBase = () => {
		const testFromLocalStorage = localStorage.getItem('testAB');
		if (testFromLocalStorage) {
			const testJson = JSON.parse(testFromLocalStorage);
			if (testJson.teste_ld_home) {
				eventPush('web_home_teste_digitar_ld_A', 'INFO');
				return <>{templateTestA()}</>;
			} else {
				eventPush('web_home_teste_digitar_ld_B', 'INFO');
				return <>{templateTestB()}</>;
			}
		}
	};

	return (
		<>
			<VirtusNavbar title="Minha conta" link="/user" />
			<DisplayVersion />
			<ContainerReturnStyled>
				{isNegative ? templateNegativeUser() : templateBase()}
			</ContainerReturnStyled>
		</>
	);
}
