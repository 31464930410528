import styled from 'styled-components';

export const SearchBarStyled = styled.input`
	padding: 16px 24px;
	border-radius: 8px;
	background: 'white';
	border: none;
	color: rgba(10, 10, 10, 0.8);
`;

export const DivLogoStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	height: 140px;
	background: #fff;
	width: 140px;
	box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.1);
	margin: 0.6rem;
	padding: 1rem;
`;

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	margin: 2rem 0;
	position: relative;
`;

export const DivSearchStyled = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	align-items: center;
	top: 5px;
	right: 15px;
	background-color: #fff;
`;

export const BarStyled = styled.div`
	margin-right: 20px;
	height: 40px;
	width: 2px;
	background: rgba(10, 10, 10, 0.2);
`;

export const RenderDivStyled = styled.div`
	margin: 1rem 0;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
`;
