import redirect from './redirect';

const checkOrderNextStep = (
	nextStep: any,
	actualPage: any,
	transaction: any
) => {
	if (nextStep === actualPage) return { redirectPage: null };

	return redirect(nextStep, transaction);
};

export { checkOrderNextStep };
