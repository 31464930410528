class CreditDto {
	holder_name = '';
	card_number = '';
	code = '';
	exp_month = '';
	exp_year = '';
	brand = '';
}

export default CreditDto;
