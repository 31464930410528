import { useEffect, useLayoutEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import {
	MainStyled,
	IMGStyled,
	TitleStyled,
	TextStyled,
	LinkStyled,
	SpanStyled,
} from './styles';

import { isValidToken } from '../../shared/utils/isValidToken';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_400.png';

import {
	Button,
	DisplayVersion,
	Input,
	InputPassword,
} from '../../shared/components/index';

import AuthController from '../../shared/controllers/authController';
// import { colors } from '../../shared/styles/colors';

import eventPush from '../../shared/utils/eventPush';
import { validEmail } from '../../shared/utils/validators';

import { useAuth } from '../../hooks/auth/auth';
import { useLoading } from '../../hooks/loading';

import { config } from '../../config.json';

import { firebase } from '../../firebase';
import {
	initializeAppCheck,
	ReCaptchaV3Provider,
	getToken,
} from 'firebase/app-check';

import dotenv from 'dotenv';
dotenv.config();

interface FormData {
	userName: string;
	password: string;
}

const schema = Yup.object().shape({
	userName: Yup.string()
		.trim()
		.email('Digite seu email')
		.required('Digite seu email'),
	password: Yup.string()
		.required('Informe sua senha atual')
		.min(8, 'Informe sua senha atual')
		.matches(/([a-zA-Z])/, 'Informe sua senha atual')
		.matches(/([0-9])/, 'Informe sua senha atual'),
});

export const Login = () => {
	const appCheck = initializeAppCheck(firebase, {
		provider: new ReCaptchaV3Provider(
			'6LeB980bAAAAAAL5rRueBMzqR9MMRMRwOWvxMg6F'
		),
	});

	const history = useHistory();

	const { user, setData, setTypeUser } = useAuth();
	const { setIsLoading } = useLoading();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const authController = new AuthController();
	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		if (isValidToken()) {
			if (user.profile === 'tomador') window.location.href = '/taker';
			if (user.profile === 'cedente' && config.environment !== 'PROD')
				window.location.href = '/assignor';
		}

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
		// return () => {
		// 	setIsLoading(true);
		// };
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isValidToken()) {
			if (user?.incomplete_step === 'complementar') {
				history.push('/register/2');
			} else if (user?.incomplete_step === 'endereco') {
				history.push('/register/3');
			} else if (user?.address?.cep) {
				if (user?.profile === 'tomador') {
					window.location.assign('/taker');
				}
			} // eslint-disable-next-line

			if (config.environment !== 'PROD') {
				if (user?.profile === 'cedente') window.location.assign('/assignor');
				else if (user?.profile === 'vendedor')
					window.location.assign('/salesman');
				else if (user?.profile === 'admin') window.location.assign('/admin');
			}
		}
	}, [user]);

	const login = async (form: FormData) => {
		let appCheckTokenResponse;
		try {
			appCheckTokenResponse = await getToken(appCheck, false);
		} catch (err) {
			console.log(err);
			return;
		}

		const validatedFields = validateFields(form);

		if (validatedFields) {
			authController.username = form.userName.trim().toLowerCase();
			authController.password = form.password;
			authController.captcha = appCheckTokenResponse.token;
			// authController.captcha = recaptcha;

			setIsLoading(true);

			const result = await authController.login(false);

			if (result?.response) {
				eventPush('web_login_entrar', 'INFO');
				setData(result.response); // Alterará a User State e chamará o useEffect, que irá redirecionar a página.
			}
		}

		setIsLoading(false);
	};

	const validateFields = (form: FormData) => {
		if (!validEmail(form.userName.trim().toLowerCase())) {
			Swal.fire({
				text: 'E-mail inválido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}
		return true;
	};

	const loginIfEnterPressed = (e: any, form?: FormData) => {
		if (e.keyCode === 13 && form) login(form);
	};

	// const facebookLoginHandler = async (data: any) => {
	// 	setIsLoading(true);

	// 	await authController.facebookLogin(data);

	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 1000);
	// };

	// const facebookLogin = () => {
	// 	return (
	// 		<FacebookProvider appId={facebookId}>
	// 			<FBLogin
	// 				scope="email"
	// 				onCompleted={facebookLoginHandler}
	// 				onError={authController.facebookError}
	// 			>
	// 				{({ handleClick }: any) => (
	// 					<span onClick={handleClick}>
	// 						<ButtonFaceStyled>
	// 							<FaFacebookF
	// 								color="#fff"
	// 								size={20}
	// 								style={{ marginTop: '3px' }}
	// 							/>
	// 						</ButtonFaceStyled>
	// 					</span>
	// 				)}
	// 			</FBLogin>
	// 		</FacebookProvider>
	// 	);
	// };

	const userType = () => {
		eventPush('web_login_cadastro', 'INFO');
		// if (config.environment === 'PROD') {
		setTypeUser('tomador');
		history.push('/register');
		// } else {
		// 	return Swal.fire({
		// 		html: `<h2 class='is-size-3 has-text-blue'>Por que você está se cadastrando?</h2>`,
		// 		iconColor: `#28C0CD`,
		// 		icon: 'question',
		// 		showDenyButton: true,
		// 		confirmButtonText: 'Quero PARCELAR uma compra online',
		// 		denyButtonText: 'Quero OFERECER LIMITE do meu cartão de crédito',
		// 		denyButtonColor: `${colors.primaryBlue}`,
		// 		confirmButtonColor: `${colors.primaryBlue}`,
		// 	}).then((result) => {
		// 		if (result.isConfirmed) {
		// 			eventPush('web_pq_cadastro_quero_parc', 'INFO');
		// 			setTypeUser('tomador');
		// 			history.push('/register');
		// 		} else if (result.isDenied) {
		// 			eventPush('web_pq_cadastro_quero_of_lim', 'INFO');
		// 			setTypeUser('cedente');
		// 			history.push('/register');
		// 		}
		// 	});
		// }
	};

	return (
		<>
			<DisplayVersion />
			<MainStyled onSubmit={handleSubmit(login)}>
				<IMGStyled src={Logo} alt="Logo VirtusPay" />

				<TitleStyled>
					Entre ou crie <br /> uma conta
				</TitleStyled>

				<div style={{ width: '300px' }}>
					<Input
						control={control}
						id="userName"
						name="userName"
						type="userName"
						dataTestID="nameInputLogin"
						placeholder="Digite seu e-mail"
						error={errors.userName && errors.userName.message}
					/>

					<InputPassword
						isControlled={true}
						control={control}
						onKeyPress={loginIfEnterPressed}
						type="password"
						placeholder="Digite sua senha"
						dataTestId="passwordInputLogin"
						name="password"
						error={errors.password && errors.password.message}
					/>
				</div>

				<LinkStyled
					to="/recover_password"
					onClick={() => {
						eventPush('web_login_esq_senha', 'INFO');
					}}
				>
					Esqueci minha senha
				</LinkStyled>

				<Button
					type="submit"
					className="has-gradient-blue"
					border="none"
					width="300px"
					text="Entrar"
					textColor="#fff"
					dataTestid="loginButton"
				/>

				<TextStyled style={{ marginTop: '1.8rem' }}>
					Ainda não possui uma conta?
					<SpanStyled onClick={() => userType()}> Fazer cadastro</SpanStyled>
				</TextStyled>

				{/* <TextStyled>ou</TextStyled>

						<TextStyled>Entre com suas redes sociais</TextStyled>

						<div style={{ marginTop: '1rem' }}>{facebookLogin()}</div> */}
			</MainStyled>
		</>
	);
};
