import { dataLayerPush } from './dataLayerPush';
import { hashEmailMd5, hashEmailSha256 } from '../hashes';

interface IUSer {
	email: string;
	full_name: string;
	cellphone: string;
	birthdate: string | null;
	tests: string;
	address: {
		city: string;
		cep: string;
		state: string;
	};
}

export const dataLayerUserData = (user: IUSer) => {
	dataLayerPush({
		event: 'user_data',
		user: {
			id: hashEmailMd5(user.email),
			email: hashEmailSha256(user.email),
			name: user.full_name,
			phone: user.cellphone,
			city: user.address?.city,
			state: user.address?.state,
			zipcode: user.address?.cep,
			birthdate: user.birthdate,
			tests: user.tests,
		},
	});
};
