import { AClickStyled, PStyled, RowDivStyled } from '../styles';

import { OrderProps } from '../interfaces';

import { Button } from '../../../shared/components/index';

interface SimplifiedProcessSectionProps {
	order: OrderProps;

	handleNextSection: () => void;
}

import dotenv from 'dotenv';
import { getRemoteConfig, getString } from 'firebase/remote-config';
dotenv.config();

const SimplifiedProcessSection = ({
	order,
	handleNextSection,
}: SimplifiedProcessSectionProps) => {
	const validateSecondSection = () => {
		handleNextSection();
	};

	const remoteConfig = getRemoteConfig();

	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	const termsSCR = getString(remoteConfig, 'web_terms_scr_url');
	const termsPrivacy = getString(remoteConfig, 'web_privacy_policy_url');
	const termsUse = getString(remoteConfig, 'web_terms_and_conditions_url');

	return (
		<section className="column px-0 pt-0 is-flex is-flex-direction-column is-12-mobile is-6-desktop is-offset-3-desktop">
			<div className="column px-0 is-flex is-flex-direction-column is-12-mobile is-8-tablet is-offset-2-tablet">
				<h2 className="is-size-4 has-text-black has-text-centered">
					Olá {order.customer.full_name.split(' ')[0]}, <br />
					seja bem-vindo(a)!
				</h2>

				<RowDivStyled>
					<PStyled
						style={{
							marginTop: '1rem',
						}}
					>
						Ao continuar, você concorda com os
						<AClickStyled rel="noreferrer" target="_blank" href={termsUse}>
							Termos de uso
						</AClickStyled>
						e a
						<AClickStyled rel="noreferrer" target="_blank" href={termsPrivacy}>
							Política de Privacidade
						</AClickStyled>
						e autoriza a consulta de informações ao seu respeito e o
						compartilhamento com a VirtusPay de informações do
						<AClickStyled rel="noreferrer" target="_blank" href={termsSCR}>
							SCR - Banco Central do Brasil
						</AClickStyled>
						.
					</PStyled>
				</RowDivStyled>

				<div className="is-flex is-justify-content-center">
					<Button
						onClick={validateSecondSection}
						className="my-3 has-gradient-blue has-text-white"
						width="80%"
						text="Próximo"
					/>
				</div>
			</div>
		</section>
	);
};

export default SimplifiedProcessSection;
