import styled from 'styled-components';

export const SelectsStyled = styled.select`
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 4px;
	padding: 14px 20px;
	font-size: 0.85rem;
	width: 100%;
	background-color: white;
`;
