import styled from 'styled-components';

import { StyleI } from '.';

export const BarDivStyled = styled.div`
	height: 4px;
	width: 100%;
	margin-top: 9px;
	background: rgba(174, 174, 194, 0.3);
	border-radius: 45px;
`;

export const StrengthStyled = styled.div<StyleI>`
	height: 4px;
	width: ${({ password }) => (password.length / 16) * 100}%;
	max-width: 100%;
	background-color: ${({ color }) => color};
	border-radius: 45px;
`;

export const PasswordStrengthStyled = styled.div`
	font-size: 12px;
	margin-top: 3px;
	text-align: right;
`;
