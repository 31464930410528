export default function getCookie(name: string) {
	let cookieValue = null;

	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === name + '=') {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}

	return cookieValue;
}

export function setCookie(
	key: string,
	value: string,
	expireDays: number,
	expireSeconds?: number | null,
	expireHours?: number | null,
	expireMinutes?: number | null
) {
	const expireDate = new Date();

	if (expireDays) expireDate.setDate(expireDate.getDate() + expireDays);

	if (expireHours) expireDate.setHours(expireDate.getHours() + expireHours);

	if (expireMinutes)
		expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);

	if (expireSeconds)
		expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);

	document.cookie =
		key +
		'=' +
		escape(value) +
		';domain=' +
		window.location.hostname +
		';path=/' +
		';expires=' +
		expireDate.toUTCString();
}

export function deleteCookie(name: string) {
	setCookie(name, '', 1, null, null, null);
}
