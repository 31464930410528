import { useEffect, useLayoutEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { IMGStyled, TitleStyled, PStyled, CenterDivStyled } from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import ThanksImage from '../../assets/images/thanks_2.0.png';
import RefusedImage from '../../assets/images/refused_2.0.png';
import AlertImage from '../../assets/images/alert.png';

import OrderEmailValidationController from './controllers/orderEmailValidationController';

import OrderNavbar from './components/OrderNavbar';
import { useLoading } from '../../hooks/loading';

const OrderEmailValidation = () => {
	const [success, setSuccess] = useState(true);
	const [alreadyValidated, setAlreadyValidated] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const { id } = useParams<{ id: string }>();
	const { search } = useLocation();
	const { setIsLoading } = useLoading();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		const query = new URLSearchParams(search);
		const token = query.get('token');
		const controller = new OrderEmailValidationController();

		const emailValidation = async () => {
			const { result, error } = await controller.validation(id, token);

			if (error) {
				const message =
					result.data.message ?? 'Não foi possível validar o seu e-mail.';
				if (message == 'E-mail já está validado.') {
					setAlreadyValidated(true);
				} else {
					setSuccess(false);
					setErrorMessage(message);
				}
			}

			setIsLoading(false);
		};

		emailValidation();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore: Unreachable code error
		csdp('sessionid', id);
	}, [id]);

	const SectionSuccess = () => {
		return (
			<CenterDivStyled>
				<IMGStyled src={ThanksImage} alt="Validado" />
				<TitleStyled>Seu e-mail foi confirmado com sucesso!</TitleStyled>
				<PStyled>
					Estamos fazendo algumas verificações e esse processo pode durar até 2
					minutos.
				</PStyled>
				<PStyled>Retorne ao site e aguarde os próximos passos.</PStyled>
			</CenterDivStyled>
		);
	};

	const SectionAlreadyValidated = () => {
		return (
			<CenterDivStyled>
				<IMGStyled src={AlertImage} alt="Validado" />
				<TitleStyled>Opa, seu e-mail já foi validado!</TitleStyled>
				<PStyled>Retorne ao site e siga os próximos passos.</PStyled>
			</CenterDivStyled>
		);
	};

	const SectionError = () => {
		return (
			<CenterDivStyled>
				<IMGStyled src={RefusedImage} alt="Erro" />
				<TitleStyled>Não foi possível validar o seu e-mail.</TitleStyled>
				<PStyled>{errorMessage}</PStyled>
			</CenterDivStyled>
		);
	};

	return (
		<>
			<OrderNavbar src={Logo} />
			<main>
				{alreadyValidated ? (
					<SectionAlreadyValidated />
				) : success ? (
					<SectionSuccess />
				) : (
					<SectionError />
				)}
			</main>
		</>
	);
};

export { OrderEmailValidation };
