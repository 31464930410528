import { ContainerStyled } from './styles';

interface OrderNavbarProps {
	src: string;
	className?: string;
}

const OrderNavbar = ({ src }: OrderNavbarProps) => {
	return (
		<ContainerStyled>
			<img src={src} alt="Logo VirtusPay" style={{ margin: '1rem auto' }} />
		</ContainerStyled>
	);
};

export default OrderNavbar;
