import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const ContainerStyled = styled.div`
	display: flex;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 35%;
	background: hsl(0, 0%, 96%);

	@media (max-width: 2200px) {
		width: 42%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 65%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}
`;

export const ContainerReturnStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: hsl(0, 0%, 96%);
`;

export const ContainerTemplateStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1.5rem 0;
`;

export const FourthDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.5rem;
	margin-bottom: 2.1rem;

	@media (max-width: 760px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const ItemsDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 40%;
	margin: 0 0.2rem;

	@media (max-width: 760px) {
		width: 95%;
		margin: 0;
	}
`;

export const PItemsStyled = styled.p`
	font-size: 16px;
	font-weight: 401;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	margin: 1.7rem 0;
`;

export const CenterDivStyledLogo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
`;

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	width: auto;
	margin: 1.2rem 0;
`;