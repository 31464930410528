import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	TitleBankStyled,
	CenterDivStyledTrackingStyled,
	InstructionsDivStyled,
	DivBankStyled,
	DivPBankMobileStyled,
	PStyled,
	DivUploadStyled,
	PBankStyled,
	DivPBankStyled,
	DivPBankSpaceStyled,
	ButtonDivStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Ext from '../../assets/images/KYC/ext.png';
import Tracking from '../../assets/images/KYC/tracking-ext.png';

import { UploadButton } from './components/UploadButton/uploadButton';
import OrderNavbar from '../order/components/OrderNavbar';

import KYCController from './controllers/kycController';
import BankExtractController from './controllers/bankExtController';

import { Button } from '../../shared/components/index';
import fileToBase64 from '../../shared/utils/fileToBase64';
import { validFileExtension } from '../../shared/utils/validators';

import { IParams } from './types/types';
import { useLoading } from '../../hooks/loading';

export const KycBankExt = () => {
	const [extController, setExtController] = useState<BankExtractController>();
	const [uploadedFiles, setUploadedFiles] = useState(0);
	const [file01, setFile01] = useState<string | null>(null);
	const [file02, setFile02] = useState<string | null>(null);
	const [file03, setFile03] = useState<string | null>(null);

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		setLotes(); // eslint-disable-next-line
	}, []);

	const { setIsLoading } = useLoading();

	const history = useHistory();

	const params: IParams = useParams();
	const { id } = params;

	const controller = new KYCController();

	const setLotes = async () => {
		setIsLoading(true);

		const { result, error, redirect } = await controller.getSteps(id);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.string, // No controller, esse é o result.data.message
				confirmButtonText: 'Fechar',
			});
		}

		if (redirect) {
			return history.push(redirect);
		}

		const { next_step } = result;

		if (next_step !== 'bank_ext') {
			return history.push(`/taker/order/${id}/formalizacao/${next_step}`);
		}

		if (!result.lote) {
			return Swal.fire({
				icon: 'error',
				title: 'Houve um erro',
				text: 'Entre em contato com nosso time de atendimento.',
			}).then(() => {
				window.location.href = '/';
			});
		}

		setExtController(new BankExtractController(result.lote));

		setUploadedFiles(result.files);
		if (result.files === 3)
			return history.push(`/taker/order/${id}/formalizacao/contacts`);

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const inputHandler = async (event: any, index: number) => {
		const file = event.target.files[0];

		if (!validFileExtension(file, true)) {
			clearFields(index);
			return Swal.fire({
				icon: 'error',
				text: 'Formato de arquivo inválido. Só aceitamos imagens ou pdf.',
			});
		}

		const image = `data:${file.type};base64,` + (await fileToBase64(file));

		setField(index, image);
	};

	const renderButtons = () => {
		const buttons = [];

		for (let index = 0; index < 3 - uploadedFiles; index++) {
			buttons.push(
				<input
					key={'key-input-' + index}
					accept=".pdf,.png,.jpg,.jpeg"
					onChange={(event) => inputHandler(event, index)}
					className="hidden"
					type="file"
					id={'file-0' + (index + 1)}
				/>
			);
			if (index === 0) {
				buttons.push(
					<UploadButton
						key={'key-button-' + index}
						file={file01}
						number={index + 1}
					/>
				);
			} else if (index === 1) {
				buttons.push(
					<UploadButton
						key={'key-button-' + index}
						file={file02}
						number={index + 1}
					/>
				);
			} else {
				buttons.push(
					<UploadButton
						key={'key-button-' + index}
						file={file03}
						number={index + 1}
					/>
				);
			}
		}

		return buttons;
	};

	const submit = async () => {
		if (!extController) return;

		setIsLoading(true);

		const files = [file01, file02, file03];

		const { redirect, success, emptyFiles } = await extController.submit(
			id,
			files
		);

		setIsLoading(false);

		if (success) {
			Swal.fire({
				icon: 'success',
				text: 'Arquivos enviados, deseja enviar mais?',
				showCancelButton: true,
				confirmButtonText: 'Sim, enviar mais arquivos',
				cancelButtonText: 'Não, continuar o processo',
			}).then((answer) => {
				if (answer.isDenied || answer.isDismissed) {
					history.push(redirect);
				}
				setFile01(null);
				setFile02(null);
				setFile03(null);
				setTimeout(() => setLotes(), 1000); // Tempo necessário para a api processar o arquivo uploaded
			});
		} else {
			if (emptyFiles)
				return Swal.fire({
					icon: 'error',
					text: 'Precisamos de ao menos um arquivo',
				});

			history.push(redirect);
		}
	};

	const renderFinishButton = () => {
		if (!file01 && !file02 && !file03 && uploadedFiles === 0) return <div />;

		if (file01 || file02 || file03)
			return (
				<Button
					onClick={submit}
					text="Enviar extratos"
					className="has-gradient-blue"
				/>
			);

		if (uploadedFiles > 0)
			return (
				<Button
					onClick={finishProcess}
					text="Pular Etapa"
					className="has-gradient-blue"
				/>
			);
	};

	const finishProcess = () =>
		history.push(`/taker/order/${id}/formalizacao/contacts`);

	const clearFields = (index: number) => {
		switch (index) {
			case 0:
				setFile01(null);
				break;
			case 1:
				setFile02(null);
				break;
			case 2:
				setFile03(null);
				break;

			default:
				setFile01(null);
				break;
		}
	};

	const setField = (index: number, image: string) => {
		switch (index) {
			case 0:
				setFile01(image);
				break;
			case 1:
				setFile02(image);
				break;
			case 2:
				setFile03(image);
				break;

			default:
				setFile01(image);
				break;
		}
	};

	return (
		<>
			<OrderNavbar src={Logo} />
			<div>
				<InstructionsDivStyled>
					<CenterDivStyledTrackingStyled>
						<img width="300px" src={Tracking} alt="tracking" />
					</CenterDivStyledTrackingStyled>

					<TitleBankStyled>Envie seu extrato bancário</TitleBankStyled>

					<DivBankStyled>
						<DivPBankSpaceStyled>
							<PBankStyled>
								Envie um extrato do banco que você mais utiliza
							</PBankStyled>

							<PBankStyled>
								Deve conter saldo inicial e final dos últimos 3 meses
							</PBankStyled>
						</DivPBankSpaceStyled>

						<DivPBankMobileStyled>
							<PBankStyled>
								Em um formato com boa qualidade e informações legíveis
							</PBankStyled>
						</DivPBankMobileStyled>

						<img src={Ext} alt="Extrato Bancário" />

						<DivPBankStyled>
							<PBankStyled style={{ marginTop: '1rem' }}>
								Em um formato com boa qualidade e informações legíveis
							</PBankStyled>
						</DivPBankStyled>
					</DivBankStyled>

					<PStyled>
						Envie até {3 - uploadedFiles} extratos (PDF ou imagem)
					</PStyled>

					<DivUploadStyled>{renderButtons()}</DivUploadStyled>

					<ButtonDivStyled>{renderFinishButton()}</ButtonDivStyled>
				</InstructionsDivStyled>
			</div>
		</>
	);
};
