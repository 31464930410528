import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
	TitleThanksStyled,
	ContainerThanksStyled,
	DivThanksStyled,
	GapStyled,
	PStyled,
	PClickStyled,
	ButtonDivStyled,
} from './styles';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import ThanksImage from '../../assets/images/thanks_2.0.png';

import OrderThanksController from '../order/controllers/orderThanksController';

import { DownloadApp, Button } from '../../shared/components/index';
import { checkOrderNextStep } from '../../shared/utils/checkOrderNextStep';
import OrderNavbar from '../order/components/OrderNavbar';

import { IParams } from './types/types';
import { useLoading } from '../../hooks/loading';
import eventPush from '../../shared/utils/eventPush';

const KycThanks = () => {
	const [companyName, setCompanyName] = useState(null);
	const [channelType, setChannelType] = useState('');
	const [returnUrl, setReturnUrl] = useState(null);
	const [hasGracePeriodGt0, setHasGracePeriodGt0] = useState(false);

	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const controller = new OrderThanksController();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		checkOrder(); // eslint-disable-next-line
	}, [id]);

	const checkOrder = async () => {
		const { result, redirect } = await controller.checkKyc(id);

		setIsLoading(false);

		if (redirect) return history.push(redirect);

		const { next_step } = result;
		const actualPage = 'TakerOrderFormalizacao';

		const { redirectPage } = checkOrderNextStep(next_step, actualPage, id);
		if (redirectPage) history.push(redirectPage);

		setCompanyName(result.company_name);
		setChannelType(result.channel_type);
		setReturnUrl(result.return_url);
		setHasGracePeriodGt0(result.has_grace_period_gt_0);
	};

	const handleFollowOrder = async () => {
		const controller = new OrderThanksController();
		await controller.checkWhatsapp(id);

		window.location.href = '/register';
	};

	const SectionViajaNet = () => {
		return (
			<ContainerThanksStyled>
				<img className="mb-3" src={ThanksImage} width="150px" alt="Obrigado" />
				<TitleThanksStyled>
					Pronto! <br />
					Agora é só aguardar :)
				</TitleThanksStyled>
				<PStyled>
					As informações do seu pedido já estão com nosso time de análise de
					crédito
				</PStyled>
				<PStyled>
					Importante: nossa mesa de crédito funciona de segunda a sexta, das
					08:00 até 20:00.
				</PStyled>
				<GapStyled />
				<ButtonDivStyled>
					<PStyled>
						Quer receber informações sobre o andamento do seu parcelamento?
					</PStyled>

					<Button
						width="300px"
						padding="20px"
						onClick={() => {
							eventPush('web_props_finalizada_meus_parc', 'INFO', {
								ld: id,
							});
							handleFollowOrder();
						}}
						className="has-gradient-blue"
						text="Acompanhar meu parcelamento"
					/>
				</ButtonDivStyled>
				<PClickStyled
					onClick={() => {
						eventPush('web_props_finalizada_voltar_site', 'INFO', {
							ld: id,
						});
						returnUrl
							? (window.location.href = returnUrl)
							: history.push('/taker');
					}}
				>
					{`Voltar ao site ${returnUrl ? 'da loja' : ''}`}
				</PClickStyled>
			</ContainerThanksStyled>
		);
	};

	const SectionAll = () => {
		return (
			<ContainerThanksStyled>
				<img src={ThanksImage} width="150px" alt="Obrigado" />
				<TitleThanksStyled>
					Pronto! <br />
					Agora é só aguardar :)
				</TitleThanksStyled>
				<DivThanksStyled>
					<PStyled>
						Seu pedido já está com nosso analista de crédito. Fique de olho em
						seu e-mail (ou spam) para saber se foi aprovado!
					</PStyled>
					<PStyled>
						Importante: nossa mesa de crédito funciona de segunda à sexta, das
						08:00 até 20:00.
					</PStyled>
				</DivThanksStyled>
				<ButtonDivStyled>
					<Button
						width="300px"
						onClick={() => {
							eventPush('web_props_finalizada_voltar_site', 'INFO', {
								ld: id,
							});
							returnUrl
								? (window.location.href = returnUrl)
								: history.push('/taker');
						}}
						className="has-gradient-blue"
						text={`Voltar ao site ${returnUrl ? 'da loja' : ''}`}
					/>
				</ButtonDivStyled>
				<PClickStyled
					onClick={() => {
						eventPush('web_props_finalizada_meus_parc', 'INFO', {
							ld: id,
						});
						history.push('/taker/order');
					}}
				>
					{hasGracePeriodGt0 ? 'Minhas compras' : 'Meus Parcelamentos'}
				</PClickStyled>
			</ContainerThanksStyled>
		);
	};

	return (
		<>
			<OrderNavbar src={Logo} />
			<main>
				{companyName === 'ViajaNet' ? <SectionViajaNet /> : <SectionAll />}
				{channelType === 'b2b' && <DownloadApp hasGracePeriodGt0 />}
			</main>
		</>
	);
};

export { KycThanks };
