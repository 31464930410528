import { AiFillCheckCircle } from 'react-icons/ai';

import {
	ButtonStyled,
	OkayButtonStyled,
	PClickStyled,
	PClickSuccessStyled,
	SpanStyled,
} from './styles';

interface UploadButtonProps {
	file: string | null;
	number: number;
}

export const UploadButton = ({ file, number }: UploadButtonProps) => {
	const inputHandler = () => {
		const inputHandlerConst: HTMLElement | null = document.querySelector(
			`#file-0${number}`
		);

		inputHandlerConst?.click();
	};

	return (
		<>
			{file !== null ? (
				<OkayButtonStyled onClick={inputHandler}>
					<SpanStyled>
						Extrato {number}{' '}
						<AiFillCheckCircle className="ml-1 has-text-success" />
					</SpanStyled>
					<PClickSuccessStyled>Escolher outro</PClickSuccessStyled>
				</OkayButtonStyled>
			) : (
				<ButtonStyled onClick={inputHandler}>
					<SpanStyled>Extrato {number}</SpanStyled>
					<PClickStyled>Escolher arquivo</PClickStyled>
				</ButtonStyled>
			)}
		</>
	);
};
