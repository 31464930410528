import { sha256 } from 'js-sha256';

export const hashEmailSha256 = (email: string) => {
	const clearedEmail = email?.toLowerCase()?.replace(' ', '');

	if (clearedEmail) {
		const emailHash = sha256(clearedEmail);
		return emailHash;
	}

	return '';
};
