import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

interface IDiv {
	isHidden?: boolean;
}

export const MainStyled = styled.main`
	min-height: 100vh;
	min-width: 100vh;
	padding-bottom: 0.5em;
`;

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 0.7rem;
`;

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const CenterDivStyled = styled.div<IDiv>`
	display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 3rem;
`;

export const CenteredDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: left;
	margin-top: 1.5rem;
`;

export const IMGStyled = styled.img`
	margin: 0 0 2rem;
	width: 150px;
`;

export const ArrowDivStyled = styled.div`
	color: grey;
	text-align: left;
	font-weight: 601;
	margin: 1rem 2rem auto;
	cursor: pointer;
	padding: 0 1.3rem;
	font-size: 24px;
`;

export const PStyled = styled.p`
	font-size: 16px;
	margin: 0.5rem 0;
	font-weight: 500;
	text-align: center;
`;

export const SpanStyled = styled.span`
	color: ${colors.textBlue};
	text-align: center;
	font-weight: 601;
	font-size: 13px;
	cursor: pointer;
`;

export const PAlertStyled = styled.p`
	font-size: 12px;
	margin: 0.5rem 0;
	font-weight: 400;
	text-align: left;
`;

export const OptSelectStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 300px;
	padding: 0.9rem 1px 0 1px;
	border-radius: 5px;
	border: 1px solid rgba(10, 10, 10, 0.1);
	cursor: pointer;

	@media (max-width: 700px) {
		width: 200px;
	}

	@media (max-width: 450px) {
		width: 160px;
	}
`;

export const PdocStyled = styled.p`
	text-align: center;
	font-weight: 400;
	padding: 0.5rem 0;
`;

export const LabelBoldPStyled = styled.span`
	font-size: 13px;
	margin: 0.4rem 0;
	font-weight: 501;
	text-align: left;
`;

export const LabelBluePStyled = styled.span`
	font-size: 13px;
	margin: 0.4rem 0;
	font-weight: 501;
	text-align: left;
	color: ${colors.primaryBlue};
`;

export const MarginsDivStyled = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 500px;

	@media (max-width: 1000px) {
		width: 330px;
	}

	@media (max-width: 600px) {
		width: 300px;
	}
`;

export const SelectStyled = styled.select`
	border: 1px solid rgba(10, 10, 10, 0.1);
	border-radius: 4px;
	padding: 14px 20px;
	font-size: 0.85rem;
	width: 100%;
	background-color: white;
	margin-bottom: 10px;
`;

export const ErrorStyled = styled.p`
	color: #e83f5b;
	font-size: 0.8rem;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	margin: 7px 0;
`;

export const InputsStyled = styled.div`
	margin-top: 0.6rem;
	margin-bottom: 2rem;
`;

export const ClickStyled = styled.p`
	text-align: center;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.textBlue};
	margin-top: 1rem;
`;

export const ModalStyled = styled.div`
	display: flex;
	padding: 3rem 0 0 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
`;

export const AlignStyled = styled.div`
	display: flex;
	margin: 1rem 0 0 0;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	text-align: left;
	width: 300px;
`;
