import Loader from 'react-loader-spinner';

import { DivStyled } from './styles';

import { colors } from '../../styles/colors';

export const VirtusLoader = () => {
	return (
		<DivStyled data-testid="loader">
			<Loader type="Grid" color={colors.primary} height={80} width={80} />
		</DivStyled>
	);
};
