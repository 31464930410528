import OrderThanksRepository from '../repositories/orderThanksRepository';

class OrderThanksController {
	repository: OrderThanksRepository;

	constructor() {
		this.repository = new OrderThanksRepository();
	}

	async checkKyc(transaction: string) {
		const result = await this.repository.checkKyc(transaction);

		if (result.data.kyc_type === 'acesso') {
			return { result, redirect: `/taker/order/${transaction}/formalizacao` };
		}

		return { result: result.data, redirect: null };
	}

	async checkWhatsapp(transaction: string) {
		const result = await this.repository.checkWhatsapp(transaction);

		return result.data;
	}
}

export default OrderThanksController;
