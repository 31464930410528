import { moneyMask } from '../../../shared/utils/masks';
import { InstallmentButton } from '../components/InstallmentButton/InstallmentButton';

import { SelectedInstallmentProps } from '../interfaces';

interface RenderInstallmentsProps {
	installments: [];
	cet: string;
	defaultInstallment: string;
	selectedInstallment: SelectedInstallmentProps | null;
	setSelectedInstallment: (installment: any) => void;
	hasGracePeriodGt0?: boolean;
	totalAmount: string;
}

const RenderInstallments = ({
	installments,
	defaultInstallment,
	cet,
	selectedInstallment,
	setSelectedInstallment,
	hasGracePeriodGt0,
	totalAmount,
}: RenderInstallmentsProps) => {
	const gracePeriod30days = new Date();
	gracePeriod30days.setDate(gracePeriod30days.getDate() + 30);

	return installments?.length ? (
		<>
			<div className="column is-12">
				<div className="columns is-flex is-flex-direction-row is-justify-content-space-around mt-1 mb-3 is-12 is-multiline">
					{installments.map((installment: any) => (
						<InstallmentButton
							active={selectedInstallment?.parcelas === installment.parcelas}
							onSelect={() => setSelectedInstallment(installment)}
							key={installment.parcelas}
							installments={installment.parcelas}
							entrada={installment.entrada}
							restante={installment.qtd_restante}
							valorRestante={installment.restante}
							total={installment.total}
							defaultInstallment={installment.parcelas === defaultInstallment}
						/>
					))}
				</div>
			</div>
			{selectedInstallment && (
				<div className="my-3 has-text-centered">
					{selectedInstallment.parcelas !== 1 && (
						<div>
							<h6 className="my-2 has-text-centered is-size-5">
								Entrada de{' '}
								<span className="has-text-blue has-text-weight-bold">
									{moneyMask(selectedInstallment.entrada)}
								</span>
								<br />
							</h6>

							<h6 className="my-2 has-text-centered is-size-5">
								mais {selectedInstallment.qtd_restante}x de{' '}
								<span className="has-text-blue has-text-weight-bold">
									{moneyMask(selectedInstallment.restante)}
								</span>
							</h6>
						</div>
					)}

					{hasGracePeriodGt0 ? (
						<>
							<p className="is-size-6 my-2">
								Total a pagar até vencimento{' '}
								{gracePeriod30days.toLocaleString('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								})}{' '}
								<strong>{moneyMask(totalAmount)}</strong>
							</p>
							<p className="is-size-6 my-2">
								Total a pagar depois do vencimento:{' '}
								<strong>{moneyMask(selectedInstallment.total)}</strong>
							</p>
						</>
					) : (
						<p className="is-size-6 my-2">
							Total a pagar:{' '}
							<strong>{moneyMask(selectedInstallment.total)}</strong>
						</p>
					)}

					{selectedInstallment.parcelas !== 1 && (
						<p className="is-size-7 my-3 my-2">
							Juros de {selectedInstallment.juros} a.m. CET de {cet}
						</p>
					)}
				</div>
			)}
		</>
	) : null;
};

export default RenderInstallments;
