export const redirectReturnUrlVtex = (
	returnUrl: null,
	channel: string,
	transaction: string
) => {
	const openedReturnUrl = localStorage.getItem(
		`${transaction}-return-url-opened`
	);

	if (returnUrl && channel === 'app_vtex' && !openedReturnUrl) {
		localStorage.setItem(`${transaction}-return-url-opened`, channel);

		window.open(window.location.href);
		window.location.href = returnUrl;
	}
};
