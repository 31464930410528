import axios from '../../../shared/http/customAxios';

import { PayloadStepOne } from '../SignUpStepOne';
import { PayloadStepTwo } from '../SignUpStepTwo';
import { FormData } from '../SignUpStepThree';
import dto from '../models/signupModel';

class SignupRepository {
	async submitStepOne(payload: PayloadStepOne) {
		try {
			const { captcha, rcptch_version, ...data } = payload;
			const result = await axios().post('/user/register', data, {
				headers: {
					'X-Firebase-AppCheck': captcha,
					'X-Recaptcha-Version': rcptch_version
				},
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submitStepTwo(payload: PayloadStepTwo) {
		try {
			const result = await axios().post('/user/register/2', payload);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async tokenRedefinePasswordRepository(token: string) {
		try {
			const result = await axios().get(`/user/register/verify?token=${token}`, {
				headers: { noAuthorization: true },
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submitAddress(payload: FormData) {
		try {
			const result = await axios().post('/user/register/address', {
				address: payload,
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submitOffers(dto: dto) {
		try {
			const result = await axios().post('/user/register/offers', dto);

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async preRegister(token: string) {
		try {
			const result = await axios().get(`/user/pre-register?token=${token}`, {
				headers: { noAuthorization: true },
			});
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default SignupRepository;
