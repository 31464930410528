import styled from 'styled-components';

export const ContainerStyled = styled.div`
	display: flex;
	border-bottom: 1px solid #28c0cd;
	margin: 0;
	padding: 0;
	flex-direction: column;
	align-items: initial;
	justify-content: center;
	overflow-x: hidden;
`;

export const ContainerLogoVirtusPayPartner = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin: 2rem 1rem;
`;

export const ImgLogoPartner = styled.img`
	max-width: 200px;
	max-height: 100px;
`;

export const ImgLogoVirtusPay = styled.img`
	max-width: 250px;
	max-height: 125px;
`;
