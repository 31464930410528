import { ElkService } from '../../../shared/service/elkService';

import { hashCpfMd5, hashEmailMd5 } from '../../../shared/utils/hashes';

const service = new ElkService();

export const elkBankingBilletConsult = (context: {
	email: string;
	cpf: string;
	consultType: string;
}) => {
	const extras = {
		etapa: 'banking-billet-consult',
		email_hash: hashEmailMd5(context.email),
		cpf_hash: hashCpfMd5(context.cpf),
		consult_type: context.consultType,
		plataforma: 'web-app',
	};

	service.send('INFO', 'Consulta Boleto', extras);
};

export const elkClickButtonLinkFinanzero = (transaction: string) => {
	const extras = {
		etapa: 'click-finanzero-refused',
		plataforma: 'web-app',
		transaction,
	};

	service.send('INFO', 'Clique botão Finanzero Recusa', extras);
};
