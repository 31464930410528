import { PayloadStepOne } from '../SignUpStepOne';
import { PayloadStepTwo } from '../SignUpStepTwo';

import Swal from 'sweetalert2';

import Dto from '../models/signupModel';
import SignupRepository from '../repositories/signupRepository';

import AuthController from '../../../shared/controllers/authController';

import { dataLayerPush } from '../../../shared/utils/dataLayer/dataLayerPush';
import { dataLayerUserData } from '../../../shared/utils/dataLayer/dataLayerUserData';

class SignUpController {
	dto: Dto;
	repository: SignupRepository;
	authController: AuthController;

	constructor() {
		this.dto = new Dto();
		this.repository = new SignupRepository();
		this.authController = new AuthController();
	}

	async submitStepOne(payload: PayloadStepOne) {
		const result = await this.repository.submitStepOne(payload);

		if (result.status !== 200) return { result, error: true };

		if (payload.version === 'v1') {
			this.authController.username = payload.email;
			this.authController.password = payload.password;

			const user = result.data;

			dataLayerUserData(user);
			dataLayerPush({
				event: 'conversion',
				data: {
					name: 'registration_initial',
				},
			});

			await this.authController.login(false, true);
		}

		return { result: result.data, error: false };
	}

	async submitStepTwo(payload: PayloadStepTwo) {
		const result = await this.repository.submitStepTwo(payload);

		if (result.status !== 200) return { result, error: true };

		const user = result.data;

		dataLayerUserData(user);
		dataLayerPush({
			event: 'conversion',
			data: {
				name: 'registration_complete',
			},
		});

		return { result: result.data, error: false };
	}

	async submitStepOffers(celcoin = false) {
		if (celcoin) delete this.dto.cupom;

		const result = await this.repository.submitOffers(this.dto);

		if (result.status !== 200) {
			return Swal.fire({
				icon: 'error',
				text:
					result.data.message ??
					'Erro enviar os dados. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			});
		}

		return result;
	}

	async tokenSignUpStepTwoController(token: string) {
		const result = await this.repository.tokenRedefinePasswordRepository(token);

		if (result.status !== 200) return { error: true };

		return { result: result.data, error: false };
	}

	async preRegister(token: string) {
		const result = await this.repository.preRegister(token);

		if (result.status !== 200) return { result, error: true };

		return { result: result.data, error: false };
	}
}

export default SignUpController;
