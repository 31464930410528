import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import {
	TitleStyled,
	BlueTitleStyled,
	ContainerStyled,
	InstructionsDivStyled,
	IMGDivStyled,
	CenterDivStyled,
	CenterDivStyledTrackingStyled,
	PStyled,
	PClickStyled,
	CamDivStyled,
	ArrowStyled,
	ButtonDivStyled,
} from './styles';

import Canvas from './components/Canvas/canvas';

import KYCController from './controllers/kycController';

import OrderNavbar from '../order/components/OrderNavbar';

import { UnicoFrame, Button } from '../../shared/components/index';

import { modalErrorUnico } from './utils/errorUnico';

import { clearBase64 } from '../../shared/utils/clearBase64';
import eventPush from '../../shared/utils/eventPush';

import Logo from '../../assets/images/logo_virtuspay_azulgrad_200.png';
import Tracking from '../../assets/images/KYC/tracking-selfie.png';
import ExampleSelfie from '../../assets/images/KYC/example-selfie.png';

import { IParams, IResponseErrorUnico } from './types/types';
import { useLoading } from '../../hooks/loading';

declare const navigator: any;

export const KycSelfie = () => {
	const [selfieBase64, setSelfieBase64] = useState('');
	const [selfieEncrypted, setSelfieEncrypted] = useState('');
	const [showCamera, setShowCamera] = useState(false);

	// States que cuidarão do aparecimento das respetivas DIV's na tela
	const [isHiddenSelect, setIsHiddenSelect] = useState(false);
	const [isHiddenPreview, setIsHiddenPreview] = useState(true);
	const [isHiddenInstructions, setIsHiddenInstructions] = useState(false);

	const params: IParams = useParams();
	const { id } = params;

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const controller = new KYCController();

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		checkStep(); // eslint-disable-next-line
	}, []);

	const checkStep = async () => {
		const { result, error, redirect } = await controller.getSteps(id);

		setIsLoading(false);

		if (error) {
			return Swal.fire({
				icon: 'error',
				text: result.string, // No controller, esse é o result.data.message
				confirmButtonText: 'Fechar',
			});
		}

		if (redirect) {
			return history.push(redirect);
		}

		const { next_step } = result;

		if (next_step !== 'selfie') {
			return history.push(`/taker/order/${id}/formalizacao/${next_step}`);
		}
	};

	const handleShowCamera = () => {
		setIsHiddenSelect(true);
		setIsHiddenPreview(true);
		setIsHiddenInstructions(true);

		setShowCamera(true);
	};

	const showPreview = () => {
		setShowCamera(false);
		setIsHiddenPreview(false);
		setIsHiddenInstructions(false);
	};

	const showInstructions = () => {
		setShowCamera(false);
		setIsHiddenSelect(false);
		setIsHiddenInstructions(false);
		setIsHiddenPreview(true);
		setSelfieBase64('');
		setSelfieEncrypted('');
	};

	const handleSetSelfieBase64 = (base64: string, encrypted: string) => {
		setSelfieBase64(base64);
		setSelfieEncrypted(encrypted);

		showPreview();
	};

	const submitSelfie = async () => {
		controller.dto.image_base_64 = clearBase64(selfieBase64);
		controller.dto.image_encrypted = selfieEncrypted;

		setIsLoading(true);

		const { result, error, expiredProcess, redirect } =
			await controller.submitSelfie(id);

		if (expiredProcess.errorExpired) {
			Swal.fire({
				icon: 'error',
				text: expiredProcess.resultExpired.data.message,
				confirmButtonText: 'Refazer Processo',
			}).then(() => {
				history.push(expiredProcess.redirectExpired);
			});
		}

		if (error) {
			Swal.fire({
				icon: 'error',
				text: result.data.message ?? 'Erro ao enviar a selfie. Tente novamente em alguns minutos.',
				confirmButtonText: 'Fechar',
			}).then(() => {
				showInstructions();
				checkStep();
			});
		}

		if (redirect) return history.push(redirect);

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const handleErrorUnico = (error: IResponseErrorUnico, call: string) => {
		modalErrorUnico(error, call, () =>
			history.push(`/taker/order/${id}/formalizacao`)
		);
	};

	const camera = () => {
		if (showCamera) {
			const selfieCameraType = navigator.deviceMemory >= 3 ? 2 : 1;
			return (
				<div>
					<CamDivStyled>
						<ArrowStyled size={30} onClick={showInstructions} />
					</CamDivStyled>

					<UnicoFrame
						handleError={(error: IResponseErrorUnico, call: string) =>
							handleErrorUnico(error, call)
						}
						handlePicture={handleSetSelfieBase64}
						camera="selfie"
						type={selfieCameraType}
					/>
				</div>
			);
		}
	};

	return (
		<main>
			<div id="instructions" className={`${isHiddenInstructions && 'hidden'}`}>
				<OrderNavbar src={Logo} />
				<ContainerStyled>
					<InstructionsDivStyled id="select" isHidden={isHiddenSelect}>
						<CenterDivStyledTrackingStyled>
							<img width="300px" src={Tracking} alt="tracking" />
						</CenterDivStyledTrackingStyled>

						<TitleStyled>Tire uma selfie</TitleStyled>

						<IMGDivStyled>
							<img src={ExampleSelfie} alt="Maneira correta de tirar foto" />
						</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Não corte o seu rosto, ajuste a iluminação e cuidado com o
							foco
						</PStyled>

						<ButtonDivStyled>
							<Button
								textColor="#fff"
								className="has-gradient-blue"
								text="Tirar selfie"
								onClick={() => {
									eventPush('web_selfie_tirar_selfie', 'INFO', {
										ld: id,
									});
									handleShowCamera();
								}}
								width="300px"
							/>
						</ButtonDivStyled>
					</InstructionsDivStyled>

					<div id="preview" className={`${isHiddenPreview && 'hidden'}`}>
						<BlueTitleStyled>Ótimo, gostou da foto?</BlueTitleStyled>

						<IMGDivStyled>
							<Canvas base64={selfieBase64} />
						</IMGDivStyled>

						<PStyled>
							<b>Dica:</b>
							<br /> Verifique se sua foto está com boa iluminação e bem
							centralizada
						</PStyled>

						<CenterDivStyled>
							<Button
								className="has-gradient-blue step_selfie"
								textColor="#fff"
								text="Sim, enviar"
								onClick={() => {
									eventPush('web_selfie_conf_sim_enviar', 'INFO', {
										ld: id,
									});
									submitSelfie();
								}}
							/>
						</CenterDivStyled>

						<CenterDivStyled>
							<PClickStyled
								onClick={() => {
									eventPush('web_selfie_conf_tirar_outra', 'INFO', {
										ld: id,
									});
									handleShowCamera();
								}}
							>
								Quero tirar outra
							</PClickStyled>
						</CenterDivStyled>
					</div>
				</ContainerStyled>
			</div>

			{camera()}
		</main>
	);
};
