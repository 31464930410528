import axios from '../../../shared/http/customAxios';

import dto from '../models/tokenModel';

class TokenRepository {
	async send(phoneNumber: string, transaction: string, contactType: string) {
		try {
			await axios().post(
				'/v1/contacttoken',
				{
					contact_type: contactType,
					contact_desc: `+55${phoneNumber}`,
					transaction: transaction,
				},
				{
					headers: { noAuthorization: true },
				}
			);
		} catch (error: any) {
			return error.response;
		}
	}

	async check(transaction: string) {
		try {
			const result = await axios().get(`/v1/order/${transaction}/token`, {
				headers: { noAuthorization: true },
			});

			return result;
		} catch (error: any) {
			return error.response;
		}
	}

	async submit(dto: dto) {
		try {
			const result = await axios().post(
				`/v1/order/${dto.transaction}/token`,

				{
					token: dto.token,
					contact_type: dto.contactType,
				},
				{
					headers: { noAuthorization: true },
				}
			);
			return result;
		} catch (error: any) {
			return error.response;
		}
	}
}

export default TokenRepository;
