import { logEvent } from 'firebase/analytics';

import { v4 as uuidv4 } from 'uuid';

import { hashCpfMd5, hashEmailSha256 } from './hashes';

import { ElkService } from '../service/elkService';

import { firebaseEventPush } from '../../firebase';
import { config } from '../../config.json';

import packageJson from '../../../package.json';

const service = new ElkService();

interface osPlatarformI {
	firefox: string;
	samsungBrowser: string;
	opera: string;
	opr: string;
	trident: string;
	edge: string;
	edg: string;
	chrome: string;
	safari: string;
}

const sUsrAg = navigator.userAgent;
let sBrowser = '';

const osPlatarform: osPlatarformI = {
	firefox: 'Mozilla Firefox',
	samsungBrowser: 'Samsung Internet',
	opera: 'Opera',
	opr: 'Opera',
	trident: 'Microsoft Internet Explorer',
	edge: 'Microsoft Edge (Legacy)',
	edg: 'Microsoft Edge (Chromium)',
	chrome: 'Google Chrome',
	safari: 'Apple Safari',
};

const eventPush = async (event: string, action: string, data?: any) => {
	const userFromLocalStorage = localStorage.getItem('user');
	if (!userFromLocalStorage) return;

	const userFromLocalStorageJson = JSON.parse(userFromLocalStorage);

	Object.keys(osPlatarform).map((item) => {
		const sUsrLowerCase = sUsrAg.toLowerCase() as keyof osPlatarformI;

		if (sUsrLowerCase.indexOf(item) > -1 && !sBrowser) {
			sBrowser = osPlatarform[item as keyof osPlatarformI];
		}
	});

	const currentDate = new Date().toUTCString();

	const standard = {
		application: 'WebApp',
		environment: config.environment,
		systemTime: currentDate,
		device_id: uuidv4(),
		app_info_version: packageJson.version,
		os: sBrowser,
		email_hash: hashEmailSha256(userFromLocalStorageJson.email),
		cpf_hash: hashCpfMd5(userFromLocalStorageJson.cpf),
		is_negative: userFromLocalStorageJson.is_negative,
		state: userFromLocalStorageJson.address.state,
	};

	const payload = Object.assign({}, data, standard);

	logEvent(firebaseEventPush, event, payload);

	service.send(action, event, payload);
};

export default eventPush;
