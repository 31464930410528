import Swal from 'sweetalert2';

import { emptyValidation } from '../../../shared/utils/validators';
import { colors } from '../../../shared/styles/colors';
import BilletService from '../services/billetService';

const billetService = new BilletService();

class OrderSendingValidator {
	validateCategory(category: string) {
		const productInput: HTMLElement | null =
			document.querySelector('#category-input');

		if (!productInput) return false;

		if (!productInput || !emptyValidation(category)) {
			Swal.fire({
				text: 'Selecione uma categoria de produto',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			productInput.style.borderColor = colors.errorColor;

			return false;
		}

		productInput.style.borderColor = colors.successColor;

		return true;
	}

	validateOtherCategory(category: string) {
		const productInput: HTMLElement | null = document.querySelector(
			'#other-category-input'
		);

		if (!productInput) return false;

		if (!emptyValidation(category)) {
			Swal.fire({
				text: 'Digite uma categoria de produto',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			productInput.style.borderColor = colors.errorColor;

			return false;
		}
		productInput.style.borderColor = colors.successColor;

		return true;
	}

	validateReason(reason: string) {
		const reasonInput: HTMLElement | null = document.querySelector(
			'#installment-reason-input'
		);
		if (!reasonInput) return false;

		if (!emptyValidation(reason)) {
			Swal.fire({
				text: 'Selecione um motivo para o parcelamento',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			reasonInput.style.borderColor = colors.errorColor;

			return false;
		}

		reasonInput.style.borderColor = colors.successColor;

		return true;
	}

	validateBillet(amount: number | string, dueDate: string) {
		const billetInput: HTMLElement | null =
			document.querySelector('#billet-input');
		const dateInput: HTMLElement | null =
			document.querySelector('#due-date-input');
		const amountInput: HTMLElement | null =
			document.querySelector('#amount-input');

		if (!billetInput || !dateInput || !amountInput) return false;

		if (amount == null || dueDate == null) {
			Swal.fire({
				text: 'Boleto inválido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			billetInput.style.borderColor = colors.errorColor;

			return false;
		}

		if (amount < 150 || amount > 10000) {
			Swal.fire({
				text: 'O valor do boleto deve ser de no mínimo R$ 150,00 e no máximo R$ 10.000,00',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			amountInput.style.borderColor = colors.errorColor;
			billetInput.style.borderColor = colors.errorColor;

			return false;
		}

		if (!billetService.validDueDate(dueDate)) {
			Swal.fire({
				// text: 'O boleto não pode estar vencido ou ter vencimento para hoje.',
				text: 'O boleto não pode estar vencido.',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			billetInput.style.borderColor = colors.errorColor;
			dateInput.style.borderColor = colors.errorColor;

			return false;
		}

		billetInput.style.borderColor = colors.successColor;
		dateInput.style.borderColor = colors.successColor;
		amountInput.style.borderColor = colors.successColor;

		return true;
	}

	validateStore(store: string | any) {
		const reasonInput: HTMLElement | null = document.querySelector('#store');

		if (!reasonInput) return false;

		if (!emptyValidation(store)) {
			Swal.fire({
				text: 'Selecione uma loja aceita',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			reasonInput.style.borderColor = colors.errorColor;

			return false;
		}

		reasonInput.style.borderColor = colors.successColor;

		return true;
	}
}

export default OrderSendingValidator;
