import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../../shared/components/index';

import NoOffer from '../../../../assets/images/no-offer.png';

import {
	SendingMainStyled,
	CenterDivStyled,
	ThanksDivStyled,
	SendingGreyPStyled,
	SendingGreyTitleStyled,
} from '../../styles';

const PreApprovedNegative: FC = () => {
	const history = useHistory();

	return (
		<>
			<SendingMainStyled>
				<CenterDivStyled>
					<ThanksDivStyled>
						<img src={NoOffer} alt="Ícone sem ofertas" />

						<SendingGreyTitleStyled>
							Poxa, não temos uma oferta pra você no momento
						</SendingGreyTitleStyled>

						<SendingGreyPStyled>
							Não desanime, verifique todos seus dados e tente com um valor
							menor! 🤗
						</SendingGreyPStyled>

						<Button
							onClick={() => history.push('/taker')}
							className="has-gradient-blue"
							width="100%"
							textColor="#fff"
							text="Voltar"
						/>
					</ThanksDivStyled>
				</CenterDivStyled>
			</SendingMainStyled>
		</>
	);
};

export default PreApprovedNegative;
