import { useState, useEffect, useLayoutEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
	ContainerNoTileStyled,
	TitleStyled,
	MainStyled,
	ArrowDivStyled,
	OrdersDivStyled,
	OrderDivStyled,
} from './styles';

import {
	getBoolean,
	getRemoteConfig,
	fetchAndActivate,
} from 'firebase/remote-config';

import { AiOutlineArrowLeft } from 'react-icons/ai';

import { VirtusNavbar, Button } from '../../shared/components/index';

import OrderController from './controllers/orderController';

import { OrderTile } from './components/OrderTile/orderTile';
import { useLoading } from '../../hooks/loading';

export const OrderList = () => {
	const [tiles, setTiles] = useState('');
	const [isSendOrderActive, setIsSendOrderActive] = useState<boolean>(false);

	const history = useHistory();
	const { setIsLoading } = useLoading();

	const remoteConfig = getRemoteConfig();
	fetchAndActivate(remoteConfig);
	remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

	// eslint-disable-next-line
	useEffect(() => {
		fetchAndActivate(remoteConfig)
			.then(() => {
				const isSendOrderActiveValue = getBoolean(
					remoteConfig,
					'is_send_order_active'
				);
				setIsSendOrderActive(isSendOrderActiveValue);
			})
			.catch((err) => {
				console.log(err);
			});

		getOrders();
	}, []);

	useLayoutEffect(() => {
		setIsLoading(true);
	}, []);

	const controller = new OrderController();

	const getOrders = async () => {
		const orders = await controller.list();

		const orderTiles = orders.map((item: any) => {
			return (
				<OrderTile
					href={'/taker/order/' + item.transaction}
					transaction={item.transaction}
					data-steps={item.steps}
					data-signature={item.signature}
					key={item.transaction}
					createdAt={item.created_at}
					interest={item.interest}
					installments={item.installment}
					paidInstallments={item.paid_receivables}
					amount={item.total_amount}
					store={item.company_pop ?? item.company_name}
					status={item.get_status_for_taker}
					paymentError={item.is_allowed_change}
					description={item.description}
					orderRef={item.order_ref}
				/>
			);
		});

		setIsLoading(false);
		setTiles(orderTiles);
	};

	const renderOrderTiles = () => {
		if (tiles?.length < 1) {
			return (
				<ContainerNoTileStyled>
					<TitleStyled>Você ainda não tem nenhum parcelamento</TitleStyled>

					{isSendOrderActive ? (
						<Button
							text="Quero parcelar"
							className="has-gradient-blue"
							textColor="#fff"
							onClick={() => history.push('/taker/order/add')}
						/>
					) : null}
				</ContainerNoTileStyled>
			);
		}

		return tiles;
	};

	return (
		<MainStyled>
			<VirtusNavbar title="Minha conta" link="/user" />

			<ArrowDivStyled>
				<AiOutlineArrowLeft onClick={() => history.push('/taker')} />
			</ArrowDivStyled>
			<OrderDivStyled>
				<OrdersDivStyled>{renderOrderTiles()}</OrdersDivStyled>
			</OrderDivStyled>
		</MainStyled>
	);
};
