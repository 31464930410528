import Swal from 'sweetalert2';

import {
	validPassword,
	validBirthdate,
	validPhone,
	validSalary,
	validDocumentExtension,
	emptyValidation,
	validEmail,
	validNickname,
} from '../../../shared/utils/validators';

import { hashCpfMd5 } from '../../../shared/utils/hashes';

import { validCPF as isValidCPF } from '../../../shared/utils/validators';

import ValidatorController from '../../../shared/controllers/validatorController';

import { colors } from '../../../shared/styles/colors';

const validatorController = new ValidatorController();

export default class SignUpValidator {
	validateName = (name: string) => {
		const input: HTMLElement | null = document.querySelector('#name');
		const errorInput: HTMLElement | null = document.querySelector(
			'#name-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		const regexName = /^([a-zA-ZÀ-ú]{1,}[ ])+([a-zA-ZÀ-ú]{1,})+$/;

		if (!regexName.test(name.trim())) {
			const message = 'Digite nome e sobrenome, números são proibidos!';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;

		errorInput.innerText = '';

		return true;
	};

	validateCV = (file: File) => {
		const input: HTMLElement | null = document.querySelector('#cv');

		if (!input) return;

		input.style.borderColor = colors.defaultGrey;

		if (!validDocumentExtension(file)) {
			input.style.borderColor = colors.errorColor;

			Swal.fire({
				text: 'Documento inválido',
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;

		return true;
	};

	validateEmailNormal = (email: string) => {
		const input: HTMLElement | null = document.querySelector('#email');
		const errorInput: HTMLElement | null = document.querySelector(
			'#email-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validEmail(email)) {
			const message = 'Digite um e-mail válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		errorInput.innerText = '';
		input.style.borderColor = colors.successColor;

		return true;
	};

	validateEmail = async (email: string) => {
		const input: HTMLElement | null = document.querySelector('#email');
		const errorInput: HTMLElement | null = document.querySelector(
			'#email-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		const result = await validatorController.validateEmail(email);

		const data = result.data;

		if (!data.valid) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				title: 'Dados incorretos',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Este e-mail é inválido.',
			});

			return false;
		} else if (data.has_user) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				title: 'Dados incorretos',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Este e-mail já foi cadastrado.',
			});
			return false;
		}

		errorInput.innerText = '';
		input.style.borderColor = colors.successColor;

		return true;
	};

	validatePassword = (password: string) => {
		const input: HTMLElement | null = document.querySelector('#password');
		const errorInput: HTMLElement | null = document.querySelector(
			'#password-error-message'
		);

		if (!input || !errorInput) return false;

		const { result, message } = validPassword(password);

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		if (!result) {
			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		return true;
	};

	validatePasswordConfirm = (confirm: string, password: string) => {
		const input: HTMLElement | null =
			document.querySelector('#repeat-password');
		const errorInput: HTMLElement | null = document.querySelector(
			'#password-confirm-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (confirm !== password) {
			const message = 'As senhas devem ser iguais';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		errorInput.innerText = '';

		return true;
	};

	validateBirthdate = (date: string) => {
		const input: HTMLElement | null = document.querySelector('#birthdate');
		const errorInput: HTMLElement | null = document.querySelector(
			'#birthdate-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validBirthdate(date)) {
			const message = 'Data de nascimento inválida';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateCPF = async (cpf: string) => {
		const input: HTMLElement | null = document.querySelector('#cpf');
		const errorInput: HTMLElement | null =
			document.querySelector('#cpf-error-message');

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!isValidCPF(cpf)) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				title: 'Dados incorretos',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Digite um CPF válido.',
			});

			return false;
		}

		const result = await validatorController.validateCPF(hashCpfMd5(cpf));

		const data = result.data;

		if (result.status !== 200) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				title: 'Dados incorretos',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Não conseguimos validar seu CPF, tente novamente.',
			});

			return false;
		}

		if (data.has_user) {
			input.style.borderColor = colors.errorColor;
			Swal.fire({
				title: 'Dados incorretos',
				icon: 'error',
				confirmButtonText: 'Fechar',
				text: 'Este CPF já foi cadastrado.',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validatePhone = (phone: string) => {
		const input: HTMLElement | null = document.querySelector('#phone');
		const errorInput: HTMLElement | null = document.querySelector(
			'#phone-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validPhone(phone)) {
			const message = 'Digite um celular válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateSalary = (salary: string) => {
		const input: HTMLElement | null = document.querySelector('#salary');
		const errorInput: HTMLElement | null = document.querySelector(
			'#salary-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validSalary(salary.toString())) {
			const message = 'A renda deve ser maior que R$ 1.000,00 mensais';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateCity = (city: string) => {
		const input: HTMLElement | null = document.querySelector('#city');
		const errorInput: HTMLElement | null = document.querySelector(
			'#city-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(city)) {
			const message = 'Digite a cidade';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateCEP = (cep: string) => {
		const input: HTMLElement | null = document.querySelector('#cep');
		const errorInput: HTMLElement | null =
			document.querySelector('#cep-error-message');

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (cep.replace(/[^\d]+/g, '').length < 8) {
			const message = 'Digite um CEP válido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateStreet = (cep: string) => {
		const input: HTMLElement | null = document.querySelector('#street');
		const errorInput: HTMLElement | null = document.querySelector(
			'#street-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(cep)) {
			const message = 'Digite o logradouro';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateNeighborhood = (neighborhood: string) => {
		const input: HTMLElement | null = document.querySelector('#neighborhood');
		const errorInput: HTMLElement | null = document.querySelector(
			'#neighborhood-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(neighborhood)) {
			const message = 'Digite o bairro';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateNumber = (number: string) => {
		const input: HTMLElement | null = document.querySelector('#number');
		const errorInput: HTMLElement | null = document.querySelector(
			'#number-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(number)) {
			const message = 'Digite o número da residência';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateUF = (uf: string) => {
		const input: HTMLElement | null = document.querySelector('#uf');
		const errorInput: HTMLElement | null =
			document.querySelector('#uf-error-message');

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!emptyValidation(uf)) {
			const message = 'Selecione o estado';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});

			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};

	validateNickname = (name: string) => {
		const input: HTMLElement | null = document.querySelector('#nickname');
		const errorInput: HTMLElement | null = document.querySelector(
			'#nickname-error-message'
		);

		if (!input || !errorInput) return false;

		input.style.borderColor = colors.defaultGrey;

		if (!validNickname(name)) {
			const message = 'Digite um apelido';

			input.style.borderColor = colors.errorColor;
			errorInput.innerText = message;

			Swal.fire({
				text: message,
				icon: 'error',
				confirmButtonText: 'Entendi',
			});
			return false;
		}

		input.style.borderColor = colors.successColor;
		errorInput.innerText = '';

		return true;
	};
}
