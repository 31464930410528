const redirect = function (redirect: string, transaction: string) {
	switch (redirect) {
		case 'TakerOrderCheckout':
			return { redirectPage: `/taker/order/${transaction}/accept` };
		case 'TakerOrderTokenValidation':
			return { redirectPage: `/taker/order/${transaction}/token` };
		case 'TakerOrderThanks':
			return { redirectPage: `/taker/order/${transaction}/thanks` };
		case 'TakerOrderSignature':
			return { redirectPage: `/taker/order/${transaction}/signature` };
		case 'TakerOrderRefused':
			return { redirectPage: `/taker/order/${transaction}/refused` };
		case 'TakerOrderApproved':
			return { redirectPage: `/taker/order/${transaction}/approved` };
		default:
			return { redirectPage: `/taker/order/${transaction}/formalizacao` };
	}
};

export default redirect;
