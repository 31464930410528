import GIF from '../../../../assets/images/check.gif';
import {
	BlueTitleStyled,
	CenterDivStyled,
	ContainerStyled,
	H1Styled,
} from '../../styles';
import Logo from '../../../../assets/images/logo_virtuspay_azulgrad_200.png';
import { useAuth } from '../../../../hooks/auth/auth';

interface TokenI {
	title: string;
	isSignUpStepOne: boolean;
	button?: React.ReactElement;
}

export const EmailToken = ({ title, isSignUpStepOne, button }: TokenI) => {
	const { user } = useAuth();

	return (
		<ContainerStyled>
			<CenterDivStyled>
				<img src={Logo} style={{ marginTop: '2rem' }} alt="Logo VirtusPay" />

				<img src={GIF} height={300} width={300} />

				<BlueTitleStyled>{title}</BlueTitleStyled>

				{isSignUpStepOne ? (
					<>
						<H1Styled>
							Para confirmar o seu cadastro, acesse o seu e-mail. <br />
							<strong>{user.email}</strong>
						</H1Styled>
						<H1Styled>
							Enviamos um e-mail com as instruções e um link para você confirmar
							o seu cadastro.
						</H1Styled>
						<H1Styled>
							<strong>Lembre-se de olhar a caixa de spam.</strong>
						</H1Styled>
					</>
				) : (
					<>
						<H1Styled>
							Você já pode usar esse email e senha para acessar sua conta aqui
							no site ou no App VirtusPay.
						</H1Styled>
						<H1Styled>
							Clique em continuar para preencher as informações que serão
							necessárias para análise de crédito dos seus parcelamentos
						</H1Styled>
					</>
				)}

				{button}
			</CenterDivStyled>
		</ContainerStyled>
	);
};
