import styled from 'styled-components';

import { colors } from '../../shared/styles/colors';

export const MainStyled = styled.main`
	background: #f5f5f5;
	min-height: 100vh;
	padding-bottom: 5.5em;
`;

export const CenterDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: start;
`;

export const CouponsDivStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	text-align: left;
	width: 38%;

	@media (max-width: 2200px) {
		width: 40%;
	}

	@media (max-width: 1900px) {
		width: 50%;
	}

	@media (max-width: 1550px) {
		width: 60%;
	}

	@media (max-width: 1100px) {
		width: 75%;
	}

	@media (max-width: 1020px) {
		width: 95%;
	}

	@media (max-width: 700px) {
		width: 100%;
	}
`;

export const ContainerNoTileStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 10vh;
`;

export const TitleStyled = styled.h1`
	color: ${colors.primaryBlue};
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 1rem;
`;

export const ArrowDivStyled = styled.div`
	color: grey;
	text-align: left;
	font-weight: 601;
	margin: 1rem 2rem;
	cursor: pointer;
	padding: 0 1.3rem;
	font-size: 24px;
`;

export const ContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
	border-radius: 14px;
	margin: 2px;

	@media (max-width: 710px) {
		align-items: center;
	}
`;

export const GapDivStyled = styled.div`
	background-color: rgba(10, 10, 10, 0.2);
`;

export const GapMobileDivStyled = styled.div`
	display: none;
	background-color: rgba(10, 10, 10, 0.2);

	@media (max-width: 700px) {
		display: flex;
	}
`;

export const CupomStyled = styled.img`
	width: 24px;
	height: 24px;
	margin-left: 32%;

	@media (max-width: 1900px) {
		margin-left: 26%;
	}

	@media (max-width: 1550px) {
		margin-left: 22%;
	}

	@media (max-width: 1100px) {
		margin-left: 15%;
	}

	@media (max-width: 1020px) {
		margin-left: 5%;
	}

	@media (max-width: 710px) {
		margin-top: 1.5rem;
		margin-left: 14%;
	}

	@media (max-width: 500px) {
		margin-top: 1.8rem;
	}

	@media (max-width: 300px) {
		margin-top: 2rem;
	}
`;

export const SmallPStyled = styled.p`
	flex: 40%;
	margin-top: 0.3rem;
	text-align: left;
	font-size: 20px;
	color: #000000;
	opacity: 1;

	@media (max-width: 710px) {
		margin-top: 0.8rem;
		font-size: 17px;
		text-align: center;
	}
`;

export const TitleCouponStyled = styled.p`
	flex: 40%;
	margin-top: 0.3rem;
	text-align: left;
	font-size: 20px;
	color: #000000;
	opacity: 1;

	@media (max-width: 710px) {
		margin-top: 1.8rem;
		font-size: 17px;
		text-align: center;
	}
`;

export const SmallRulesPStyled = styled.p`
	flex: 40%;
	font-size: 15px;
	margin-top: 0.3rem;
	text-align: left;
	color: #707070;

	@media (max-width: 710px) {
		font-size: 12px;
		text-align: left;
		line-height: 1rem;
		margin: 0.4rem 1rem;
	}

	@media (max-width: 500px) {
		margin: 0.4rem 0.5rem;
	}
`;

export const RowDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: auto;
	padding: 0 0.5rem;
`;

export const RowRulesDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: auto;
`;

export const DesktopDivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
`;

export const Desktop2DivStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const PendenciasDivStyled = styled.div`
	cursor: pointer;
	text-align: center;
	font-size: 12px;
	margin-right: 0.5rem;

	@media (max-width: 710px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0.5rem 0;
	} ;
`;

export const CouponTitleStyled = styled.h2`
	text-align: center;
	font: normal normal bold 25px/30px Montserrat;
	letter-spacing: 0px;
	margin-left: 1rem;
	color: ${colors.primary};
	opacity: 1;
	font-size: 24px;

	@media (max-width: 710px) {
		margin: auto;
	}
`;

export const CouponRulesStyled = styled.h2`
	text-align: center;
	letter-spacing: 0px;
	margin: 0 3rem;
	color: ${colors.primary};
	opacity: 1;
	font-size: 16px;
	font-weight: 501;
	cursor: pointer;

	@media (max-width: 710px) {
		margin: 0.4rem 1rem;
		font-size: 12px;
	}

	@media (max-width: 500px) {
		margin: 0.4rem 0.5rem;
	}
`;

export const OrangeButtonStyled = styled.button`
	background-color: #ff9900;
	width: 150px;
	padding: 0.6rem 0.2rem;
	margin-bottom: 0.4rem;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	border-radius: 5px;
`;

export const TileDivStyled = styled.div`
	border-radius: 20px;
	background-color: #fff;
	padding: 20px 10px;
	width: 100%;

	@media (max-width: 710px) {
		display: none;
	}
`;

export const MobileCouponDivStyled = styled.div`
	display: none;
	border-radius: 20px;
	flex-direction: column;
	background-color: #fff;
	padding: 2rem 1rem;
	width: 80%;

	@media (max-width: 710px) {
		display: flex;
	}
`;
